import {useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { ID, KTSVG } from '../../../../_metronic/helpers'
import { getAllPaymentLogs } from '../../../../network/api'
import Header from '../../../../_metronic/shared-components/Header'
import { PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, setAuthPromise, useAuth } from '../../auth'
import { axiosPrivate } from '../../../../axiosInstance'
import { PiBankBold, PiExportBold } from 'react-icons/pi'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'

type Props = {
  id: ID
  email: string
  role: string
  userclient_id: number
  role_id: number
}


interface ILoginUser {
  id: number
  role: number
  email: string
  uuid: string
}
const PaymentLogs = () => {
  const [userList, setUserList] = useState<[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [visibleData, setVisibleData] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const { currentUser } = useAuth();
  
  const getAllUserList = async () => {
    setLoading(true)
    try {
      const data = await getAllPaymentLogs();
      setUserList(data)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getAllUserList()
  }, [])

  useEffect(() => {
    const filteredSearch = search.length !== 0
    ? userList.filter((item: any) => item.account_holder.first_name?.toLowerCase().includes(search.toLowerCase()) ||
      item.account_holder.last_name?.toLowerCase().includes(search.toLowerCase()) ||
      item.account_holder.email?.toLowerCase().includes(search.toLowerCase())) : userList;
    setVisibleData(filteredSearch)
  }, [userList, search])
  
  const loginUser = async ({id, role, email, uuid}: ILoginUser) => {
    const currentAuth = await getAuthPromise()
    const {data: auth} = await axiosPrivate.post('/auth/login_user_by_admin', {email})
  
    if (auth.api_token && currentAuth) {
      currentAuth.email = currentUser?.email
      currentAuth.first_name = currentUser?.firstName || ''
      currentAuth.last_name = currentUser?.lastName || ''

      const promises = [
        setAuthPromise(currentAuth, PREV_AUTH_LOCAL_STORAGE_KEY),
        setAuthPromise(auth)
      ]

      Promise.all(promises)
        .then(async () => {
          // const admin_body = {
          //   first_name: currentUser?.userclient.first_name || '',
          //   last_name: currentUser?.userclient.last_name || '',
          //   email: currentUser?.email,
          //   loginuser_id: id,
          //   userclient_id: 47821104,
          //   role_id,
          //   action: 'signed-in',
          // }
          // return await axiosPrivate.post('/add_admin_logs', admin_body)
        })
        .then(() => {
          window.location.href = '/';
        })
        .catch((error) => {
          console.error("Error setting auth:", error);
        });
    }
  }
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        Cell: (props: any) => {
          const { first_name, last_name} = props.row.original.account_holder
          return `${first_name} ${last_name}`
        }
      },
      {
        Header: 'Email',
        accessor: 'account_holder.email'
      },
      {
        Header: 'Program',
        Cell: (props: any) => {
          const { selected_program, selected_session } = props.row.original.program;
          const { programData, name } = selected_program
          const {session_name, start_date, end_date} = selected_session;
          const formatStart = moment().utc(start_date).format('MM/DD/YYYY');
          const formatEnd = moment().utc(end_date).format('MM/DD/YYYY');
          return `${programData.campus}: ${name} ${session_name} (${formatStart} - ${formatEnd})`
        }
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Timestamp',
        accessor: 'created_at',
        Cell: (props: any) => {
          const { created_at } = props.row.original;
          const createTimeStamp = moment(created_at).format('MM/DD/YYYY h:mm a');
          return createTimeStamp
        }
      }
    ],
    []
  )
  return (
    <>
      <div className='card card-body p-0'>
        <div className="d-flex align-items-center justify-content-between pe-9">
          <div className='d-flex align-items-center'>
            <Header
              setSearch={setSearch}
              search={search}
              placeholder='Search'
            />
          </div>
          <div className='mt-5'>
            
          </div>
          <div className='d-flex mt-5 justify-content-end'>
            </div>
        </div>
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading}/>
        
      </div>
    </>
  )
}

export default PaymentLogs
