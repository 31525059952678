/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAuth } from '../core/Auth';
import { axiosInstance } from '../../../../axiosInstance';
import { TbCircleArrowLeftFilled } from 'react-icons/tb';
import './Login.css';
import useAuthRequests from '../hooks/useAuthRequests';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(false);
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const {getCurrentUser} = useAuthRequests()
  const [status, setStatus] = useState('');
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 992);
    };

    handleResize(); // Check initial width
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigationHandler = () => {
    navigate('/');
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const { data }: any = await axiosInstance.post('/login', values);
      console.log(data);
  
      if (data.api_token) {
        saveAuth(data);
  
        try {
          const {currentUser} = await getCurrentUser();
          console.log(currentUser, 'currentUser');
          setCurrentUser(currentUser);
          navigate(`/auth`);
        } catch (authError) {
          setStatus('The login detail is incorrect')
          console.error('Authentication error:', authError);
          setLoading(false);
        }
      }
    } catch (error: any) {
      console.error('Login error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setStatus('The password you entered is incorrect. Please try again. If you have forgotten your password, you can reset it using the “Forgot Password” link.')
      }
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {

        return (
          <Form className="form" noValidate id="kt_login_signin_form">
            <div style={{ maxWidth: '460px', width: '100%', textAlign: 'center'}}>
              <h1 className="text-dark mb-1"style={{ fontSize: '24px', fontWeight: 'bold' }}>
                Welcome back!
              </h1>
              <p className="text-dark mb-3"style={{ fontSize: '16px', marginTop: '10px' }}>
                Please enter your credentials to access your Summer Springboard account and explore exciting learning opportunities and resources.
              </p>
            </div>
            {status.length > 0 && (
            <div className="mb-lg-15 alert alert-danger" style={{ maxWidth: '400px', width: '100%',margin: '0 auto' }}>
              <div className="alert-text font-weight-bold">{status}</div>
            </div>
            )}
            <div className="fv-row mb-10">
              <label style={{ fontSize: '20px' }} className="form-label fw-bolder text-dark">Email</label>
              <Field
                name="email"
                type="email"
                placeholder="Email"
                className='form-control form-control-lg form-control-solid'
              />
              <ErrorMessage name="email" component="div" className="fv-plugins-message-container text-danger" />
            </div>
            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex justify-content-between w-100 mb-2">
                  <label style={{ fontSize: '20px' }} className="form-label fw-bolder text-dark mb-0">Password</label>
                  <Link  to="/auth/forgot-password" className="link-primary fw-bolder" style={{ marginLeft: '5px', fontSize: '20px' }}>
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <Field
                name="password"
                type="password"
                placeholder="Password"
                className='form-control form-control-lg form-control-solidv'
              />
              <ErrorMessage name="password" component="div" className="fv-plugins-message-container text-danger" />
            </div>
            <div className="text-center">
              <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" disabled={isSubmitting}>
                <span className="indicator-label">Continue</span>
                {loading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
            <div className="goback-btn" onClick={navigationHandler}>
              <TbCircleArrowLeftFilled className="icon" size={window.innerWidth <= 300 ? 20 : 30} />
              <div>{isMobileView ? 'Go back' : 'Go back to account registration page'}</div>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
}
