import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Field, Form, Formik, useField} from 'formik'
import {EnrollmentGroupSchema} from './Schemas/ValidationSchema'
import {IEnrollmentFormGroup} from './EnrollmentList'
import {AddOrEditEnrollmentFormGroup, fetchEnrollmentFormGroupById, getDocumentAPI} from '../../../../network/api'
import CustomInput from '../../../../_metronic/shared-components/CustomInput'
import CustomReactSelect from '../../../../_metronic/shared-components/CustomReactSelect'
import CustomCheckbox from '../../../../_metronic/shared-components/CustomCheckbox'
import CustomTextArea from '../../../../_metronic/shared-components/CustomTextArea'
import DatePickerField from '../../Shared/components/DatePickerField'
import {axiosPrivate} from '../../../../axiosInstance'
import Select from 'react-select'
import ReactQuill from 'react-quill'


const SalesforceAPIStatus =[
  {
    value: 'SSB_Extra_Activity__c',
    label: 'Authorized pickups: Status',
  },
  {
    value: 'SSB_Medical_Consent_Student_Name__c',
    label: 'Authorized Persons to Pickup',
  },
  {
    value: 'SSB_Medical_Consent_Student_Signature__c',
    label: 'SSB General Form 1 Status',
  },
  {
    value: 'SSB_Medical_Consent_Complete__c',
    label: 'SSB General Form 2 Status',
  },
  {
    value: 'SSB_Waiver_Student_Initial__c',
    label: 'Campus/Course Form 1 Status',
  },
  {
    value: 'SSB_Waiver_Student_Name__c',
    label: 'Campus/Course Form 2 Status',
  },
  {
    value: 'SSB_Waiver_Student_Phone__c',
    label: 'Campus/Course Form 3 Status',
  },
  {
    value: 'SSB_Current_Gpa__c',
    label: 'Campus/Course Form 4 Status',
  },
  {
    value: 'PDC_Flight_Itinerary_Status__c',
    label: 'Flight Form Status'
  },
  {
    value: 'Immunization_Record_Received__c',
    label: 'Immunization Form Status',
  },
  {
    value: 'SSB_Medical_Consent_Parent_Signature__c',
    label: 'Insurance card Form Status',
  },
  {
    value: 'SSB_Covid_Consent_Parent_Signature__c',
    label: 'SSB Elective Choose 1',
  },
  {
    value: 'SSB_Waiver_Complete__c',
    label: 'SSB Elective Choose 2',
  },
  {
    value: 'SSB_Waiver_Parent_Phone__c',
    label: 'SSB Elective Choose 3',
  },
]

const SalesforceAPIDate =[
  {
    value: 'SSB_Challenge__c',
    label: 'Authorized pickups: Date of Submission',
  },
  {
    value: 'SSB_Medical_Consent_Student_Name__c',
    label: 'Authorized Persons to Pickup',
  },
  {
    value: 'SSB_Medical_Consent_Date__c',
    label: 'SSB General Form 1 Submission Date',
  },
  {
    value: 'SSB_Waiver_Parent_Initial__c',
    label: 'SSB General Form 2 Submission Date',
  },
  {
    value: 'SSB_Waiver_Student_Signature__c',
    label: 'Campus/Course Form 1 Submission Date',
  },
  {
    value: 'SSB_Waiver_Student_Date__c',
    label: 'Campus/Course Form 2 Submission Date',
  },
  {
    value: 'SSB_Waiver_Parent_Signature__c',
    label: 'Campus/Course Form 3 Submission Date',
  },
  {
    value: 'SSB_Covid_Consent_Complete__c',
    label: 'Campus/Course Form 4 Submission Date',
  },
  {
    value: 'SSB_Covid_Consent_Date__c',
    label: 'Flight Form Date of Submission'
  },
  {
    value: 'SSB_Covid_Consent_Parent_Name__c',
    label: 'Immunization Form Submission Date',
  },
  {
    value: 'SSB_Waiver_Parent_Relationship__c',
    label: 'Insurance card Form Submission Date'
  },
  {
    value: 'SSB_Covid_Consent_Parent_Signature__c',
    label: 'SSB Elective Choose 1',
  },
  {
    value: 'SSB_Waiver_Complete__c',
    label: 'SSB Elective Choose 2',
  },
  {
    value: 'SSB_Waiver_Parent_Phone__c',
    label: 'SSB Elective Choose 3',
  },
]

const type = [
  {
    value: 'pre-departure',
    label: 'Pre-Departure',
  },
  {
    value: 'form',
    label: 'Enrollment Forms',
  },
]

const task_title = [
  {
    value: 'waiver',
    label: 'Waiver',
  },
  {
    value: 'upload',
    label: 'Upload',
  },
  {
    value: 'form',
    label: 'Form',
  },
]

  const task_type = [
    {
      value: 'upload-template',
      label: 'UPLOAD TEMPLATE',
    },
    {
      value: 'third-party-link',
      label: '3RD PARTY LINK',
    },
  ]

  const task_type_alone = [
    {
      value: 'hellosign',
      label: 'HELLOSIGN',
    },
  ]

interface SessionDetail {
  name: string
  division: string
  startDate: string
  endDate: string
  id: number
  type: string
  program_track: any[]
}
interface Program {
  groupName: string
  session: SessionDetail[]
}

interface HelloSignDList {
  documentId: number
  templateTitle: string
}

const EnrollmentEdit = () => {
  const [formData, setFormData] = useState({} as IEnrollmentFormGroup)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {enrollment_form_group_id, client} = useParams()

  const [programs, setPrograms] = useState<Program[]>([])
  const [helloSignDocumentList, setHelloSignDocumentList] = useState<HelloSignDList[]>([])
  const navigate = useNavigate()

  const initialValues = {
    name: formData?.name,
    description: formData?.description || '',
    client_id: formData?.client_id,
    title: formData?.title || '',
    type: formData?.type,
    status: formData?.status === 'active' ? true : false,
    is_selected_all: formData?.is_selected_all ? "true" : "false",
    selected_programs: formData?.selected_programs?.map((item: any) => {
      return item
    }) || [],
    selected_sessions: formData?.selected_sessions?.map((item: any) => {
      return item
    }) || [],
    selected_items: formData?.selected_items?.map((item: any) => {
      return {...item}
    }) || [],
    task_overview: formData.task_overview || '',
    task_deadline: formData.task_deadline || '',
    task_type: formData.task_type || '',
    custom_input: formData.task_type === 'hellosign' ? Number(formData.custom_input) :  formData.custom_input || '',
    instruction: formData.instruction || '',
    sf_api_name_status: formData.sf_api_name_status || '',
    sf_api_name_date: formData.sf_api_name_date || ''
  }

  useEffect(() => {
    setLoading(true)
    const fetchFormData = async () => {
      try {
        const {data} = await fetchEnrollmentFormGroupById(enrollment_form_group_id)
        setLoading(false)
        setFormData(data)
      } catch (error) {
        console.error('Error fetching form data:', error)
        setLoading(false)
        setErrorMessage('Failed to fetch form data')
      }
    }

    fetchFormData()
  }, [enrollment_form_group_id])

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axiosPrivate.get(`terra-admin/fetch_all_products/${client}`)
        setPrograms(response.data)
      } catch (error) {
        console.error('Error fetching programs:', error)
      }
    }

    getDocumentList()
    fetchPrograms()
  }, [client])

  const onSubmit = async (values: IEnrollmentFormGroup) => {
    try {

      if (values.type === 'pre-departure') {
        if (values.title === 'waiver') {
          values.route_name = '/portal/:client/waiver'
        } else if (values.title === 'upload'){
          values.route_name = '/portal/:client/upload';
        } else {
          values.route_name = '/portal/:client/form';
        }


        const modifiedValues = {
          ...values,
          status: values?.status ? 'active' : 'inactive',
          selected_programs: values.selected_programs && values?.is_selected_all === "false" ? JSON.stringify(values.selected_programs) : null,
          selected_sessions: values.selected_sessions && values?.is_selected_all === "false" ? JSON.stringify(values.selected_sessions) : null,
          selected_items: values.selected_items && values?.is_selected_all === "false" ? JSON.stringify(values.selected_items) : null,
          is_selected_all: values?.is_selected_all !== "false",
        }

        await AddOrEditEnrollmentFormGroup(
          modifiedValues,
          enrollment_form_group_id ? enrollment_form_group_id : null
        )
      } else if (values.type === 'form') {
        values.route_name = '';
  
        const modifiedValues = {
            ...values,
            status: values?.status ? 'active' : 'inactive',
            selected_programs: null,
            selected_sessions: null,
            selected_items: null,
            is_selected_all: null,
        };

        await AddOrEditEnrollmentFormGroup(
          modifiedValues,
          enrollment_form_group_id ? enrollment_form_group_id : null
        )
      }
      setSuccessMessage('Enrollment form groups updated successfully')

      setTimeout(() => {
        navigate(`/apps/${client}/enrollment-group-management/enrollment-group`)
      }, 3000)
    } catch (error: any) {
      if (error.response.status === 500) {
        return setErrorMessage('Something went wrong, please try again.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else if (error.response.status === 403) {
        return setErrorMessage('Need client id provided.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else setErrorMessage('Something went wrong, please try again.')

      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  const getDocumentList = async () => {
    try {
      const response = await getDocumentAPI();
      setHelloSignDocumentList(response);
    } catch (error) {
      console.error(error) 
    }
  }

  const CustomReactSelectV2 = ({formData, setFieldValue, label, required, options, ...props}: any) => {
    const [field, meta, helpers] = useField(props.name)

    const handleChange = (selectedOption: any) => {
      console.log(selectedOption, 'selected option')
      helpers.setValue(selectedOption ? selectedOption.value : '')
      if (formData.task_type !== selectedOption.value) {
        setFieldValue('custom_input', '')
      }
    }
  
    const handleBlur = () => {
      helpers.setTouched(true)
    }

    return (
      <div className='fv-row mb-7'>
        <label className={`flex-grow-1 ${required ? 'required' : ''} fw-bold fs-6 mb-2`}>
          {label}
        </label>
        <Select
          {...field}
          {...props}
          options={options}
          onChange={handleChange}
          onBlur={handleBlur}
          value={options.find((option: any) => option.value === field.value)}
          className='cursor-pointer'
          isClearable
        />
        {meta.touched && meta.error && (
          <div className='fv-plugins-message-container mt-3 text-danger'>{meta.error}</div>
        )}
      </div>
    )
  }



  return (
    <>
      {successMessage && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{successMessage}</span>
            </div>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <span className='indicator-progress' style={{display: 'flex', justifyContent: 'center'}}>
          <span
            style={{height: '40px', width: '40px'}}
            className='spinner-border spinner-border-sm align-middle mt-20'
          />
        </span>
      ) : (
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body'>
            <div className='card'>
              <Formik
                initialValues={initialValues}
                validationSchema={EnrollmentGroupSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({values, isSubmitting, setFieldValue}) => {

                  return (
                    <Form className='container'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <CustomInput
                            type='text'
                            label='Client'
                            name='client_id'
                            readOnly
                            value={
                              formData.client_id === '47821104'
                                ? 'Summer Springboard'
                                : 'Teen Travel Network'
                            }
                          />
                        </div>

                        <div className='col-lg-6'>
                          <CustomReactSelect
                            label='Type'
                            name='type'
                            placeholder='Select a Type'
                            options={type.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                          />
                        </div>

                        <div className='col-lg-6'>
                          <CustomInput label='Name' required name='name' placeholder='Enter a Name' />
                        </div>

                        <div className='col-lg-6'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>
                          <CustomCheckbox label='Active' type='checkbox' name='status' />
                        </div>

                        {values.type === 'pre-departure' && (
                          <>
                            <div className='col-lg-6'>
                              <CustomTextArea
                                label='Task Overview'
                                name='task_overview'
                                placeholder='Enter a Task Overview'
                              />
                            </div>

                            <div className='col-lg-6'>
                              <CustomInput label='Description' required name='description' placeholder='Enter a description' />
                            </div>

                            <label className='fw-bold fs-6 mb-2'>Instruction</label>
                            <ReactQuill theme="snow" value={values.instruction} onChange={(value) => {setFieldValue('instruction', value)}} className='mb-20'/>

                            <div className='col-lg-6'>
                              <CustomReactSelect
                                required={true}
                                label='Task Title'
                                name='title'
                                placeholder='Select a Task Title'
                                options={task_title}
                              />
                            </div>

                            {values.title === 'upload' || values.title === 'form' ? (
                            <>
                              <div className='col-lg-6'>
                                <CustomReactSelect
                                  required={true}
                                  label='Salesforce API Status name'
                                  name='sf_api_name_status'
                                  placeholder='Enter a salesforce name with __c'
                                  options={SalesforceAPIStatus.map((item) => ({
                                    value: item.value,
                                    label: item.label,
                                  }))}
                                />
                              </div>
                              <div className='col-lg-6'>
                                <CustomReactSelect
                                  required={true}
                                  label='Salesforce API Date name'
                                  name='sf_api_name_date'
                                  placeholder='Enter a salesforce name with __c'
                                  options={SalesforceAPIDate.map((item) => ({
                                    value: item.value,
                                    label: item.label,
                                  }))}
                                />
                              </div>
                            </>
                            ): ''}

                            <div className='col-lg-6'>
                              <label className='form-label fw-bolder text-dark fs-6 required'>
                                Task Deadline
                              </label>
                              <DatePickerField
                                className='form-control form-control-lg form-control-solid mb-2'
                                name='task_deadline'
                                placeholder='Select a deadline'
                              />
                            </div>

                            <div className='my-5'>
                            <label className='flex-grow-1 required fw-bold fs-6'>Task Type</label>
                              <CustomReactSelectV2
                                name='task_type'
                                options={values.title == 'form' || values.title == 'upload' ? task_type : task_type_alone}
                                placeholder='Select a Task Type'
                                isClearable 
                                formData={formData}
                                setFieldValue={setFieldValue}
                              />
                            </div>

                            {values.task_type === 'hellosign' ? (
                              <CustomReactSelect
                                label='Template ID'
                                name='custom_input'
                                options={helloSignDocumentList.map((item) => ({
                                  value: item.documentId,
                                  label: item.templateTitle,
                                }))}
                                placeholder='Enter a valid template ID'
                              />
                            ) : values.task_type === 'upload-template' ? (
                              <CustomInput
                                label='Template Download Link'
                                name='custom_input'
                                placeholder='Enter a template download link'
                              />
                            ) : values.task_type === 'third-party-link' ? (
                              <CustomInput
                                label='3rd Party Link'
                                name='custom_input'
                                placeholder='Enter third party link'
                              />
                            ) : (
                              ''
                            )}

                            <div className='d-flex gap-2 col-lg-6'>
                              <label className='d-flex gap-2'>
                                <Field type='radio' name='is_selected_all' value='true' />
                                All Programs
                              </label>
                              <label className='d-flex gap-2'>
                                <Field type='radio' name='is_selected_all' value='false' />
                                Custom Program Selection
                              </label>
                            </div>

                            {values.is_selected_all === 'false' ? (
                              <>
                                <h2 className='mt-5'>Campus</h2>
                                <div style={{display: 'grid'}}>
                                {programs.map((program, programIndex) => {
                                  return (
                                    <div key={programIndex} style={{ display: 'flex', gap: 10 }}>
                                      <Field
                                        type='checkbox'
                                        value={program.groupName}
                                        name='selected_programs'
                                        checked={values?.selected_programs?.includes(program.groupName)}
                                        onChange={(e: any) => {
                                          const isChecked = e.target.checked;
                                          if (isChecked) {
                                            const updatedSelectedPrograms = [...values.selected_programs, program.groupName];
                                            setFieldValue('selected_programs', updatedSelectedPrograms);
                                            const updatedSessions = values.selected_sessions.filter((session: any) => session.campus !== program.groupName);
                                            const updatedItems = values.selected_items.filter((item: any) => item.groupName !== program.groupName);
                                            setFieldValue('selected_sessions', updatedSessions);
                                            setFieldValue('selected_items', updatedItems);
                                          } else {
                                            setFieldValue('selected_programs', values.selected_programs.filter((item: any) => item !== program.groupName));
                                            const uncheckedSessions = values.selected_sessions.filter((session: any) => session.name !== program.groupName);
                                            const uncheckedItems = values.selected_items.filter((item: any) => item.program_group_name !== program.groupName);
                                            setFieldValue('selected_sessions', uncheckedSessions);
                                            setFieldValue('selected_items', uncheckedItems);
                                          }
                                        }}
                                      />
                                      <label>{program.groupName}</label>
                                    </div>
                                  );
                                })}
                                </div>

                                {values.selected_programs?.length > 0 ? (
                                  <>
                                    <h3 className='mt-5'>Sessions</h3>
                                    <div>
                                      {values.selected_programs?.map((selectedCampus: any, index: any) => (
                                        <div className='mt-2' key={index}>
                                          <h1>{selectedCampus}</h1>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {programs
                                              .filter((program) => program.groupName === selectedCampus)
                                              .flatMap((program) => program.session)
                                              .map((session, sessionIndex) => {
                                                return (
                                                  <div key={sessionIndex}>
                                                    <div className='d-flex gap-2'>
                                                      <Field
                                                        type='checkbox'
                                                        value={session.id.toString()}
                                                        checked={values.selected_sessions.some((selectedSession: any) => selectedSession.id === session.id)}
                                                        onChange={(e: any) => {
                                                          const isChecked = e.target.checked;
                                                          if (isChecked) {
                                                            setFieldValue('selected_sessions', [...values.selected_sessions, session]);
                                                          } else {
                                                            setFieldValue('selected_sessions', values.selected_sessions.filter((item: any) => item.id !== session.id));
                                                          }
                                                        }}
                                                      />
                                                      <label>{`${session.name} (${session.startDate}-${session.endDate}) ${session.type}`}</label>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                ) : ('')}
                              </>
                            ) : (
                              ''
                            )}

                            {(client === '47821104' && values.is_selected_all === 'false'  && values.selected_sessions.length > 0) 
                            && (
                              <div>
                                <div>
                                  <h3 className='mt-5'>Courses</h3>
                                  {values.selected_sessions.map((session: any) =>
                                    session?.program_track?.map((track: any, index: any) => (
                                      <div key={index} className='d-flex gap-2'>
                                        <Field
                                          type='checkbox'
                                          value={track.id.toString()}
                                          name='selected_items'
                                          checked={values.selected_items?.some((item: any) => item.id === track.id)} 
                                          onChange={(e: any) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                              setFieldValue('selected_items', [...values.selected_items, track]);
                                            } else {
                                              setFieldValue('selected_items', values.selected_items.filter((item: any) => item.id !== track.id));
                                            }
                                          }}
                                        />
                                        <label>{track.name}</label>
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        <div className='text-center pt-15'>
                          <button
                            disabled={isSubmitting}
                            type='submit'
                            className='btn btn-primary cursor-pointer'
                            data-kt-users-modal-action='submit'
                          >
                            <span className='indicator-label'>Update</span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EnrollmentEdit
