import React, {useEffect} from 'react'
import {useAuth} from '../../modules/auth'
import useAppRequests from '../../modules/application/Hooks/useAppRequests'
import {useQuery} from 'react-query'
import _queryKeys from '../../modules/application/core/_queryKeys'
import {IOpportunity} from '../../modules/application/core/_models'

import './FlightPlan.css'
import moment from 'moment'
import { useParams } from 'react-router-dom'

interface iAirline {
  code: string
  created_at: string
  id: number
  name: string
  updated_at: string
}

interface iAirport {
  city: string
  code: string
  country: string
  created_at: string
  id: number
  updated_at: string
}

interface iProgramProduct {
  client_id: string
  country: string | null
  created_at: string
  division: string
  due_upon_application: number
  duration: number
  end_date: string
  group: boolean
  id: number
  is_active: boolean
  is_guaranteed: boolean
  name: string
  predeparture: string | null
  pricebook_entry_id: string
  program_summary: string
  session_id: number | null
  sf_id: string
  slug: string | null
  start_date: string
  status: string | number | null
  student_type: string
  tuition: number
  updated_at: string
}

interface IStudent {
  client_id: string[]
  client_names: string | null
  created_at: string
  created_by: number
  email: string | any
  id: number
  isPrimary: string | null
  opportunity_active: number
  opt_flightplan: string | null
  password: string
  password_reset_token: string | null
  password_reset_token_expiration: string | null
  pending_password_reset: string | null
  role_id: number
  sf_id: string
  status: string
  updated_at: string
}
export interface iFlight {
  airline: iAirline
  airline_id: number
  arrival_airport: iAirport
  arrival_airport_id: number
  arrival_date: string
  arrival_time: string
  created_at: string
  departure_airport: iAirport
  departure_airport_id: number
  departure_date: string
  departure_time: string
  flight_number: string
  flight_type: string
  id: number
  notes: string
  opportunity: IOpportunity
  opportunity_id: number
  program_product: iProgramProduct
  program_product_id: number
  sf_id: string
  status_id: number
  student: IStudent
  student_id: number
  updated_at: string
}
interface Flight {
  flightCode: string
  details: string
  notes: string | null
}

interface Student {
  studentName: string
  location: string
  type: string
  airport_origin: string | null
  going: Flight[]
  returning: Flight[]
}

const FlightPlan = () => {
  const {currentUser} = useAuth()
  const {client} = useParams()
  const clientRoutes = client === '47821104' ? 'SSB' : client === '88562775' ? 'TTN' : 'GLA'
  const {getFlightRostersById} = useAppRequests()
  const currentRoute = window.location.pathname.split('/')[4] || '0'
  const {
    isLoading,
    isError,
    refetch,
    data: flights,
  } = useQuery([_queryKeys.getFlightRostersById, currentRoute], () =>
    getFlightRostersById(parseInt(currentRoute))
  )

  useEffect(() => {
    refetch()
  }, [currentRoute])

  if (isLoading) {
    return (
      <div className='w-100 my-10'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-5'>Please wait...</span>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  interface Props {
    flights: iAirport
    notes: string
    departure_date?: string
    departure_time?: string
    arrival_date?: string
    arrival_time?: string
  }

  const NestedTable = ({
    flights,
    notes,
    departure_date,
    departure_time,
    arrival_date,
    arrival_time,
  }: Props) => {
    console.log(moment(departure_date).format('MM-DD-YYYY'), departure_time?.slice(0, -3), '1')
    return (
      <table className='table tableBorder bg-light'>
        <thead className='tableBorder'>
          <tr className='tableBorder'>
            <th className='fw-bold p-3 tableBorder'>Flight</th>
            <th
              style={{
                textAlign: 'left',
              }}
              className='fw-bold p-3 tableBorder'
            >
              Details
            </th>
            <th
              style={{
                textAlign: 'left',
                padding: 10,
              }}
              className='fw-bold tableBorder'
            >
              Notes
            </th>
          </tr>
        </thead>
        <tbody className='tableBorder'>
          {/* {flights.map((flight: any, index: any) => ( */}
          <tr className='tableBorder'>
            <td className='text-center' style={{border: '1px #ddd solid'}}>
              {`${flights.code} ${flights.city} ${flights.country}`}
            </td>
            {departure_date && departure_time ? (
              <td className='tableBorder' style={{border: '1px #ddd solid'}}>
                {moment(departure_date).format('MM-DD-YYYY')} {departure_time?.slice(0, -3)}{' '}
                {`${flights.code} ${flights.country}`}
              </td>
            ) : (
              <td className='tableBorder' style={{border: '1px #ddd solid'}}>
                {moment(arrival_date).format('MM-DD-YYYY')} {arrival_time?.slice(0, -3)}{' '}
                {`${flights.code} ${flights.country}`}
              </td>
            )}
            <td className='tableBorder' style={{border: '1px #ddd solid'}}>
              {departure_date && departure_time && notes}
            </td>
          </tr>
          {/* ))} */}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='card-header px-0 mb-10'>
            <div>
              <h1>{flights?.data.product_name}</h1>
              <span></span>
            </div>
            <div className='alert alert-warning d-flex align-items-center p-5 mb-10'>
              <div className='d-flex flex-column'>
                <p>
                  Flights will not be added until your confirmed itinerary has been submitted to
                  {clientRoutes}.
                </p>
                <p>
                  Please contact {clientRoutes} if you've purchased the optional Unaccompanied Minor service
                  for your student and require a point of contact for arrival/departure.
                </p>
                <p>
                  Please see the Travel Portal for approved arrival and departure windows for your
                  program. Flights booked outside of these windows will not be accepted, and must be
                  re-booked at the family's expense.
                </p>
              </div>
            </div>
          </div>

          <table className='table tableBorder table-striped '>
            {/* begin::Table head */}
            <thead className='tableBorder'>
              <tr className='fw-bold bg-light tableBorder'>
                <th className='tableBorder'>Student Name</th>
                <th className='tableBorder'>Location</th>
                <th>Type</th>
                <th className='tableBorder' style={{width: '150px'}}>
                  Airport of Origin
                </th>
                <th className='tableBorder' style={{width: '400px'}}>
                  Outbound Flight
                </th>
                <th className='tableBorder' style={{width: '400px'}}>
                  Return Flight
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='fs-5'>
              {flights?.data.flights?.map((fl, idx) => {
                console.log(fl.departure_airport?.city, 'ffff')
                return (
                  <tr key={idx}>
                    <td
                      className='tableBorder'
                      style={{
                        verticalAlign: 'top',
                        border: '1px #ddd solid',
                        textAlign: 'center',
                        fontSize: 13,
                      }}
                    >
                      {fl.student?.email}
                    </td>
                    <td
                      className='tableBorder'
                      style={{verticalAlign: 'top', border: '1px #ddd solid', width: '200px'}}
                    >
                      {`${fl.departure_airport.code} ${fl.departure_airport.country} ${fl.departure_airport.city}`}
                    </td>
                    <td
                      className='tableBorder'
                      style={{verticalAlign: 'top', border: '1px #ddd solid'}}
                    >
                      {fl.flight_type}
                    </td>
                    <td
                      className='tableBorder'
                      style={{verticalAlign: 'top', border: '1px #ddd solid'}}
                    >
                      {fl.departure_airport.country}- {fl.departure_airport.city}
                    </td>
                    <td
                      className='tableBorder'
                      style={{verticalAlign: 'top', border: '1px #ddd solid'}}
                    >
                      {fl.flight_type === 'Outbound Flight' && (
                        <NestedTable
                          flights={fl.departure_airport}
                          notes={fl.notes}
                          departure_date={fl.departure_date}
                          departure_time={fl.departure_time}
                        />
                      )}
                    </td>
                    <td
                      className='tableBorder'
                      style={{verticalAlign: 'top', border: '1px #ddd solid'}}
                    >
                      {fl.flight_type === 'Return Flight' && (
                        <NestedTable
                          flights={fl.departure_airport}
                          notes={fl.notes}
                          departure_date={fl.departure_date}
                          departure_time={fl.departure_time}
                        />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
        </div>
      </div>
    </>
  )
}

export default FlightPlan
