import React from 'react'
import {useAuth} from '../../auth'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import EnrollmentExtrasList from './EnrollmentExtrasList'
import EnrollmentExtrasAdd from './EnrollmentExtrasAdd'
import EnrollmentExtrasEdit from './EnrollmentExtrasEdit'


const EnrollmentExtras = () => {
  const {currentUser} = useAuth()

  const EnrollmentExtrasBreadCrumbs: Array<PageLink> = [
    {
      title: 'Enrollment Extras',
      path: `/apps/${currentUser?.currentClient}/enrollment-extras-management/enrollment-extras`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='enrollment-extras'
          element={
            <>
              <PageTitle breadcrumbs={EnrollmentExtrasBreadCrumbs}>Enrollement Extras List</PageTitle>
              <EnrollmentExtrasList />
            </>
          }
        />
        <Route
          path='add-enrollment-extras'
          element={
            <>
              <PageTitle breadcrumbs={EnrollmentExtrasBreadCrumbs}>Add Enrollment Extras</PageTitle>
              <EnrollmentExtrasAdd />
            </>
          }
        />
        <Route
          path='edit-enrollment-extras/:enrollment_extras_id'
          element={
            <>
              <PageTitle breadcrumbs={EnrollmentExtrasBreadCrumbs}>Edit Enrollment Extras</PageTitle>
              <EnrollmentExtrasEdit />
            </>
          }
        />
      </Route>
      <Route
        index
        element={<Navigate to={`/apps/${currentUser?.currentClient}/enrollment-extras-management/enrollment-extras`} />}
      />
    </Routes>
  )
}

export default EnrollmentExtras
