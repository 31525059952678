import { Link } from 'react-router-dom'
import { KTSVG } from '../helpers'
import { Dispatch, SetStateAction } from 'react'

interface IProps {
  buttonLink?: string
  buttonText?: string
  placeholder?: string
  setSearch: Dispatch<SetStateAction<string>>
  search: string
}

const Header = ({buttonLink, buttonText, setSearch, search, placeholder}: IProps) => {
  return (
    <div className='card-header border-0 pt-6 display-space-between'>
      <div className='card-title'>
        <div className='d-flex align-items-center position-relative my-1'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-300px ps-14'
            placeholder={placeholder}
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            {buttonLink && (
            <Link
              to={buttonLink}
              replace={true}
              className='btn btn-primary'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              {buttonText}
            </Link>
            )}
        </div>
      </div>
    </div>
  )
}

export default Header