import {useEffect, useMemo, useState} from 'react'
import {getAllProgress, exportProgress} from '../../../../network/api'
import {PiExport} from 'react-icons/pi'
import Header from '../../../../_metronic/shared-components/Header'
import moment from 'moment'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'

const UserProgressList = () => {
  const [progressLoading, setProgressLoading] = useState(false)
  const [progressData, setProgressData] = useState<any>(null)

  // STATES
  const [progress, setProgress] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const [visibleData, setVisibleData] = useState<any[]>([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    setLoading(true)
    const getAllUserProgress = async () => {
      const response = await getAllProgress()
      setProgress(response)
      setLoading(false)
    }

    getAllUserProgress()
  }, [])

  const getExportProgress = async () => {
    setProgressLoading(true)
    const res = await exportProgress()
    setProgressData(res)
    setProgressLoading(false)
  }


  useEffect(() => {
    const filteredProgress = search.length !== 0 
    ? progress.filter(item => item?.contactInfo?.studentInfo?.email?.toLowerCase().includes(search.toLowerCase())) 
    : progress

    setVisibleData(filteredProgress)
  }, [progress, search])
  
  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({row}: any) => {
          const email = row.original?.contactInfo?.studentInfo?.email
          return email || 'N/A'
        },
      },
      {
        Header: 'Product',
        accessor: 'product',
        Cell: ({row}: any) => {
          const product = row.original?.product
          return product === -1 || !product ? 'N/A' : product
        },
        minWidth: 200,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        Cell: ({row}: any) => {
          const firstName = row.original?.contactInfo?.studentInfo?.firstName
          return firstName || 'N/A'
        },
        minWidth: 120,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        Cell: ({row}: any) => {
          const lastName = row.original?.contactInfo?.studentInfo?.lastName
          return lastName || 'N/A'
        },
        minWidth: 120,
      },
      {
        Header: 'Phone',
        accessor: 'phoneNumber',
        Cell: ({row}: any) => {
          const phoneNumber = row.original?.contactInfo?.studentInfo?.phoneNumber
          return phoneNumber || 'N/A'
        },
        minWidth: 120,
      },
      {
        Header: 'Created Date',
        accessor: 'created_at',
        Cell: ({value}: any) => {
          const formattedDate = moment(value).format('MM-DD-YYYY');
          return formattedDate
        },
        minWidth: 120,
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        Cell: ({row}: any) => {
          const gender = row.original?.contactInfo?.studentInfo?.gender
          return gender || 'N/A'
        },
        minWidth: 120,
      },
      {
        Header: 'Parent Email',
        accessor: 'parent_email',
        Cell: ({row}: any) => {
          const parent_email = row.original?.contactInfo?.parentInfo?.email
          return parent_email || 'N/A'
        },
        minWidth: 100,
      },
      {
        Header: 'Parent First Name',
        accessor: 'parent_firstName',
        Cell: ({row}: any) => {
          const parent_firstName = row.original?.contactInfo?.parentInfo?.firstName
          return parent_firstName || 'N/A'
        },
        minWidth: 100,
      },
      {
        Header: 'Parent First Name',
        accessor: 'parent_lastName',
        Cell: ({row}: any) => {
          const parent_lastName = row.original?.contactInfo?.parentInfo?.lastName
          return parent_lastName || 'N/A'
        },
        minWidth: 100,
      },
      {
        Header: 'Address Info',
        accessor: 'addressInfo',
        Cell: ({row}: any) => {
          const addressInfo = row.original?.contactInfo?.studentAddress
          return addressInfo?.address && addressInfo?.city && addressInfo?.country
            ? `${addressInfo.address} ${addressInfo.city} ${addressInfo.country}`
            : 'N/A'
        },
        minWidth: 100,
      },
    ],
    []
  )

  return (
    <>
      {progressLoading ? (
        <div className='card-body'>
          <div className='alert alert-warning d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>Export is in progress</span>
            </div>
          </div>
        </div>
      ) : (progressData && progressData?.status === 200) ? (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{progressData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : (progressData && progressData?.status !== 200) ? (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{progressData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : ''}

     

      <div className='card card-body p-0'>

        <div className='d-flex align-items-center justify-content-between pe-9'>
          <Header
            setSearch={setSearch}
            search={search}
            placeholder='Search User Progress'
          />

          <div className='mt-5'>
            <button 
              className='btn btn-primary d-flex align-items-center' 
              onClick={getExportProgress} 
              disabled={progressLoading}
            >
              {progressLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <>
                  <PiExport size={20} className='me-2' color='white' />
                  Export Progress
                </>
              )}
            </button>
          </div>
        </div>
       
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading}/>
      </div>
    </>
  )
}
export default UserProgressList
