import { ErrorMessage } from 'formik'
import ReactHtmlParser from 'react-html-parser'
import { IEnrollPartTwoForm } from '../../core/_models'
import CustomField from './CustomField'

interface Props {
  form: IEnrollPartTwoForm
  currentFormikVal: any
  prefixFieldName: string
  formsList: IEnrollPartTwoForm[]
  objectName: string
}

const HiddenField = ({
  form,
  currentFormikVal,
  prefixFieldName,
  formsList,
  objectName,
}: Props): any => {
  const currentParentField = formsList.filter((f) => f.id === form.for)[0]
  if (!currentParentField) {
    return null
  }
  const currentFormikValObj = currentFormikVal as {[key: string]: any}
  const objForms = currentFormikValObj[objectName]

  const medicalVal = Array.isArray(objForms[currentParentField.name])
    ? objForms[currentParentField.name]
    : [objForms[currentParentField.name]]

    
    if (
      (form.type === 'description' || form.type === 'last_episode') &&
      medicalVal.includes(form.choiceId?.toString())
      ) {
    return (
      <div className='col-lg-12 mb-10'>
        {form.label && (
          <label className={'form-label fw-bolder text-dark fs-6 required'}>
            {ReactHtmlParser(form.label)}
          </label>
        )}
        <CustomField form={form} currentFormikVal={currentFormikVal} />
        <div className='text-danger mt-2'>
          <ErrorMessage name={prefixFieldName + form.name} />
        </div>
      </div>
    )
  }
  return null
}

export default HiddenField
