import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { useAuth } from '../../auth'
import AddOpportunityPage from './AddOpportunityPage'
import OpportunitiesList from './components/OpportunitiesList'
import OpportunitiesProvider from './components/OpportunitiesProvider'
import UpdateOpportunityPage from './UpdateOpportunityPage'

const OpportunitiesPage = () => {
  const {currentUser} = useAuth()
  const opportunitiesBreadcrumbs: Array<PageLink> = [
    {
      title: 'Opportunities Management',
      path: `/apps/${currentUser?.currentClient}/opportunities-management/opportunities`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <OpportunitiesProvider>
            <Outlet />
          </OpportunitiesProvider>
        }
      >
        <Route
          path='opportunities'
          element={
            <>
              <PageTitle breadcrumbs={opportunitiesBreadcrumbs}>Opportunites list</PageTitle>
              <OpportunitiesList />
            </>
          }
        />
        <Route
          path='new'
          element={
            <>
              <PageTitle breadcrumbs={opportunitiesBreadcrumbs}>New Opportunity</PageTitle>
              <AddOpportunityPage />
            </>
          }
        />
        <Route
          path='edit/:opportunityId'
          element={
            <>
              <PageTitle breadcrumbs={opportunitiesBreadcrumbs}>Edit Opportunity</PageTitle>
              <UpdateOpportunityPage />
            </>
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate
            to={`/apps/${currentUser?.currentClient}/opportunities-management/opportunities`}
          />
        }
      />
    </Routes>
  )
}

export default OpportunitiesPage
