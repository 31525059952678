import React from 'react';
import { MdKeyboardDoubleArrowDown } from 'react-icons/md';
import useScrollArrow from '../../hooks/useScrollArrow';

interface ScrollArrowProps {
  size?: number;
  positionStyles?: React.CSSProperties;
}

const ScrollArrow: React.FC<ScrollArrowProps> = ({ size = 50, positionStyles = { bottom: '3%', right: '1%' } }) => {
  const { isScrollable, scrollDown } = useScrollArrow();

  if (!isScrollable) return null;

  return (
    <MdKeyboardDoubleArrowDown
      size={size}
      style={{ position: 'fixed', ...positionStyles, cursor: 'pointer' }}
      className='scroll-down-btn'
      onClick={scrollDown}
    />
  );
};

export default ScrollArrow;
