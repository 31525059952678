import {useField, Field, ErrorMessage} from 'formik'
import {useEffect, useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {COUNTRIES} from '../../../../../utils'
import {useAuth} from '../../../auth'
import {GRADE_ENTERING, IProgram, T_SHIRT_SIZE} from '../../core/_models'
import AddressInfo from './contact-info/AddressInfo'
import AdvisorOpt from './contact-info/AdvisorOpt'
import ParentInfo from './contact-info/ParentInfo'
import { useParams } from 'react-router-dom'

const DatePickerField = ({...props}: any) => {
  const [field, , {setValue}] = useField(props)
  return (
    <DatePicker
      {...field}
      {...props}
      closeOnScroll={true}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setValue(val)
      }}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    />
  )
}

interface Props {
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const Step4 = ({currentFormikVal, setFieldValue}: Props) => {
  const {client} = useParams()
  const {currentUser} = useAuth()
  const [parentOptions, setParrentOptions] = useState({
    secondParentOpt: false,
    advisorOpt: false,
  })

  useEffect(() => {
    if (currentUser) {
      setFieldValue('contactInfo.studentInfo.email', currentUser.email)
      //remove signup fields
      setFieldValue('contactInfo.studentAccount.email', '')
      setFieldValue('contactInfo.studentAccount.password', '')
      setFieldValue('contactInfo.studentAccount.confirmPassword', '')
    } else {
      setFieldValue('contactInfo.studentInfo.email', currentFormikVal.studentAccount?.email)
    }
    return () => {}
  }, [currentFormikVal.studentAccount?.email])

  if (currentUser) {
    return (
      <div className='text-center w-100 fs-3'>
        <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
          Step 4: Enter Your Contact Information
        </h2>
        <div className='alert alert-primary d-flex align-items-center px-5 py-10 mb-10'>
          <div className='d-flex flex-column w-100'>
            <h5 className='mb-1'>You're currently logged in as with username:</h5>
            <span className='fw-bold'>{currentUser.email}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='fv-row w-100'>
      <div className='row'>
        <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
          Step 4: Enter Your Contact Information
        </h2>
        <div className='col-lg-6 pe-5'>
          <div className='f-row row mb-7'>
            <div className='mb-7'>
              <h4 className='fw-bold'>Student's Information</h4>
              <span>(As it appears on Passport)</span>
            </div>
            <div className='col-lg-6'>
              <label className='form-label fw-bolder text-dark fs-6 required'>First Name</label>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='contactInfo.studentInfo.firstName'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.firstName' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-label fw-bolder text-dark fs-6 required'>Last Name</div>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='contactInfo.studentInfo.lastName'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // setFieldValue('contactInfo.parentInfo.lastName', e.target.value)
                  setFieldValue('contactInfo.studentInfo.lastName', e.target.value)
                }}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.lastName' />
              </div>
            </div>
          </div>
          <div className='col-lg-12 mb-7'>
            <div className='form-label fw-bolder text-dark fs-6 required'>Email</div>
            <Field
              type='email'
              className='form-control form-control-lg form-control-white'
              name='contactInfo.studentInfo.email'
              disabled={true}
              //value={currentFormikVal.studentAccount?.email}
            />
          </div>
          <div className='fv-row row mb-7'>
            <div className='col-lg-6'>
              <div className='form-label fw-bolder text-dark fs-6 required'>Country</div>
              <Field
                as='select'
                name='contactInfo.studentInfo.country'
                className='form-select form-select-lg form-select-solid'
              >
                <option>Select Country</option>
                {COUNTRIES.map((c, idx) => {
                  return (
                    <option value={c.code} key={c.code+'_'+idx}>
                      {c.name + ' (' + c.mobileCode + ')'}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.country' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-label fw-bolder text-dark fs-6 required'>Phone Number</div>
              <Field
                type='string'
                className='form-control form-control-lg form-control-solid'
                name='contactInfo.studentInfo.phoneNumber'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.phoneNumber' />
              </div>
            </div>
          </div>
          <div className='fv-row row mb-7'>
            <div className='col-lg-qw'>
              <div className='form-label fw-bolder text-dark fs-6 required'>Gender</div>
              <Field
                as='select'
                name='contactInfo.studentInfo.gender'
                className='form-select form-select-lg form-select-solid'
              >
                <option value=''>Select Gender</option>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
                <option value='Other'>Other</option>
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.gender' />
              </div>
            </div>
            {currentFormikVal?.contactInfo?.studentInfo.gender == 'Other' && (
              <div className='col-lg-qw'>
                <div className='form-label fw-bolder text-dark fs-6 required'>
                  Gender Description
                </div>
                <Field
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  name='contactInfo.studentInfo.genderDescription'
                  // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  //   // setFieldValue('contactInfo.parentInfo.lastName', e.target.value)
                  //   setFieldValue('contactInfo.studentInfo.genderDescription', e.target.value)
                  // }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='contactInfo.studentInfo.genderDescription' />
                </div>
              </div>
            )}

            <div className='col-lg-qw'>
              <div className='form-label fw-bolder text-dark fs-6 required'>
                Sex Assigned At Birth
              </div>

              <div className='col-lg-qw'>
                <Field
                  as='select'
                  name='contactInfo.studentInfo.sexAssignedAtBirth'
                  className='form-select form-select-lg form-select-solid'
                >
                  <option value=''>Select Gender</option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  {/* <option value='Other'>Other</option> */}
                </Field>
              </div>
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.sexAssignedAtBirth' />
              </div>
            </div>
          </div>
          <div className='fv-row row mb-7'>
            <div className='col-lg-qw'>
              <div className='form-label fw-bolder text-dark fs-6 required'>Pronouns</div>
              <Field
                as='select'
                name='contactInfo.studentInfo.pronouns'
                className='form-select form-select-lg form-select-solid'
              >
                <option value=''>Select Pronouns</option>
                <option value='she, her, hers'>she, her, hers</option>
                <option value='he, him, his'>he, him, his</option>
                <option value='they, them, theirs'>they, them, theirs</option>
                <option value="I do not use he, she, or they, and ask that I'm addressed only by name	">
                  I do not use he, she, or they, and ask that I'm addressed only by name
                </option>
                <option value='Input Preference'>Input Preference</option>
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.pronouns' />
              </div>
            </div>
          </div>
          {currentFormikVal?.contactInfo?.studentInfo.pronouns == 'Input Preference' && (
            <div className='col-lg-qw'>
              <div className='form-label fw-bolder text-dark fs-6 required'>
                Pronoun Description
              </div>
              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='contactInfo.studentInfo.pronounsDescription'
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                //   // setFieldValue('contactInfo.parentInfo.lastName', e.target.value)
                //   setFieldValue('contactInfo.studentInfo.pronounDescription', e.target.value)
                // }}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.pronounsDescription' />
              </div>
            </div>
          )}
          <div className='col-lg-12 mb-7'>
            <div className='form-label fw-bolder text-dark fs-6 required'>
              Date of Birth (MM/DD/YYYY)
            </div>
            <DatePickerField
              className='form-control form-control-lg form-control-solid'
              name='contactInfo.studentInfo.birthday'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='contactInfo.studentInfo.birthday' />
            </div>
          </div>
          <div className='col-lg-12 mb-7'>
            <div className='form-label fw-bolder text-dark fs-6 required'>T-Shirt Size</div>
            <Field
              as='select'
              name='contactInfo.studentInfo.tshirtSize'
              className='form-select form-select-lg form-select-solid'
            >
              <option>---- Select Size ---- </option>
              {T_SHIRT_SIZE.map((size, idx) => {
                return (
                  <option value={size.value} key={idx}>
                    {size.adult}
                  </option>
                )
              })}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='contactInfo.studentInfo.tshirtSize' />
            </div>
          </div>
          <div className='col-lg-12 mb-7'>
            <div className='form-label fw-bolder text-dark fs-6 required'>
              Name of School Attending
            </div>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='contactInfo.studentInfo.schoolAttending'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='contactInfo.studentInfo.schoolAttending' />
            </div>
          </div>
          {client === '47821104' && (
            <div className='col-lg-12 mb-7'>
              <div className='form-label fw-bolder text-dark fs-6 required'>
                Grade entering, Fall 2024
              </div>
              <Field
                as='select'
                className='form-control form-control-lg form-control-solid'
                name='contactInfo.studentInfo.gradeEntering'
              >
                <option>---- Select Grade ---- </option>
                {GRADE_ENTERING.map((grade, idx) => {
                  return (
                    <option value={grade.value} key={grade.value+'_'+idx}>
                      {grade.label}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='contactInfo.studentInfo.gradeEntering' />
              </div>
            </div>
          )}
          <div className='col-lg-12 mb-7'>
            <div className='form-label fw-bolder text-dark fs-6'>Nickname</div>
            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='contactInfo.studentInfo.nickname'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='contactInfo.studentInfo.nickname' />
            </div>
          </div>
        </div>
        <div className='col-lg-6 ps-5'>
          <ParentInfo opt={false} />
          <div className='d-flex flex-column'>
            <div className='form-check form-check-custom form-check-solid mb-5'>
              <input
                className='form-check-input'
                type='checkbox'
                id='sencondParent'
                checked={parentOptions.secondParentOpt}
                onChange={() =>
                  setParrentOptions({
                    ...parentOptions,
                    secondParentOpt: !parentOptions.secondParentOpt,
                  })
                }
              />
              <label className='form-check-label' htmlFor='sencondParent'>
                Add a second parent/guardian?
              </label>
            </div>
            {parentOptions.secondParentOpt && (
              <div className='mt-5'>
                <ParentInfo opt={true} />
              </div>
            )}
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='advisorInfo'
                checked={parentOptions.advisorOpt}
                onChange={() =>
                  setParrentOptions({
                    ...parentOptions,
                    advisorOpt: !parentOptions.advisorOpt,
                  })
                }
              />
              <label className='form-check-label' htmlFor='advisorInfo'>
                Add an advisor?
              </label>
            </div>
            {parentOptions.advisorOpt && (
              <div>
                <AdvisorOpt />
              </div>
            )}
          </div>
          <div className='my-5 text-end'>
            <span className='required'></span>
            <span>= required information</span>
          </div>
        </div>
      </div>
      <AddressInfo currentFormikVal={currentFormikVal} setFieldValue={setFieldValue} />
    </div>
  )
}

export default Step4
