import {useEffect, useState, useMemo} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {stringifyRequestQuery} from '../../../../_metronic/helpers'
import DefaultSpinnerLoading from '../../Shared/components/loading/DefaultSpinnerLoading'
import {useQueryRequest} from '../../Shared/core/QueryRequestProvider'
import AddUpdatePaymentSchedule from './components/AddUpdatePaymentSchedule'
import _queryKeys from './components/core/_queryKeys'
import { usePaymentSchedulesRequests } from './Hooks/usePaymentSchedulesRequests'

const UpdatePaymentSchedulePage = () => {
  const [message, setMessage] = useState('')
  const {paymentScheduleId} = useParams()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const {getPaymentScheduleDetailReq, savePaymentScheduleDetailReq} = usePaymentSchedulesRequests()
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isLoading: isFieldLoading, isError: isFieldError} = useQuery(
    _queryKeys.getPaymentScheduleDetailReq,
    () => getPaymentScheduleDetailReq(paymentScheduleId)
  )

  const {
    isLoading: isUpdatePSLoading,
    isSuccess: isUpdatePSSuccess,
    isError: isUpdatePSError,
    error,
    mutateAsync: updatePSByAdminAsync,
  } = useMutation(savePaymentScheduleDetailReq)

  if (isFieldLoading) {
    return <DefaultSpinnerLoading />
  }

  if (isFieldError) {
    return (
      <div className='card-body'>
        <div className='alert alert-dismissible bg-secondary d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span className=''>Error while loading payment schedule detail!</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {isUpdatePSSuccess && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{message}</span>
            </div>
          </div>
        </div>
      )}
      {isUpdatePSError && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>Some error occurred while processing your request!</span>
            </div>
          </div>
        </div>
      )}
      <AddUpdatePaymentSchedule
        isLoading={isUpdatePSLoading}
        setMessage={setMessage}
        paymentScheduleId={paymentScheduleId}
        updatePSByAdminAsync={updatePSByAdminAsync}
      />
    </>
  )
}

export default UpdatePaymentSchedulePage
