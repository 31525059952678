import clsx from 'clsx'
import {Field, ErrorMessage} from 'formik'
import { IProgram } from '../../../core/_models'
import React from 'react';
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../../../../../utils'

interface Props {
  sameAddressOpt: boolean
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  country: string
  setCountryValue: any
}
const AddressInfoStudentField = ({sameAddressOpt,currentFormikVal, setFieldValue,country,setCountryValue}: Props) => {
  const fieldClassName = clsx(
    'form-control form-control-lg',
    sameAddressOpt ? 'form-control-solid' : 'form-control-solid'
  )
  // const [selected, setSelected] = useState('')
console.log(sameAddressOpt,'sameAddressOpt')
  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>, field:string) => {
      if (field == 'country') {
        setCountryValue(e.target.value);
      }
      setFieldValue('contactInfo.studentAddress.' + field, e.target.value)
      console.log(sameAddressOpt)
      if (sameAddressOpt) {
        setFieldValue('contactInfo.parentAddress.' + field, e.target.value)
      }
  }
  return (
    <>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Address</label>
        <Field
          type='text'
          className={fieldClassName}
          name={'contactInfo.studentAddress.address'}
          // disabled={sameAddressOpt}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAddressChange(e, 'address')}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.studentAddress.address'} />
        </div>
      </div>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>City</label>
        <Field
          type='text'
          className={fieldClassName}
          name={'contactInfo.studentAddress.city'}
          // disabled={sameAddressOpt}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAddressChange(e, 'city')}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.studentAddress.city'} />
        </div>
      </div>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Postal Code</label>
        <Field
          type='text'
          className={fieldClassName}
          name={'contactInfo.studentAddress.postalCode'}
          // disabled={sameAddressOpt}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAddressChange(e, 'postalCode')}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.studentAddress.postalCode'} />
        </div>
      </div>
      <div className='col-lg-12 mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Country</label>
        <Field
          as='select'
          name={'contactInfo.studentAddress.country'}
          className={fieldClassName}
          // disabled={sameAddressOpt}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAddressChange(e, 'country')}
        >
          <option>Select Country</option>
          {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
            return (
              <option value={c.alpha_3} key={idx}>
                {c.name}
              </option>
            )
          })}
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name={'contactInfo.studentAddress.country'} />
        </div>
      </div>
        <div className='col-lg-12 mb-5'>
          <label className='form-label fw-bolder text-dark fs-6 required'>State/Province</label>
          <Field
            as='select'
            className={fieldClassName}
            name={'contactInfo.studentAddress.stateProvince'}
            // disabled={sameAddressOpt}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAddressChange(e, 'stateProvince')}
          >
            <option>Select State/Province</option>
            {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
              if (c.alpha_3 == country) {
                let provinces = c.divisions?.map((d, id) => {
                  return (
                    <option value={d.code} key={id}>
                      {d.name}
                    </option>
                  )
                })
                return provinces
              }
            })}
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name={'contactInfo.studentAddress.stateProvince'} />
          </div>
        </div>
    </>
  )
}

export default AddressInfoStudentField
