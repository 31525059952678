import * as Yup from 'yup';
import moment, { MomentInput } from 'moment'; // Make sure to import MomentInput

export const ProgramSessionSchema = Yup.object({
  name: Yup.string().required('Enter a Name'),
  year: Yup.string().required('Enter a Valid Year'),
  capacity: Yup.string().required('Enter Capacity'),
  start_date: Yup.string().required('Select Start Date'),
  end_date: Yup.string()
    .required('Select End Date')
    .test('end_date', 'End date must be after Start date', function (value) {
      const start_date = this.resolve(Yup.ref('start_date')) as MomentInput; // Cast to MomentInput
      return !start_date || !value || moment(value).isSameOrAfter(start_date);
    }),
  status: Yup.boolean().oneOf([true, false]),
});