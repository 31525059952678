import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import UsersList from './UserList'

const UserManagement = () => {
  const {client} = useParams()

  const UserManagementBreadCrumbs: Array<PageLink> = [
    {
      title: 'Auto-pay Actions Management',
      path: `/apps/${client}/user-management/users`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='users'
          element={
            <>
              <PageTitle breadcrumbs={UserManagementBreadCrumbs}>User Management</PageTitle>
              <UsersList/>
            </>
          }
        />
        {/* <Route
          path='add-enrollment-group'
          element={
            <>
              <PageTitle breadcrumbs={PendingActionsBreadCrumbs}>Add Enrollment Group</PageTitle>
              <EnrollmentAdd />
            </>
          }
        /> */}
        {/* <Route
          path='list/:id'
          element={
            <>
              <PageTitle breadcrumbs={PendingActionsBreadCrumbs}>Transaction History</PageTitle>
              <TransactionHistoryModal />
            </>
          }
        /> */}
      </Route>
      <Route
        index
        element={<Navigate to={`/apps/${client}/user-management/users`} />}
      />
    </Routes>
  )
}

export default UserManagement
