import React, { Dispatch, SetStateAction } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const listStatus = [
  {
    value: 0,
    label: 'All'
  }, {
    value: 2,
    label: 'Incomplete - Deposit Due'
  }, {
    value: 3,
    label: 'Incomplete - Deposit Paid'
  }, {
    value: 4,
    label: 'Applied - Pending'
  }, {
    value: 5,
    label: 'Enrolled - Balance Due'
  }, {
    value: 6,
    label: 'Enrolled - Paid'
  }, {
    value: 16,
    label: 'Ready to Depart'
  }, {
    value: 17,
    label: 'Cancelled'
  }, {
    value: 18,
    label: 'Incomplete - Waitlist'
  }, {
    value: 20,
    label: 'Waitlist - Not Interested'
  }
]

const listClient = [
  {
    value: '',
    label: 'All'
  }, {
    value: '47821104',
    label: 'Summer Springboard'
  }, {
    value: '88562775',
    label: 'Teen Travel Network'
  },
]

interface IProps {
  setStatus: Dispatch<SetStateAction<number>>
  setSearch: Dispatch<SetStateAction<string>>
  search: string
  placeholder: string
  exportLoading?: boolean
  getExport?: () => void
}

interface CustomDatePickerInputProps {
  value?: string;
  onClick?: () => void;
}

const Filter = ({setStatus, setSearch, search, placeholder, exportLoading, getExport}: IProps) => {


  return (
    <div className='card-header border-0 py-6 d-flex justify-content-between '>
      <div className='fv-row me-5'>
        <div className='d-flex align-items-center position-relative'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
            />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-300px ps-14'
            placeholder={placeholder}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </div>
      </div>

      <div className='card-toolbar p-0 m-0'>
        <div className='fv-row'>
          <Select
            options={listStatus}
            noOptionsMessage={() => 'Select a type first'}
            isClearable={false}
            isSearchable={false}
            className='w-250px'
            defaultValue={{
              value: 0,
              label: 'Application Status'
            }}
            onChange={(value) => {
              if (value === null) {
                return
              }
              setStatus(value.value)
            }}
          />
        </div>
        <div className='fv-row ms-5'>
          <button className='btn btn-sm btn-primary'
            onClick={getExport}
            disabled={exportLoading}
            >
            {exportLoading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
              ) : (
                <>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                  Export Reports
                </>
              )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Filter