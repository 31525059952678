const glaEnrollBanner = {
  heading: 'Enrollment',
  bodies: [
    'USE THE FORM BELOW TO SAVE YOUR SPOT ON A GLA PROGRAM',
    'USE THE FORM BELOW TO SAVE YOUR SPOT ON A SUMMER SPRINGBOARD PROGRAM',
    'NEED HELP? CALL US AT 1-858-771-0645 OR EMAIL US AT INFO@EXPERIENCEGLA.COM',
    'NEED HELP? CALL US AT (858) 780-5660 OR EMAIL TO SUPPORT@SUMMERSPRINGBOARD.COM',
    'USE THE FORM BELOW TO SAVE YOUR SPOT ON A TEEN TRAVEL NETWORK PROGRAM',
    'NEED HELP? CALL US AT (858) 771-4886 OR EMAIL US AT INFO@TEENTRAVELNETWORK.COM',
  ],
}

const EnrollBanner = () => {
  const currentRoute = window.location.pathname.split('/')[2] || ''

  return (
    <div className='container text-light text-center text-uppercase py-5'>
      <div className='fw-bold fs-5x'>{glaEnrollBanner.heading}</div>
      <div className='fw-bold fs-2x'>
        {currentRoute == '84984438' ? glaEnrollBanner.bodies[0] : currentRoute == '88562775' ? glaEnrollBanner.bodies[4] : glaEnrollBanner.bodies[1]}
      </div>
      <div className='fw-light fs-5'>
        {currentRoute == '84984438' ? glaEnrollBanner.bodies[2] : currentRoute == '88562775' ? glaEnrollBanner.bodies[5] : glaEnrollBanner.bodies[3]}
      </div>
    </div>
  )
}

export default EnrollBanner
