// @ts-nocheck
import {PaymentScheduleCustomHeader} from './PaymentScheduleCustomHeader'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../../auth'
import { PSActionsCell } from './PSActionsCell'

const paymentSchedulesColumns = [
  {
    Header: (props) => (
      <PaymentScheduleCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => {
      const {currentUser} = useAuth()
      return (
        <Link
          to={`/apps/${currentUser?.currentClient}/payment-schedules-management/edit/${
            props.data[props.row.index].id
          }`}
        >
          {props.data[props.row.index].name}
        </Link>
      )
    },
  },
  {
    Header: (props) => (
      <PaymentScheduleCustomHeader tableProps={props} title='Type' className='min-w-125px' />
    ),
    accessor: 'type',
  },
  {
    Header: (props) => (
      <PaymentScheduleCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status.name',
  },
  {
    Header: (props) => (
      <PaymentScheduleCustomHeader tableProps={props} title='Client' className='min-w-125px' />
    ),
    accessor: 'client.name',
  },
  {
    Header: (props) => (
      <PaymentScheduleCustomHeader
        tableProps={props}
        title='Actions'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <PSActionsCell
        id={props.data[props.row.index].id}
      />
    ),
  },
]

export {paymentSchedulesColumns}
