import React, { Dispatch, FC, SetStateAction } from 'react'
import { KTSVG as KtSvg } from '../../../../../_metronic/helpers'
import { FormikHelpers } from 'formik'
import { IEnrollmentForm } from '../data'
import { DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'

interface IProps {
  values: IEnrollmentForm
  setFieldValue: FormikHelpers<any>['setFieldValue']
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

const CustomChoices: FC<IProps> = ({values, setFieldValue, setOpenModal}) => {
  const choices = values.choices ? JSON.parse(values.choices) : []
  
  const removeChoice = (i: number) => {
    const parse = choices.filter((item: string, index: number) => index !== i)
    const newChoices = JSON.stringify(parse)
    if (parse.length > 0) {
      setFieldValue('choices', newChoices) 
      return
    }
    setFieldValue('choices', '')
  }

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const newChoices = [...choices];
    const [movedChoice] = newChoices.splice(result.source.index, 1);
    newChoices.splice(result.destination.index, 0, movedChoice);
    setFieldValue('choices', JSON.stringify(newChoices));
  }

  return (
    <div className='fv-row mb-7'>
      <label className={`flex-grow-1 required fw-bold fs-6 mb-2`}>
        Choices
      </label>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="options-droppable">
          {(provided: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {choices.map((item: string, index: number) => {
                if (item === '---- Select Answer ----') return ''

                return (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='d-flex mb-2'
                      >
                        <p className='form-control form-control-solid mb-3 mb-lg-0'>{item}</p>
                        <button
                          type='button'
                          className='btn btn-icon-danger btn-text-danger p-0'
                          onClick={() => removeChoice(index)}
                        >
                          <span className='svg-icon svg-icon-1'>
                            <KtSvg
                              path='/media/icons/duotune/general/gen034.svg'
                              className='svg-icon-muted svg-icon-2hx'
                            />
                          </span>
                        </button>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <button
        type='button'
        className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm mb-5'
        onClick={() => {
          setOpenModal(prev => !prev)
        }}
      >
        + Add Option
      </button>
    </div>
  )
}

export default CustomChoices