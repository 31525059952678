import {AxiosResponse} from 'axios'
import { ID } from '../../../../../_metronic/helpers'
import { IPortalClient } from '../../../auth'
import useAxiosPrivate from '../../../auth/hooks/useAxiosPrivate'
import {ClientsQueryResponse} from '../components/core/_models'


export const useClientsRequests = () => {
  const axisosPrivate = useAxiosPrivate()
  const getAllClientsReq = (query: string): Promise<ClientsQueryResponse> => {
    return axisosPrivate
      .get(`/get_all_clients_by_admin/?${query}`)
      .then((d: AxiosResponse<ClientsQueryResponse>) => d.data)
  }

  const getClientDetailReq = async (id: string | undefined) => {
    if (!id) {
      return
    }
    const {data} = await axisosPrivate.get<{
      data: IPortalClient
    }>(`/get_client_detail_by_admin/${id}`)
    return data
  }
  const saveClientDetailReq = async (inputs: any) => {
    const {data} = await axisosPrivate.put(`/update_client_by_admin`, inputs)
    return data
  }
  const addClientDetailReq = async (inputs: any) => {
    const {data} = await axisosPrivate.post(`/add_client_by_admin`, inputs)
    return data
  }
  const deleteClientReq = (paymentScheduleId: ID): Promise<void> => {
    return axisosPrivate.delete(`/deactive_client/${paymentScheduleId}`).then(() => {})
  }
  return {
    getAllClientsReq,
    getClientDetailReq,
    saveClientDetailReq,
    addClientDetailReq,
    deleteClientReq,
  }
}
