import React from 'react'
import {useField} from 'formik'
import Select from 'react-select'

const CustomReactSelect = ({label, required, options, ...props}: any) => {
  const [field, meta, helpers] = useField(props.name)

  const handleChange = (selectedOption: any) => {
    helpers.setValue(selectedOption ? selectedOption.value : '') // Set the selected value or null if cleared
  }

  const handleBlur = () => {
    helpers.setTouched(true)
  }

  return (
    <div className='fv-row mb-7'>
      <label className={`flex-grow-1 ${required ? 'required' : ''} fw-bold fs-6 mb-2`}>
        {label}
      </label>
      <Select
        {...field}
        {...props}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        value={options.find((option: any) => option.value === field.value)}
        className='cursor-pointer'
        isClearable
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container mt-3 text-danger'>{meta.error}</div>
      )}
    </div>
  )
}

export default CustomReactSelect
