import React from 'react'
import { KTCard } from '../../../../../_metronic/helpers'
import { ProductListHeader } from './header/ProductListHeader'
import { ProductsTable } from './table/ProductsTable'

const ProductsList = () => {
  return (
    <KTCard>
      <ProductListHeader />
      <ProductsTable />
    </KTCard>
  )
}

export default ProductsList