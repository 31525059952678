import React, { useState } from 'react';
import { useAuth } from '../modules/auth';
import * as Yup from 'yup';
import { FaAddressCard } from 'react-icons/fa';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../axiosInstance';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import './AddProgramPage.scss';
import {COUNTRIES_ISO_3166_ALPHA_3} from '../../utils'

interface FormValues {
  nationality: string;
  first_name: string;
  last_name: string;
  email: string;
  birth_date: string;
  mobile: string;
  gender: string;
  relationship: string;
  other_gender?: string;
  other_relationship?: string;
  sub_type?: string;
}

const ContactDetailSchema = Yup.object({
  first_name: Yup.string().required('First Name is required').min(2, 'First Name is too short'),
  last_name: Yup.string().required('Last Name is required').min(2, 'Last Name is too short'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  birth_date: Yup.date()
  .required('Birthdate is required')
  .max(new Date(), 'Birthdate cannot be in the future'),
  mobile: Yup.string().required('Mobile is required'),
  gender: Yup.string().required('Gender is required'),
  sub_type: Yup.string().required('Contact type is required'),
  nationality: Yup.string().required('Nationality is required'),
});

const AddProfilePage: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { state }: any = useLocation()

  let subType = '';

  if (state?.sub_type === "Child/Traveler") {
    subType = "child";
  } else if (state?.sub_type === "Parent/Guardian") {
    subType = "guardian";
  }

  const initialValues: FormValues = {
    first_name: '',
    last_name: '',
    email: '',
    birth_date: '',
    mobile: '',
    gender: '',
    relationship: '',
    sub_type: subType,
    nationality: '',
  };

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const submitValues = {
      ...values,
      gender: values.gender === 'others' ? values.other_gender : values.gender,
      relationship: values.relationship === 'others' ? values.other_relationship : values.relationship,
      nationality: values.nationality,
    };

    console.log(values.nationality)

    delete submitValues.other_gender;
    delete submitValues.other_relationship;

    try {
      await axiosPrivate.post('/add-contact', submitValues);
      actions.resetForm();
      setMessage('Verification Link has been sent to the provided email');
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      if (state?.page && state?.page === 'enrollment') {
        console.log(state?.page, 'state?.page')
        const selectedCampus = localStorage.getItem('selectedCampus');
        localStorage.setItem('currentStep', '4')
        navigate(`/application/47821104/program/${selectedCampus}`)
      } else {
        navigate(`/my-contacts/${currentUser?.currentClient}`)
      }
      
      setTimeout(() => {
        setMessage('')
      }, 3000)
    }
  };

  const handleDiscard = () => {
    navigate(-1);
  };

  const renderTooltip = (text: string) => (
    <Tooltip id="tooltip">{text}</Tooltip>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ContactDetailSchema}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, isValid, setFieldValue }) => {
        const handleContactTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const { value } = e.target;
          setFieldValue('sub_type', value);
        };

        const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const { value } = e.target;
          setFieldValue('gender', value);
          if (value !== 'others') {
            setFieldValue('other_gender', '');
          }
        };

        const handleRelationshipChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          const { value } = e.target;
          setFieldValue('relationship', value);
          if (value !== 'others') {
            setFieldValue('other_relationship', '');
          }
        };

        return (
          <div className="card card-body">
            <div className="d-flex align-items-center justify-content-center gap-3">
              <FaAddressCard size={100} style={{ color: '#009ef7' }} />
              <h1 style={{ color: '#009ef7' }}>New Contact Details</h1>
            </div>
            <Form>
              {/* First Name and Last Name */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="first_name">First Name</label>
                    <Field type="text" name="first_name" className="form-control form-control-lg" />
                    <ErrorMessage name="first_name" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="last_name">Last Name</label>
                    <Field type="text" name="last_name" className="form-control form-control-lg" />
                    <ErrorMessage name="last_name" component="div" className="text-danger" />
                  </div>
                </div>
              </div>

              {/* Email and Date of Birth */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="email">Email</label>
                    <span className="text-muted small fst-italic d-block">Email must be different than account holder email</span>
                    <Field type="email" name="email" className="form-control form-control-lg" />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="birth_date">Date of Birth</label>
                    <Field
                      type="date"
                      name="birth_date"
                      className="form-control form-control-lg"
                    />
                    <ErrorMessage name="birth_date" component="div" className="text-danger" />
                  </div>
                </div>
              </div>

              {/* Mobile and Contact Type */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="mobile">Mobile</label>
                    <Field type="text" name="mobile" className="form-control form-control-lg" />
                    <ErrorMessage name="mobile" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="sub_type">
                      Contact type
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip("Select the type of contact you are adding.")}
                      >
                        <span className="tooltip-icon ms-2">
                          <IoIosInformationCircleOutline size={15} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <Field as="select" name="sub_type" className="form-control form-control-lg" onChange={handleContactTypeChange}>
                      <option value="">Select a Contact type</option>
                      <option value="guardian">Parent/Guardian</option>
                      <option value="child">Child/Traveler</option>
                    </Field>
                    <ErrorMessage name="sub_type" component="div" className="text-danger" />
                  </div>
                </div>
              </div>

              {/* Gender and Relationship */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="gender">Gender</label>
                    <Field as="select" name="gender" className="form-control form-control-lg" onChange={handleGenderChange}>
                      <option value="">Select a gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="non_binary">Non-binary</option>
                      <option value="preferred_not_to_say">Preferred not to say</option>
                      <option value="others">Other, please specify</option>
                    </Field>
                    {values.gender === 'others' && (
                      <Field type="text" name="other_gender" className="form-control form-control-lg mt-2" placeholder="Specify Gender" />
                    )}
                    <ErrorMessage name="gender" component="div" className="text-danger" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="relationship">
                      Relationship
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip("Specify the relationship of this contact to the account holder.")}
                      >
                        <span className="tooltip-icon ms-2">
                          <IoIosInformationCircleOutline size={15} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <Field as="select" name="relationship" className="form-control form-control-lg" onChange={handleRelationshipChange}>
                      <option value="">Select a relationship</option>
                      <option value="child">Child</option>
                      <option value="spouse">Spouse</option>
                      <option value="sibling">Sibling</option>
                      <option value="agent">Agent</option>
                      <option value="coach">Coach</option>
                      <option value="others">Others, please specify</option>
                    </Field>
                    {values.relationship === 'others' && (
                      <Field type="text" name="other_relationship" className="form-control form-control-lg mt-2" placeholder="Specify Relationship" />
                    )}
                    <ErrorMessage name="relationship" component="div" className="text-danger" />
                  </div>
                </div>
              </div>
              {/* Nationality Field */}
              <div className="f-row row mb-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label fw-bolder text-dark fs-6 required" htmlFor="nationality">
                      What is your primary nationality?
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip('Which passport will you be traveling with?')}
                      >
                        <span className="tooltip-icon ms-2">
                          <IoIosInformationCircleOutline size={15} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <Field as="select" name="nationality" className="form-control form-control-lg">
                      <option value="">Select your nationality</option>
                      {COUNTRIES_ISO_3166_ALPHA_3.map((country, index) => (
                        <option key={index} value={country.name}>{country.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage name="nationality" component="div" className="text-danger" />
                  </div>
                </div>
              </div>


              {/* Form Actions */}
              <div className="d-flex justify-content-start gap-3">
                <Button variant="danger" type="button" onClick={handleDiscard}>Discard</Button>
                <Button variant="primary" type="submit" disabled={isSubmitting || !isValid}>Submit</Button>
              </div>
              {message && <Alert variant="success" className="mt-3">{message}</Alert>}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddProfilePage;
