export default {
  getRecentPaymentSummariesReq: 'getRecentPaymentSummariesReq',
  getEnrollPartTwoForms: 'getEnrollPartTwoForms',
  fetchEnrollStatusReq: 'fetchEnrollStatusReq',
  getUserPaymentsReq: 'getUserPaymentsReq',
  getAllEnrollmentSummariesReq: 'getAllEnrollmentSummariesReq',
  fetchPredetureInfoFormsReq: 'fetchPredetureInfoFormsReq',
  getFlightRostersById: 'getFlightRostersById',
  getListOfFlightsByUser: 'getListOfFlightsByUser',
  getCurrentClientDetails: 'getCurrentClientDetails',
  getPredepartureDocumentsReq: 'getPredepartureDocumentsReq',
  getPrograms: 'getPrograms',
  getOpportunityReq: 'getOpportunityReq',
}