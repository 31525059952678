/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { KTSVG as KtSvg, useDebounce } from '../../../../_metronic/helpers';

interface ReusableSearchComponentProps {
  placeholder?: string;
  value?: string;
  onSearch: (searchTerm: string) => void;
}
const ReusableSearchComponent: React.FC<ReusableSearchComponentProps> = ({ placeholder, value = '', onSearch }) => {
  const [searchTerm, setSearchTerm] = useState<string>(value);
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KtSvg path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  );
};
export { ReusableSearchComponent };