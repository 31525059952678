import React, {useEffect, useMemo, useState} from 'react'
import {useAuth} from '../../auth'
import {useNavigate, useParams} from 'react-router-dom'
import DynamicListHeader from '../../../../_metronic/shared-components/dynamic-list-header'
import DynamicTable from '../../../../_metronic/shared-components/dynamic-table'
import DynamicPagination from '../../../../_metronic/shared-components/dynamic-pagination'
import {KTSVG} from '../../../../_metronic/helpers'
import {getAllFlight} from '../../../../network/api'
import moment from 'moment'
import {
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../products-management/components/core/QueryResponseProvider'
import CustomPagination from '../../../../_metronic/shared-components/CustomPagination'

interface iAirline {
  code: string
  created_at: string
  id: number
  name: string
  updated_at: string
}

interface iArrivalAirport {
  city: string
  code: string
  country: string
  created_at: string
  id: number
  updated_at: string
}

interface iDepartureAirport {
  city: string
  code: string
  country: string
  created_at: string
  id: number
  updated_at: string
}

interface iProgramProduct {
  client_id: string
  country: string
  created_at: string
  division: string
  due_upon_application: number
  duration: number
  end_date: string
  group: null
  id: number
  is_active: false
  is_guaranteed: false
  name: string
  predeparture: null
  pricebook_entry_id: string
  program_summary: string
  session_id: null
  sf_id: string | null
  slug: null
  start_date: string
  status: null
  student_type: null
  tuition: number
  updated_at: string
}

interface iStudent {
  client_id: string[]
  client_names: null
  created_at: string
  created_by: null
  email: string
  id: number
  isPrimary: null
  opportunity_active: null
  opt_flightplan: null
  password: string
  password_reset_token: string
  password_reset_token_expiration: string
  pending_password_reset: boolean
  role_id: number
  sf_id: string | null
  status: string
  updated_at: string
}

interface iFlight {
  airline: iAirline[]
  airline_id: number
  arrival_airport: iArrivalAirport[]
  arrival_airport_id: number
  arrival_date: string
  arrival_time: string
  created_at: string
  departure_airport: iDepartureAirport[]
  departure_airport_id: number
  departure_date: string
  departure_time: string
  flight_number: string
  flight_type: string
  id: number
  notes: string
  opportunity: null
  opportunity_id: null
  program_product: iProgramProduct[]
  program_product_id: number
  sf_id: string | null
  status_id: number
  student: iStudent[]
  student_id: number
  updated_at: string
  uuid: string
}

const FlightList = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const params = useParams()

  // STATES
  const [data, setData] = useState<iFlight[]>([])
  const [errorDelete, setErrorDelete] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const handleCallBackFunctionSearch = (searchQuery: string) => {}
  const handleCallBackFunctionButton = () => {}
  const callBackPagination = (data: any) => {
    console.log(data, 'data')
  }
  const editFlightHandler = (e: any, id: any) => {
    navigate(`/apps/${currentUser?.currentClient}/flight-management/edit-flight/${id}`)
  }
  const handleOpenAction = (id: any) => {
    console.log(id, 'id')
    navigate(`/apps/${params.client}/flight-management/flights`)
  }

  useEffect(() => {
    const getAllFlightList = async () => {
      try {
        setLoading(true)
        const {data} = await getAllFlight()
        setData(data)
      } catch (error) {
        console.log(error, 'error')
      } finally {
        setLoading(false)
      }
    }

    getAllFlightList()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Student Name',
        accessor: 'student_id',
        Cell: ({value}: any) => {
          const email = data?.map((email: any) => email.student)
          const emails = email?.find((item: any) => item.id === value)
          return emails ? emails.email : ''
        },
      },
      {
        Header: 'Program Product',
        accessor: 'program_product_id',
        Cell: ({value}: any) => {
          const program = data?.map((program: any) => program.program_product)
          const programs = program?.find((item: any) => item.id === value)
          return programs ? programs.program_summary : ''
        },
        minWidth: 300,
      },
      {
        Header: 'Flight Type',
        accessor: 'flight_type',
        minWidth: 130,
      },
      {
        Header: 'Airline',
        accessor: 'airline_id',
        Cell: ({value}: any) => {
          const airline = data?.map((airline: any) => airline.airline)
          const airlines = airline?.find((item: any) => item.id === value)
          return airlines ? airlines.name : ''
        },
        minWidth: 100,
      },
      {
        Header: 'Flight Number',
        accessor: 'flight_number',
      },
      {
        Header: 'Departure Airport',
        accessor: 'departure_airport_id',
        Cell: ({value}: any) => {
          const departureAirport = data?.map((airport: any) => airport.departure_airport)
          const airports = departureAirport?.find((item: any) => item.id === value)
          return airports ? `${airports.code} - ${airports.country}, ${airports.city}` : ''
        },
        minWidth: 130,
      },
      {
        Header: 'Departure Date',
        accessor: 'departure_date',
        Cell: ({value}: any) => moment(new Date(value)).format('YYYY-MM-DD'),
        minWidth: 100,
      },
      {
        Header: 'Departure Time',
        accessor: 'departure_time',
      },
      {
        Header: 'Arrival Airport',
        accessor: 'arrival_airport_id',
        Cell: ({value}: any) => {
          const arrivalAirport = data?.map((airport: any) => airport.arrival_airport)
          const airports = arrivalAirport?.find((item: any) => item.id === value)
          return airports ? `${airports?.code} - ${airports?.country}, ${airports?.city}` : ''
        },
        minWidth: 130,
      },
      {
        Header: 'Arrival Date',
        accessor: 'arrival_date',
        Cell: ({value}: any) => moment(new Date(value)).format('YYYY-MM-DD'),
        minWidth: 100,
      },
      {
        Header: 'Arrival Time',
        accessor: 'arrival_time',
      },
      {
        Header: 'Status ID',
        accessor: 'status_id',
        Cell: ({value}: any) => (
          <div
            style={{
              backgroundColor: value === 14 ? '#e8fff3' : '#ffe8e8',
              color: value === 14 ? '#50cd89' : '#cd5050',
              padding: '0.5rem',
              borderRadius: '0.25rem',
              minWidth: value === 14 ? 60 : 70,
              maxWidth: value === 14 ? 60 : 70,
            }}
          >
            {value === 14 ? 'Active' : 'Inactive'}
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'id',
        Cell: (props: any) => (
          <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
            <a
              href='javascript:void(0)'
              onClick={() => {
                handleOpenAction(props.value)
              }}
              className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              id={`action_button_` + props.value}
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
              id={'subMenu_' + props.value}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  onClick={(e) => {
                    editFlightHandler(e, props.value)
                  }}
                >
                  Edit
                </a>
              </div>
            </div>
          </>
        ),
      },
    ],
    [data]
  )

  const [perPage, setPerPage] = useState(10)
  const [visibleData, setVisibleData] = useState<iFlight[]>([])

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    // Update the visible data based on the current page and items per page
    const startIndex = (currentPage - 1) * perPage
    let endIndex = startIndex + perPage

    // Ensure endIndex doesn't exceed the total number of items
    endIndex = Math.min(endIndex, data.length)

    setVisibleData(data.slice(startIndex, endIndex))
  }, [data, currentPage, perPage])

  return (
    <>
      <DynamicListHeader
        isSearch={false}
        searchPlaceHolder={'Search Flight List'}
        callBackFunctionSearch={handleCallBackFunctionSearch}
        buttonText={'Add Flight'}
        buttonLink={`/apps/${currentUser?.currentClient}/flight-management/add-flight`}
      />
      <div className='card-body mt-8'>
        <DynamicTable columns={columns} data={visibleData} />
        {loading && (
        <span className='indicator-progress' style={{display: 'flex', justifyContent: 'center'}}>
          <span
            style={{height: '40px', width: '40px'}}
            className='spinner-border spinner-border-sm align-middle mt-20'
          ></span>
        </span>
        )}
      </div>
      <CustomPagination
        currentPage={currentPage}
        totalPages={Math.ceil(data.length / perPage)}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default FlightList
