import {AxiosResponse} from 'axios'
import useAxiosPrivate from '../../../auth/hooks/useAxiosPrivate'
import {IProductDetailData, ProductsQueryResponse} from '../components/core/_models'


export const useProductRequests = () => {
  const axisosPrivate = useAxiosPrivate()
  const getAllProductsReq = (query: string): Promise<ProductsQueryResponse> => {
    return axisosPrivate
      .get(`/get_all_products/?${query}`)
      .then((d: AxiosResponse<ProductsQueryResponse>) => d.data)
  }

  const getProductDetailFieldsReq = async (productId: string | undefined) => {
    if (!productId) {
      return
    }
    const {data} = await axisosPrivate.get<{
      data: IProductDetailData
    }>(`/get_product_detail_fields/${productId}`)
    return data
  }

  const getProductFieldsReq = async () => {
    const {data} = await axisosPrivate.get<{
      data: IProductDetailData
    }>(`/get_product_fields`)
    return data
  }

  const saveProductDetailReq = async (inputs: any) => {
    const {data} = await axisosPrivate.post(`/save_product_detail`, inputs)
    return data
  }

  const addProductDetailReq = async (inputs: any) => {
    const {data} = await axisosPrivate.post(`/add_product_detail`, inputs)
    return data
  }
  
  return {
    getAllProductsReq,
    getProductDetailFieldsReq,
    saveProductDetailReq,
    getProductFieldsReq,
    addProductDetailReq,
  }
}
