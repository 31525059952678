import {useField} from 'formik'

const CustomCheckbox = ({label, ...props}: any) => {
  const [field, meta] = useField(props)
  return (
    <>
      <div className='form-check form-check-custom form-check-solid'>
        <input
          {...field}
          {...props}
          className={meta.touched && meta.error ? 'cursor-pointer' : 'cursor-pointer'}
        />
        <span className='form-check-label'>{label}</span>
      </div>

      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container text-danger'>{meta.error}</div>
      )}
    </>
  )
}

export default CustomCheckbox
