import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { exportEnrolleeAndSessionById, fetchEnrolleeAndSessionById } from '../../../../network/api'
import {useParams} from 'react-router-dom'
import Filter from './components/Filter'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'

const CourseEnrollList = () => {
  const { program_session_id } = useParams()

  // STATES
  const [list, setList] = useState<any[]>([])
  const [currentSession, setCurrentSession] = useState<any>('')
  const [currentStatus, setCurrentStatus] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [exportError, setExportError] = useState('')
  const [exportSuccess, setExportSuccess] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [search, setSearch] = useState('')

  useEffect(() => {
    setLoading(true)
    const GetAllEnrollee = async () => {
      try {
        const {status, data} = await fetchEnrolleeAndSessionById(program_session_id)
        const {program_session, studentList} = data
        setList(studentList)
        setCurrentSession(program_session)
        setLoading(false)
      } catch (err) {
        setErrorMessage(`Error while fetching data: ${err}`)
        console.error(err)
      }
    }
    GetAllEnrollee()
  }, [refresh])

  const getExportSession = async () => {
    setExportLoading(true)
    try {
      const { data } = await exportEnrolleeAndSessionById(program_session_id, currentStatus)
      if(data.statusCode === 1) {
        setExportSuccess(data.message)
      } else {
        setExportError(data.message)
      }
      setExportLoading(false)
      setTimeout(() => {
        setExportError('')
        setExportSuccess('')
      }, 2000)
    } catch (error) {
      setExportError("Export Data Failed")
      setExportLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'updated_at',
        Cell: ({value}: any) => {
          const formattedDate = moment(value).format('MM/DD/YYYY hh:mm:ss A')
          return formattedDate
        },
        minWidth: 120,
      },
      {
        Header: 'Last Name',
        accessor: 'userclient.last_name',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
      {
        Header: 'First Name',
        accessor: 'userclient.first_name',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
      {
        Header: 'Gender',
        accessor: 'userclient.sex_assigned_at_birth',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
      {
        Header: 'Age',
        accessor: 'userclient.date_of_birth',
        Cell: ({value}: any) => {
          const birthDate = moment(value)
          const currentDate = moment()
          const age = currentDate.diff(birthDate, 'years')
          return age || 0
        }
      },
      {
        Header: 'Grade',
        accessor: 'userclient.grade_entering_fall',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
      {
        Header: 'Application Status',
        accessor: 'opp_status.name',
        Cell: ({value}: any) => {
          return value || 'N/A'
        }
      },
    ],
    [list]
  )

  const [visibleData, setVisibleData] = useState<any[]>([])

  useEffect(() => {
    const filteredStatus = currentStatus !== 0 ? list.filter(
      item => item.opp_status?.id === currentStatus
    ) : list
    const filteredList = search.length !== 0 
    ? filteredStatus.filter(
        item => item.userclient?.last_name?.toLowerCase().includes(search.toLowerCase()) ||
        item.userclient?.first_name?.toLowerCase().includes(search.toLowerCase())
      ) 
    : filteredStatus
    setVisibleData(filteredList)
  }, [list, search, currentStatus])

  return (
    <>
      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}
      {exportSuccess && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{exportSuccess}</span>
            </div>
          </div>
        </div>
      )}
      
      {(exportError) && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{exportError}</span>
            </div>
          </div>
        </div>
      )}
    <div className='card card-body p-0'>
      <Filter
          placeholder={`Search ${currentSession.name ? currentSession.name : 'Course'}`}
          setStatus={setCurrentStatus} 
          setSearch={setSearch} 
          search={search}
          exportLoading={exportLoading}
          getExport={getExportSession}/>
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading} />
      </div>
    </>
  )
}

export default CourseEnrollList

