import React from 'react'
import { KTCard } from '../../../../../_metronic/helpers'
import { OppListHeader } from './header/OppListHeader'
import { OpportunitiesTable } from './table/OpportunitiesTable'

const OpportunitiesList = () => {
  return <KTCard>
    <OppListHeader/>
    <OpportunitiesTable/>
  </KTCard>
}

export default OpportunitiesList