import React from 'react'
import { KTCard } from '../../../../../_metronic/helpers'
import { PSListHeader } from './header/PSListHeader'
import {PaymentSchedulesTable} from './table/PaymentSchedulesTable'

const PaymentSchedulesList = () => {
  return (
    <KTCard>
      <PSListHeader />
      <PaymentSchedulesTable />
    </KTCard>
  )
}

export default PaymentSchedulesList