import { useEffect, useMemo, useState } from "react"
import { useAuth } from "../../auth"
import { KTSVG } from "../../../../_metronic/helpers"
import { getAllTypeFormByAdmin, addOrEditTypeForm } from "../../../../network/api"
import { useNavigate, useParams } from "react-router-dom"
import Header from "../../../../_metronic/shared-components/Header"
import DynamicTableV2 from "../../../../_metronic/shared-components/dynamic-table-v2"


const TypeFormList = () => {
    const navigate = useNavigate()
    const {currentUser} = useAuth()
    const params = useParams()
    // const [enrollmentForm, setEnrollmentForm] = useState<IEnrollmentForm[]>([]) 
    const [loading, setLoading] = useState(false)
    const [visibleData, setVisibleData] = useState<any[]>([])
    const [search, setSearch] = useState('')

    useEffect(() => {
        setLoading(true)
        const fetchAllTypeForms = async () => {
          try {
            const { data } = await getAllTypeFormByAdmin()
            data.sort((a: any, b: any) => b.id - a.id)
            setVisibleData(data)
          } catch (error) {
            console.error("Error fetching TypeForms:", error)
          } finally {
            setLoading(false)
          }
        }
        fetchAllTypeForms()
    }, [])

    useEffect(() => {
        if (search.length > 0) {
          const filteredData = visibleData.filter((item) =>
            item.name?.toLowerCase().includes(search.toLowerCase()) ||
            item.form_id?.toLowerCase().includes(search.toLowerCase()) ||
            item.overview?.toLowerCase().includes(search.toLowerCase())
          )
          setVisibleData(filteredData)
        }
    }, [search, visibleData])

    const addOrEditTypeForm = (e: any, id: any) => {
        navigate(
          `/apps/${currentUser?.currentClient}/typeform-management/edit-typeform/${id}`
        )
    }
    
    const handleOpenAction = (id: any) => {
        navigate(`/apps/${params.client}/typeform-management/typeform`)
    }


    const columns = useMemo(() => [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Form ID',
          accessor: 'form_id',
          Cell: (props: any) => {
              const form_id = props?.row?.original?.form_id
              return form_id || 'N/A'
          }
        },
        {
          Header: 'Overview',
          accessor: 'overview',
          Cell: (props: any) => {
              const overview = props?.row?.original?.overview
              return overview || 'N/A'
          }
        },
        {
            Header: 'Actions',
            accessor: 'id',
            disableSortBy: true,
            Cell: (props: any) => (
              <>
                <button 
                  onClick={() => {
                    handleOpenAction(props.value)
                  }}
                  className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  id={`action_button_` + props.value}
                >
                  Actions
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
                </button>
                {/* begin::Menu */}
                <div
                  id={'subMenu_' + props.value}
                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                  data-kt-menu='true'
                >
                  {/* begin::Menu item */}
                  <div className='menu-item px-3'>
                    <a
                      className='menu-link px-3'
                      onClick={(e) => {
                        addOrEditTypeForm(e, props.value)
                      }}
                    >
                      Edit
                    </a>
                  </div>
                </div>
              </>
            ),
          },
    ], [])

    return (
        <>
        <div className="card card-body p-0">
            <Header
            buttonLink={`/apps/${currentUser?.currentClient}/typeform-management/add-typeform`}
            buttonText='Add TypeForm'
            placeholder='Search typeform'
            setSearch={setSearch}
            search={search}
            />
            <DynamicTableV2 columns={columns} data={visibleData} loading={loading}/>
        </div>
        </>
    )
}

export default TypeFormList