import ReactHtmlParser from 'react-html-parser'

const enrollInfo = {
  heading: `Start Your Enrollment - Apply Now!`,
  note: ``,
  bodies: [
    `<p>Congratulations on taking the next step on your path to joining one of our service-learning travel programs 
    for high school students. This is your chance to <i style="color: #777777; font-size: inherit;">be the change you wish to see in the world</i>, and you're only a 
    few clicks away from making it happen.</p>
    <p>Please note that our 2023 programs are open for applications, but are not currently confirmed to depart. We
     will confirm sessions to run on a rolling basis, but encourage you to save your spot early before waitlists begin 
     to form.</p>
    <p>Please familiarize yourself with our 
    <a
    target='_blank'
    rel='noopener noreferrer'
    href='https://www.experiencegla.com/corona-response/?_ga=2.229580316.1812872946.1668638517-2065406207.1619755917'
  >COVID-19</a> response plan and our 
  <a
  target='_blank'
    rel='noopener noreferrer'
    href='https://www.experiencegla.com/faq-tuition/?_ga=2.242229122.1812872946.1668638517-2065406207.1619755917'
  >refund policy</a> prior to placing your deposit. 
    Your deposit and application fee are non-refundable, but fully transferable as a lifetime credit. We recommend Travel 
    Insurance with Cancel for Any Reason coverage to protect your investment beyond our Terms & Conditions. Travelers 
    will need to wait to purchase flights until the program has been confirmed to run. We anticipate programs will 
    be confirmed to begin booking flights starting in late winter and continuing through the spring.</p>
    
   <p> Apply by mail- If you would prefer to apply by mail please 
   <a href="https://link.experiencegla.com/paperapp?_ga=2.242229122.1812872946.1668638517-2065406207.1619755917" target="_blank">
   <span>click here</span></a> to download and print a paper version of our application.</p>
    
    <p>Program availability- Our programs begin to fill in January. Applying early assures a spot in your desired 
    session date.</p>
    
    <p>Virtual program registration- If you are looking for our virtual program or internship registration form, 
    <a href="https://www.experiencegla.com/register?_ga=2.31996990.1812872946.1668638517-2065406207.1619755917"><span>click here</span></a>
    to register.</p>`,
  ],
}

const EnrollInfo = () => {
  const currentRoute = window.location.pathname.split('/')[2] || ''
  return (
    <div className='my-xl-15'>
      <div className='fw-bold fs-3x text-uppercase'>
        {currentRoute === '84984438' ? enrollInfo.heading : ''}
      </div>
      <p className='fw-bold fs-4'>{currentRoute === '84984438' ? enrollInfo.note : ''}</p>
      {currentRoute === '84984438' ? (
        enrollInfo.bodies.map((t, i) => (
          <div className='fs-4' key={i}>
            {ReactHtmlParser(t)}
          </div>
        ))
      ) : (
        <div className='fs-4'>
          {currentRoute !== '88562775' ? (
            <>
              <h3>Tuition Options:</h3>
              <div style={{padding: '15px 0 0 20px',}}>
                <p>Residential Students</p> 
                  <ul>
                    <li>Includes: All meals, lodging, field trips, course and weekend excursions</li>
                    <li>Excludes: Optional airport pickup and drop off service (available for an additional fee)</li>
                  </ul>
                <p>Commuter Students</p> 
                  <ul>
                    <li>Includes: Lunch, course and programming from 9am to 6pm Monday to Friday.</li> 
                    <li>Excludes: Lodging, breakfast, dinner, evening activities and weekend excursions</li>
                  </ul>
              </div>
            </>
          ) : ''}
        </div>
      )}
    </div>
  )
}

export default EnrollInfo
