import React from 'react'
import {useAuth} from '../../auth'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import {UsersListWrapper} from '../user-management/users-list/UsersList'
import EnrollmentAdd from './EnrollmentAdd'
import EnrollmentEdit from './EnrollmentEdit'
import EnrollmentList from './EnrollmentList'

const EnrollmentGroup = () => {
  const {currentUser} = useAuth()

  const enrollmentGroupBreadCrumbs: Array<PageLink> = [
    {
      title: 'Enrollment Group',
      path: `/apps/${currentUser?.currentClient}/enrollment-group-management/enrollment-group`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='enrollment-group'
          element={
            <>
              <PageTitle breadcrumbs={enrollmentGroupBreadCrumbs}>Enrollement Group List</PageTitle>
              <EnrollmentList />
            </>
          }
        />
        <Route
          path='add-enrollment-group'
          element={
            <>
              <PageTitle breadcrumbs={enrollmentGroupBreadCrumbs}>Add Enrollment Group</PageTitle>
              <EnrollmentAdd />
            </>
          }
        />
        <Route
          path='edit-enrollment-group/:enrollment_form_group_id'
          element={
            <>
              <PageTitle breadcrumbs={enrollmentGroupBreadCrumbs}>Edit Enrollment Group</PageTitle>
              <EnrollmentEdit />
            </>
          }
        />
      </Route>
      <Route
        index
        element={<Navigate to={`/apps/${currentUser?.currentClient}/enrollment-group-management/enrollment-group`} />}
      />
    </Routes>
  )
}

export default EnrollmentGroup
