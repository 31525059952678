import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'

const Error404: FC = () => {
  const {currentUser, logout, logoutServer} = useAuth()
  const isLoading = logoutServer?.isLoading
  function onLogout() {
    //execute to call logout API
    logoutServer?.mutate(null, {
      onSuccess: () => {
        //remove in local
        logout()
      },
      onError: (err: any) => {
        if (err.response.status === 404) {
          //remove in local
          logout()
        }
      },
    })
  }
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/18.png')}
          alt=''
          className='mw-100 mb-10 h-lg-450px'
        />
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
          Seems there is nothing here
        </h1>
        {/* end::Message */}
        <div className='w-lg-500px rounded p-10 p-lg-15 mx-auto text-center'>
          {/* begin::Link */}
          <Link to='/' className='btn btn-primary'>
            Return Home
          </Link>

          {currentUser && (
            <>
              {' '}
              <div className='text-center text-muted text-uppercase fw-bolder my-5'>or</div>
              <button className='btn btn-light' onClick={onLogout}>
                {!isLoading ? (
                  <div className='d-flex align-items-center justify-content-center'>
                    <span className='btn-label px-2'>Sign Out</span>
                    <i className='fa-solid fa-right-from-bracket'></i>
                  </div>
                ) : (
                  <div className='d-flex align-items-center justify-content-center'>
                    <span className='btn-label px-2' style={{display: 'block'}}>
                      Please wait...
                    </span>
                    <span className='spinner-border spinner-border-sm align-middle '></span>
                  </div>
                )}
              </button>{' '}
            </>
          )}
        </div>
        {/* end::Link */}
      </div>
    </div>
  )
}

export {Error404}
