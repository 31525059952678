const QUERIES = {
  USERS_LIST: 'users-list',
  CAMPAIGNS_LIST: 'campaigns-list',
  OPPORTUNITIES_LIST: 'opportunities-list',
  PRODUCTS_LIST: 'products-list',
  PAYMENT_SCHEDULES_LIST: 'payment-schedules-list',
  CLIENTS_LIST: 'clients-list',
}

export {QUERIES}
