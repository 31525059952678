import React, { useMemo, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import { currencyFormat } from '../../../../../utils/funcHelpers'
import { IProduct, IProductDetail, IProgram, IGeneralFee } from '../../core/_models'
import { useQueryClient } from 'react-query'
import { getScholarShipCodeDataAPI } from '../../../../../network/api';
import moment from 'moment'
import { useParams } from 'react-router-dom'

interface Props {
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const Step5a = ({ currentFormikVal, setFieldValue }: Props) => {
  const {client} = useParams()
  const [scholarshipCode, setScholarshipCode] = useState("")
  const [scholarshipCodeError, setScholarshipCodeError] = useState(false)
  const [scholarshipCodeSuccess, setScholarshipCodeSuccess] = useState(false)
  const [scholarShipFieldError, setScholarShipFieldError] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [scholarshipData, setScholarshipData] = useState<any>(null)
  const queryClient = useQueryClient()
  const product_raw_list = queryClient.getQueryData<{
    programs: IProduct[]
    general_fees: IGeneralFee[]
  }>('programsEnroll')

  let selected_product_data: IProductDetail[] = [];

  // console.log("product_raw_list", product_raw_list)
  product_raw_list?.programs.forEach((d) =>
    d.detail.forEach(p => {
      if (currentFormikVal.product == p.id) {
        // get addition fees and update to enrollment_extras
        const additional_options: any = []

        currentFormikVal.additionalOpt?.forEach((o) => {
          const obj = JSON.parse(o)
          // console.log(obj)
          if (obj.program_id === p.id.toString()) {
            additional_options.push(obj.additional)
          }
          // console.log("additional_options", additional_options)
        })

        console.log("additional_options", additional_options)

        const newExtras = p.extras?.filter((ex) => additional_options.includes(ex.id) || ex.is_required == true)
        selected_product_data.push({
          id: p.id,
          name: p.name,
          country: p.country,
          startDate: p.startDate,
          endDate: p.endDate,
          duration: p.duration,
          tuition: p.tuition,
          deposit: p.deposit,
          status: p.status,
          type: p.type,
          extras: newExtras,
          group: p.group,
          program_track: p.program_track,
        })
      }
    })
  )
  const product = useMemo(() => {
    return selected_product_data
  }, [selected_product_data])

  // console.log("product", product)
  // console.log("selected_product_data", selected_product_data)
  // console.log("currentFormikVal.product", currentFormikVal.additionalOpt)

  const totalAmount =
    product && product.reduce((accumulator, obj) => {
      const amountFees = obj.extras?.reduce((accumulator2, obj2) => {
        return accumulator2 + obj2.enrollment_extra.amount
      }, 0)
      return accumulator + obj.tuition + amountFees
    }, 0)

    let totalFees = product && product.reduce((accumulator, obj) => {
      const amountFees = obj.extras?.reduce((accumulator2, obj2) => {
        if (obj2?.enrollment_extra?.category === 8) {
              return accumulator2 + obj2.enrollment_extra.amount;
          } else {
              return accumulator2;
          }
      }, 0);
      return amountFees ? accumulator + amountFees + obj.deposit : accumulator + obj.deposit;
  }, 0);

  if (scholarshipData && scholarshipData.type === 'Discount') {
    totalFees += scholarshipData.standard_price.unit_price
  }
  
  // const removeproduct = (id: string | number) => {
  //   console.log('removeproduct', id)
  //   const product = currentFormikVal.product

  //   const index = product?.indexOf(id.toString())
  //   if (!_.isUndefined(index) && index > -1) {
  //     // only splice array when item is found
  //     product?.splice(index, 1)
  //     setFieldValue('product', product)
  //   }
  // }
  const handleSubmitScholarShipCode = (e: any) => {
    if (scholarshipCode == '') {
      setScholarShipFieldError(true)
      return;
    } else {
      setScholarShipFieldError(false)
    }
    let dataToSend = {
      scholarship_code: scholarshipCode
    }
    handleSubmitScholarShipCodeServer(dataToSend)
  }

  const handleSubmitScholarShipCodeServer = async (data: any) => {
    setIsLoader(true)
    const response = await getScholarShipCodeDataAPI(data);
    setIsLoader(false)
    console.log(response,'response')
    if (response?.data) {
      setFieldValue('scholarshipCode', response?.data?.id)
      // setMappedDocuments(response?.data?.message)
      setScholarshipData(response?.data)
      setScholarshipCodeSuccess(true)
      setScholarshipCodeError(false)
    } else {
      setScholarshipCodeError(true)
      setScholarshipCodeSuccess(false)
    }
  }
  const currentRoute = window.location.pathname.split('/')[2] || ''

  const course = currentFormikVal.academicTrack?.split('"')[3]
  const currentCourse = selected_product_data[0]?.program_track?.find((item: any) => item.id === Number(course))

  const clientRoutes = 
  client === '88562775' ? 'https://teentravelnetwork.com/legal/terms/' : 
  client === '47821104' ? 'https://summerspringboard.com/privacy-policy' : 
  'https://www.experiencegla.com/privacy-policy/'
  
  return (
    <div className='fv-row w-100'>
      <div className='row'>
        <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
          Step 5: Confirm Your Enrollment
        </h2>
      </div>
      <input type="hidden" defaultValue={scholarshipCodeSuccess.toString()} name="scholarshipCodeSuccess"/>
      <input type="hidden" defaultValue={scholarshipCode} name="scholarshipCode"/>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive table-confirm-enrollment'>
          {/* begin::Table */}
          <table className='table table-row-dashed  align-middle gs-0 gy-4' style={{ color: '#020038' }}>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold' style={{ background: '#e6e6e6'}}>
                <th><h2 className='ps-5' style={{ color: '#020038' }}>Programs</h2></th>
                <th><h3 style={{ color: '#020038' }}>Total Amount</h3></th>
                <th><h3 style={{ color: '#020038' }}>Due now</h3></th>
                <th></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='fs-5'>
              {selected_product_data?.map((p, idx) => (
                <>
                  <tr key={idx}>
                    <td>
                      <span className='d-block ps-5'>
                        {/* {((p.country) ? p.country + "" : '') +
                          ': ' +
                          p?.name +
                          ' (' +
                          p.startDate +
                          '-' +
                          p.endDate +
                          ')'} */}
                          {`${p?.country}: ${p?.name} ${p?.type} 
                          (${moment(p.startDate).format('MM/DD/YYYY')} - 
                          ${moment(p.endDate).format('MM/DD/YYYY')}) `}
                          {currentRoute === '47821104' && currentCourse?.name}
                      </span>
                    </td>
                    <td>
                      <span className='d-block'>{currencyFormat.format(p.tuition)}</span>
                    </td>
                    <td>
                      <span className='d-block'>
                        {currencyFormat.format(p.deposit)}
                      </span>
                    </td>
                    {/* <td className='text-center'>
                      <button
                        type='button'
                        className='btn btn-icon-danger btn-text-danger'
                        onClick={() => removeproduct(p.id)}
                      >
                        <span className='svg-icon svg-icon-1'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen034.svg'
                            className='svg-icon-muted svg-icon-2hx'
                          />
                        </span>
                      </button>
                    </td> */}
                  </tr>
                  <tr>
                    <td className='fw-bold ps-5'>Fee</td>
                  </tr>
                  {p.extras?.map((f) => {
                    return (
                      <tr key={f.id}>
                        <td className='ps-5'>{f.enrollment_extra.name}</td>
                        <td>{currencyFormat.format(f.enrollment_extra.amount)}</td>
                        <td>{f?.enrollment_extra?.category == 8 ? currencyFormat.format(f.enrollment_extra.amount) : ''}</td>
                    </tr>
                    )
                  })}
                </>
              ))}
              {/* <tr>
                <td></td>
                <td className='text-right'>Subtotal</td>
                <td>{totalAmount && totalFees && currencyFormat.format(totalAmount + totalFees)}</td>
              </tr> */}

              {scholarshipCodeSuccess &&
                <tr>
                  <td className='fw-bold ps-5'>
                   Scholarship
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              }
              {scholarshipCodeSuccess &&
                <tr>
                  <td className='ps-5'>
                    {scholarshipData?.name}
                  </td>
                  <td>{scholarshipData?.standard_price?.name.indexOf("%") > -1?scholarshipData?.standard_price?.name: scholarshipData?.standard_price?.unit_price && currencyFormat.format(scholarshipData?.standard_price?.unit_price)}</td>
                  <td>{scholarshipData?.type === 'Discount' ? currencyFormat.format(scholarshipData?.due_upon_application) : '$0.00' }</td>
                </tr>
              }
              {scholarshipCodeSuccess ?
                <tr style={{ background: '#e6e6e6'}}>
                  <td className='ps-5'><h2 style={{ color: '#020038' }}>Total</h2></td>
                  <td>
                    {totalAmount &&
                      currencyFormat.format(
                        totalAmount -
                          (scholarshipData?.standard_price?.name.indexOf('%') > -1
                            ? (scholarshipData?.standard_price?.name.replace('%', '') / 100) *
                              totalAmount
                            : scholarshipData?.standard_price?.unit_price * -1)
                      )}
                  </td>
                  <td className='fw-bold'>
                    {totalFees && currencyFormat.format(totalFees)} Due Now
                  </td>
                </tr>
                :
                <tr style={{ background: '#e6e6e6'}}>
                  <td className='ps-5'><h2 style={{ color: '#020038' }}>Total</h2></td>
                  <td>{totalAmount && currencyFormat.format(totalAmount)}</td>
                  <td className='fw-bold'>{totalFees && currencyFormat.format(totalFees)} Due Now</td>
                </tr>
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* <div className='col-lg-8 d-flex my-7'>
          <label className='form-label fw-bold text-dark fs-6 me-5 mb-0'>Scholarship Code</label>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid me-5'
            name='scholarshipCode'
          />
          <a href='#' className='btn btn-primary'>
            Redeem
          </a>
        </div> */}
        <div className='my-10'>
        {currentRoute != "88562775"?
          <>
            <div className='row' style={{marginBottom: '10px'}}>
              <label className='flex-grow-1 fs-6 mb-2 fw-bolder'>If you were given a scholarship code, please enter it below:</label>
              <div className='col-sm-2'>
                <input
                  type='text'
                  style={{borderColor: scholarShipFieldError ? 'red' : ''}}
                  className='form-control'
                  placeholder='Scholarship Code'
                  onChange={(e) => {
                    setScholarshipCode(e.target.value)
                  }}
                />
              </div>
              <div className='col-sm-2'>
                <a
                  className='btn btn-primary'
                  onClick={(e) => {
                    handleSubmitScholarShipCode(e)
                  }}
                >
                  {isLoader ? (
                    <>
                      Fetching{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </>
                  ) : (
                    'Submit'
                  )}
                </a>
              </div>
            </div>
            {scholarshipCodeError &&
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>Invalid Scholarship code</div>
              </div>
            }
            {scholarshipCodeSuccess &&
              <div className='mb-lg-15 alert alert-success'>
                <div className='alert-text font-weight-bold'>Your Scholarship has been applied to your application</div>
                </div>
            }
            <p className='fs-2 text-center'>
            This is the first step of your enrollment. Once you click this button and complete your
            payment, your spot will be saved on your selected program. After that, you can come back
            a bit later to complete the remainder of your enrollment. Click the button to proceed
            and save your spot!
            </p>
          </>
          :
          <>
            <p className='fs-2 text-center'>
              You must pay your application fee and place a deposit on the next page to finish
              saving your spot on a Teen Travel Network program.
            </p>
            <div className='row' style={{marginBottom: '10px'}}>
              <label className='flex-grow-1 fs-6 mb-2 fw-bolder'>If you were given a scholarship code, please enter it below:</label>
              <div className='col-sm-2'>
                <input
                  type='text'
                  style={{borderColor: scholarShipFieldError ? 'red' : ''}}
                  className='form-control'
                  placeholder='Scholarship Code'
                  onChange={(e) => {
                    setScholarshipCode(e.target.value)
                  }}
                />
              </div>
              <div className='col-sm-2'>
                <a
                  className='btn btn-primary'
                  onClick={(e) => {
                    handleSubmitScholarShipCode(e)
                  }}
                >
                  {isLoader ? (
                    <>
                      Fetching{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </>
                  ) : (
                    'Submit'
                  )}
                </a>
              </div>
            </div>
            {scholarshipCodeError &&
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>Invalid Scholarship code</div>
              </div>
            }
            {scholarshipCodeSuccess &&
              <div className='mb-lg-15 alert alert-success'>
                <div className='alert-text font-weight-bold'>Your Scholarship has been applied to your application</div>
                </div>
            }
          </>
          }
          <div>
            <br />
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                border: '1px dotted #019ef7',
                padding: '10px',
              backgroundColor:'#e3e3e3'
            }}>
              <Field
                type='checkbox'
                className='form-check-input'
                name='acceptTerms'
                id={'acceptTerms'}
              style={{ border: '2px solid #019ef7' }}
              />
              <label className='form-check-label ms-2 fs-6 fw-bold' htmlFor={'acceptTerms'}>
                By selecting Reserve Your Spot, you acknowledge and agree to the {' '}
                <a href={clientRoutes} target='_blank' rel="noreferrer">
                 Terms & Conditions and Privacy Policy.
                </a>
              </label>
            </div>
          </div>
          <div className='text-danger text-center fs-5 my-5'>
            <ErrorMessage name='acceptTerms' className='' />
          </div>
        </div>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default Step5a
