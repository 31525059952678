import {Formik, Form,} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {incompletedPartTwoTabId} from '../../../../utils/funcHelpers'
import {ScrollTopComponent, StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../auth'
import _queryKeys from '../core/_queryKeys'
import clsx from 'clsx'

import {
  ICompletionStatusData,
  IEnrollPartTwo,
  initPolicies,
  IEnrollPartTwoForm,
} from '../core/_models'

import useAppRequests from '../Hooks/useAppRequests'
import CustomStep from './PartTwoSteps/CustomStep'
import ErrorMessages from '../../Shared/components/ErrorMessages'

const EnrollPartTwoStep = () => {
  const navigate = useNavigate()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  // const [currentSchema, setCurrentSchema] = useState(partTwoSchemas[0])
  //const [initValues, setInitValues] = useState<any>(initPolicies)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [message, setMessage] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [isWarning, setIsWarning] = useState(false)
  const [compeletionData, setCompeletionData] = useState<ICompletionStatusData | null>(null)
  const {saveAndContinueLater, enrollPartTwoFinalSubmission, fetchEnrollPartTwo} = useAppRequests()
  const {currentUser} = useAuth()
  const currentRoute = window.location.pathname.split('/')[2] || ''
  const {opportunity} = useParams()

  initPolicies.opportunity = opportunity;
  const {
    isLoading: isFinalLoading,
    isError: isFinalError,
    mutate: onFinalSubmission,
  } = useMutation(enrollPartTwoFinalSubmission)

  const {
    isLoading: isFieldLoading,
    isError: isFieldError,
    data: fieldFormsData,
    refetch: refetchfieldFormsData,
  } = useQuery(_queryKeys.getEnrollPartTwoForms, () => fetchEnrollPartTwo(opportunity))

  const initStatus = fieldFormsData?.status

  /***********************************************************************************
   * SPECIAL CASE FOR CUSTOM FORM FIELDS: SIMPLE INTEGRATION, SUBJECT FOR IMPROVEMENT.
   ***********************************************************************************/
  let opportunity_forms: any[] = [] // This is for GLA hard-coded forms.
  let custom_forms_types: any[] = [] // This is for name of SSB forms.
  let custom_forms: IEnrollPartTwoForm[] = [] // This is for SSB form data.
  fieldFormsData?.data.forEach((data) => {
    if (data.custom_form && data.status === 'active') {
      // custom form is null for hardcoded forms. Vice versa for custom forms.
      custom_forms_types.push(data.custom_form?.name)
      custom_forms.push(data)
      initPolicies.customField[data.name] = data.answer
    }
  })
  custom_forms_types = custom_forms_types.filter((c, index) => {
    return custom_forms_types.indexOf(c) === index
  })
  opportunity_forms = opportunity_forms.filter((c, index) => {
    return opportunity_forms.indexOf(c) === index
  })
  const {
    isLoading: isSaveLoading,
    isSuccess: isSaveSuccess,
    isError: isSaveError,
    mutateAsync: saveContinueLaterAsync,
    //data: statusData,
  } = useMutation(saveAndContinueLater)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = async (values: IEnrollPartTwo, goto?: number) => {
    if (!stepper.current) {
      return
    }
    console.log(
      'current prevStep',
      stepper?.current?.currentStepIndex,
      stepper?.current.totatStepsNumber
    )
    try {
      const res = await saveContinueLaterAsync(values)
      if (res) {
        setMessage(res.message)
        setCompeletionData(res.data)
        if (res.data) {
          refetchfieldFormsData()
        }
      }
      if (goto) {
        stepper.current.goto(goto)
      } else {
        stepper.current.goPrev()
      }

      setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    } catch (error) {
      console.log(error)
      setErrMessage('Could not save your essay!')
    } finally {
      ScrollTopComponent.goTop()
    }
  }

  const submitStep = async (values: any, metaData: any, setErrors: any) => {
    if (!stepper.current) {
      return
    }
    const checker = metaData.filter((form: any, idx: number) => {
      if (values.customField[form.name] === '') {
        return form
      }
    })
    const error: any = {}
    if (checker.length > 0) {
      checker.forEach((item: any, index: number) => {
        error[item.name] = 'This field is required!'
      })
      setErrors(error)
    }
    if (Object.entries(error).length > 0) {
      return
    }
    if (stepper.current?.getCurrentStepIndex() !== initStatus) {
      try {
        const mes = "Redirecting you to the form that you haven't submitted yet, Please submit this form first"
        await onSaveContiuneLater(values, initStatus, undefined, mes)
      } catch (error: any) {
        const message = error.response?.data.message
        setErrMessage(message)
        ScrollTopComponent.goTop()
      }
      return
    }
    onSaveContiuneLater(values, undefined, true)
  }

  const onSaveContiuneLater = async (values: IEnrollPartTwo, goto?: number, isComplete?: boolean, mes?: string) => {
    if (!stepper.current) {
      return
    }
    setIsWarning(false)

    try {
      values.isComplete = isComplete || false
      const res = await saveContinueLaterAsync(values)
      if (res) {
        setMessage(mes || res.message)
        setCompeletionData(res.data)
        if (res.data) {
          refetchfieldFormsData()
        }
      }
      if (!isComplete && stepper.current?.getCurrentStepIndex() !== initStatus) {
        setIsWarning(true)
      }
      if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber!) {
        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
          if (goto) {
            stepper.current.goto(goto)
            return
          }
          stepper.current.goNext()
        }
      } else {
        if (goto && stepper.current?.getCurrentStepIndex() !== initStatus) {
          stepper.current.goto(goto)
          return
        }
        return navigate(`/dashboard/${currentRoute}`, {replace: true})
      }
    } catch (err: any) {
      const message = err.response?.data.message
      setErrMessage(message)
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
    stepper?.current?.goto(incompletedPartTwoTabId(initStatus))
    console.log('incompleteTab', incompletedPartTwoTabId(initStatus))
    
    return () => {
      //setErrMessage('')
      //setMessage('')
    }
  }, [stepperRef, initStatus])

 // useEffect(() => {
  //   refetchfieldFormsData()
  //   return () => {}
  // }, [currentUser])

  if (isFieldLoading) {
    return (
      <div className='w-100 my-10'>
        <div className='d-flex justify-content-center align-items-center'>
          <span className='me-5'>Please wait...</span>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  if (isFieldError) {
    return (
      <div className='alert alert-danger d-flex align-items-center p-5 my-10'>
        <div className='d-flex flex-column'>
          <h5 className='mb-1'>Oops!</h5>
          <span>Something went wrong!</span>
        </div>
      </div>
    )
  }

  return (
    <div className='card'>
      {isSaveLoading && (
        <div id='splash-screen2' className='splash-screen2'>
          <div className='border rounded bg-white py-5 px-15'>
            <span className='fs-3 fw-bold'>Saving ...</span>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </div>
        </div>
      )}
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          {isSaveSuccess && (
            <div className={`alert ${isWarning ? 'alert-warning' : 'alert-success'} d-flex align-items-center p-5 mb-10`}>
              <div className='d-flex flex-column '>
                <span>{message}</span>
              </div>
            </div>
          )}
          <ErrorMessages isError={isSaveError} messages={errMessage} />
          <Formik initialValues={initPolicies} onSubmit={() => {}}>
            {({values, errors, setFieldValue, resetForm, setErrors}) => {

              return (
                <>
                  <div className='stepper-nav mb-5'>
                    {custom_forms_types?.map((name, idx, self) => (
                      <div
                        key={idx}
                        className={clsx({
                          'stepper-item d-flex align-items-center cursor-pointer': name,
                          current: idx == 0,
                        })}
                        data-kt-stepper-element='nav'
                        onClick={async () => {
                          if (Number(stepper.current?.getCurrentStepIndex()) - 1 !== idx) {
                            if (Number(stepper.current?.getCurrentStepIndex()) - 1 > idx) {
                              prevStep(values, idx + 1)
                              return;
                            }
                            onSaveContiuneLater(values, idx + 1)
                          }
                        }}
                      >
                        <h3 className='stepper-title'>{name}</h3>
                        {initStatus && initStatus - 1 > idx && (
                          <span className='text-success ms-2'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen043.svg'
                              className='svg-icon svg-icon-2hx svg-icon-success'
                            />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>

                  <Form
                    className='mx-auto mw-800px w-100 pt-10 pb-10 px-5 border bg-light rounded'
                    id='kt_create_account_form'
                  >
                    {/* GLOBAL: ACCOUNT-LEVEL FORM GOES HERE. */}
                    {custom_forms_types?.map(
                      (form, idx) => 
                        initStatus && (
                          <div
                            key={idx}
                            className={clsx('', idx == 0 ? 'current' : '')}
                            data-kt-stepper-element='content'
                          >
                            {(idx == initStatus - 1 ||
                            (stepper?.current?.currentStepIndex &&
                            stepper?.current?.currentStepIndex - 1 == idx)) && (
                              <CustomStep
                                key={idx}
                                name={form}
                                metadata={custom_forms?.filter((f) => f.custom_form?.name === form)}
                                currentFormikVal={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                              />
                            )}
                          </div>
                        )
                    )}

                    <div className='my-5'>
                      <button
                        type='button'
                        onClick={() => onSaveContiuneLater(values)}
                        className='btn btn-lg btn-light-success me-3'
                      >
                        {!isSaveLoading ? (
                          <>
                            Save and Continue Later
                            <i className='fa-regular fa-floppy-disk ms-2'></i>
                          </>
                        ) : (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <ErrorMessages isError={isFinalError} messages={errMessage} />
                    <div className='d-flex flex-stack'>
                      <div className='mr-2'>
                        <button
                          type='button'
                          onClick={() => prevStep(values)}
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Back
                        </button>
                      </div>
                      <div>
                        <button 
                          type='button' 
                          className='btn btn-lg btn-primary me-3'
                          onClick={() => {
                            let cusForm: any = []
                            custom_forms_types.forEach((form, idx) => {
                              if (initStatus && (idx == initStatus - 1 ||
                              (stepper?.current?.currentStepIndex &&
                              stepper?.current?.currentStepIndex - 1 == idx))) {
                                const s: any = custom_forms
                                  ?.filter((f) => f.custom_form?.name === form)
                                  .sort((a, b) => a.id - b.id)
                                cusForm = s
                              }
                            })
                            const metaData: any = cusForm.filter((form: any, idx: number) => {
                              if (form.type === 'question') {
                                return form
                              }
                            })
                            submitStep(values, metaData, setErrors)
                          }}
                        >
                          {!isFinalLoading && (
                            <span className='indicator-label'>
                              {!isSubmitButton && 'Next Step'}
                              {isSubmitButton && 'Submit'}
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-3 ms-2 me-0'
                              />
                            </span>
                          )}
                          {isFinalLoading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                    {Object.keys(errors).length ? (
                      <div className='text-danger text-center mt-10'>
                        Please complete all the fields.
                      </div>
                    ): ''}
                  </Form>
                </>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default EnrollPartTwoStep
