import {useEffect, useState, useMemo} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {stringifyRequestQuery} from '../../../../_metronic/helpers'
import DefaultSpinnerLoading from '../../Shared/components/loading/DefaultSpinnerLoading'
import {useQueryRequest} from '../../Shared/core/QueryRequestProvider'
import AddUpdateClient from './components/AddUpdateClient'
import _queryKeys from './components/core/_queryKeys'
import {useClientsRequests} from './Hooks/useClientsRequests'

const UpdateClientPage = () => {
  const [message, setMessage] = useState('')
  const {clientId} = useParams()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const {getClientDetailReq, saveClientDetailReq} = useClientsRequests()
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {isLoading: isFieldLoading, isError: isFieldError} = useQuery(
    _queryKeys.getClientDetailReq,
    () => getClientDetailReq(clientId)
  )

  const {
    isLoading: isUpdateClientLoading,
    isSuccess: isUpdateClientSuccess,
    isError: isUpdateClientError,
    error,
    mutateAsync: updateClientByAdminAsync,
  } = useMutation(saveClientDetailReq)

  if (isFieldLoading) {
    return <DefaultSpinnerLoading />
  }

  if (isFieldError) {
    return (
      <div className='card-body'>
        <div className='alert alert-dismissible bg-secondary d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span className=''>Error while loading Client detail!</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {isUpdateClientSuccess && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{message}</span>
            </div>
          </div>
        </div>
      )}
      {isUpdateClientError && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>Some error occurred while processing your request!</span>
            </div>
          </div>
        </div>
      )}
      <AddUpdateClient
        isLoading={isUpdateClientLoading}
        setMessage={setMessage}
        itemId={clientId}
        updateClientByAdminAsync={updateClientByAdminAsync}
      />
    </>
  )
}

export default UpdateClientPage
