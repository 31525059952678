/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import ListPayment from '../../../modules/application/components/ListPayment'
import _queryKeys from '../../../modules/application/core/_queryKeys'

const ManagePaymentPageGLA: FC = () => {

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-lg-12'>
          <ListPayment />

        </div>
      </div>
    </>
  )}

const PaymentWrapperGLA: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PAYMENTMETHOD'})}</PageTitle>
      <ManagePaymentPageGLA />
    </>
  )
}

export {PaymentWrapperGLA}
