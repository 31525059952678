import { useEffect, useMemo, useState } from "react"
import { useAuth } from "../../auth"
import { KTSVG } from "../../../../_metronic/helpers"
import { useNavigate, useParams } from "react-router-dom"
import { getAllTypeFormByAdmin, getAllProgram } from "../../../../network/api";
import { axiosInstance } from '../../../../axiosInstance';
import Header from "../../../../_metronic/shared-components/Header"
import DynamicTableV2 from "../../../../_metronic/shared-components/dynamic-table-v2"

interface iPrograms{
    id: string,
    name: string,
    campus: string,
}

const ProgramList = () => {
    const navigate = useNavigate()
    const {currentUser} = useAuth()
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [visibleData, setVisibleData] = useState<any[]>([])
    const [programs, setPrograms] = useState<iPrograms[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [originalVisibleData, setOriginalVisibleData] = useState<any[]>([]); // New state for unfiltered data
    const [typeForms, setTypeForms] = useState<{ id: string, name: string }[]>([]);
    const [search, setSearch] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch programs
                const programResponse = await axiosInstance.get('/terra-programs/api/programs');
                const programsArray = programResponse.data.data;

                if (Array.isArray(programsArray)) {
                    const allPrograms = programsArray.map((program) => ({
                        id: program.id,
                        name: program.name.trim(),
                        campus: program.programData.campus
                    }));
                
                    const sortedPrograms = allPrograms.sort((a, b) => {
                        const campusComparison = a.campus.localeCompare(b.campus);
                        if (campusComparison !== 0) {
                            return campusComparison;
                        }
                        return a.name.localeCompare(b.name);
                    });
                
                    setPrograms(sortedPrograms);
                }
            } catch (error) {
                console.error("Error fetching programs:", error)
            } finally {
                setLoading(false)
            }
        };
        fetchData()
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch typeforms
                const { data: formData } = await getAllTypeFormByAdmin();
                setTypeForms(formData);
    
                // Fetch program list
                const { data } = await getAllProgram();
    
                // Sort data by id
                const sortedData = data.sort((a: any, b: any) => b.id - a.id);
    
                // Filter visibleData to only include those whose program_id matches the id in programs
                const filteredData = sortedData.filter((item: any) =>
                    programs.some((program) => program.id === item.program_id)
                );
    
                setOriginalVisibleData(filteredData); // Store original data
                setVisibleData(filteredData); // Set filtered data for display
                setFilteredData(filteredData); // Set filteredData for search purposes
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [programs]);
    

    useEffect(() => {
        if (search.trim().length > 0) {
            const searchTerm = search.toLowerCase();
    
            // Filter and sort by program name during search
            const filtered = originalVisibleData.filter((item) => {
                const programName = getProgramNameById(item.program_id).toLowerCase();
                const formName = getFormNameById(item.form_id).toLowerCase();
    
                return programName.includes(searchTerm) || formName.includes(searchTerm);
            }).sort((a, b) => {
                const programNameA = getProgramNameById(a.program_id).toLowerCase();
                const programNameB = getProgramNameById(b.program_id).toLowerCase();
                return programNameA.localeCompare(programNameB); // Sort by name alphabetically
            });
    
            setFilteredData(filtered);
        } else {
            // Sort by id if search is empty
            const sortedById = originalVisibleData.sort((a: any, b: any) => b.id - a.id);
            setFilteredData(sortedById); // Reset to original data sorted by id
        }
    }, [search, originalVisibleData]);  

    const getProgramNameById = (id: string) => {
        const program = programs.find((prog) => prog.id === id);
        return program && `${program.campus} - ${program.name}`|| '';
    };

    const getFormNameById = (id: string) => {
        const form = typeForms.find((form) => form.id === id);
        return form && form.name || '';
    };

    const addOrEditProgram = (e: any, id: any) => {
        navigate(
          `/apps/${currentUser?.currentClient}/program-management/edit-program/${id}`
        )
    }
    
    const handleOpenAction = (id: any) => {
        navigate(`/apps/${params.client}/program-management/program`)
    }

    const columns = useMemo(() => [
        {
            Header: 'Program Name',
            accessor: 'program_id',
            Cell: (props: any) => {
                const programName = getProgramNameById(props.row.original.program_id);
                return programName;
            }
        },
        {
            Header: 'Form/s',
            accessor: 'form_id',
            Cell: (props: any) => {
                const formName = getFormNameById(props.row.original.form_id);
                return formName;
            }
        },
        {
            Header: 'Status',
            accessor: 'isActive',
            Cell: ({value}: any) => (
              <div
                style={{
                  backgroundColor: value ? '#e8fff3' : '#ffe8e8',
                  color: value ? '#50cd89' : '#cd5050',
                  padding: '0.5rem',
                  borderRadius: '0.25rem',
                  minWidth: value ? 60 : 70,
                  maxWidth: value ? 60 : 70,
                }}
              >
                {value ? 'Active' : 'Inactive'}
              </div>
            ),
        },
        {
            Header: 'Actions',
            accessor: 'id',
            disableSortBy: true,
            Cell: (props: any) => (
                <>
                    <button
                        onClick={() => {
                            handleOpenAction(props.value)
                        }}
                        className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        id={`action_button_` + props.value}
                    >
                        Actions
                        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
                    </button>
                    {/* begin::Menu */}
                    <div
                        id={'subMenu_' + props.value}
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                        data-kt-menu='true'
                    >
                    {/* begin::Menu item */}
                        <div className='menu-item px-3'>
                            <a
                                className='menu-link px-3'
                                onClick={(e) => {
                                    addOrEditProgram(e, props.value)
                                }}
                                >
                                Edit
                            </a>
                        </div>
                    </div>
                </>
            ),
          },
    ], [programs, typeForms])

    return (
        <>
            <div className="card card-body p-0">
                <Header
                buttonLink={`/apps/${currentUser?.currentClient}/program-management/add-program`}
                buttonText='Add Program'
                placeholder='Search Program'
                setSearch={setSearch}
                search={search}
                />
                <DynamicTableV2 columns={columns} data={filteredData} loading={loading}/>
            </div>
        </>
    )
}

export default ProgramList