import React from 'react'
import {useAuth} from '../../auth'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import EnrollmentFormList from './EnrollmentFormList'
import EnrollmentFormAdd from './EnrollmentFormAdd'
import EnrollmentFormEdit from './EnrollmentFormEdit'

const EnrollmentForm = () => {
  const {currentUser} = useAuth()

  const enrollmentFormBreadCrumbs: Array<PageLink> = [
    {
      title: 'Enrollment Form',
      path: `/apps/${currentUser?.currentClient}/enrollment-form-management/enrollment-form`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='enrollment-form'
          element={
            <>
              <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>Enrollement Form List</PageTitle>
              <EnrollmentFormList />
            </>
          }
        />
        <Route
          path='add-enrollment-form'
          element={
            <>
              <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>Add Enrollement Form</PageTitle>
              <EnrollmentFormAdd />
            </>
          }
        />
        <Route
          path='edit-enrollment-form/:enrollment_form_id'
          element={
            <>
              <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>Edit Enrollement Form</PageTitle>
              <EnrollmentFormEdit />
            </>
          }
        />
      </Route>
      <Route
        index
        element={<Navigate to={`/apps/${currentUser?.currentClient}/enrollment-form-management/enrollment-group`} />}
      />
    </Routes>
  )
}

export default EnrollmentForm