import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { ssbTermsTemplate, ttnTermsTemplate } from './terms&conditionTemplate'

const PaymentTerms = () => {
  const currentRoute = window.location.pathname.split('/')[2] || ''

  return (
    <div className='my-10'>
      {ReactHtmlParser(currentRoute === "88562775" ? ttnTermsTemplate : ssbTermsTemplate)}
    </div>
  )
}

export default PaymentTerms