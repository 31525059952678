type Props = {
  handleTPPCheckboxChange: any;
  isChecked?: boolean;
};

const PaymentProtectionPlan = ({ handleTPPCheckboxChange, isChecked }: Props) => {
  return (
    <div style={{ marginTop: 100 }}>
      <form className="container p-3 px-5 mb-5" style={{ border: '1px black solid', borderRadius: 30 }}>
        <h3 className="text-center">Tuition Protection Plan</h3>
        <fieldset id="ppp">
          <div
            className="mx-auto d-flex gap-3 rounded mb-3"
          >
            <input
              style={{ transform: 'scale(1.5)' }}
              checked={!isChecked}
              type="radio"
              name="protectionPlan"
              onChange={handleTPPCheckboxChange}
            />
            <h6>No.</h6>
          </div>
          <div
            className="mx-auto d-flex gap-4"
            style={{ flexDirection: 'column' }}
          >
            <div className="d-flex gap-3">
              <input
                style={{ transform: 'scale(1.5)' }}
                checked={isChecked}
                type="radio"
                name="protectionPlan"
                onChange={handleTPPCheckboxChange}
              />
              <h6>
                Yes, I would like to add Tuition Protection Plan of $499.00 total.
              </h6>
            </div>
            <p>
              The Tuition Protection Plan allows cancellation with full refund, for any
              reason up to the day the program starts.
            </p>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default PaymentProtectionPlan;