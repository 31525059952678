import React from 'react'

interface Props {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

const CustomPagination: React.FC<Props> = ({currentPage, totalPages, onPageChange}) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page)
    }
  }

  const renderPaginationItems = () => {
    const paginationItems = []

    paginationItems.push(
      <li key='prev' className={`page-item px-5 ${currentPage === 1 ? 'disabled' : ''}`}>
        <button
          className='page-link '
          onClick={() => handlePageChange(currentPage - 1)}
          aria-label='Previous'
        >
          Previous
        </button>
      </li>
    )

    // Show the first page
    paginationItems.push(
      <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
        <button className='page-link' onClick={() => handlePageChange(1)}>
          1
        </button>
      </li>
    )

    // Show ellipsis after "Previous" if the current page is beyond the second page
    if (currentPage > 2) {
      paginationItems.push(
        <li key='ellipsis-prev' className='page-item disabled'>
          <span className='page-link'>...</span>
        </li>
      )
    }

    // Show the previous 2 pages, current page, and next 2 pages
    const startPage = Math.max(2, currentPage - 2)
    const endPage = Math.min(totalPages - 1, currentPage + 2)
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <button className='page-link' onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      )
    }

    // Show ellipsis if the current page is before the second-to-last page
    if (currentPage < totalPages - 1) {
      paginationItems.push(
        <li key='ellipsis-next' className='page-item disabled'>
          <span className='page-link'>...</span>
        </li>
      )
    }

    // Show the last page
    if (totalPages > 1) {
      paginationItems.push(
        <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
          <button className='page-link' onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        </li>
      )
    }

    paginationItems.push(
      <li key='next' className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
        <button
          className='page-link'
          onClick={() => handlePageChange(currentPage + 1)}
          aria-label='Next'
        >
          Next
        </button>
      </li>
    )

    return paginationItems
  }

  return (
    <nav aria-label='Page navigation'>
      <ul className='pagination justify-content-end mt-5'>{renderPaginationItems()}</ul>
    </nav>
  )
}

export default CustomPagination
