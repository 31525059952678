export const client = [
  {
    value: '47821104',
    label: 'SSB',
  },
  {
    value: '88562775',
    label: 'TTN',
  }
]

export const collumn = {
  type: [
    {
      value: 'question',
      label: 'Question',
      field_type: [
        {
          value: 'text',
          label: 'Text',
        },
        {
          value: 'textarea',
          label: 'Textarea',
        },
        {
          value: 'boolean',
          label: 'Boolean',
        },
        {
          value: 'choice',
          label: 'Choice',
        },
        {
          value: 'checkbox',
          label: 'Checkbox',
        },
        {
          value: 'datepicker',
          label: 'Date',
        },
      ],
      required: [
        {
          value: true,
          label: 'True',
        },
        {
          value: false,
          label: 'False',
        },
      ]
    },
    {
      value: 'text',
      label: 'Text',
      field_type: [
        {
          value: 'text',
          label: 'Text',
        },
      ],
      required: [
        {
          value: false,
          label: 'False',
        },
      ]
    },
  ]
}

// INTERFACE
interface IPortalClient {
  id?: number
  name?: string
  reference_id?: string
  abbreviation?: string
  status_id?: number 
}
export interface IEnrollmentFormGroup {
  id?: number
  name: string
  description: string
  client_id: string
  status: string | boolean
  title: string
  type: string
  route_name: string
  portal_client?: IPortalClient | null
}

export interface IEnrollmentForm {
  id?: number
  name?: string
  type?: string
  field_type?: string
  label?: string
  helper_text?: string
  required?: boolean
  for?: number
  choiceId?: number
  choices?: string
  status?: string
  group?: string
  client_id?: string
  enrollment_form_group_id?: number | null
}