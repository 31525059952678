import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { moveToEnroll } from '../../../../../network/api'

type Props = {
  opportunityId: number
  isFull: boolean
}

const EnrollAction = ({opportunityId, isFull}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      setLoading(true)
      
      let res = await moveToEnroll(opportunityId)
      if(res){
        setTimeout(() => {
          setShowModal(false)
        }, 1000)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <a onClick={() => setShowModal(true)} className='menu-link px-3'>Enroll</a>
      {/* <button className='btn btn-info ms-4'>Edit Protection Plan</button> */}
      {!isFull ? (<Modal title="Test" onHide={handleClose} show={showModal} backdrop='static' centered keyboard={false} >
        <Modal.Header closeButton>
            <Modal.Title>Enroll this student?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Enrolling will change their status to <b>"Incomplete - Deposit Due".</b></h5>
        </Modal.Body>

        <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='danger' onClick={handleClose}>
              Cancel
            </Button>
            <Button 
              variant='primary' 
              className='ms-2' 
              onClick={onSubmit}
            >
            {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Enroll'
            }
            </Button>
          </Modal.Footer>
      </Modal>
      ) :
      (
        <Modal title="Test" onHide={handleClose} show={showModal} backdrop='static' centered keyboard={false} >
          <Modal.Header closeButton>
              <Modal.Title>Capacity is Full</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>This class is full capacity. Please adjust capacity numbers in order to enroll this student.</h5>
          </Modal.Body>

          <Modal.Footer style={{padding: '20px 30px'}}>
              <Button variant='danger' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
        </Modal>
      )}
      
    </>
  )
}

export default EnrollAction