import { useParams, Navigate } from 'react-router-dom'
import { useAuth } from '../auth'
import useCurrentClientRoute from '../auth/hooks/useCurrentClientRoute'
import EnrollBanner from './components/EnrollBanner'
import EnrollInfo from './components/EnrollInfo'
import GLALayout from './components/GLALayout'
import NewEnrollStep from './components/NewEnrollStep'

const Enrollment = () => {
  const {currentUser} = useAuth()
  const {currentClientRoute} = useCurrentClientRoute()
  const { client, trackId } = useParams();

  let track = null; // Why is this necessary?
  if (trackId) {
    track = trackId;
  }

  if (currentClientRoute === 'undefined' && !currentUser) {
    return <Navigate to={'/auth'} />
  }
  document.documentElement.setAttribute("data-theme", "light");
  
  return (
    <GLALayout>
      <>
        <div className='bg-primary'>
          <EnrollBanner />
        </div>
        <div className='container'>
          <EnrollInfo />
          <NewEnrollStep />
        </div>
      </>
    </GLALayout>
  )
}

export default Enrollment