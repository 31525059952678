import { Link } from 'react-router-dom'
import { KTSVG as KtSvg } from '../../../../../../_metronic/helpers'
import { useAuth } from '../../../../auth'

const OppListToolbar = () => {
  const {currentUser} = useAuth()

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add User
      </button> */}
      <Link
        to={`/apps/${currentUser?.currentClient}/opportunities-management/new`}
        replace={true}
        className='btn btn-primary'
      >
        <KtSvg path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        New Opportunity
      </Link>
      {/* end::Add user */}
    </div>
  )
}

export {OppListToolbar}
