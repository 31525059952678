// @ts-nocheck
import {Column} from 'react-table'
import {OpportunityCustomHeader} from './OpportunityCustomHeader'
import {User} from '../../core/_models'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../../auth'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <OpportunityCustomHeader
        tableProps={props}
        title='Opportunity Name'
        className='min-w-125px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => {
      const {currentUser} = useAuth()
      return (
      <Link to={`/apps/${currentUser?.currentClient}/opportunities-management/edit/${props.data[props.row.index].id}`}>
        {props.data[props.row.index].name}
      </Link>
    )},
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Account Name' className='min-w-125px' />
    ),
    accessor: 'account',
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader tableProps={props} title='Close Date' className='min-w-125px' />
    ),
    accessor: 'end_date',
  },
  {
    Header: (props) => (
      <OpportunityCustomHeader
        tableProps={props}
        title='Deposit Received?'
        className='min-w-125px'
      />
    ),
    id: 'deposit_received',
    Cell: ({...props}) =>
      props.data[props.row.index].deposit_received ? (
        <span className='badge badge-light-success p-3'>
          <i className='fa-sharp fa-solid fa-circle-check text-success'></i>
        </span>
      ) : (
        <span className='badge badge-light-secondary p-3'>
          <i className='fa-sharp fa-solid fa-circle-check'></i>
        </span>
      ),
  },
]

export {usersColumns}
