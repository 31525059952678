import { useState } from 'react';

/**
 * A custom hook for handling payment input.
 * @returns A tuple containing the payment amount, a function to handle input changes, and a function to reset the payment amount.
 */
const usePaymentInput = (): [string, (inputValue: string) => void, () => void] => {
  const [paymentAmount, setPaymentAmount] = useState<string>('');

  /**
   * Handles input change by sanitizing the input value and updating the payment amount state.
   * @param inputValue The input value to be sanitized and set as the payment amount.
   */
  const handleInputChange = (inputValue: string): void => {
    // Remove non-digit and non-decimal point characters
    let sanitizedValue = inputValue.replace(/[^\d.]/g, '');

    // Remove leading zeros except if it's the only digit before a decimal point
    sanitizedValue = sanitizedValue.replace(/^0+(?=\d)/, '');

    // Ensure there's only one decimal point
    const decimalCount = sanitizedValue.split('.').length - 1;
    if (decimalCount > 1) {
      sanitizedValue = sanitizedValue.substring(0, sanitizedValue.lastIndexOf('.'));
    }

    // Limit to two decimal places
    const decimalIndex = sanitizedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = sanitizedValue.slice(0, decimalIndex);
      let decimalPart = sanitizedValue.slice(decimalIndex + 1);
      decimalPart = decimalPart.slice(0, 2); // Only take up to two decimal places
      sanitizedValue = `${integerPart}.${decimalPart}`;
    }

    setPaymentAmount(sanitizedValue);
  };

  /**
   * Resets the payment amount to an empty string.
   */
  const resetPaymentAmount = () => {
    setPaymentAmount('');
  };

  return [paymentAmount, handleInputChange, resetPaymentAmount];
};

export default usePaymentInput;
