import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IEnrollmentExtras, categories, clients } from './model'
import { Form, Formik, FormikValues } from 'formik'
import { addOrEditEnrollmentExtras } from '../../../../network/api'
import { EnrollmentExtraSchema } from './Schemas/EnrollmentExtrasSchema'
import CustomInput from '../../../../_metronic/shared-components/CustomInput'
import CustomTextArea from '../../../../_metronic/shared-components/CustomTextArea'
import CustomReactSelect from '../../../../_metronic/shared-components/CustomReactSelect'
import CustomCheckbox from '../../../../_metronic/shared-components/CustomCheckbox'
import { codeMessage } from '../../../../network/codeMessage'

const EnrollmentExtrasAdd = () => {

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const navigate = useNavigate()
  const { client } = useParams()

  const initialValues = {
    name: '',
    type: '',
    category: 0,
    amount: 0,
    status_id: false,
    default_client: ''
  }

  const onSubmit = async (values: IEnrollmentExtras, actions: FormikValues) => {
    try {
      const modifiedValues = {
        ...values,
        category: Number(values.category),
        amount: Number(values.amount),
        status_id: values?.status_id === true ? 14 : 15
      }
      const res = await addOrEditEnrollmentExtras(modifiedValues)
      const Success = codeMessage[res.status]
      setSuccessMessage(Success)
  
      actions.resetForm()
  
      setTimeout(() => {
        navigate(`/apps/${client}/enrollment-extras-management/enrollment-extras`)
      }, 3000)
    } catch (error: any) {
      const errorMsg = codeMessage[error.response.status]
      setErrorMessage(errorMsg)

      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  
  return (
    <>
    {successMessage && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{successMessage}</span>
            </div>
          </div>
        </div>
      )}

    {errorMessage && (
      <div className='card-body'>
        <div className='alert alert-danger d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span>{errorMessage}</span>
          </div>
        </div>
      </div>
    )}

    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='card'>
          <Formik
            initialValues={initialValues}
            validationSchema={EnrollmentExtraSchema}
            onSubmit={onSubmit}
          >
            {({isSubmitting, setFieldValue}) => {
              return (
                <Form>
                  <CustomInput label='Name' name='name' placeholder='Enter a Name' />

                  <CustomInput label='Type' name='type' placeholder='Enter Type' />

                  <CustomReactSelect
                    label='Client'
                    name='default_client'
                    required
                    placeholder='Select a Client'
                    options={clients.map((item) => ({
                      value: item.value,
                      label: item.label,
                      name: item.name,
                    }))}
                  />

                  <CustomReactSelect
                    label='Category'
                    name='category'
                    required
                    placeholder='Select a category'
                    options={categories.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                  />

                  <CustomInput label='Amount' name='amount' placeholder='Enter an Amount' />
                  <CustomInput label='QB Full Name' name='qb_full' placeholder='Enter QB Full Name' />

                  <CustomCheckbox label='Status' type='checkbox' name='status_id' />

                  <div className='text-center pt-15'>
                    <button
                      disabled={isSubmitting}
                      type='submit'
                      className='btn btn-primary cursor-pointer'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
    </>
  )
}

export default EnrollmentExtrasAdd