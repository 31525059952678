export const enrollment_questionnaires = [
    {
      "value": "Parent_Signature_Medical__c",
      "label": "Parent Signature Medical"
    },
    {
      "value": "Med_Height__c",
      "label": "Height"
    },
    {
      "value": "Med_Weight__c",
      "label": "Weight"
    },
    {
      "value": "Gender__c",
      "label": "Gender"
    },
    {
      "value": "Gender_Description__c",
      "label": "Gender Description"
    },
    {
      "value": "Sex_Assigned_At_Birth__c",
      "label": "Sex Assigned At Birth"
    },
    {
      "value": "Pronouns__c",
      "label": "Pronouns"
    },
    {
      "value": "Hispanic_or_Latino__c",
      "label": "Hispanic or Latino"
    },
    {
      "value": "Ethnicity__c",
      "label": "Ethnicity"
    },
    {
      "value": "Taking_Prescription__c",
      "label": "Taking Prescription"
    },
    {
      "value": "Taking_Prescription_Description__c",
      "label": "Taking Prescription Description"
    },
    {
      "value": "Mark_Details__c",
      "label": "Please mark all that applies"
    },
    {
      "value": "Allergies__c",
      "label": "Allergies"
    },
    {
      "value": "Allergies_Description__c",
      "label": "Allergies Description"
    },
    {
      "value": "Med_Allergies_drug__c",
      "label": "Allergies (drug)"
    },
    {
      "value": "Allergies_drug_Description__c",
      "label": "Allergies (drug) Description"
    },
    {
      "value": "Other_Dietary_Description__c",
      "label": "Other Dietary Description"
    },
    {
      "value": "Food_Intolerance_Description__c",
      "label": "Food Intolerance Description"
    },
    {
      "value": "Vegan_Diet_Description__c",
      "label": "Vegan Diet Description"
    },
    {
      "value": "Halal_Kosher_Description__c",
      "label": "Halal / Kosher Description"
    },
    {
      "value": "Food_Allergy_Description__c",
      "label": "Food Allergy Description"
    },
    {
      "value": "Med_Allergies_EpiPen__c",
      "label": "EpiPen"
    },
    {
      "value": "Med_Allergies_EpiPen_Description__c",
      "label": "EpiPen Description"
    },
    {
      "value": "Med_Allergies_EpiPen_Last_Use__c",
      "label": "EpiPen Last Use"
    },
    {
      "value": "Med_Asthma__c",
      "label": "Asthma (or use of an inhaler)"
    },
    {
      "value": "Med_Asthma_Description__c",
      "label": "Asthma Description"
    },
    {
      "value": "Med_Asthma_Inhaler__c",
      "label": "Inhaler"
    },
    {
      "value": "Med_Asthma_Inhaler_Description__c",
      "label": "Inhaler Description"
    },
    {
      "value": "Med_Heart_Problems__c",
      "label": "Heart Problems"
    },
    {
      "value": "Med_Heart_Problems_Description__c",
      "label": "Heart Problems Description"
    },
    {
      "value": "Med_Fainting_or_Dizziness_Last_Episode__c",
      "label": "Fainting or Dizziness Last Episode"
    },
    {
      "value": "Med_Diabetes__c",
      "label": "Diabetes"
    },
    {
      "value": "Med_Diabetes_Description__c",
      "label": "Diabetes Description"
    },
    {
      "value": "Med_Headache__c",
      "label": "Migraines"
    },
    {
      "value": "Med_Headache_Description__c",
      "label": "Headache Description"
    },
    {
      "value": "Med_Seizures_Last__c",
      "label": "Seizures"
    },
    {
  "value": "Med_Seizures_Description__c",
  "label": "Seizures Description"
  },
  {
  "value": "Med_Broken_Bones__c",
  "label": "Mobility / Bone Issues"
  },
  {
  "value": "Med_Broken_Bones_Description__c",
  "label": "Mobility / Bone Issues Description"
  },
  {
  "value": "Med_Concussion__c",
  "label": "Concussion"
  },
  {
  "value": "Med_Concussion_Description__c",
  "label": "Concussion Description (Dates)"
  },
  {
  "value": "Med_Surgeries__c",
  "label": "Surgeries"
  },
  {
  "value": "Med_Surgeries_Description__c",
  "label": "Surgeries Description"
  },
  {
  "value": "Med_Earaches_Hearing__c",
  "label": "Hard of hearing"
  },
  {
  "value": "Med_Earaches_Hearing_Description__c",
  "label": "Hard of Hearing Description"
  },
  {
  "value": "Med_Glasses__c",
  "label": "Glasses or Contacts"
  },
  {
  "value": "Med_Sleepwalking__c",
  "label": "Sleepwalking"
  },
  {
  "value": "Med_Sleepwalking_Description__c",
  "label": "Sleepwalking Description"
  },
  {
  "value": "Med_Fear_Swimming__c",
  "label": "Fear of Swimming"
  },
  {
  "value": "Med_Fear_Swimming_Description__c",
  "label": "Fear of Swimming Description"
  },
  {
  "value": "Med_Fear_Swimming_Choices__c",
  "label": "Fear of Swimming Choice"
  },
  {
  "value": "Med_ADHD_ADD__c",
  "label": "ADHD/ADD"
  },
  {
  "value": "Med_ADHD_ADD_Description__c",
  "label": "ADHD/ADD Description"
  },
  {
  "value": "Med_Diagnosed_with_Depression__c",
  "label": "Diagnosed with Depression"
  },
  {
  "value": "Med_Diagnosed_with_Depression_Descript__c",
  "label": "Diagnosed with Depression Description"
  },
  {
  "value": "Med_Depression_Last_Episode__c",
  "label": "Last Depressive Episode"
  },
  {
  "value": "Med_Anxiety__c",
  "label": "Anxiety"
  },
  {
  "value": "Med_Anxiety_Description__c",
  "label": "Anxiety Description"
  },
  {
  "value": "Med_OCD__c",
  "label": "OCD"
  },
  {
  "value": "Med_OCD_Description__c",
  "label": "OCD Description"
  },
  {
  "value": "Med_Anxiety_Last_Episode__c",
  "label": "Last Anxiety Symptoms"
  },
  {
  "value": "Med_Bi_Polar_Last_Episode__c",
  "label": "Bi-Polar Disorder"
  },
  {
  "value": "Med_Bi_Polar_Disorder_Description__c",
  "label": "Bi-Polar Disorder Description"
  },
  {
  "value": "Med_Bi_Polar_Last_Episode__c",
  "label": "Last Bi-polar Episode"
  },
  {
  "value": "Med_Self_Mutilation__c",
  "label": "Self-Mutilation"
  },
  {
  "value": "Med_Self_Mutilation_Description__c",
  "label": "Self-Mutilation Description"
  },
  {
  "value": "Med_Self_Mutilation_Last_Episode__c",
  "label": "Last Self-Mutilation"
  },
  {
  "value": "Eating_Disorder__c",
  "label": "Eating Disorder"
  },
  {
  "value": "Eating_Disorder_Description__c",
  "label": "Eating Disorder Description"
  },
  {
  "value": "Med_Eating_Disorder_Last__c",
  "label": "Last Disordered Eating"
  },
  {
  "value": "Med_Panic_Attacks__c",
  "label": "Panic Attacks"
  },
  {
  "value": "Med_Panic_Attacks_Description__c",
  "label": "Panic Attacks Description"
  },
  {
  "value": "Med_Panic_Attacks_Frequency__c",
  "label": "Panic Attack Frequency"
  },
  {
  "value": "Med_Suicidal_Thoughts__c",
  "label": "Suicidal Thoughts"
  },
  {
      "value": "Med_Suicidal_Thoughts_Description__c",
      "label": "Suicidal Thoughts Description"
    },
    {
      "value": "Med_Suicidal_Thoughts_Last__c",
      "label": "Last Had Suicidal Thoughts"
    },
    {
      "value": "Med_Suicide_Attempt__c",
      "label": "Suicide Attempt"
    },
    {
      "value": "Med_Last_Suicide_Attempt_Description__c",
      "label": "Last Suicide Attempt Description"
    },
    {
      "value": "Med_Last_Suicide_Attempt__c",
      "label": "Last Suicide Attempt"
    },
    {
      "value": "Med_Alcohol_Drugs__c",
      "label": "Alcohol and/or other drug abuse"
    },
    {
      "value": "Med_Alcohol_Drugs_Description__c",
      "label": "Alcohol Drugs Description"
    },
    {
      "value": "Med_Autism_Spectrum__c",
      "label": "Autism Spectrum Disorders"
    },
    {
      "value": "Med_Autism_Spectrum_Description__c",
      "label": "Autism Spectrum Disorders Description"
    },
    {
      "value": "Distracting_Behaviors__c",
      "label": "Distracting Behaviors"
    },
    {
      "value": "Distracting_Behaviors_Description__c",
      "label": "Distracting Behaviors Description"
    },
    {
      "value": "Med_Missing_Routine_Vaccinations__c",
      "label": "Missing Routine Vaccinations"
    },
    {
      "value": "Med_Missing_Routine_Vaccinations_Descrip__c",
      "label": "Missing Routine Vaccinations Description"
    },
    {
      "value": "Med_Self_Advocacy_Concerns__c",
      "label": "Self-Advocacy Concerns"
    },
    {
      "value": "Med_Self_Advocacy_Concerns_Description__c",
      "label": "Self-Advocacy Concerns Description"
    },
    {
      "value": "Med_Suspended_Or_Expelled__c",
      "label": "Suspended or Expelled"
    },
    {
      "value": "Med_Suspended_Or_Expelled_Description__c",
      "label": "Suspended or Expelled Description"
    },
    {
      "value": "Med_Positive_For_COVID_19__c",
      "label": "Tested Positive for COVID-19"
    },
    {
      "value": "Med_Positive_For_COVID_19_Date__c",
      "label": "Tested Positive for COVID-19 Date"
    },
    {
      "value": "Med_Covid_19_Fully_Vaccinated__c",
      "label": "Med Covid 19 Fully Vaccinated"
  },
  {
      "value": "Med_Covid_19_Vaccine_Type__c",
      "label": "Med Covid 19 Vaccine Type"
    },
    {
      "value": "Med_Covid_19_Recent_Booster_Shot__c",
      "label": "Med Covid 19 Recent Booster Shot"
    },
    {
      "value": "Med_Other__c",
      "label": "Medical Anything Else"
    },
    {
      "value": "Med_Other_Description__c",
      "label": "Medical Anything Else Description"
    },
    {
      "value": "Self_Assessment_1__c",
      "label": "Self Assessment 1"
    },
    {
      "value": "Self_Assessment_2__c",
      "label": "Self Assessment 2"
    },
    {
      "value": "Self_Assessment_3__c",
      "label": "Self Assessment 3"
    },
    {
      "value": "Self_Assessment_4__c",
      "label": "Self Assessment 4"
    },
    {
      "value": "Self_Assessment_5__c",
      "label": "Self Assessment 5"
    },
    {
      "value": "Self_Assessment_6__c",
      "label": "Self Assessment 6 (old; not in use)"
    },
    {
      "value": "Self_Assessment_7__c",
      "label": "Self Assessment 6"
    },
    {
      "value": "Self_Assessment_8__c",
      "label": "Self Assessment 7"
    },
    {
      "value": "Self_Assessment_9__c",
      "label": "Applying with a friend/family member?"
    },
    {
      "value": "Self_Assessment_9_Description__c",
      "label": "Applying w/friend/family member details"
    },
    {
      "value": "SSB_Current_Gpa__c",
      "label": "Current GPA"
    },
    {
      "value": "Extracurricular_activities__c",
      "label": "Extracurricular activities"
    },
    {
      "value": "Essay_Question_1__c",
      "label": "Self-Reflection Question 1"
    },
    {
      "value": "Essay_Question_2__c",
      "label": "Self-Reflection Question 2"
    },
    {
      "value": "How_Family_First_Heard__c",
      "label": "How family first heard about GLA"
    },
    {
      "value": "Parent_Signature_CoC__c",
      "label": "Parent Signature CoC"
    },
    {
      "value": "Student_Signature_CoC__c",
      "label": "Student Signature CoC"
    },
    {
      "value": "Parent_Signature_Terms__c",
      "label": "Parent Signature Terms"
    },
    {
      "value": "SSB_Name_of_School_Next_Fall__c",
      "label": "SSB Name of School Next Fall"
    },
    {
      "value": "Current_GPA__c",
      "label": "Current GPA"
    },
    {
      "value": "SSB_Motivation__c",
      "label": "SSB Motivation"
    },
    {
      "value": "Essay_Question_1__c",
      "label": "Self-Reflection Question 1"
    },
    {
      "value": "SSB_Why_Choose__c",
      "label": "SSB Why Choose"
    },
    {
      "value": "Extracurricular_activities__c",
      "label": "Extracurricular activities"
    },
    {
      "value": "How_Family_First_Heard__c",
      "label": "How family first heard about GLA"
    },
    {
      "value": "Pre_Departure_Form_Complete__c",
      "label": "Pre-Departure Form Complete"
    },
    {
      "value": "Gender__c",
      "label": "Gender"
    },
    {
      "value": "PassportCountry__c",
      "label": "Passport Country"
    },
    {
      "value": "PassportNumber__c",
      "label": "Passport Number"
    },
    {
      "value": "PassportExpirationDate__c",
      "label": "Passport Expiration Date"
    },
    {
      "value": "Months_of_Passport_Validity_Upon_Return__c",
      "label": "Months of Passport Validity Upon Return"
    },
    {
      "value": "T_shirt_Size__c",
      "label": "T-shirt Size"
    },
    {
      "value": "DC_total_number_travelers__c",
      "label": "DC total number travelers"
    },
    {
      "value": "Rezdy_Order_Number__c",
      "label": "Rezdy Order Number"
    },
    {
      "value": "EmergencyContactName__c",
      "label": "Emergency Contact Name"
    },
    {
      "value": "EmergencyContactRelation__c",
      "label": "Emergency Contact Relation"
    },
    {
      "value": "EmergencyContactPrimaryPhone__c",
      "label": "Emergency Contact Primary Phone"
    },
    {
      "value": "EmergencyContactSecondaryPhone__c",
      "label": "Emergency Contact Secondary Phone"
    },
    {
      "value": "Sponsor_Org_Emergency_Contact_Name__c",
      "label": "Emergency Contact Name Sponsor Org"
    },
    {
      "value": "EmergencyContactSponsorOrgEmail__c",
      "label": "Emergency Contact Sponsor Org Email"
    },
    {
      "value": "EmergencyContactSponsorOrg_Phone__c",
      "label": "Emergency Contact Sponsor Org Phone"
    },
    {
      "value": "DateOfBirth__c",
      "label": "Date Of Birth"
    },
    {
      "value": "Gender_Description__c",
      "label": "Gender Description"
    },
    {
      "value": "Citizenship__c",
      "label": "Citizenship"
    },
    {
      "value": "Disclaimer__c",
      "label": "Disclaimer"
    },
    {
      "value": "Dietary_Restriction__c",
      "label": "Dietary Preference"
    },
    {
      "value": "Occupancy_Option__c",
      "label": "Occupancy Option"
    },
    {
      "value": "EmergencyContact2Name__c",
      "label": "Emergency Contact 2 Name"
    },
    {
      "value": "EmergencyContact2Relation__c",
      "label": "Emergency Contact 2 Relation"
    },
    {
      "value": "EmergencyContact2PrimaryPhone__c",
      "label": "Emergency Contact 2 Primary Phone"
    },
    {
      "value": "EmergencyContact2SecondaryPhone__c",
      "label": "Emergency Contact 2 Secondary Phone"
    },
    {
      "value": "SSB_Student_Login_Email__c",
      "label": "Student Login Email"
    },
    {
      "value": "Purchased_Travel_Guard__c",
      "label": "Purchased Travel Insurance?"
    }
  ]