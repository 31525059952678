import {Link} from 'react-router-dom'

const Error403 = () => {
   return (
      <div className='d-flex flex-column flex-root'>
         <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
            {/* begin::Illustration */}
            <div className='fw-bold fs-5x text-danger'>403</div>
            {/* end::Illustration */}
            {/* begin::Message */}
            <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
               Access Denied/Forbidden
            </h1>
            <p>Sorry. your're not allowed in here </p>
            <p>Please prefer to system administrator </p>
            {/* end::Message */}
            {/* begin::Link */}
            <Link to='/' className='btn btn-primary'>
               Return Home
            </Link>
            {/* end::Link */}
         </div>
      </div>
   )
}

export default Error403
