import * as Yup from 'yup'

export const EnrollmentExtraSchema = Yup.object({
  name: Yup.string().required('Enter Student Name'),
  type: Yup.string().required('Enter Type'),
  default_client: Yup.string().required('Please choose an Client'),
  category: Yup.number().required('Please choose a category'),
  amount: Yup.number().required('Enter Amount'),
  status_id: Yup.boolean().oneOf([true, false]),
})
