import { useEffect, useMemo, useState } from "react"
import { useAuth } from "../../auth"
import { useNavigate, useParams } from "react-router-dom"
import { addOrEditTypeForm } from '../../../../network/api'

interface iData{
    name: string
    form_id: string
    overview: string
}

const TypeFormAdd = () => {
    const navigate = useNavigate()
    const { client } = useParams()
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<iData>({
        name: '',
        form_id: '',
        overview: ''
    });      

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoading(true)
        try {
            
            await addOrEditTypeForm( data ) 
            setSuccessMessage('TypeForm added successfully')
            
            setTimeout(() => {
                navigate(`/apps/${client}/typeform-management/typeform`)
            }, 3000)
            
        } catch (error: any) {
            if (error.response.status === 500) {
                return setErrorMessage('Something went wrong, please try again.')
            } else if (error.response.status === 400) {
                return setErrorMessage('Something went wrong while processing the request!')
            } else if (error.response.status === 403) {
                return setErrorMessage('Need client id provided.')
            } else if (error.response.status === 400) {
                return setErrorMessage('Something went wrong while processing the request!')
            } else setErrorMessage('Something went wrong, please try again.')
            setTimeout(() => {
                setErrorMessage('')
        }, 3000)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
    return (
        <span className='indicator-progress' style={{display: 'flex', justifyContent: 'center'}}>
        <span
            style={{height: '40px', width: '40px'}}
            className='spinner-border spinner-border-sm align-middle mt-20'
        ></span>
        </span>
    )
    }
      

    return (
        <>
            {successMessage && (
                <div className='card-body'>
                <div className='alert alert-success d-flex align-items-center p-5'>
                    <div className='d-flex flex-column '>
                    <span>{successMessage}</span>
                    </div>
                </div>
                </div>
            )}

            {errorMessage && (
                <div className='card-body'>
                <div className='alert alert-danger d-flex align-items-center p-5'>
                    <div className='d-flex flex-column '>
                    <span>{errorMessage}</span>
                    </div>
                </div>
                </div>
            )}

            <div className='card-body'>
                <form onSubmit={onSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='name' className='form-label'>
                        Name
                        </label>
                        <input
                        type='text'
                        className='form-control'
                        id='data'
                        value={data.name}
                        onChange={(e) => setData({...data, name: e.target.value })}
                        required
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='formId' className='form-label'>
                        Form ID
                        </label>
                        <input
                        type='text'
                        className='form-control'
                        id='id'
                        value={data.form_id}
                        onChange={(e) => setData({...data, form_id: e.target.value })}
                        required
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='name' className='form-label'>
                            Overview
                        </label>
                        <input
                            type='text'
                            className='form-control'
                            id='overview'
                            value={data.overview}
                            onChange={(e) => setData({...data, overview: e.target.value })}
                            required
                        />
                    </div>
                    <button type='submit' className='btn btn-primary'>
                        Submit
                    </button>
                </form>
            </div>
        
        </>
    )
}

export default TypeFormAdd