// Type Imports
import { IEnrollee } from "../core/_models";

const EnrolleeSelection = (props: any) => {
  const {
    listOfEnrollees,
    selectedEnrollee,
    setSelectedEnrollee
  } = props;

  const changeSelectedEnrollee = (enrolleeID: number) => {
    let chosenEnrollee = listOfEnrollees.find((enrollee: IEnrollee) => {
      return enrollee.id === enrolleeID
    })

    console.log('- - - Chosen Enrollee - - -');
    console.log(chosenEnrollee);

    setSelectedEnrollee(chosenEnrollee)
  }

  return (
    <div className="mb-5">
      <h2 className="text-dark text-center text-uppercase fw-bolder fs-xl-2x mb-7">Select a Child to Enroll on their behalf</h2>

      <form className="w-50 p-5 mx-auto border border-2">
        {
          listOfEnrollees && listOfEnrollees.map((enrollee: IEnrollee) => {
            return (
              <div>
                <label className='form-label mt-2 mb-0'>
                  <input
                    type="radio"
                    className={`form-check-input border border-3 ${selectedEnrollee.id === enrollee.id ? 'border-primary' : 'border-success'}`}
                    name="enrollee"
                    value={enrollee.id}
                    checked={selectedEnrollee.id === enrollee.id}
                    onChange={() => changeSelectedEnrollee(enrollee.id)}
                  />
                  <h4 className='d-inline mx-3'>{`${enrollee.firstName} ${enrollee.lastName}`}</h4>
                </label>
              </div>
            )
          })
        }
      </form>
    </div>
  );
};

export default EnrolleeSelection;
