import React from 'react'
import {useAuth} from '../../auth'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import Discount from './Discount'
import DiscountAdd from './DiscountAdd'
import DiscountEdit from './DiscountEdit'

const EnrollmentExtras = () => {
  const {currentUser} = useAuth()

  const EnrollmentExtrasBreadCrumbs: Array<PageLink> = [
    {
      title: 'Discount Management',
      path: `/apps/${currentUser?.currentClient}/discount-management/list`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={EnrollmentExtrasBreadCrumbs}>Discount List</PageTitle>
              <Discount />
            </>
          }
        />
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={EnrollmentExtrasBreadCrumbs}>Add Discount</PageTitle>
              <DiscountAdd />
            </>
          }
        />
        <Route
          path='edit/:discount_id'
          element={
            <>
              <PageTitle breadcrumbs={EnrollmentExtrasBreadCrumbs}>Edit Discount</PageTitle>
              <DiscountEdit />
            </>
          }
        />
      </Route>
      <Route
        index
        element={<Navigate to={`/apps/${currentUser?.currentClient}/discount-management/list`} />}
      />
    </Routes>
  )
}

export default EnrollmentExtras
