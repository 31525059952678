import React from 'react'
import './style.css'
interface Props {
  title: string
  subheader?: string
  supportingHeader?: string
}

const ConfirmPayBanner = ({title, subheader, supportingHeader}: Props) => {
  return (
    <>
      <div style={{ background: '#f7942a', width: '95%'}} className='d-flex justify-content-center rounded mx-auto col-md-12'>
        <div className='text-center py-2 text-light border-radius-20'>
          <h1 className='fw-bolder fs-2x text-light'>{title}</h1>
          <div className='fw-bold text-uppercase'>{subheader}</div>
          <span className='text-uppercase'>{supportingHeader}</span>
        </div>
      </div>
    </>
  )
}

export default ConfirmPayBanner
