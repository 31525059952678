import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { useAuth } from '../auth'
import useIsAdminOrSuperAdmin from '../auth/hooks/useRole'
import ConfirmAndPay from './ConfirmAndPay'
import CustomPayment from './CustomPayment'
import Enrollment from './Enrollment'
import EnrollmentAccess from './EnrollmentAccess'
import EnrollmentPartTwo from './EnrollmentPartTwo'

const EnrollPage = () => {
  const { currentUser } = useAuth()
  const { isAdminOrSuperAdmin } = useIsAdminOrSuperAdmin()
  const currentRoute = window.location.pathname.split('/')[2] || '84984438'

  console.log("currentRoute", currentRoute)
  if (isAdminOrSuperAdmin) {
    return (
      <Routes>
        <Route index element={<EnrollmentAccess />} />
      </Routes>
    )
  }
  const htmlElement: any = document.querySelector('html');
  htmlElement.setAttribute('data-theme', 'light');
  const theme = htmlElement.getAttribute('data-theme');
  return (

    <Routes>
      {/* If there is no client specified, redirect them to login */}
      <Route index element={<Navigate to='/auth' />} />
      <Route element={<Outlet />}>
        <Route path='/:client/:progress_id' element={<Enrollment />} />

        {currentUser ? (
          <>
            <Route path='/:client/make-a-payment/:opportunity' element={<CustomPayment />} />
            <Route path='/:client/confirm-and-pay/:opportunity' element={<ConfirmAndPay />} />
            <Route path='/:client/enroll-part-two/:opportunity' element={<EnrollmentPartTwo />} />
          </>
        ) : (
          <Route index element={<Navigate to={`/enroll/${currentRoute}`} />} />
        )}

        {/* <Route path='/:client' element={<Navigate to={`/dashboard/${currentUser?.currentClient}`} />} /> */}
        <Route path='/:client' element={<Enrollment />} />
        {/* <Route index element={<Enrollment />} /> */}
      </Route>

      <Route path='*' element={<Navigate to={`/enroll/${currentUser?.currentClient}`} />} />
    </Routes>
  )
}

export default EnrollPage
