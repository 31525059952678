import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreatePassword.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../axiosInstance';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const passwordValidation = (value: string): string | undefined => {
  const errors: string[] = [];
  if (!value) {
    errors.push('Password is required.');
  } else {
    if (value.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }
    if (!/[A-Z]/.test(value)) {
      errors.push('Password must contain at least one uppercase letter.');
    }
    if (!/[a-z]/.test(value)) {
      errors.push('Password must contain at least one lowercase letter.');
    }
    if (!/[0-9]/.test(value)) {
      errors.push('Password must contain at least one number.');
    }
    if (!/[!@#$%^&*]/.test(value)) {
      errors.push('Password must contain at least one special character.');
    }
  }
  return errors.length > 0 ? errors.join('. ') : undefined;
};

const validationSchema = Yup.object({
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('Confirm password is required.'),
});

const CustomErrorMessage: React.FC<{ name: string }> = ({ name }) => {
  const { errors, touched } = useFormikContext<FormValues>();
  const fieldErrors = (errors as any)[name];
  const fieldTouched = (touched as any)[name];

  return fieldErrors && fieldTouched ? (
    <div className="invalid-feedback">
      {fieldErrors.split('. ').map((error: string, index: number) => (
        <div key={index}>{error}</div>
      ))}
    </div>
  ) : null;
};



const PasswordForm: React.FC = () => {
  const {hash, token} = useParams()
  const navigate = useNavigate();
  const [message, setMessage] = useState({
    err: '',
    success: ''
  })

  const onSubmit = async (values: FormValues, action: any) => {
    try {
      const res = await axiosInstance.post(`/auth/create-account/${hash}/${token}`, values);
      console.log(res, 'response');
      setMessage({ err: '', success: 'Welcome to Summer Springboard! Your account has been successfully created. ' });
      action.resetForm();
      navigate('/auth');
    } catch (error: any) {
      setMessage({ err: 'Password not saved, Please try again!', success: '' });
    }
  }

  return (
    <div className='createpw-background'>
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <h1 className="text-center">Create a Strong Password</h1>
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isValid, dirty, isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      name="password"
                      type="password"
                      validate={passwordValidation}
                      className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                    />
                    <CustomErrorMessage name="password" />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Field
                      name="confirmPassword"
                      type="password"
                      className={`form-control ${errors.confirmPassword && touched.confirmPassword ? 'is-invalid' : ''}`}
                    />
                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                  </div>
                  <Button type="submit" disabled={isSubmitting || !isValid} className="w-100 mt-3">
                    Submit
                  </Button>
                  {message.success && <Alert className='mt-3' variant='success'>{message.success}</Alert>}
                  {message.err && <Alert className='mt-3' variant='danger'>{message.err}</Alert>}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>

        <h5 className='mt-3'>Already have a password? Select here to {' '}
          <Link to='/auth'>
            Login
          </Link>
        </h5>
      </Container>
    </div>
  );
};

export default PasswordForm;
