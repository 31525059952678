import moment from 'moment'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const TransactionHistoryModal = ({selectedData, open, setOpen, setSelectedActionData}: any) => {
  const [loading] = useState(false) 
  const handleClose = () => {
    setOpen((prev: any) => !prev)
    setSelectedActionData(null)
  }
  // let AllPayments = null
  if (selectedData && selectedData?.payments) {
    const AllPayments = selectedData.payments.map((item: any) => item.amount) 
    console.log(AllPayments)
  }

  return (
    <Modal className='modal-xl' show={open} onHide={handleClose} backdrop='static' keyboard={false}  centered>
        <Modal.Body>
          <Modal.Header closeButton>
              <Modal.Title>Transaction History</Modal.Title>
          </Modal.Header>
        <div className='p-8'>
          <div className='d-flex justify-content-between'>
            <h3>Total Amount Paid:</h3>
            <h3>Total Due:</h3>
          </div>
          <table className='table table-hover table-striped table-responsive-xxl'>
            <thead>
              <tr>
                <th className='fw-bolder'>Payment Id</th>
                <th className='fw-bolder'>Status</th>
                <th className='fw-bolder'>Date</th>
                <th className='fw-bolder'>Amount</th>
                <th className='fw-bolder'>Note</th>
              </tr>
            </thead>
            <tbody>
              {selectedData && selectedData.payment.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item.payment_id}</td>
                  <td>{item.status}</td>
                  <td>{moment(item.created_at).format('l')}</td>
                  <td>{item.amount}</td>
                  <td>{item.note}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </Modal.Body>
          <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
      </Modal>
  )
}

export default TransactionHistoryModal