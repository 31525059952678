import React, { useMemo } from 'react'
import DynamicTable from '../../../../../_metronic/shared-components/dynamic-table'
import LoadingGIF from '../../../../../assets/Logo/loading.gif'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'


interface IProps {
  reports: any
  loading: boolean
}

const Table = ({reports, loading}: IProps) => {
  const columns = useMemo(() => [
    {
      Header: 'FULL NAME',
      accessor: 'sf_name',
      Cell: ({value}: any) => {
        if (!value) return '-' 
        const parseHtml = ReactHtmlParser(value)
        return parseHtml
      }
    },
    {
      Header: 'AGE',
      accessor: 'age',
      Cell: ({value}: any) => {
        return value || '-'
      }
    },
    {
      Header: 'PROGRAM SESSION',
      accessor: 'program_session',
      Cell: ({value}: any) => {
        return value || '-'
      }
    },
    {
      Header: 'PROGRAM SUMMARY',
      accessor: 'program_summary',
      Cell: ({value}: any) => {
        return value || '-'
      }
    },
    {
      Header: 'Paid Amount',
      accessor: 'paid_amount',
      Cell: ({value}: any) => {
        return value || '-'
      }
    },
    {
      Header: 'APPLICATION STATUS',
      accessor: 'application_status',
      Cell: ({value}: any) => {
        return value || '-'
      }
    },
    {
      Header: 'Created Date',
      accessor: 'created_at',
      Cell: ({value}: any) => {
        const formattedDate = moment(value).format('YYYY-MM-DD');
        return formattedDate
      }

    },
  ], [])

  return (
    <div className='position-relative '>
      <DynamicTable columns={columns} data={reports} />
      
      {loading ? (
        <div style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          background: '#00000013',
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <img src={LoadingGIF} alt="" />
        </div>
      ) : ''}

      {reports?.length === 0 ? (
        <h3 className='text-center mb-5 p-5'>No data found!</h3>
      ) : ''}
    </div>
  )
}

export default Table