import React, { useEffect, useState } from 'react'
import { axiosPrivate } from '../../../../axiosInstance'
import { useQuery } from 'react-query';
import Table from './components/Table';
import Filter from './components/Filter';
import { KTSVG, useDebounce } from '../../../../_metronic/helpers';
import CustomPagination from '../../../../_metronic/shared-components/CustomPagination';
import LoadingGIF from '../../../../assets/Logo/loading.gif'

const ReportList = () => {
  const [status, setStatus] = useState(0)
  const [page, setPage] = useState(1)
  const [client, setClient] = useState('')
  const [search, setSearch] = useState('')
  const [dates, setDates] = useState({
    createAt: '',
    createTo: ''
  })
  const debouncedSearch= useDebounce(search, 1000)
  const [loading, setLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [exportError, setExportError] = useState('')
  const [exportSuccess, setExportSuccess] = useState('')

  const exports = async () => {
    let url = 'terra-admin/export_progress_report'
    url += `/${status}`
    url += '?'
    if (client) url += `client=${client}&`
    if (dates.createAt && dates.createTo) url += `&created_at=${dates.createAt}&created_to=${dates.createTo}`
    setExportLoading(true)
    try {
      const res: any = await axiosPrivate.get(url)
      setExportSuccess(res.data.message)
    } catch (error: any) {
      setExportError(error.message)
    } finally {
      setExportLoading(false)
      setTimeout(() => {
        setExportError('')
        setExportSuccess('')
      }, 5000)
    }
  }

  const fetchData = async () => {
    let url = 'get_reports'
    if (status) {
      url += `/${status}`
    }
    url += '?'
    if (client) url += `client=${client}&`
    if (page) url += `page=${page}`
    if (debouncedSearch) url += `&search=${debouncedSearch.trim()}`
    if (dates.createAt && dates.createTo) url += `&created_at=${dates.createAt}&created_to=${dates.createTo}`

    const response = await axiosPrivate.get(url)
    return response
  };
  const { data: reports, isLoading, isError, refetch } = useQuery('reports', fetchData)

  useEffect(() => {
    if (dates.createAt && !dates.createTo) return

    setLoading(true)
    const fetchDataAgain = async () => {
      await refetch()
      setLoading(false)
    }
    fetchDataAgain()
  }, [debouncedSearch, status, page, client, dates, refetch])

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber)
  }

  if (isError) {
    return (
      <div>Error</div>
    )
  }

  return (
    <>
      {exportSuccess && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{exportSuccess}</span>
            </div>
          </div>
        </div>
      )}
      
      {(exportError || isError) && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{exportError}</span>
            </div>
          </div>
        </div>
      )}

      <div className='d-flex justify-content-end'>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <button className='btn btn-primary' onClick={exports}>
                {exportLoading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <>
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    Export Reports
                  </>
                )}
              </button>
          </div>
        </div>
      </div>
      <div className="card card-body p-0 mt-5" style={{minHeight: 300}}>
        <Filter 
          setStatus={setStatus} 
          setClient={setClient} 
          setSearch={setSearch} 
          setPage={setPage}
          dates={dates}
          setDates={setDates} 
          search={search} />
        {!isLoading ? (
          <Table reports={reports?.data?.ResponseData} loading={loading} />
        ) : (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src={LoadingGIF} alt="" />
          </div>
        ) }
      </div>
      <CustomPagination 
        currentPage={page}
        totalPages={Math.ceil(reports?.data?.NoOfData / 10)}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default ReportList