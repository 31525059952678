import * as Yup from 'yup'

export const EnrollmentFormSchema = Yup.object({
  enrollment_form_group_id: Yup.string().required('Choose Enrollment Group').nullable(),
  client_id: Yup.string().required('Choose a Client'),
  name: Yup.string().required('Enter Salesforce Name'),
  type: Yup.string().required('Choose Type'),
  field_type: Yup.string().required('Choose Field Type'),
  required: Yup.boolean().nullable(),
  label: Yup.string().nullable(),
  helper_text: Yup.string().nullable(),
})
