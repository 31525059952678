import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {IAuth, IUser} from './_models'
import * as authHelper from './AuthHelpers'
import {checkConnection, automateSwitchClient} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {matchPath, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useMutation, UseMutationResult} from 'react-query'
import {AxiosResponse} from 'axios'
import useAuthRequests from '../hooks/useAuthRequests'
import { IPaymentData, IProgram } from '../../application/core/_models'
import useCurrentClientRoute from '../hooks/useCurrentClientRoute'

type AuthContextProps = {
  auth: IAuth | undefined
  saveAuth: (auth: IAuth | undefined) => void
  currentUser: IUser | undefined
  setCurrentUser: Dispatch<SetStateAction<IUser | undefined>>
  logout: () => void
  logoutServer: UseMutationResult<AxiosResponse<any, any>, unknown, null, unknown> | undefined
  showSplashScreen: boolean
  setShowSplashScreen: Dispatch<SetStateAction<boolean>>
  currentLocalDomain: string
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  logoutServer: undefined,
  showSplashScreen: true,
  setShowSplashScreen: () => {},
  currentLocalDomain: 'terra.local',
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<IAuth | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IUser | undefined>()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const currentLocalDomain = window.location.hostname;
  const {axiosAuthLogout} = useAuthRequests()

  const saveAuth = (auth: IAuth | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
      authHelper.removeAuth(authHelper.PREV_AUTH_LOCAL_STORAGE_KEY)
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  const logoutServer = useMutation(axiosAuthLogout)

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        logoutServer,
        showSplashScreen,
        setShowSplashScreen,
        currentLocalDomain,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
interface LocationState {
  from?: {
    pathname: string
  }
}


const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, saveAuth} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const {getCurrentUser} = useAuthRequests()
  const {currentClientRoute} = useCurrentClientRoute()
  const navigate = useNavigate()

  //this one will get clientID in URL

  const state = useLocation().state as LocationState
  const {pathname} = useLocation()

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const checkConnectionSever = async () => {
      try {
        const response = await checkConnection()
        if (response?.status === 200 && pathname === '/error/503') {
          return navigate(state?.from?.pathname || '/', {replace: true})
        }
      } finally {
        setShowSplashScreen(false)
      }
    }

    // //if client is change, then trigger to backend
    // const automateSwichClient = async () => {
    //   try {
    //     const res = await automateSwitchClient(currentClientRoute)
    //     if (res.api_token) {
    //       saveAuth({api_token: res.api_token})
    //       const {currentUser} = await getCurrentUser()
    //       setCurrentUser(currentUser)
    //     } else {
    //       // console.log('do something here', res);
    //     }
    //   } finally {
    //     ///setShowSplashScreen(false)
    //   }
    // }

    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {currentUser} = await getCurrentUser()
          if (currentUser) {
            setCurrentUser(currentUser)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token && pathname !== '/auth/program') {
      // automateSwichClient()
      requestUser()
      
    } else {
      logout()
      setShowSplashScreen(false)
      checkConnectionSever()
    }

    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   //if client is change, then trigger to backend
  //   const automateSwichClient = async () => {
  //     try {
  //         const res = await automateSwitchClient(changeClient)
  //         if (res.status == 200) {
  //           //we get redirect url back from backend, then do redirect
  //           return (window.location.href = res.data.redirectUrl)
  //         }
  //     } finally {
  //       setShowSplashScreen(false)
  //     }
  //   }

  // }, [changeClient])
  

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
