import {Field, ErrorMessage} from 'formik'
import {COUNTRIES} from '../../../../../utils'
import DatePickerField from '../../../Shared/components/DatePickerField'
import {IEnrollPartTwoForm} from '../../core/_models'

interface Props {
  form: IEnrollPartTwoForm
  prefixFieldName: string
  dateFormat?: string
}

const FilterField = ({form, prefixFieldName, dateFormat}: Props): any => {
  const field_type = form.field_type === 'choice' ? 'option' : form.field_type
  switch (field_type) {
    case 'text':
      return (
        <Field
          type='text'
          className='form-control form-control-lg'
          name={prefixFieldName + form.name}
          id={form.name + '_' + form.id}
          required={form.type === 'description'}
        />
      )
    case 'textarea':
      return (
        <Field
          as='textarea'
          rows='5'
          className='form-control form-control-lg'
          name={prefixFieldName + form.name}
          id={form.name + '_' + form.id}
          required={form.type === 'description'}
        />
      )
    case 'option':
      return (
        <Field
          as='select'
          name={prefixFieldName + form.name}
          className='form-select form-select-lg'
          id={form.name + '_' + form.id}
          required={form.type === 'last_episode' ? 'required' : false}
        >
          {form.choices?.map((c, idx) => {
            return c === '---- Select Answer ----' ? (
              <option key={idx}>---- Select Answer ----</option>
            ) : (
              <option value={idx} key={idx}>
                {c}
              </option>
            )
          })}
        </Field>
      )
    case 'option_object':
      return (
        <Field
          as='select'
          name={prefixFieldName + form.name}
          className='form-select form-select-lg'
          id={form.name + '_' + form.id}
          required={form.type === 'last_episode' ? 'required' : form.required ? true : false}
        >
          {form.choicesObject?.map((c, idx) => {
            return c.title === '---- Select Answer ----' ? (
              <option key={idx}>---- Select Answer ----</option>
            ) : (
              <option value={c.value} key={idx} selected={c.title === 'TTN' ? true : false}>
                {c.title}
              </option>
            )
          })}
        </Field>
      )
    case 'checkbox':
      return form.choices ? (
        form.choices?.map((c, idx) => (
          <div className='mb-4' key={idx}>
            <Field
              type='checkbox'
              className='form-check-input me-2'
              name={prefixFieldName + form.name}
              value={idx.toString()}
              id={form.name + '_' + idx}
            />
            <label className='form-check-label' htmlFor={form.name + '_' + idx}>
              {c}
            </label>
          </div>
        ))
      ) : form.choicesObject ? (
        form.choicesObject?.map((c, idx) => (
          <div className='mb-4' key={idx}>
            <Field
              type='checkbox'
              className='form-check-input me-2'
              name={prefixFieldName + form.name}
              value={c.value.toString()}
              id={form.name + '_' + c.value}
            />
            <label className='form-check-label' htmlFor={form.name + '_' + c.value}>
              {c.title}
            </label>
          </div>
        ))
      ) : (
        <div className='mb-4'>
          <Field
            type='checkbox'
            className='form-check-input me-2'
            name={prefixFieldName + form.name}
            //value={form.answer ? 1 : 0}
            id={form.name}
          />
        </div>
      )
    case 'boolean':
      return (
        <div className='d-flex'>
          <div className='mb-4 me-5'>
            <Field
              type='radio'
              className='form-check-input me-2'
              value='0'
              name={prefixFieldName + form.name}
              id={form.name + '_' + form.id}
            />
            <label className='form-check-label' htmlFor={form.name + '_' + form.id}>
              No
            </label>
          </div>
          <div className='mb-4'>
            <Field
              type='radio'
              className='form-check-input me-2'
              value='1'
              name={prefixFieldName + form.name}
              id={form.name + '_' + form.id}
            />
            <label className='form-check-label' htmlFor={form.name + '_' + form.id}>
              Yes
            </label>
          </div>
        </div>
      )
    case 'choice_country':
      return (
        <Field
          as='select'
          name={prefixFieldName + form.name}
          className='form-select form-select-lg'
          id={form.name + '_' + form.id}
        >
          <option>---- Select Country ----</option>
          {COUNTRIES.map((c, idx) => {
            return (
              <option value={c.code} key={idx}>
                {c.name}
              </option>
            )
          })}
        </Field>
      )
    case 'datepicker':
      return (
        <DatePickerField
          className='form-control form-control-lg'
          name={prefixFieldName + form.name}
          dateFormat={dateFormat}
        />
      )
    case 'number':
      return (
        <Field
          type='number'
          className='form-control'
          placeholder=''
          min='0'
          name={prefixFieldName + form.name}
        />
      )
    default:
      return null
  }
}

export default FilterField
