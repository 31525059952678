import {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import { getAuthPromise, PREV_AUTH_LOCAL_STORAGE_KEY } from '../../app/modules/auth'
import useWindowSize from '../../hooks/useWindowSize'

const MasterLayout = () => {
  const location = useLocation()
  const { width } = useWindowSize()

  // Trigger reinitialization of the menu component
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  const [hasAdmin, setHasAdmin] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      const prevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (prevAuth) {
        setHasAdmin(true)
        document.body.classList.add('admin-layout')
      } else {
        setHasAdmin(false)
        document.body.classList.remove('admin-layout')
      }
    }

    checkAdmin()
  }, [])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className={`d-flex flex-column flex-row-fluid ${hasAdmin || width < 991  ? 'wrapper2' : 'wrapper'}`} id='kt_wrapper'>
            <HeaderWrapper />

            <div
              id='kt_content'
              className='content d-flex flex-column flex-column-fluid position-relative'
            >
              <Toolbar />
              <div className='px-10' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <RightToolbar />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
