import { initialQueryState } from '../../../../../../_metronic/helpers';
import { ReusableSearchComponent } from '../../../../Shared/components/ReusableSearchComponent';
import { useQueryRequest } from '../../../../Shared/core/QueryRequestProvider';

const OppListSearchComponent = () => {
  const { updateState } = useQueryRequest();
  const handleSearch = (searchTerm: string) => {
    updateState({ search: searchTerm, ...initialQueryState });
  };
  return (
    <div>
      <ReusableSearchComponent placeholder="Search opportunity" onSearch={handleSearch} />
    </div>
  )
};

export {OppListSearchComponent}
