import {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../auth'
import _queryKeys from '../core/_queryKeys'
import PaymentMethodField from './Shared/PaymentMethodField'
import useAppRequests from '../Hooks/useAppRequests'
import PaymentModal from './modal/PaymentModal'

const AlertMessage = ({ type, message }: { type: string; message: string }) => {
  const alertClass = type === 'success' ? 'alert-success' : 'alert-danger';

  return message ?(
    <div className='card-body'>
      <div className={`alert ${alertClass} d-flex align-items-center p-5`}>
        <div className='d-flex flex-column'>
          <span>{message}</span>
        </div>
      </div>
    </div>
  ) : null;
};

const ListPayment = () => {
  const [message, setMessage] = useState<any>({})
  const {currentUser} = useAuth()

  const {
    getUserPaymentsReq,
    addNewPaymentMethod,
    updatePaymentMethodV2,
  } = useAppRequests()
  const {pMethodId} = useParams()

  const {isLoading: isUpdatepMethodLoading, data: payments_data} = useQuery(
    _queryKeys.getUserPaymentsReq,
    () => getUserPaymentsReq(currentUser?.id)
  )
  let selectedPayment = undefined
  if(payments_data?.payment_methods){
    selectedPayment = payments_data?.payment_methods.filter(
      (pm) => pm.id?.toString() === pMethodId
    )[0]
  }

  const [itemId, setItemId] = useState<string | undefined>(undefined)
  const [addItem, setAddItem] = useState<boolean>(false)
  const addNewPayment = (): any => {
    setAddItem(true)
  }

  const {
    isLoading: isCreatePMethodLoading,
    isSuccess: isCreatePMethodSuccess,
    isError: isCreatePMethodError,
    mutateAsync: createPMethodByAdminAsync,
  } = useMutation(addNewPaymentMethod)

  const {
    isLoading: isUpdatePMethodLoading,
    isSuccess: isUpdatePMethodSuccess,
    isError: isUpdatePMethodError,
    mutateAsync: updatePMethodByAdminAsync,
  } = useMutation(updatePaymentMethodV2)
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (message && message.message) {
      timer = setTimeout(async () => {
        setMessage({});
        window.location.reload();
      }, 5000); // 5000 milliseconds = 5 seconds
      
    }
    return () => clearTimeout(timer);
  }, [message])

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body py-3'>
        <button type='button' className='btn btn-sm btn-primary me-5' onClick={addNewPayment}>
          <i className='fa-solid fa-plus pb-1 pe-0'></i> Add New Payment Method
        </button>
      </div>
      {(isCreatePMethodSuccess || isUpdatePMethodSuccess) && (
        <AlertMessage 
          type={message.statusCode ? 'success' : 'danger'} 
          message={message.message} 
        />
      )}
      {(isCreatePMethodError || isUpdatePMethodError) && (
        <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Oops!</h5>
            <span>Some error occurred while processing your request!</span>
          </div>
        </div>
      )}
      {payments_data && (
        <div className='mb-7'>
          <PaymentMethodField
            paymentData={payments_data}
            isPaymentLoading={isUpdatepMethodLoading}
            payment_method={selectedPayment}
            id={itemId}
            setMessage={setMessage}
            close={setItemId}
          />
        </div>
      )}
      {addItem && (
        <PaymentModal
          id={undefined}
          isPaymentLoading={isCreatePMethodLoading || isUpdatePMethodLoading}
          payment_method={selectedPayment}
          setMessage={setMessage}
          close={setAddItem}
          paymentMutateAsync={{
            createPMethodByAdminAsync,
            updatePMethodByAdminAsync,
          }}
        />
      )}
    </div>
  )
}

export default ListPayment
