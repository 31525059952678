import {CLIENT_NAMES_LIST, payment_types} from './'
import {
  IEnrollPartTwoForm,
  IEnrollmentObject,
  IPaymentMethod,
} from '../app/modules/application/core/_models'
export interface IClientDomain {
  value: string
  name: string
  domainName: string
  domainUrl: string
  abbreviation: string
}

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max)
}

export function getClientList(clientNames: string[] | undefined): IClientDomain[] | null {  
  if (!clientNames) {
    return null
  }
  return CLIENT_NAMES_LIST.filter((c) => clientNames.includes(c.value))
}

export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export const createLinkText = (text: string, url: string) => {
  return `<a
      target='_blank'
      rel='noopener noreferrer'
      href='${url}'
    >
      ${text}
    </a>`
}

export const getPartTwoObject = (
  fieldFormsData: IEnrollPartTwoForm[] | undefined,
  objName: string
) => fieldFormsData?.filter((f) => f.group === objName)

export const totalAmountCal = (enrollment_objects: IEnrollmentObject[] | undefined, fee: number): number | null => {
  if (!Array.isArray(enrollment_objects) || !enrollment_objects.length || enrollment_objects === undefined) {
    return null
  }
  return (
    enrollment_objects.reduce((accumulator, obj) => {
      return accumulator + obj.tuition
    }, 0) + fee
  )
}
export const totalDuenowCal = (
  enrollment_objects: IEnrollmentObject[] | undefined,
  fee: number
): number | null => {
  if (!Array.isArray(enrollment_objects) || !enrollment_objects.length || enrollment_objects === undefined) {
    return null
  }
  return (
    enrollment_objects.reduce((accumulator, obj) => {
      return accumulator + obj.due_upon_application
    }, 0) + fee
  )
}

export const incompletedPartTwoTabId = (status: number | undefined) : number =>{
  if(!status || status === undefined){
    return 1
  }
  return status
}

export const parse_payment = (pm: IPaymentMethod | undefined) => {
    if(!pm){
      return null
    }
    var converted_text = payment_types.find(function (post) {
      if (post.raw == pm?.subType) return true
    })
    return converted_text
}

export const keyify = (obj:any, prefix = '') : any =>
  Object.keys(obj).reduce((res:any, el) => {
    if (Array.isArray(obj[el])) {
      return res
    } else if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], prefix + el + '.')]
    }
    return [...res, prefix + el]
  }, [])

export const scrollToFormikError = (errors:any) => {
  const errKeys = keyify(errors)
  if (errKeys.length) {
    //console.log('errKeys', errKeys)
    errKeys.forEach((e: any) => {
      const input = document.querySelector(`input[name="${e}"]`)
      if (input) {
        console.log('input', input)
        input.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
        return
      }
    })
  }
}