import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {useAuth, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise} from '../../app/modules/auth'
import moment from 'moment';
import { getAllProgram, getAllTypeForm, getAllTypeFormData } from '../../network/api';
import { axiosPrivate } from '../../axiosInstance';
interface TypeformData {
  id: number;
  form_id: string;
  response_type: string;
  name: string;
  overview: string;
  category: string;
  action: string;
}

const SavedProgress = ({ savedProgress }: any) => {

  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getTypeformData, setTypeformData] = useState<TypeformData[]>([]);
  const [getTypeForm, setTypeform] = useState<TypeformData[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState<boolean>(false);
  const [showModalConfirmationApproveDeny, setShowModalConfirmationApproveDeny] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState({ success: '', error: ''});
  const [modalMessage, setModalMessage] = useState('');
  const [getAction, setAction] = useState('');
  const [isError, setIsError] = useState<string | null>(null);
  const [getFormsByProgram, setFormsByProgram] = useState<{ [key: number]: number[] }>({});
  const [getContactPrograms, setContactPrograms] = useState<number[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<string>()
  const [getCurrentStatus, setCurrentStatus] = useState<string>()
  const [formData, setFormData] = useState({
    studentUUID: null,
    programUUID: null,
    formID: null,
  });

  const [hasAdmin, setHasAdmin] = useState<any>(null)

  useEffect(() => {
    const getCurrentAuth = async () => {
      const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (PrevAuth) {
        setHasAdmin(true)
      } else {
        setHasAdmin(false)
      }
    }
    getCurrentAuth()
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch typeform data
        const [typeformRes, typeformDataRes] = await Promise.all([
          getAllTypeForm(),
          getAllTypeFormData(),
        ]);

        const { data: typeformData } = typeformRes;
        const categoryForms = typeformData.filter((form: { category: string; }) => form.category === "application");
  
        const studentIds = savedProgress?.flatMap((entry: { student_info: { id: any; }; }) =>
          entry.student_info?.id ? [entry.student_info.id] : []
        ) || [];

        const formIds = categoryForms.map((form: { id: any; }) => form.id);

        const formsForStudent = studentIds.reduce(
          (acc: { [key: string]: number[] }, studentId: number) => {
            acc[studentId] = formIds;
            return acc;
          },
          {}
        );
  
        setTypeformData(categoryForms)
        setFormsByProgram(formsForStudent); 
        setTypeform(typeformDataRes.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [savedProgress]);

  const handleDelete = async (program_uuid: string) => {
    if (window.confirm("Are you sure you want to delete this program?")) {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.post(`/remove-incomplete-apps/${program_uuid}`);
        console.log("Program UUID:", program_uuid);

        if (response.status === 200) {
          alert(response.data.message); 
          window.location.reload();  
        }
      } catch (error) {
        console.error('Error while deleting:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleShowModal = async (student_uuid: any, program_uuid: any, form_id: any, status: string) => {
    setSelectedStatus(status);
    setCurrentStatus(status)
    setShowModal(true)
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  const handleShowModalConfirmation = (student_uuid: any, program_uuid: any, form_id: any, message: string) => {
    setModalMessage(message);
    setShowModalConfirmation(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  const handleShowModalConfirmationApproveDeny = (student_uuid: any, program_uuid: any, form_id: any, action: string, message: string) => {
    setModalMessage(message);
    setAction(action);
    setShowModalConfirmationApproveDeny(true);
    setFormData({
      studentUUID: student_uuid,
      programUUID: program_uuid,
      formID: form_id,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalConfirmation(false);
    setShowModalConfirmationApproveDeny(false);
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleSubmitStatusChange = async (student_uuid: any, program_uuid: any, form_id: any) => {
    if (selectedStatus === getCurrentStatus) {
      setIsError(`The form status is already set to '${getCurrentStatus}'. No changes will be made.`)

      setTimeout(() => {
        setIsError('')
      }, 3000)

      return;
    }

    try {
      const response = await axiosPrivate.get('/api/typeform-updateStatus', {
        params: { student_uuid, program_uuid, form_id, selectedStatus},
      });
      
    } catch (error) {
      setIsError('An error occurred while submitting the form: ' + error)
    }

    handleCloseModal()
  };

  const handleSubmitRequest = async(student_uuid: any, program_uuid: any, form_id: any, message: string, account_holder_uuid?: string) => {
    try {
      setIsLoading(true);
      const data = { student_uuid, program_uuid, form_id, type: message, account_holder_uuid}
      const response = await axiosPrivate.post('/api/typeform-request', data);

      if (response.status === 200) {
        setShowSuccessMessage({ success: response.data.message, error: ''})
      }
      
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while submitting the form: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleApproveAndDenyRequest = async(student_uuid: any, program_uuid: any, form_id: any, action: any, message: string) =>{
    try {
      setIsLoading(true);
      const data = { student_uuid, program_uuid, form_id, action}
      if (message === 'Approve'){
        const response = await axiosPrivate.post('/api/typeform-approveRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})

        }
      } else {
        const response = await axiosPrivate.post('/api/typeform-denyRequest', data);
        if (response.status === 200) {
          setShowSuccessMessage({ success: response.data.message, error: ''})

        }
      }
    } catch (error: any) {
      setShowSuccessMessage({ success: '', error: 'An error occurred while processing the request: : ' + error.message})
    } finally {
      setTimeout(() => {
        handleCloseModal()
        setShowSuccessMessage({success: '', error: ''});
        setIsLoading(false);
      }, 2000);
    }
  };

  const navigateForms = async (student_uuid: string, id: any, program_uuid: string, form_id: string, completedForms: any[], sf_id: string) => {
    try {
      setIsLoading(true);
      const data  = getTypeformData

      const formIdToFormId = data.reduce((acc: { [key: number]: string }, form: any) => {
        acc[form.id] = form.form_id;
        return acc;
      }, {});

      const matchedForm = data.find((form: any) => form.id === form_id);

      if (!matchedForm) {
        console.error('No matching form_id found.')
        return;
      }

      const formsForProgram = getFormsByProgram[id] || []
      const completedFormsSet = new Set(completedForms.map(id => Number(id)));
      const filterIncompleteForms = formsForProgram.filter((formId: number) => !completedFormsSet.has(formId));
      const currentForm = matchedForm.form_id
      const remainingForms = filterIncompleteForms
        .map(formId => formIdToFormId[formId])
        .filter(form => form && form !== currentForm)

      navigate(`/dashboard/${currentUser?.currentClient}/forms/${currentForm}`, { replace: true, state: {
        student_uuid: student_uuid,
        program_uuid: program_uuid,
        form_id: currentForm,
        remaining_forms: remainingForms,
        auth: currentUser?.currentClient,
        rawTypeformData: data,
        sf_id
      }})
    } catch (error) {
      console.error("Error handling navigation:", error);
    } finally {
      setIsLoading(false);
    }
  }

    const handleContinue = (data: any) => {
      const { form_data, current_step, program_uuid, selected_campus, selected_student } = data
        localStorage.setItem('multiStepFormData', JSON.stringify(form_data));
        localStorage.setItem('scholarshipCodes', JSON.stringify(form_data?.discounts))
        localStorage.setItem('currentStep', current_step);
        localStorage.setItem('selectedCampus', selected_campus);
        sessionStorage.setItem('selectedStudent', selected_student);
        sessionStorage.setItem('selectedAppType', form_data?.enrollment_track || '');
        sessionStorage.setItem('program_uuid', program_uuid);
        sessionStorage.setItem('tuition_plan', form_data?.tuition_plan);
        sessionStorage.setItem('tnc', form_data?.tnc);
        sessionStorage.setItem('selectedExtras', JSON.stringify(form_data?.enrollment_extras))
        sessionStorage.setItem('student_contact_id', form_data?.student_contact_id)
        sessionStorage.setItem('contact_sf_id', form_data?.contact_sf_id || '')

        navigate(`/application/${currentUser?.currentClient}/add-program`);
    };

    if (savedProgress?.length === 0) return null;

    return (
      <div>
        {savedProgress?.map((item: any, index: number) => {

          const student_uuid = item.student_info.uuid
          const studentName = `${item.student_info.first_name} ${item.student_info.last_name}`
          const { form_data } = item

          if (item.status === "enrolled") return null

          const campusName = form_data?.selectedProgram.programData.campus
          const tuitionName = form_data?.student_Type
          const sessionName = `${form_data?.selectedSession?.session_name} (${moment.utc(form_data.selectedSession.start_date).format('MM/DD/YYYY')} - ${moment.utc(form_data.selectedSession.end_date).format('MM/DD/YYYY')})`
          const programName = form_data.selectedProgram.name

          const getApplicationType = form_data?.enrollment_track

          const matchedTypeform = getTypeForm.filter(
            (form: any) => {
              return form.student_uuid && String(form.student_uuid) === String(student_uuid);
            }
          );

          const getCompletedForms = matchedTypeform
          .filter((item: any) => item.response_type === 'Submitted - Needs Review')
          .map((item: any) => item.form_id)

          const uniqueForm = Array.from(
            new Map(getTypeformData.map((form) => [form.form_id, {
              id: form.id,
              form_id: form.form_id,
              name: form.name,
              overview: form.overview
            }])).values()
          );
          
          return (
            <div key={index}> 
              <div className='card card-body my-3'>
                <div className='d-flex gap-3'>
                  <div className='d-flex flex-column gap-3'>
                    <span className='fs-3 text-center'>{studentName}</span>
                    <div className='d-flex flex-column' style={{ background: "#ccc", padding: '5px' }}>
                      <span>{campusName}:</span>
                      <span className='text-capitalize'>{tuitionName} Student</span>
                      <span>{sessionName}</span>
                      <span>{programName}</span>
                    </div>
                    <div style={{ background: "#f7942a", padding: '5px', fontSize: 15 }}>Application Status: {'Incomplete - Application Started'}</div>
                    <Button onClick={() => handleContinue(item)} variant='primary'>Click here to continue your application.</Button>
                    <Button 
                      className="btn btn-danger" 
                      onClick={() => handleDelete(item?.program_uuid)}
                    >
                      Delete
                    </Button>
                  </div>

                  {getApplicationType && getApplicationType.trim() !== '' && (
                    <div className='flex-grow-1' style={{ alignSelf: 'flex-start'}}>
                      <table className='table table-bordered'>
                        <thead>
                          <tr>
                            <th>Section</th>
                            <th>Task Name</th>
                            <th>Overview</th>
                            <th>Status</th>
                            <th style={{ width: 150 }}>Action</th>
                          </tr>
                        </thead>
                        <tbody style={{ border: '1px solid #dee2e6' }}>
                          {isLoading ? (
                            <tr>
                              <td colSpan={6} style={{ textAlign: 'center' }}>
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {uniqueForm.map((typeform: any, index: number) => {

                                  const form_id = typeform.id; 
                                  const correspondingTypeform = matchedTypeform.find((typeform: { form_id: any; }) => typeform.form_id === form_id);

                                  const isSubmitted = correspondingTypeform ? correspondingTypeform.response_type === 'Submitted - Needs Review' : false;
                                  const isCompleted = correspondingTypeform ? correspondingTypeform.response_type === 'Completed' : false;

                                  let status = 'Incomplete';
                                  if (isCompleted) status = 'Completed';
                                  else if (isSubmitted) status = 'Submitted - Needs Review';

                                  return (
                                    <tr key={typeform.id}>
                                      {index === 0 && (
                                        <td rowSpan={getTypeformData.length} style={{ verticalAlign: "middle", background: '#ccc', textAlign: 'center' }}>
                                          Application Form
                                        </td>
                                      )}
                                      <td className='px-1'>{typeform?.name || 'N/A'}</td>
                                      <td>{typeform?.overview || 'N/A'}</td>
                                      <td 
                                        className={`${status === 'Incomplete' ? 'incomplete' : status === 'Completed' ? 'completed' : 'submitted'}`}
                                        onClick={status === 'Incomplete' ? () => navigateForms(
                                          student_uuid, 
                                          item.student_info.id, 
                                          item.program_uuid, 
                                          form_id!,
                                          getCompletedForms,
                                          form_data?.contact_sf_id
                                        ) : undefined}
                                      >
                                        {status}
                                      </td>
                                      <td style={{ textAlign: 'center'}}>
                                        {!hasAdmin && status === 'Incomplete' ? (
                                          <Button 
                                            variant="primary"
                                            style={{ width: '100px' }}
                                            onClick={() => navigateForms(
                                              student_uuid, 
                                              item.student_info.id, 
                                              item.program_uuid, 
                                              form_id!,
                                              getCompletedForms,
                                              form_data?.contact_sf_id
                                            )}
                                          >
                                            Submit
                                          </Button>
                                        ) : (
                                          hasAdmin ? (
                                            <>
                                              <Button variant="secondary" style={{ width: '100px', whiteSpace: 'nowrap', fontSize: '12px' }} 
                                                onClick={() =>{handleShowModal(student_uuid, item.program_uuid, form_id, status)}}>
                                                Form Status
                                              </Button>
                                              {correspondingTypeform && correspondingTypeform.action && (
                                                <>
                                                  <Button variant="success" style={{ width: '100px' }} onClick={() =>{handleShowModalConfirmationApproveDeny( student_uuid, item.program_uuid, form_id, correspondingTypeform.action, 'Approve')}}>
                                                    Approve
                                                  </Button>
                                                  <Button variant="danger" style={{ width: '100px' }} onClick={() =>{handleShowModalConfirmationApproveDeny( student_uuid, item.program_uuid, form_id, correspondingTypeform.action, 'Deny')}}>
                                                    Deny
                                                  </Button>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {correspondingTypeform && correspondingTypeform.action ? (
                                                <>
                                                  {correspondingTypeform.action === 'resubmit' ? (
                                                    <p style={{ fontSize: '14px' }}>Resubmit requested</p>
                                                  ) : (
                                                    <p style={{ fontSize: '14px' }}>Update requested</p>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <Button
                                                    variant="link"
                                                    style={{ textDecoration: 'underline', color: 'black' }}
                                                    onClick={() => handleShowModalConfirmation(student_uuid, item.program_uuid, form_id, 'resubmit')}
                                                  >
                                                    Request to resubmit
                                                  </Button>
                                                  <hr style={{ width: '75%', margin: '10px auto' }} />
                                                  <Button
                                                    variant="link"
                                                    style={{ textDecoration: 'underline', color: 'black' }}
                                                    onClick={() => handleShowModalConfirmation(student_uuid, item.program_uuid, form_id, 'update')}
                                                  >
                                                    Request to update
                                                  </Button>
                                                </>
                                              )}
                                            </>
                                          )
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}

                </div>
              </div>
            </div>
          )
        })}

        {/* Modal for Change Status */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          backdrop='static'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Form Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formStatus">
              <Form.Label>Select Form Status</Form.Label>
              <Form.Select value={selectedStatus} onChange={handleChangeStatus}>
                <option value="Incomplete">Incomplete</option>
                <option value="Submitted - Needs Review">Submitted - Needs Review</option>
                <option value="Completed">Completed</option>
              </Form.Select>
            </Form.Group>
            {isError && <div className="text-danger">{isError}</div>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            {hasAdmin && (
              <Button variant="primary" onClick={() => { handleSubmitStatusChange(formData.studentUUID, formData.programUUID, formData.formID)}}>
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/* Modal Confirmation for User Resubmit/Update */}
        <Modal show={showModalConfirmation} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to request to {modalMessage}?
            {showSuccessMessage.success && (
              <Alert variant="success" className="mt-3">
                {showSuccessMessage.success}
              </Alert>
            )}
            {showSuccessMessage.error && (
              <Alert variant="danger" className="mt-3">
                {showSuccessMessage.error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" disabled={isLoading} onClick={() => { handleSubmitRequest(formData.studentUUID, formData.programUUID, formData.formID, modalMessage , currentUser?.uuid)}}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal Confirmation for Admin Approve/Deny*/}
        <Modal show={showModalConfirmationApproveDeny} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to {modalMessage} the request?
            {showSuccessMessage.success && (
              <Alert variant="success" className="mt-3">
                {showSuccessMessage.success}
              </Alert>
            )}
            {showSuccessMessage.error && (
              <Alert variant="danger" className="mt-3">
                {showSuccessMessage.error}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" disabled={isLoading} onClick={() => {handleApproveAndDenyRequest(formData.studentUUID, formData.programUUID, formData.formID, getAction, modalMessage) }}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
};

export default SavedProgress;