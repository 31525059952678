import React, { Dispatch, SetStateAction } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'

interface IProps {
  setSearch: Dispatch<SetStateAction<string>>
  search: string
}

interface CustomDatePickerInputProps {
  value?: string;
  onClick?: () => void;
}

const Filter = ({ setSearch, search}: IProps) => {

  const formatDate = (date: Date) => {
    if (!date) return '' 
    const formattedDate = date?.toISOString();
    const dob = moment(formattedDate).utc(true);
    return dob.format('MM-DD-YYYY')
  }


  const CustomDatePickerInput = React.forwardRef<HTMLInputElement, CustomDatePickerInputProps>(
    ({ value, onClick }, ref) => (
      <input
        ref={ref}
        type="text"
        value={value}
        onClick={onClick}
        className="form-control"
        style={{
          width: '100%',
          height: '38px',
          borderRadius: '4px',
          borderColor: '#d2d2d2',
          background: 'transparent',
          outline: 'none',
        }}
        placeholder="Select range date"
        readOnly
      />
    )
  );

  return (
    <div className='card-header border-0 py-6 d-flex justify-content-between '>
      <div className='fv-row me-5'>
        <label htmlFor="" className='fw-bold ms-1 mb-2 '>
          Search
        </label>
        <div className='d-flex align-items-center position-relative'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
            />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Search'
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter