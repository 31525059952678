import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../modules/auth";
import {IRequireAuth } from "./core/_models"

const RequireAuth = ({ allowedRoles } : IRequireAuth) => {
   const {currentUser} = useAuth()
   const location = useLocation();
   const roles = Array.isArray(currentUser?.role)
      ? currentUser?.role
      : [currentUser?.role];

   return roles?.find((role) => role ? allowedRoles?.includes(role) : false) ? (
      <Outlet />
   ) : currentUser?.id ? (
      <Navigate to="/error/403" state={{ from: location }} replace />
   ) : (
      <Navigate to="/signin" state={{ from: location }} replace />
   );
};

export default RequireAuth;
