import ReactHtmlParser from 'react-html-parser'
import {Field, ErrorMessage} from 'formik'
import { IEnrollPartTwo } from '../../core/_models'
import StudentDetail from './MediacalForm/StudentDetail'
const medicalInfo = `
<b>To be filled out by parents/guardians of the applicant. This information you share with us will be kept in strict confidence and will be used to ensure the safety of your child while on their GLA program.</b>
<br/><br/>
Please note: GLA offers intensive service-learning adventure programs. Your child may be involved in various forms of physical activity, such as day-long hiking at high altitude, river rafting, farm labor, and long air and ground travel. Your child will be in emotionally challenging and stressful situations. Any history of depression, anxiety, eating issues, trauma, or other mental health challenges often resurface during these times. Please be sure to report all such history so GLA can ensure your child's safety and well-being, as well as assess the likelihood that they can be successful on a program.
<br/><br/>
GLA may require additional documentation from your student's care providers in cases of recent or severe health issues. GLA makes every effort to accommodate all students, but there are instances where based on the application, a student may be deemed unlikely to be successful on a program. In this case, the deposit less the application fee will be refunded ($600). If information is found to be excluded from this form, your student could be at risk of being sent home from the program at the expense of the family. We thank you for being as thorough and detailed in your responses as possible.
<br/><br/>
<i>Please only list medications your child will be taking at the time of their GLA program. If your child begins taking medication after completing this form, you MUST let us know via email or phone. Each student is responsible for managing & administering their own medications. They must bring sufficient supplies of all medications to last safely for the duration of the program.</i>
<br/><br/>
If anything changes prior to your student traveling, please alert us as soon as possible.
<br/><br/>
`

const parentSignature = `
I, the parent/guardian, in completing this form confirm that my child is able to travel and participate in an active program that includes activities such as hiking, walking, active volunteering on a daily basis and excursions.
<br/><br/>
I confirm that the medical history information that I have supplied is accurate and complete. Falsification or willfully omitting information about my child's health status may result in a decision to decline their application or could subject them to an early departure from the program at my own expense. I agree to alert GLA to any updates to medical or mental health history in a timely manner prior to the program start date.
<br/><br/>
I agree that it is my responsibility to understand GLA's policies, the required or recommended vaccinations and any medical risks, whether it be via the Centers for Disease Control or through a licensed travel physician.
<br/><br/>
My child's physician(s) also supports their participation in this program. I understand their physician will need to sign off on the physician release form and any additional documentation requested by GLA.
`

interface Props {
  currentFormikVal: IEnrollPartTwo
}

const Step4 = ({currentFormikVal}:Props) => {
  return (
    <div>
      <div className='my-5'>{ReactHtmlParser(medicalInfo)}</div>
      <div className='border rounded m-2 p-5 bg-secondary'>
        <h1>Parent Signature</h1>
        {ReactHtmlParser(parentSignature)}
        <div className='d-flex flex-column my-5'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Parent Signature</label>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder='Parent: Write your name here *'
            name='medical_form.parent_signature_medical'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='medical_form.parent_signature_medical' />
          </div>
          <span className='my-5'>
            By writing your name here, you are validating that you have read and agree to the above.
          </span>
        </div>
      </div>
      <StudentDetail currentFormikVal={currentFormikVal} />
    </div>
  )
}

export default Step4