import {useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import { fetchAllAutoPaymentEnabled, getExportEnabledAutopay, runAutoPayment } from '../../../../network/api'
import Header from '../../../../_metronic/shared-components/Header'
import TransactionHistoryModal from './TransactionHistoryModal'
import { PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, setAuthPromise, useAuth } from '../../auth'
import { axiosPrivate } from '../../../../axiosInstance'
import { PiBankBold, PiExportBold } from 'react-icons/pi'
import RunAutoPayModal from './AutoPayActionModal'
import moment from 'moment'
import { currencyFormat } from '../../../../utils/funcHelpers'
import { ToastContainer, toast } from 'react-toastify'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'
const notify = async (values: any, status: string, option?: any) => {
  toast.dismiss()
  const setOption = option ? option : '';
  switch (status) {
    case 'warn':
      toast.warn(values, setOption)
      break;
    case 'success':
      toast.success(values, setOption)
      break;
    case 'error':
      toast.error(values, setOption)
      break;
    case 'dismiss':
      toast.dismiss()
      break;
    default:
      toast.info(values, setOption)
      break;
  }
}
const AutoPayActionList = () => {
  // STATES
  const [pendingActionList, setPendingActionlist] = useState<[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [visibleData, setVisibleData] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [selectedActionData, setSelectedActionData] = useState<any>();
  const {currentUser} = useAuth()
  const [progressLoading, setProgressLoading] = useState<boolean>(false)
  const [autoPayOpen, setAutoPayOpen] = useState<boolean>(false)
  const [progressData, setProgressData] = useState<any>(null)
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [exportData, setExportData] = useState<any>(null)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  // HOOKS
  const navigate = useNavigate()
  const { client } = useParams()

  const handleOpenAction = (id: any, data: any) => {
    setSelectedActionData(data)
    navigate(`/payment-tools/${client}/auto-pay-action/list`)
  }

  const getAllAutoPaymentMethod = async (checked: boolean) => {
    setPendingActionlist([])
    setLoading(true)
    try {
      const data = await fetchAllAutoPaymentEnabled(checked)
      setIsChecked(checked)
      setPendingActionlist(data)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const getExportProgress = async () => {
    setExportLoading(true)
    try {
      const res = await getExportEnabledAutopay(isChecked)
      setExportData(res)
    } catch (err) {
      console.error(err)
    } finally {
      setTimeout(() => {
        setExportLoading(false)
        setExportData('')
      }, 5000)
    }
  }
  
  const runAutoPay = async () => {
    setAutoPayOpen(false)
    setProgressLoading(true)
    try {
      const res = await runAutoPayment()
      setProgressData(res)
    } catch (err) {
      console.error(err)
    } finally {
      setTimeout(() => {
        setProgressLoading(false)
        setProgressData('')
      }, 5000)
    }
  }

  useEffect(() => {
    getAllAutoPaymentMethod(false)
  }, [])

  const toggleAutoPay = async (oppId:number) => {
    const updateAutoPay = async (opp: number) => {
      const res = await axiosPrivate.post('/update_autopay', {oppId: opp, updateStatus: false})
      if(res.status == 200) {
        const statusMessage = "Successfully toggled off"
        notify(
          statusMessage,
          'success'
        )
        getAllAutoPaymentMethod(isChecked)
      }
    }
    const cancelAction = () => {
      notify(
        '',
        'dismiss'
      )
    }
    const msg = (
      <div className='d-flex flex-column'>
        <p>Are you sure you want to turn this off?</p>
        <div className='d-flex justify-content-center'>
          <button
            className='btn btn-danger me-7'
            onClick={() => updateAutoPay(oppId)}
          >
            Yes
          </button>
          <button
            className='btn btn-success me-7'
            onClick={() => cancelAction()}
          >
            No
          </button>
        </div>
      </div>
    );
    notify(
      msg,
      'warn',
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      }
    )
  }
  
  useEffect(() => {
    const filterEnrollement = search.length !== 0 
    ? pendingActionList.filter((item: any) => item.name?.toLowerCase().includes(search.toLowerCase()) ||
    item.student?.email?.toLowerCase().includes(search.toLowerCase()) ||
    item.program_product?.program_summary?.toLowerCase().includes(search.toLowerCase()) ||
    item.program_session?.name?.toLowerCase().includes(search.toLowerCase()) 
    )
    : pendingActionList
    setVisibleData(filterEnrollement)
  }, [pendingActionList, search])


  const loginUser = async (data: any) => {
    const currentAuth = await getAuthPromise()
    const {data: auth} = await axiosPrivate.post('/auth/login_user_by_admin', {email: data?.student?.email})
  
    if (auth.api_token && currentAuth) {
      currentAuth.email = currentUser?.email
      currentAuth.first_name = currentUser?.firstName || ''
      currentAuth.last_name = currentUser?.lastName || ''

      const promises = [
        setAuthPromise(currentAuth, PREV_AUTH_LOCAL_STORAGE_KEY),
        setAuthPromise(auth)
      ]

      Promise.all(promises)
        .then(async () => {
          const admin_body = {
            first_name: currentUser?.userclient.first_name || '',
            last_name: currentUser?.userclient.last_name || '',
            email: currentUser?.email,
            loginuser_id: data?.student?.id,
            userclient_id: data?.userclient.id,
            role_id: data?.student?.role_id,
            action: 'signed-in',
          }
          return await axiosPrivate.post('/add_admin_logs', admin_body)
        })
        .then(() => {
          window.location.href = '/';
        })
        .catch((error) => {
          console.error("Error setting auth:", error);
        });
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Campus',
        accessor: 'program_product.program_summary',
      },
      {
        Header: 'Course',
        accessor: 'program_session.name',
      },
      {
        Header: 'Application Status',
        accessor: 'opp_status.name',
      },
      {
        Header: 'Payment Schedule Dates',
        Cell: (props: any) => {
          const { payment_schedule_plan } = props.row.original
          return (
            <div>
              {payment_schedule_plan?.dueDates?.map((item: any) => <div>{item.date}</div>) || 'N / A'}
            </div>
          )
        },  
        minWidth: 150
      },
      {
        Header: 'Upcoming Payment Due',
        Cell: (props: any) => {
          const { payment_schedule_plan } = props.row.original
          const DateToday = moment().utc(true);
          const FormattedDateToday = DateToday.format('MM/DD/YYYY');
          let currentPayDate = payment_schedule_plan?.dueDates?.filter((item: any) => {
            return moment(item.date).isAfter(FormattedDateToday) && !item.isPaid;
          });

          return (
            <div>
              {currentPayDate.length > 0 ? (<div className={`${currentPayDate[0].date ? 'text-info': ''}`}>{currentPayDate[0].date} - {currencyFormat.format(currentPayDate[0].amount)}</div>) : 'N / A'}
            </div>
          )
        },  
        minWidth: 150
      },
      {
        Header: 'Payment Overdue',
        Cell: (props: any) => {
          const { payment_schedule_plan } = props.row.original
          const DateToday = moment().utc(true);
          const FormattedDateToday = DateToday.format('MM/DD/YYYY');
          let DueToday = payment_schedule_plan?.dueDates?.filter((item: any) => {
            return moment(item.date).isSameOrBefore(FormattedDateToday) && !item.isPaid;
          });

          return (
            <div>
              {DueToday.length > 0 ? DueToday.map((item: any, index: number) => 
              <div key={index} className={`${item.date ? 'text-info': ''}`}>{item.date} - {currencyFormat.format(item.amount)}</div>) : 'N / A'
              } 
            </div>
          )
        },  
        minWidth: 150
      },
      {
        Header: 'Total Outstanding Balance',
        Cell: (props: any) => {
          const { payment_schedule_plan } = props.row.original
          const totalRemainingBalance = payment_schedule_plan.currentBalance
          return (
            <div>
              <div className='text-info'>{currencyFormat.format(totalRemainingBalance)}</div>
            </div>
          )
        }
      },
      {
        Header: 'Actions',
        accessor: 'id',
        disableSortBy: true,
        Cell: (props: any) => {
          const {customer, opp_status, payment, program_product, ...overrideData} = props.row.original
          return(
          <>
            <button
              onClick={() => handleOpenAction(props.value, props.row.original)}
              className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              id={`action_button_` + props.value}
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </button>
            <div
              id={'subMenu_' + props.value}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <div className='menu-link px-3' onClick={() => setOpen(true)}>
                  Transaction History
                </div>
                {/* <div className='menu-link px-3'>
                  Audit Log
                </div> */}
                <div className='menu-link px-3' onClick={() => loginUser(overrideData)}>
                  Act as
                </div>
                <div className='menu-link px-3' onClick={() => toggleAutoPay(overrideData.id)}>
                  Turn off Auto-pay
                </div>
              </div>
            </div>
          </>
        )},
      },
    ],
    []
  )

  return (
    <>
    {progressLoading ? (
        <div className='card-body'>
          <div className='alert alert-warning d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>Auto Payment is in progress</span>
            </div>
          </div>
        </div>
      ) : (progressData && progressData?.status === 200) ? (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{progressData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : (progressData && progressData?.status !== 200) ? (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{progressData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : ''}
      {exportLoading ? (
        <div className='card-body'>
          <div className='alert alert-warning d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>Export is in progress</span>
            </div>
          </div>
        </div>
      ) : (exportData && exportData?.status === 200) ? (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{exportData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : (exportData && exportData?.status !== 200) ? (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{exportData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : ''}
      <div className='card card-body p-0'>
        <div className="d-flex align-items-center justify-content-between pe-9">
          <div className='d-flex align-items-center'>
            <Header
              setSearch={setSearch}
              search={search}
              placeholder='Search'
            />
            <div className='pt-6'>
              <div>
                <input
                  type="checkbox"
                  className='form-check-input'
                  onChange={(e) => getAllAutoPaymentMethod(e.target.checked)} />
                <span className=' mx-2'>View All Autopay Data</span>
              </div>
            </div>
          </div>
          <div className='mt-5'>
            
          </div>
          <div className='d-flex mt-5 justify-content-end'>
              <button 
                  className='btn btn-info d-flex align-items-center me-5' 
                  onClick={() => getExportProgress()} 
                  disabled={exportLoading}
                >
                  {exportLoading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <>
                      <PiExportBold size={20} className='me-2' color='white' />
                      Export
                    </>
                  )}
              </button>
              <button 
                className='btn btn-primary d-flex align-items-center' 
                onClick={() => setAutoPayOpen(true)} 
                disabled={progressLoading}
              >
                {progressLoading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <>
                    <PiBankBold size={20} className='me-2' color='white' />
                    Run Auto-Pay
                  </>
                )}
              </button>
            </div>
        </div>
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading}/>
        
      </div>
        <TransactionHistoryModal 
          open={open}
          setOpen={setOpen}
          selectedData={selectedActionData}
          setSelectedActionData={setSelectedActionData}
        />
        <RunAutoPayModal
          setAutoPayOpen={setAutoPayOpen}
          autoPayOpen={autoPayOpen}
          runAutoPay={runAutoPay}
        />
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  )
}

export default AutoPayActionList
