import {useField} from 'formik'

const CustomInput = ({label, ...props}: any) => {
  const [field, meta] = useField(props)
  return (
    <div className='fv-row mb-7'>
      <label className='flex-grow-1 required fw-bold fs-6 mb-2 '>{label}</label>
      <input
        {...field}
        {...props}
        className={
          meta.touched && meta.error
            ? 'form-control form-control-solid mb-3 mb-lg-0 fv-plugins-message-container'
            : 'form-control form-control-solid mb-3 mb-lg-0'
        }
        style={props.disabled ? {
          background: '#f2f2f2'
        } : {}}
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container text-danger'>{meta.error}</div>
      )}
    </div>
  )
}

export default CustomInput
