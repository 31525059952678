import * as Yup from 'yup'

export const UserSchema = Yup.object({
  student_id:
    Yup.string()
    .required('Enter Student Name'),
  program_product_id: Yup.string().required('Selected Program Product'),
  airline_id: Yup.string().required('Please choose an Airline'),
  flight_type: Yup.string()
    .oneOf(['Outbound Flight', 'Return Flight'])
    .required('Please choose a Flight Type'),
  flight_number: Yup.string().min(4, 'Minimum 4 symbols').required('Enter Flight Number'),
  departure_airport_id: Yup.string().required('Please choose Departure Airport'),
  departure_date: Yup.string()
    .required('Enter Arrival Date')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)'),
  departure_time: Yup.string()
    .required('Enter Departure Time')
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format (HH:MM)'),
  arrival_airport_id: Yup.string().required('Please choose Arrival Airport'),
  arrival_date: Yup.string()
    .required('Enter Arrival Date')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)'),
  arrival_time: Yup.string()
    .required('Enter Arrival Time')
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format (HH:MM)'),
  notes: Yup.string().min(5, 'Minimum 5 characters'),
  status_id: Yup.boolean().oneOf([true, false]),
})
