import {ID, Response} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { IPaymentSchedule } from '../../../../application/core/_models'
import { Dispatch, SetStateAction } from 'react'


export const psSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  client_id: Yup.string().required('Required'),
  value: Yup.string(),
  due_dates: Yup.array().of(Yup.string()).min(1, 'Please add a due date'),
  status_id: Yup.number().required('Required'),
  type: Yup.string().required('Required'),
})

export const initPaymentSchedule: IPaymentSchedule = {
  name: '',
  value: '',
  due_dates: [],
  type: 'amount',
  status_id: undefined,
  client_id:''
}

export type PaymentSchedulesQueryResponse = Response<Array<IPaymentSchedule>>

export type PaymentSchedulesListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  itemIdForDelete?: {id: ID}
  setItemIdForDelete: Dispatch<SetStateAction<{id: ID} | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: PaymentSchedulesListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForDelete: () => {},
  isAllSelected: false,
  disabled: false,
}