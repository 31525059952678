
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { initialQueryState, KTSVG, useDebounce } from '../helpers'
import { useAuth } from '../../app/modules/auth'
import { Link } from 'react-router-dom'

interface Props {
    isSearch: boolean;
    searchPlaceHolder: string;
    callBackFunctionSearch: (searchQuery: string) => void
    buttonText: string;
    buttonLink: string
}

const DynamicListHeader = ({ isSearch, searchPlaceHolder, callBackFunctionSearch, buttonText, buttonLink }: Props) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debouncedSearchTerm = useDebounce(searchTerm, 150)
    const { currentUser } = useAuth()
    // Effect for API call
    useEffect(
        () => {
            if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
                callBackFunctionSearch(debouncedSearchTerm)
            }
        },
        [debouncedSearchTerm]

    )

    return (
        <div className='card-header border-0 pt-6 display-space-between'>
            <div className='card-title'>
                {/* begin::Search */}
                {isSearch ?
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTSVG
                            path='/media/icons/duotune/general/gen021.svg'
                            className='svg-icon-1 position-absolute ms-6'
                        />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder={searchPlaceHolder}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    : null}
            </div>

            {buttonText != '' ?
                <div className='card-toolbar'>
                    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                        <Link
                            to={buttonLink}
                            replace={true}
                            className='btn btn-primary'
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                            {buttonText}
                        </Link>
                    </div>
                </div>
                : null
            }

        </div>
    )
}

export default DynamicListHeader;
