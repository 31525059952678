import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const RunAutoPayModal = ({setAutoPayOpen, autoPayOpen, runAutoPay}: any) => {

  const handleClose = () => {
    setAutoPayOpen(false)
  }

  return (
    <Modal title="Test" onHide={setAutoPayOpen} show={autoPayOpen} backdrop='static' centered keyboard={false} >
      <Modal.Body>
        <h2>Are you really sure you want to Run Auto Pay? </h2>
      </Modal.Body>

      <Modal.Footer style={{ padding: '20px 30px' }}>
        <Button variant='danger' onClick={handleClose}>
          No
        </Button>
        <Button
          variant='primary'
          className='ms-2'
          onClick={runAutoPay}>
        Run Auto-Pay now
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RunAutoPayModal