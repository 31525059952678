import { useField } from 'formik'
import Select from 'react-select'

const CustomSelect = ({setFieldValue, label, name, options, ...props}: any) => {
  const [field, meta, helpers] = useField(name)
  return (
    <div className='fv-row mb-7'>
      <label className={`flex-grow-1 required fw-bold fs-6 mb-2`}>
        {label}
      </label>
      <Select 
        name={name}
        options={options.map((item: any) => ({
          value: item.value,
          label: item.label,
        }))}
        onBlur={() => helpers.setTouched(true)}
        value={options.find((option: any) => option.value === field.value && option.label)}
        onChange={(val: any) => helpers.setValue(val ? val.value : '')}
        noOptionsMessage={() => 'Select a type first'}
        isClearable
        {...props}
      />
      {meta.touched && meta.error && (
        <div className='fv-plugins-message-container mt-3 text-danger'>{meta.error}</div>
      )}
    </div>
  )
}

export default CustomSelect