import React, {useState, SetStateAction, Dispatch} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import useAppRequests from '../../Hooks/useAppRequests'
import {parse_payment} from '../../../../../utils/funcHelpers'
import _queryKeys from '../../core/_queryKeys'
import {IPaymentMethods, IPaymentMethod} from '../../core/_models'
import PaymentModal from '.././modal/PaymentModal'
import clsx from 'clsx'
import PaymentMethodModal from '../modal/PaymentMethodModal'
import { useAuth } from '../../../auth'
interface Props { 
  paymentData: IPaymentMethods | undefined
  id: string | undefined
  close: any
  isPaymentLoading: boolean
  payment_method: IPaymentMethod | undefined
  setMessage: Dispatch<SetStateAction<object>>
}


const PaymentMethodField = ({
  paymentData,
  id,
  close,
  isPaymentLoading,
  payment_method,
  setMessage,
}: Props) => {
  const queryClient = useQueryClient()
  const {currentUser} = useAuth()
  const [itemId, setItemId] = useState<string | undefined>(undefined)
  const [itemPMethod, setItemPMethod] = useState<{
    modal?: string
    uuid?: string
    item: IPaymentMethod
  } | undefined>(
    undefined
  )
  const [payment, setPayment] = useState<IPaymentMethod>()


  const {setActivePaymentMethodReq, deletePaymentMethodV2} = useAppRequests()

  const {
    isLoading: isActivatePMethodLoading,
    isSuccess: isActivePMethodSuccess,
    isError: isActivePMethodError,
    mutateAsync: activatePMethodByAdminAsync,
  } = useMutation(setActivePaymentMethodReq)

  const {
    isLoading: isDeletePMethodLoading,
    isSuccess: isDeletePMethodSuccess,
    isError: isDeletePMethodError,
    mutateAsync: deletePaymentMethodAsync,
  } = useMutation(deletePaymentMethodV2)

  const handlePaymentMethod = async (apiFunc: any, successMessage: any) => {
    try {
      let idConvertedNum = parseInt(itemPMethod?.item.id || '')
      if (idConvertedNum) {
        const res = await apiFunc({ id: idConvertedNum, card_holder_uuid: currentUser?.uuid })
        if (res) {
          setItemPMethod(undefined)
          setMessage({ statusCode: true, message: successMessage })
        }
      }
      // Refreshing list by using useQueryClient
      await queryClient.prefetchQuery(_queryKeys.getUserPaymentsReq)
    } catch (ex) {
      console.error(ex)
    } finally {
      setItemPMethod(undefined)
    }
  }

  const activatePaymentMethod = async () => {
    return handlePaymentMethod(
      activatePMethodByAdminAsync,
      'Primary card has been set successfully!'
    );
  };
  
  const deletePaymentMethod = async () => {
    return handlePaymentMethod(
      deletePaymentMethodAsync,
      'Primary card has been deleted successfully!'
    );
  };

  let active_payment = undefined
  let bank_accounts = undefined
  let credit_cards = undefined
  let debit_cards = undefined
  if(paymentData?.payment_methods){
    active_payment = paymentData?.payment_methods.filter((pm) => pm.isActive == true)
    bank_accounts = paymentData?.payment_methods.filter(
      (pm) => pm.paymentType == 'bankAccount' && pm.isActive == false
    )
    credit_cards = paymentData?.payment_methods.filter(
      (pm) => pm.paymentType == 'creditCard' && pm.isActive == false
    )
    debit_cards = paymentData?.payment_methods.filter(
      (pm) => pm.paymentType == 'debitCard' && pm.isActive == false
    )
  }

  return (
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        <h5>
          <i className='fa-regular fa-credit-card'></i> Primary Payment Method
        </h5>
        {/* begin::Table */}
        <table className='table table-row-dashed align-middle gs-0 gy-4 mb-8'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='rounded-start'></th>
              <th className='ps-4 rounded-start'>Payment Method</th>
              <th>Exp.Date</th>
              <th className='rounded-end'>Payment Type</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody className='fs-5'>
            {active_payment ? active_payment?.map((pm: IPaymentMethod, idx) => {
              let converted_text = parse_payment(pm)
              return (
                <tr key={idx}>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {
                      <>
                        {/* <button
                          type='button'
                          className='btn btn-sm btn-outline-dark'
                          onClick={() => updatePaymentMethod(pm.id)}
                        >
                          <i className='fa-solid fa-pen-to-square p-0'></i>
                        </button> */}
                        <button
                          type='button'
                          disabled={pm.isActive ? true : false}
                          className={clsx(
                            'btn btn-sm',
                            pm.isActive ? 'btn-outline-success' : 'btn-outline-secondary'
                          )}
                        >
                          <i className='fa-sharp fa-solid fa-circle-check'></i>
                        </button>
                        {/* <button
                            type='button'
                            className='btn btn-sm btn-outline-danger'
                            onClick={deletePaymentMethod}
                          >
                            <i className='fa-solid fa-trash p-0'></i>
                          </button> */}
                      </>
                    }
                  </td>
                  <td className='col-lg-6 col-md-6 col-sm-4'>
                    {
                      <span className='text-dark d-block'>
                        <i className={converted_text?.icon}></i> {converted_text?.value} Ending in{' '}
                        {pm?.cardNumber?.replaceAll('x', '')}
                      </span>
                    }
                  </td>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {<span className='text-dark d-block'>{pm?.expireDates?.toString()}</span>}
                  </td>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {<span className='text-dark d-block'>{converted_text?.type}</span>}
                  </td>
                </tr>
              )
            }) :
            <tr className='alert alert-warning align-items-center'>
              <td colSpan={4} className='text-center'>
                <h5 className='mb-1'>No Active Funding</h5>
              </td>
            </tr>
          }
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
        <h5>
          <i className='fa-regular fa-credit-card'></i> Credit Cards
        </h5>
        {/* begin::Table */}
        <table className='table table-row-dashed align-middle gs-0 gy-4 mb-8'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='rounded-start'></th>
              <th className='ps-4 rounded-start'>Payment Method</th>
              <th>Exp.Date</th>
              <th className='rounded-end'>Payment Type</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody className='fs-5'>
            {credit_cards ? credit_cards?.map((pm: IPaymentMethod, idx) => {
              let converted_text = parse_payment(pm)
              return (
                <tr key={idx}>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {
                      <>
                        {/* <button
                          type='button'
                          className='btn btn-sm btn-outline-dark'
                          onClick={() => updatePaymentMethod(pm.id)}
                        >
                          <i className='fa-solid fa-pen-to-square p-0'></i>
                        </button> */}
                        <button
                          type='button'
                          disabled={pm.isActive ? true : false}
                          className={clsx(
                            'btn btn-sm',
                            pm.isActive ? 'btn-outline-success' : 'btn-outline-secondary'
                          )}
                          onClick={() => setItemPMethod({modal: 'activePMethod', item: pm})}
                        >
                          <i className='fa-sharp fa-solid fa-circle-check'></i>
                        </button>
                        <button
                          type='button'
                          className='btn btn-sm btn-outline-danger'
                          onClick={() => setItemPMethod({modal: 'deletePMethod', item: pm, uuid: currentUser?.uuid || ''})}
                        >
                          <i className='fa-solid fa-trash p-0'></i>
                        </button>
                      </>
                    }
                  </td>
                  <td className='col-lg-6 col-md-6 col-sm-4'>
                    {
                      <span className='text-dark d-block'>
                        <i className={converted_text?.icon}></i> {converted_text?.value} Ending in{' '}
                        {pm?.cardNumber?.replaceAll('x', '')}
                      </span>
                    }
                  </td>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {<span className='text-dark d-block'>{pm?.expireDates?.toString()}</span>}
                  </td>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {<span className='text-dark d-block'>{converted_text?.type}</span>}
                  </td>
                </tr>
              )
            }) :
            <tr className='alert alert-warning align-items-center'>
              <td colSpan={4} className='text-center'>
                <h5 className='mb-1'>No Credit Card Added</h5>
              </td>
            </tr>
            }
          </tbody>
          {/* end::Table body */}
        </table>

        <h5>
          <i className='fa-solid fa-building-columns'></i> Checking Accounts
        </h5>
        {/* begin::Table */}
        <table className='table table-row-dashed align-middle gs-0 gy-4 mb-8'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='rounded-start'></th>
              <th className='ps-4 rounded-start'>Payment Method</th>
              <th className='rounded-end'>Payment Type</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody className='fs-5'>
            {bank_accounts ? bank_accounts?.map((pm: IPaymentMethod, idx) => {
              let converted_text = parse_payment(pm)
              return (
                  <tr key={idx}>
                    <td className='col-lg-2 col-md-2 col-sm-3'>
                      {
                        <>
                          {/* <button
                            type='button'
                            className='btn btn-sm btn-outline-dark'
                            onClick={() => updatePaymentMethod(pm.id)}
                          >
                            <i className='fa-solid fa-pen-to-square p-0'></i>
                          </button> */}
                          <button
                            type='button'
                            disabled={pm.isActive ? true : false}
                            className={clsx(
                              'btn btn-sm',
                              pm.isActive ? 'btn-outline-success' : 'btn-outline-secondary'
                            )}
                            onClick={() => setItemPMethod({modal: 'activePMethod', item: pm})}
                          >
                            <i className='fa-sharp fa-solid fa-circle-check'></i>
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-outline-danger'
                            onClick={() => setItemPMethod({modal: 'deletePMethod', item: pm})}
                          >
                            <i className='fa-solid fa-trash p-0'></i>
                          </button>
                        </>
                      }
                    </td>
                    <td className='col-lg-6 col-md-6 col-sm-4'>
                      {
                        <span className='text-dark d-block'>
                          <i className={converted_text?.icon}></i> {converted_text?.value} Ending in{' '}
                          {pm?.cardNumber?.replaceAll('x', '')}
                        </span>
                      }
                    </td>
                    {/* <td className='col-lg-2 col-md-2 col-sm-3'>
                      {<span className='text-dark d-block'>{pm?.expireDates?.toString()}</span>}
                    </td> */}
                    <td className='col-lg-2 col-md-2 col-sm-3'>
                      {<span className='text-dark d-block'>{converted_text?.type}</span>}
                    </td>
                  </tr>
              )
            }):
              <tr className='alert alert-warning align-items-center'>
                <td colSpan={4} className='text-center'>
                  <h5 className='mb-1'>No Bank Funding</h5>
                </td>
              </tr>
            }
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      <h5>
          <i className='fa-regular fa-credit-card'></i> Debit Cards
        </h5>
        {/* begin::Table */}
        <table className='table table-row-dashed align-middle gs-0 gy-4 mb-8'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='rounded-start'></th>
              <th className='ps-4 rounded-start'>Payment Method</th>
              <th>Exp.Date</th>
              <th className='rounded-end'>Payment Type</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody className='fs-5'>
            {debit_cards ? debit_cards?.map((pm: IPaymentMethod, idx) => {
              let converted_text = parse_payment(pm)
              return (
                <tr key={idx}>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {
                      <>
                        {/* <button
                          type='button'
                          className='btn btn-sm btn-outline-dark'
                          onClick={() => updatePaymentMethod(pm.id)}
                        >
                          <i className='fa-solid fa-pen-to-square p-0'></i>
                        </button> */}
                        <button
                          type='button'
                          disabled={pm.isActive ? true : false}
                          className={clsx(
                            'btn btn-sm',
                            pm.isActive ? 'btn-outline-success' : 'btn-outline-secondary'
                          )}
                          onClick={() => setItemPMethod({modal: 'activePMethod', item: pm})}
                        >
                          <i className='fa-sharp fa-solid fa-circle-check'></i>
                        </button>
                        <button
                          type='button'
                          className='btn btn-sm btn-outline-danger'
                          onClick={() => setItemPMethod({modal: 'deletePMethod', item: pm, uuid: currentUser?.uuid || ''})}
                        >
                          <i className='fa-solid fa-trash p-0'></i>
                        </button>
                      </>
                    }
                  </td>
                  <td className='col-lg-6 col-md-6 col-sm-4'>
                    {
                      <span className='text-dark d-block'>
                        <i className={converted_text?.icon}></i> {converted_text?.value} Ending in{' '}
                        {pm?.cardNumber?.replaceAll('x', '')}
                      </span>
                    }
                  </td>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {<span className='text-dark d-block'>{pm?.expireDates?.toString()}</span>}
                  </td>
                  <td className='col-lg-2 col-md-2 col-sm-3'>
                    {<span className='text-dark d-block'>{converted_text?.type}</span>}
                  </td>
                </tr>
              )
            }) :
            <tr className='alert alert-warning align-items-center'>
              <td colSpan={4} className='text-center'>
                <h5 className='mb-1'>No Debit Card Added</h5>
              </td>
            </tr>
            }
          </tbody>
          {/* end::Table body */}
        </table>
      {itemId != undefined && payment != undefined && (
        <PaymentModal
          isPaymentLoading={isPaymentLoading}
          payment_method={payment}
          // updateUserByAdminAsync={updateUserByAdminAsync}
          id={itemId}
          close={setItemId}
          // isPaymentLoading={isUpdateUserLoading}
          // pMethodInfo={userInfo}
          // updateUserByAdminAsync={updateUserByAdminAsync}
          setMessage={setMessage}
        />
      )}
      {/* end::Table container */}
      {/* {itemIdForActivePMethod && <ActivePrimaryPaymentModal />} */}
      {itemPMethod?.modal === 'activePMethod' && (
        <PaymentMethodModal
          header='Active Payment Method'
          body='Make this card the primary credit card?'
          setItem={setItemPMethod}
          pMethodLoading={isActivatePMethodLoading}
          onPMethod={activatePaymentMethod}
        />
      )}

      {itemPMethod?.modal === 'deletePMethod' && (
        <PaymentMethodModal
          header='Delete Payment Method'
          body={`Are you sure to delete this credit card ending with ${itemPMethod.item.cardNumber?.replaceAll(
            'x',
            ''
          )}?`}
          setItem={setItemPMethod}
          pMethodLoading={isDeletePMethodLoading}
          onPMethod={deletePaymentMethod}
        />
      )}
    </div>
  )
}

export default PaymentMethodField
