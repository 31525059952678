// @ts-nocheck
import {ClientCustomHeader} from './ClientCustomHeader'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../../auth'
import {ClientActionsCell} from './ClientActionsCell'

const clientsColumns = [
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => {
      const {currentUser} = useAuth()
      return (
        <Link
          to={`/apps/${currentUser?.currentClient}/clients-management/edit/${
            props.data[props.row.index].id
          }`}
        >
          {props.data[props.row.index].name}
        </Link>
      )
    },
  },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Abbreviation' className='min-w-125px' />
    ),
    accessor: 'abbreviation',
  },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Reference Id' className='min-w-125px' />
    ),
    accessor: 'reference_id',
  },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status.name',
  },
  {
    Header: (props) => (
      <ClientCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ClientActionsCell id={props.data[props.row.index].id} />,
  },
]

export {clientsColumns}
