import { useQueryClient } from 'react-query'
import { currencyFormat } from '../../../../../utils/funcHelpers'
import _queryKeys from '../../core/_queryKeys'
import { IOpportunityExtra, IEnrollmentObject } from '../../core/_models'
import { useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { useState } from 'react'
import EditProgram from '../EditProgram'

const glaFee = 100

const ConfirmPayTable = ({inputObject, isLimit}: any) => {
  const queryClient = useQueryClient()
  const {client, opportunity}  = useParams()
  const [showModal, setShowModal] = useState(false);
  
  const enrolledSummary = queryClient.getQueryData<{
    remaining_balance: number
    opportunity: number
    enrollment_objects: IEnrollmentObject[]
    extras: IOpportunityExtra[]
    scholarship: any
    sync_product_code: any
    scholarship_list: any[]
    detail: any[]
    editInProgress: boolean,
    protection_plan: any
  }>(_queryKeys.getRecentPaymentSummariesReq)
  inputObject.protection_price = enrolledSummary?.protection_plan ? enrolledSummary?.protection_plan[0]?.amount : 0;
  let isChecked = inputObject.isChecked
  let protection_price = inputObject.protection_price
  let remaining_balance = 0

  let totalDuenow =
    enrolledSummary &&
    enrolledSummary?.enrollment_objects?.reduce((accumulator, obj) => {
      return accumulator + obj.due_upon_application
    }, 0) || 0

  if (enrolledSummary && enrolledSummary.extras && enrolledSummary.extras.length > 0) {
    enrolledSummary.extras.forEach(ext => {
      if (ext?.program_product_extra?.enrollment_extra.category === 8) {
        totalDuenow += ext.program_product_extra.enrollment_extra.amount
      }
    })
  } else {
    totalDuenow += glaFee
  }

  // if (enrolledSummary && enrolledSummary.scholarship_list) {
  //   for (let item of enrolledSummary.scholarship_list) {
  //     if (item?.type === 'Discount') {
  //       totalDuenow += item.due_upon_application
  //     }
  //   }
  // }
  if (enrolledSummary && enrolledSummary.detail.length) {
    for (let item of enrolledSummary.detail) {
      remaining_balance += Number(item.price)
      if ((item.type === 'Scholarship' || item.type === 'Discount') && item.due_upon_application) {
        totalDuenow += item.due_upon_application
      }
    }
  }

  const scholarship_list = enrolledSummary?.detail
    ?.filter(item => (item.type === 'Scholarship' || item.type === 'Discount')) || []

  const handleClose = () => {
    setShowModal(prev => !prev)
  }

  return (
    <div className='mt-20'>
      <div className='mb-7 d-flex justify-content-between'>
        <h1 className='fs-bold fs-2x text-uppercase'>Confirm & Pay</h1>
        <Button
          variant='primary'
          disabled={enrolledSummary?.editInProgress}
          onClick={handleClose}
        >
          Edit Program
          {(enrolledSummary && enrolledSummary.editInProgress) ? (
            <span className='spinner-border spinner-border-sm align-middle ms-5'></span>
          ) : ''}
        </Button>
      </div>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive table-confirm-enrollment'>
          {/* begin::Table */}
          <table className='table table-row-dashed  align-middle gs-0 gy-4' style={{ color: '#020038' }}>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold rounded' style={{ background: '#e6e6e6'}}>
                <th><h2 className='ps-5' style={{ color: '#020038' }}>Programs</h2></th>
                <th><h3 style={{ color: '#020038' }}>Total Amount</h3></th>
                <th><h3 style={{ color: '#020038' }}>Due now</h3></th>
                <th></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='fs-5'>
              {enrolledSummary?.enrollment_objects?.map((p: any) => {
                const ProgramSessions = client === '47821104' ? p?.session[0]?.program_session[0]?.name.split('-')[1] : ''
                const fullName = `${p.program_summary} ${ProgramSessions}`
                return (
                  <tr key={p.id}>
                    <td>
                      <span className='d-block ps-5'>
                        {fullName}
                      </span>
                    </td>
                    <td>
                      <span className='d-block'>{currencyFormat.format(p.tuition)}</span>
                    </td>
                    <td>
                      <span className='d-block'>
                        {isLimit ? currencyFormat.format(0) : currencyFormat.format(p.due_upon_application)}
                      </span>
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td className='fw-bold ps-5'>Fee</td>
              </tr>
              {/* {enrolledSummary?.detail?.map((ext) => {
                return (
                  <tr>
                    <td>{ext.program_product_extra.enrollment_extra.name}</td>
                    <td>{currencyFormat.format(ext.program_product_extra.enrollment_extra.amount)}</td>
                    <td>{currencyFormat.format(ext.program_product_extra.enrollment_extra.amount)}</td>
                  </tr>
                )
              })} */}

            {enrolledSummary?.detail?.map((ext) => {
              return ext.type == 'Extra' ? (
                <tr key={ext.id}>
                  <td className='ps-5'>{ext.product_name}</td>
                  <td>{currencyFormat.format(ext.price)}</td>
                  <td>{ext.category ? currencyFormat.format(ext.price) : ''}</td>
                </tr>
              ) : (
                ''
              )})}

               {(client == "47821104" && isChecked) ? (<tr>
                <td className='fw-bold ps-5'>Tuition Protection Plan</td>
                <td className='fw-bold'>{currencyFormat.format(protection_price)}</td>
                <td className='fw-bold'>{currencyFormat.format(protection_price)}</td>
              </tr>) : ""}

              {scholarship_list?.length ? (
                <tr>
                  <td className='fw-bold ps-5'>Scholarship & Discount</td>
                  <td></td>
                  <td></td>
                </tr>
              ) : ''}
          
              {scholarship_list.length ? scholarship_list?.map((item: any, ind) => (
                <tr key={ind}>
                  <td className='ps-5'>{item.product_name}</td>
                  <td>{`(${currencyFormat.format(item.price).toString().substring(1)})`}</td>
                  <td>{item?.due_upon_application ? `(${currencyFormat.format(item.due_upon_application).toString().substring(1)})` : ''}</td>
                </tr>
              )) : ''}

              {enrolledSummary?.remaining_balance ? (
                <tr style={{ background: '#e6e6e6', color: '#020038'}}>
                  <td className='ps-5'><h2 style={{ color: '#020038' }}>Total</h2></td>
                  <td>{currencyFormat.format(!isChecked ? remaining_balance : (remaining_balance + protection_price))}</td>
                  <td className='fw-bold'>
                    {!isLimit ? totalDuenow && currencyFormat.format(!isChecked ? totalDuenow : (totalDuenow + protection_price)) : currencyFormat.format(0) } &nbsp; Due Now
                  </td>
                </tr>
              ) : ''}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <EditProgram showModal={showModal} handleClose={handleClose} opportunityID={opportunity} />
    </div>
  )
}

export default ConfirmPayTable
