import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {axiosInstance} from '../../../../axiosInstance'
import {setupAxiosStatus} from './core/ErrorHelpers'

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

const InjectAxiosInterceptors = () => {
   const navigate = useNavigate()
   const location = useLocation()

   useEffect(() => {
      setupAxiosStatus(axiosInstance, navigate, location)
   }, [navigate, location])

   return null
}

export default InjectAxiosInterceptors
