import {Field, useFormikContext} from 'formik'
import {IEnrollmentObject, IPaymentSchedule} from '../../../core/_models'
import {useQueryClient} from 'react-query'
import ReactHtmlParser from 'react-html-parser'; 
import React, { useLayoutEffect, useState } from 'react';
import useAppRequests from '../../../Hooks/useAppRequests';
import { useParams } from 'react-router-dom';
import moment from 'moment'

const text = 
    `<p>The balance due will be split into three equal payments, according to the schedule above. 
    When you are accepted into the program, you will be able to modify your payments options.</p>
    <ul>
        <li>March 10, 2023</li>
        <li>April 10, 2023</li>
        <li>May 10, 2023</li>
    </ul>
    <a href="https://summerspringboard.com/coronavirus-covid-19-updates/">Covid-19 Vaccination Guidelines</a>
    <h2>Refund Policy for Campus Based Programs</h2>
    <p>All refund requests and cancellations must be made in writing and will be calculated according to the date in which Summer Springboard receives such request or cancellation. 
    The application fee and deposit are not refundable at any time.</p>
    <ul>
        <li><strong>Tuition credit from previous years is not refundable.</strong> If a family cancels from a program, any tuition credits will be forfeited.</li>
        <li><strong>Before March 1, 2023:</strong>
            <ul>
                <li>Change programs for free</li>
                <li>Cancel without penalty (except $99 application fee)</li>
            </ul>
        </li>
        <li><strong>Between March 2 and April 1, 2023:</strong>
            <ul>
                <li>Change programs for free</li>
                <li>All tuition is refundable except deposit and application fee</li>
                <li>Option to roll over 100% of monies paid as credit for future year</li>
            </ul>
        </li>
        <li><strong>Between April 2 and May 1, 2023:</strong>
            <ul>
                <li>75% of tuition paid (minus deposit and application fee) is refundable</li>
                <li>Option to roll over 100% of monies paid towards a future year.</li>
            </ul>
        </li>
        <li><strong>Between May 2 and June 1, 2023:</strong><
            <ul>
                <li>25% of tuition (minus deposit and application fee) is refundable</li>
                <li>$300 to change programs</li>
                <li>Option to roll over 75% of monies paid towards a future year.</li>
            </ul>
        </li>
        <li><strong>After June 1, 2023:</strong>
            <ul>
                    <li>$500 to change programs</li>
                    <li>0% refundable</li>
                    <li>0% can be rolled to future years</li>
            </ul>
        </li>
    </ul>
    <ul>
        <li><strong>Online programs Refund Policy</strong>
            <ul>
                <li>More than 60 days prior program: 100% refund​</li>
                <li>More than 30 days prior program: 50% refund</li>
                <li>Less than 30 days prior to the program start date: 0% refund</li>
            </ul>
        </li>
    </ul>
    <h2>Late Payment Policy</h2>   
    <p>Payments received after May 10th will incur a $150 late fee (not applicable to applications received after May 10th). 
    If full payment is not received by June 1st it may result in a cancellation of enrollment without refund</p>
`
interface Props {
  required?: boolean
}

const PaymentSchedule = (props: Props) => {
  const [selectedText, setSelectedText] = useState('');
  const { setFieldValue } = useFormikContext();
  const queryClient = useQueryClient()
  const {client, opportunity} = useParams()
  const { getOpportunityReq } = useAppRequests()
  const [enrolledSummary, setEnrolledSummary] = useState<{
    opportunity?: number
    enrollment_objects?: IEnrollmentObject[]
    schedules?: IPaymentSchedule[]
  }>()
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await getOpportunityReq(client, Number(opportunity))
      setEnrolledSummary(res)
      setSelectedText(res?.payment_schedule?.value || '')
      setLoading(false)
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const enrolledSummary = queryClient.getQueryData<{
  //   opportunity: number
  //   enrollment_objects: IEnrollmentObject[]
  //   schedules: IPaymentSchedule[]
  // }>(_queryKeys.getRecentPaymentSummariesReq)

  const handlePaymentScheduleChange = (event: any) => {
    const selectedScheduleId = event.target.value;
    const selectedSchedule = enrolledSummary?.schedules?.find(schedule => schedule.id == selectedScheduleId);
    console.log("?", enrolledSummary)
    setSelectedText(selectedSchedule?.value || '');
    setFieldValue('payment_schedule', selectedScheduleId);  
  };

  if (loading) {
    return (
      <div style={{
        marginLeft: '15%'
      }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  const dueDatesChecker = (item: any) => {
    const dueDateArray = item.split(',')
    let countErr = 0
    for (const dueDate of dueDateArray) {
      const specifiedDate = dueDate.replaceAll('"', '')
      const formattedSpecifiedDate = moment(specifiedDate, 'MM/DD/YYYY')
      const currentDate = moment().format('MM/DD/YYYY')
      const diffDays = formattedSpecifiedDate.diff(currentDate, 'days')
      if (diffDays <= 0) {
        countErr++
        break
      }
    }
    if (countErr > 0) {
      return true
    }
    return false
  }

  return (
    <div className='row my-10'>
      <h2>Payment Schedule</h2>
      <div className='col-lg-6'>
        <div className='d-flex flex-column mb-7 fv-row'>
          <Field
            as='select'
            name='payment_schedule'
            className='form-select form-select-lg form-select-white'
            id='payment_schedule'
            onChange={handlePaymentScheduleChange}
            {...props}
          >
            <option value=''>---- Select Schedule ----</option>
            {enrolledSummary?.schedules?.map((ps_obj, idx) => {
              if (dueDatesChecker(ps_obj.due_dates)) {
                return ''
              } 
              if (ps_obj.name === '---- Select Answer ----') {
                return (
                  <option key={ps_obj.id}>---- Select Answer ----</option>
                )
              }
              return (
                <option value={ps_obj.id} key={idx}>
                  {ps_obj.name}
                </option>
              )
            })}
          </Field>
        </div>
      </div>
      <div>
        <div>{ ReactHtmlParser(selectedText) }</div>
      </div>
    </div>
  )
}

export default PaymentSchedule
