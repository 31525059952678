import { axiosPrivate } from '../../../../axiosInstance'
import { useAuth } from '../core/Auth'
import { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import { AxiosRequestConfig } from 'axios'
import { getAuth } from '../core/AuthHelpers'
const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const { auth, logout } = useAuth()
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (config.headers && !config.headers['Authorization']) {
          const authLocal = getAuth()
          //console.log('is go config', auth?.api_token, authLocal?.api_token)

          config.headers['Authorization'] = `Bearer ${authLocal?.api_token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (error?.response?.status === 401) {
          localStorage.clear();
          window.location.reload();
          logout()
          return;
          prevRequest.sent = true
          const newAccessToken = await refresh()
          console.log('newAccessToken interceptors', newAccessToken)
          // if no refresh token exist in server, logout user
          if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.reload();
            logout()
            return;
          }
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
          return axiosPrivate(prevRequest)
        }

        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh])

  return axiosPrivate
}

export default useAxiosPrivate
