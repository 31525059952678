import React, { useMemo, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ReactQuill, { Quill } from 'react-quill'
import { ImageResize } from 'quill-image-resize-module-ts';
Quill.register('modules/imageResize', ImageResize)

interface IProps {
  values: {
    filename: string
    newHtmlTemplate: string
  }
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  loading: boolean
}

const CustomHtmlEditor = ({values, setFieldValue, loading}: IProps) => {
  const quillRef = useRef<ReactQuill>(null)
  const [showModal, setShowModal] = useState(false)
  const [imageValue, setImageValue] = useState('')
  const [currentRange, setCurrentRange] = useState(0)
  const [error, setError] = useState('')

  const handleClose = () => {
    if (!quillRef.current) return

    const editor = quillRef.current.getEditor()
    const range = editor.getSelection()
    if (range) {
      setCurrentRange(range?.index)
    } 
    setShowModal(prev => !prev)
    setImageValue('')
    setError('')
  }

  const handleImage = () => {
    if (!quillRef.current) return

    const editor = quillRef.current.getEditor()
    if (imageValue) {
      editor.insertEmbed(currentRange, "image", imageValue, "user")
      handleClose()
    } else {
      setError('Image URL is required')
    }
  }

  const formats = [
    'width', 'height', 'header', 'bold', 'italic', 'indent',
    'underline', 'strike', 'blockquote','list', 'bullet', 
    'link', 'image', 'align', 'color', 'background', 'font',
  ]

  const modules = useMemo(() => (
    {
      imageResize: {
        modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
      },
      toolbar: {
        container: [
          [{'font': []}],
          [{ 'header': [1, 2, 3, 4, 5, false] }],
          [{'color': []}],
          [{'background': []}],
          [{'align': []}],
          ['bold', 'italic', 'underline','strike', 'blockquote', {'indent': '-1'}, {'indent': '+1'}],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          image: handleClose
        }
      }
    }
  ), [])

  return (
    <div className='fv-row mb-7 position-relative'>
      <label htmlFor="" className=''>
        <h4>Email Template</h4>
      </label>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        placeholder='Enter a description'
        value={values.newHtmlTemplate}
        modules={modules}
        formats={formats}
        onChange={(html) => setFieldValue('newHtmlTemplate', html)}
      />
      {loading ? (
        <div style={{
          position: 'absolute',
          top: 28,
          left: 0,
          height: 'calc(100% - 28px)',
          width: '100%',
          borderRadius: 5,
          background: '#e5e5e57a'
        }} />
      ) : ''}

      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false}>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Please enter the image URL</Modal.Title>
          </Modal.Header>
          <div className='py-10 px-10'>
            <input
              type="text" 
              className='form-control' 
              placeholder='Image URL...'
              value={imageValue} 
              onChange={(e: any) => setImageValue(e.target.value)}
            />
            <p className='text-danger text-end mt-3 me-2'>{error}</p>
          </div>
        </Modal.Body>
        
        <Modal.Footer style={{padding: '20px 30px'}}>
          <Button variant='secondary' onClick={handleClose}>
            Discard
          </Button>
          <Button variant='primary' className='ms-2' onClick={handleImage}>
            Add image
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CustomHtmlEditor