import React from 'react';
import '../../../../../app/modules/application/components/ConfirmPay/style.css'

interface Props {
  title: string;
  subheader?: string;
  supportingHeader?: string;
}

const CongratulationBanner = ({title, subheader, supportingHeader}: Props) => {
  return (
    <>
      <div className='d-flex justify-content-center rounded w-75 mx-auto col-md-12 congratulation-banner'>
        <div className='w-xl-700px  text-center py-4 text-light border-radius-20'>
          <h1 className='fw-bolder fs-2x text-light'>{title}</h1>
          <div className='fw-bold text-uppercase mb-2'>{subheader}</div>
          <span className='text-uppercase'>{supportingHeader}</span>
        </div>
      </div>
    </>
  );
};

export default CongratulationBanner;
