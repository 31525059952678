import React from 'react'
import { ROLES } from '../../../routing/core/_models'
import { useAuth } from '../core/Auth'

const useRole = () => {
  const {currentUser} = useAuth()
  return {
    isAdminOrSuperAdmin:
      currentUser?.role === ROLES.superAdmin || currentUser?.role === ROLES.admin,
    isStudentOrParent:
      currentUser?.role === ROLES.student || currentUser?.role === ROLES.parent,
  }
}

export default useRole