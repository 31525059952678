import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';

interface ScrollArrowReturn {
  isScrollable: boolean;
  scrollDown: () => void;
  y: number;
}

const useScrollArrow = (): ScrollArrowReturn => {
  const { height: windowHeight } = useWindowSize();
  const [y, setY] = useState(0);

  const handleScroll = () => {
    setY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Check if the page is scrollable
  const isScrollable = windowHeight < document.documentElement.scrollHeight;

  const scrollDown = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return { isScrollable, scrollDown, y };
};

export default useScrollArrow;
