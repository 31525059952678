import React, {Dispatch, SetStateAction, useState} from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { FormikHelpers } from 'formik'

interface IProps {
  setOpenModal: Dispatch<SetStateAction<boolean>>
  setFieldValue: FormikHelpers<any>['setFieldValue']
  values: any
}

const CustomModalOption = ({setOpenModal, values, setFieldValue}: IProps) => {
  const [optionVal, setOptionVal] = useState('')
  const [errMessage, setErrMessage] = useState('') 

  const  onAddOption = (values: any, setFieldValue: FormikHelpers<any>['setFieldValue']) => {
    if (optionVal === '') {
      setErrMessage('This field is required!')
      return
    }
    const choices = values.choices ? JSON.parse(values.choices) : []
    choices.push(optionVal)
    const newChoices = JSON.stringify(choices)
    setFieldValue('choices', newChoices)
    setOpenModal(false)
  }
  
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Add Option</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => {
                  setOpenModal(false)
                }}
                style={{cursor: 'pointer'}}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-1'
                />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-7'>
              <div className='fs-6'>
                <div className='h-200px'>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>
                        Choice
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <input 
                        type="text" 
                        className='form-control form-control-md' 
                        onChange={(e) => setOptionVal(e.target.value)} 
                        value={optionVal}
                      />
                      {errMessage && (
                        <div className='fv-plugins-message-container text-danger'>{errMessage}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Modal body */}
            <div className='modal-footer'>
              <button
                type='reset'
                onClick={() => {
                  setOpenModal(false)
                }}
                className='btn btn-light'
                data-bs-dismiss='modal'
              >
                Discard
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  onAddOption(values, setFieldValue)
                }}
              >
                <span className='indicator-label'>Submit</span>
              </button>
            </div>
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default CustomModalOption