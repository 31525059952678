import { useEffect, useMemo, useState } from "react"
import { useAuth } from "../../auth"
import { useNavigate, useParams } from "react-router-dom"
import Select from 'react-select';
import { getAllTypeForm, addOrEditProgram, getAllProgram, getProgramById } from '../../../../network/api'
import { axiosInstance } from '../../../../axiosInstance';
import CustomCheckbox from "../../../../_metronic/shared-components/CustomCheckbox";

interface iPrograms {
    id: any;
    name: string;
    campus: string;
}

interface iData{
    program_id: number;
    form_id: number | null;
    isActive: boolean,
}

interface iProgram_Form{
    program_id: number;
    form_id: number;
}

const ProgramEdit = () => {
    const navigate = useNavigate()
    const { client, program_id } = useParams()
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<iData>({
        program_id: 0,
        form_id: null,
        isActive: true,
    });
    const [programs, setPrograms] = useState<iPrograms[]>([]); 
    const [typeForms, setTypeForms] = useState<any[]>([]);
    const [formProgramBridge, setFormProgramBridge] = useState<iProgram_Form[]>([]);

    useEffect(() => {
        setLoading(true)
        const fetchProgramData = async () => {
            try {
                const response = await getProgramById(program_id)
                setLoading(false)
                if (response?.data) {
                    setData({
                        ...response.data,
                        form_id: Array.isArray(response.data.form_id)
                            ? [response.data.form_id]
                            : response.data.form_id,
                    });
                }
            } catch (error) {
                console.error('Error fetching program data:', error)
                setLoading(false)
                setErrorMessage('Failed to fetch program data')
            }
        }
        fetchProgramData()
    }, [program_id])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch programs
                const programResponse = await axiosInstance.get('/terra-programs/api/programs')
                const programsArray = programResponse.data.data || [];
        
                if (Array.isArray(programsArray)) {
                    const allPrograms = programsArray.map((program) => ({
                        id: program.id,
                        name: program.name.trim(),
                        campus: program.programData.campus
                    }));
                
                    const sortedPrograms = allPrograms.sort((a, b) => {
                        const campusComparison = a.campus.localeCompare(b.campus);
                        if (campusComparison !== 0) {
                            return campusComparison;
                        }
                        return a.name.localeCompare(b.name);
                    });
                
                    setPrograms(sortedPrograms);
                }

                // Fetch type forms
                const { data } = await getAllTypeForm()
                const sortedTypeForms = data.sort((a: any, b: any) => a.id - b.id)
                setTypeForms(sortedTypeForms)

                // Fetch form-program bridge from `typeform_bridge`
                const bridgeResponse = await getAllProgram()
                setFormProgramBridge(bridgeResponse.data)

            } catch (error) {
                console.error('Error fetching data:', error)
                setErrorMessage('Failed to fetch data. Please try again later.')
            } finally {
                setLoading(false)
            }
        };

        fetchData();
    }, []);
    
    const getProgramNameById = (id: any) => {
        const program = programs.find((program) => program.id === id);
        return program ? `${program.campus} - ${program.name}` : 'Select a Program';
    };

    const getFormNameById = (id: any) => {
        const form = typeForms.find((form) => form.id ===  id);
        return form ? form.name : 'Select a Form';
    };

    const filteredPrograms = useMemo(() => {
        return programs.filter(program => {
            const programForms = formProgramBridge
                .filter(bridge => Number(bridge.program_id) === Number(program.id))
                .map(bridge => bridge.form_id);
            const hasAllForms = typeForms.every(form => programForms.includes(form.id));
            return !hasAllForms;
        });
    }, [programs, formProgramBridge, typeForms]);

    const filteredForms = useMemo(() => {
        const linkedFormIds = formProgramBridge
            .filter(bridge => bridge.program_id === data.program_id)
            .map(bridge => bridge.form_id);
    
        return typeForms.filter(form => {
            return form.id === data.form_id || !linkedFormIds.includes(form.id);
        });
    }, [data.program_id, typeForms, formProgramBridge, data.form_id]);    

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoading(true)
        try {
            
            await addOrEditProgram( data, program_id ) 
            setSuccessMessage('Form & Program Updated successfully')
            
            setTimeout(() => {
                navigate(`/apps/${client}/program-management/program`)
            }, 3000)
            
        } catch (error: any) {
            if (error.response.status === 500) {
                return setErrorMessage('Something went wrong, please try again.')
            } else if (error.response.status === 400) {
                return setErrorMessage('Something went wrong while processing the request!')
            } else if (error.response.status === 403) {
                return setErrorMessage('Need client id provided.')
            } else if (error.response.status === 400) {
                return setErrorMessage('Something went wrong while processing the request!')
            } else setErrorMessage('Something went wrong, please try again.')
            setTimeout(() => {
                setErrorMessage('')
        }, 3000)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <span className='indicator-progress' style={{display: 'flex', justifyContent: 'center'}}>
                <span
                    style={{height: '40px', width: '40px'}}
                    className='spinner-border spinner-border-sm align-middle mt-20'
                ></span>
            </span>
        )
    }

    const handleProgramChange = (selectedOption: any) => {
        setData(prevData => ({ ...prevData, program_id: selectedOption ? Number(selectedOption.value) : 0 }));
    };

    const handleFormChange = (selectedOption: any) => {
        setData(prevData => ({...prevData, form_id: selectedOption ? Number(selectedOption.value) : null}));
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isActive = e.target.checked;
        setData(prevData => ({ ...prevData, isActive }));
    };

    return (
        <>
            {successMessage && (
                <div className='card-body'>
                <div className='alert alert-success d-flex align-items-center p-5'>
                    <div className='d-flex flex-column '>
                    <span>{successMessage}</span>
                    </div>
                </div>
                </div>
            )}

            {errorMessage && (
                <div className='card-body'>
                <div className='alert alert-danger d-flex align-items-center p-5'>
                    <div className='d-flex flex-column '>
                    <span>{errorMessage}</span>
                    </div>
                </div>
                </div>
            )}

            <div className='card-body'>
                <form onSubmit={onSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='program' className='form-label'>
                            Program
                        </label>
                        <Select
                            id='program'
                            value={programs.find(program => program.id === data.program_id) ? {
                                value: data.program_id,
                                label: getProgramNameById(data.program_id)
                            } : null}
                            onChange={handleProgramChange}
                            options={filteredPrograms.map(program => ({
                                value: program.id,
                                label: `${program.campus} - ${program.name}`
                            }))}
                            className='basic-single'
                            classNamePrefix='select'
                        />
                    </div>

                    <div className='mb-3'>
                        <label htmlFor='formId' className='form-label'>
                            Form/s
                        </label>
                        <Select
                            id='forms'
                            value={typeForms.find(form => form.id === data.form_id) ? {
                                value: data.form_id,
                                label: getFormNameById(data.form_id)
                            } : null}
                            onChange={handleFormChange}
                            options={filteredForms.map(form => ({
                                value: form.id,
                                label: form.name
                            }))}
                            className='basic-multi-select'
                            classNamePrefix='select'
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='form-label' style={{marginRight: 5}}>
                            Status
                        </label>
                        <input
                            id="isActive"
                            type="checkbox"
                            checked={data.isActive}
                            onChange={handleCheckboxChange}
                        />
                    </div>

                    <button type='submit' className='btn btn-primary'>
                        Submit
                    </button>
                </form>
            </div>
        
        </>
    )
}

export default ProgramEdit