import {useCallback, useEffect, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'
import {generate} from '@wcj/generate-password'
import {Field, ErrorMessage} from 'formik'
import {Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {IProgram} from '../../core/_models'
import {getSavedProgress, saveProgress} from '../../../../../network/api'
import useCurrentClientRoute from '../../../auth/hooks/useCurrentClientRoute'

const enrollAccountFAQ = [
  {
    heading: 'Why do I need an account?',
    bodies: [
      'See your application status',
      'Make payments online',
      'Send donation links to your friends & family',
    ],
  },
  {
    heading: 'Notes about creating your account',
    bodies: [
      '<b>Your email & password must be something you feel comfortable sharing with your parents or guardians.</b>',
      'We will be sharing all communications with your parents or guardians.',
      "Use the student's primary email address as the username",
      'Passwords are case sensitive and must be at least 8 characters long and contain at least one number.',
    ],
  },
]

interface Props {
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const genPassword = generate({
  length: 10,
})

const Step3 = ({currentFormikVal, setFieldValue}: Props) => {
  // console.log(currentFormikVal, '@@@@@@@@')
  const {currentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const {currentClientRoute} = useCurrentClientRoute()
  const [uuid, setUuid] = useState()

  useEffect(() => {
    if (currentUser) {
      setFieldValue('contactInfo.studentInfo.email', currentUser.email)
      console.log('go here', currentUser.email)

      setFieldValue('studentAccount.email', currentUser.email)
      //remove signup fields
      //setFieldValue('studentAccount.password', '')
      //setFieldValue('studentAccount.confirmPassword', '')
    } else {
      setFieldValue('contactInfo.studentInfo.email', currentFormikVal.studentAccount?.email)
    }
    return () => {}
  }, [])

  const saveUsersProgress = async (values: any) => {
    const {studentAccount} = values
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const isValidEmail = pattern.test(studentAccount.email)

    if (isValidEmail && document.visibilityState === 'visible') {
      try {
        const {responseData} = await saveProgress({
          email: studentAccount.email,
          data: values,
        })
        // console.log(responseData)
        setUuid(responseData.uuid)
      } catch (error) {
        console.log('not an email format')
      }
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        saveUsersProgress(currentFormikVal)
      }
    }

    // Save progress when visibility changes
    document.addEventListener('visibilitychange', handleVisibilityChange)

    const saveProgressInterval = setInterval(() => {
      if (document.visibilityState === 'visible') {
        saveUsersProgress(currentFormikVal)
      }
    }, 30000)

    return () => {
      clearInterval(saveProgressInterval)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [currentFormikVal])

  if (currentUser) {
    return (
      <div className='text-center w-100 fs-3'>
        <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
          Step 3: Create Your Account
        </h2>
        <div className='alert alert-primary d-flex align-items-center px-5 py-10 mb-10'>
          <div className='d-flex flex-column w-100'>
            <h5 className='mb-1'>You're currently logged in as with username:</h5>
            <span className='fw-bold'>{currentUser.email}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='fv-row w-100'>
      <div className='row'>
        <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
          Step 3: Create Your Account
        </h2>
        <div className='col-lg-6'>
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Student's Email Address
            </label>
            <Field
              type='email'
              className='form-control form-control-lg form-control-solid'
              name='studentAccount.email'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='studentAccount.email' />
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>
            <div className='position-relative'>
              <Field
                type={showPassword ? 'text' : 'password'}
                className='form-control form-control-lg form-control-solid'
                name='studentAccount.password'
              />
              <span
                className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className='bi bi-eye fs-2'></i>
                ) : (
                  <i className='bi bi-eye-slash fs-2'></i>
                )}
              </span>
            </div>
            <div className='text-danger mt-2'>
              <ErrorMessage name='studentAccount.password' />
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>
            <div className='position-relative'>
              <Field
                type={showPassword ? 'text' : 'password'}
                className='form-control form-control-lg form-control-solid'
                name='studentAccount.confirmPassword'
              />
              <span
                className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className='bi bi-eye fs-2'></i>
                ) : (
                  <i className='bi bi-eye-slash fs-2'></i>
                )}
              </span>
            </div>
            <div className='text-danger mt-2'>
              <ErrorMessage name='studentAccount.confirmPassword' />
            </div>
          </div>
          <div className='text-gray-400 fw-bold fs-5'>
            Already have an account?
            <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
              Log in here
            </Link>
          </div>
        </div>
        <div className='col-lg-6 text-dark'>
          {enrollAccountFAQ.map((q, i) => (
            <div className='ms-10' key={i}>
              <h4>{q.heading}</h4>
              <ul>
                {q.bodies.map((b, j) => (
                  <li className='mb-2' key={j}>
                    {ReactHtmlParser(b)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Step3
