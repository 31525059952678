import React, { useEffect, useState } from 'react'
import { FaCheckCircle } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../axiosInstance';

const ResendEmailRedirect = () => {
  const {hash, token} = useParams()
  const [message, setMessage] = useState()

  useEffect(() => {
    const EmailRedirectHandler = async () => {
      try {
        const response = await axiosInstance.get(`/auth/email-change-verify/${hash}/${token}`)
        console.log(response, '@@response')
      } catch (error: any) {
        setMessage(error.response.data.message || error.message)
        console.log(error?.message, 'error.message')
      } 
    }

    EmailRedirectHandler()
  }, [])

  return (
    <h1 className='d-flex align-items-center text-center gap-2 justify-content-center m-5'>
      <span>
        Hi, your email has been updated Successfully! You may now close this window! 
      </span>
      <FaCheckCircle className='text-success' />
   </h1>
  )
}

export default ResendEmailRedirect