export interface IRequireAuth {
  allowedRoles: IAllowedRoles
}

export type IAllowedRoles = string[] | string

export const ROLES = {
  student: 'student',
  parent: 'parent',
  superAdmin: 'superadmin',
  admin: 'admin',
  advisor: 'advisor',
}

// export const CLIENT_NAMES = {
//   gla: 'experienceGla',
//   ssb: 'summerSpringBoard',
// }
