import {ErrorMessage} from 'formik'
import ReactHtmlParser from 'react-html-parser'
import {IEnrollPartTwo, IEnrollPartTwoForm} from '../../../core/_models'
import { useQueryClient } from 'react-query'
import FilterField from '../../Shared/FilterField'
import _queryKeys from '../../../core/_queryKeys'
import HiddenField from '../../Shared/HiddenField'

interface Props {
  currentFormikVal: IEnrollPartTwo
}

const StudentDetail = ({currentFormikVal}: Props) => {
  const queryClient = useQueryClient()
  const enrollPartTwoFieldData =
    queryClient.getQueryData<{data: IEnrollPartTwoForm[]}>(_queryKeys.getEnrollPartTwoForms)

  const medical_form = enrollPartTwoFieldData?.data.filter((f) => f.group === 'medical_form')

  return (
    <div className='w-100 row mt-10'>
      <div className='my-5'>
        <h5>Student Details</h5>
      </div>
      {medical_form?.map((form, idx) => (
        <div key={idx}>
          {form.type === 'question' && (
            <div className='col-lg-12 mb-5' key={idx}>
              <label className={'form-label fw-bolder text-dark fs-6 required'}>
                {ReactHtmlParser(form.label)}
              </label>
              <div className='fw-bold text-dark mb-2'>
                <span>{form.helper_text}</span>
              </div>
              <FilterField form={form} prefixFieldName='medical_form.' />
              <div className='text-danger mt-2'>
                <ErrorMessage name={'medical_form.' + form.name} />
              </div>
            </div>
          )}
          <HiddenField
            form={form}
            currentFormikVal={currentFormikVal}
            formsList={medical_form}
            objectName='customField'
            prefixFieldName='medical_form.'
          />
        </div>
      ))}
    </div>
  )
}

export default StudentDetail
