import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useAuth} from '../../auth'
import AddPaymentSchedulePage from './AddPaymentSchedulePage'
import PaymentSchedulesList from './components/PaymentSchedulesList'
import PaymentSchedulesProvider from './components/PaymentSchedulesProvider'
import UpdatePaymentSchedulePage from './UpdatePaymentSchedulePage'

const PaymentSchedulesPage = () => {
  const {currentUser} = useAuth()
  const productsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Payment Schedules Management',
      path: `/apps/${currentUser?.currentClient}/payment-schedules-management/payment-schedules`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <PaymentSchedulesProvider>
            <Outlet />
          </PaymentSchedulesProvider>
        }
      >
        <Route
          path='payment-schedules'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>Payment Schedules</PageTitle>
              <PaymentSchedulesList />
            </>
          }
        />
        <Route
          path='edit/:paymentScheduleId'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>Edit Payment Schedule</PageTitle>
              <UpdatePaymentSchedulePage />
            </>
          }
        />
        <Route
          path='new'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>New Payment Schedule</PageTitle>
              <AddPaymentSchedulePage />
            </>
          }
        />
        <Route
          index
          element={
            <Navigate
              to={`/apps/${currentUser?.currentClient}/payment-schedules-management/payment-schedules`}
            />
          }
        />
      </Route>
    </Routes>
  )
}

export default PaymentSchedulesPage
