/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import { useAuth } from '../../../modules/auth'
import StudentListComponent from '../../../modules/application/components/StudentList'

const StudentListPage: FC = () => {
   const {currentUser} = useAuth()

  return (
      <div className='row g-5 g-xl-8'>
        <div className='col-lg-12'>
          <StudentListComponent />

        </div>
      </div>
  )}

const StudentList: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.STUDENTLIST'})}</PageTitle>
      <StudentListPage />
    </>
  )
}

export {StudentList}
