import {ID, Response} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { Dispatch, SetStateAction } from 'react'
import { IPortalClient } from '../../../../auth'


export const clientSchema = Yup.object().shape({
  name: Yup.string().label('Name').required(),
  abbreviation: Yup.string().label('Abbreviation').required(),
  status_id: Yup.number().required('Required'),
  //reference_id: Yup.string().label('Reference Id').required(),
})

export const initClient: IPortalClient = {
  value: '',
  name: '',
  abbreviation: '',
  reference_id: '',
}

export type ClientsQueryResponse = Response<Array<IPortalClient>>

export type ClientsListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  itemIdForDelete?: {id: ID}
  setItemIdForDelete: Dispatch<SetStateAction<{id: ID} | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ClientsListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForDelete: () => {},
  isAllSelected: false,
  disabled: false,
}