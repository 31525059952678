import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { axiosPrivate } from '../../axiosInstance';
import { currencyFormat } from '../../utils/funcHelpers';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

interface Props {
  program_uuid: string
  refetch: () => {}
}

interface IScholarship {
  name: string
  type: string
  scholarship_code: string
  amount: number
}

interface IInitialValue {
  program_uuid: string
  description: string
  price: number
  transaction_type: string
}

const AddDiscountModal = ({ program_uuid, refetch }: Props) => {
  const [scholarship, setScholarship] = useState<IScholarship[]>([]);
  const [selectedScholarship, setSelectedScholarship] = useState<IScholarship[]>([]);
  const [open, setOpen] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [initialValues, setInitialValue] = useState<IInitialValue>({
    program_uuid: program_uuid,
    description: "",
    price: 0,
    transaction_type: ""
  })
  const scholarshipSchema = Yup.object().shape({
    program_uuid: Yup.string().required(),
    price: Yup.number().required('Scholaship Amount Is Required'),
  });
  useEffect(() => {
    const fetchScholarship = async () => {
      try {
        const response = await axiosPrivate.get('/discount/get');
        setScholarship(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchScholarship();
  }, []);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCode = e.target.value;
    const selectedItem = scholarship.find(
      (item: IScholarship) => item.scholarship_code === selectedCode
    );
    const {price, description, transaction_type, ...data} = initialValues;
    const scholarshipDescription = `${selectedItem?.name}: ${selectedCode}`
    setInitialValue({
      ...data,
      description: scholarshipDescription,
      price: selectedItem?.amount || 0,
      transaction_type: selectedItem?.type || 'Scholarship'
    })
  };

  const handleConfirm = async (values: IInitialValue) => {
    try {
      const response = await axiosPrivate.post('/add-addons', values);
      if (response.status === 200) {
        setSelectedScholarship([])
      }
    } catch (error) {
      console.error('Error adding scholarship:', error);
    } finally {
      refetch();
      setOpen(false);
    }
  };
  return (
    <>
      <Button className='btn btn-hover mx-5' variant='primary' onClick={() => setOpen(true)}>Add Scholarship</Button>
      <Modal backdrop='static' show={open} onHide={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Add Scholarship</Modal.Title>
        </Modal.Header>
          <Formik
            initialValues={initialValues}
            onSubmit={handleConfirm}
            validationSchema={scholarshipSchema}
            enableReinitialize
          >
          {({values, setFieldValue, errors, handleSubmit}) =>{
            return (
              <Form>
                <Modal.Body>
                <select
                  className='form form-select form-select-lg'
                  onChange={handleSelectChange}
                  defaultValue=''
                >
                  <option value='' disabled>
                    Select Scholarship
                  </option>
                  {scholarship?.map((item: IScholarship) => (
                    <option
                      key={item.scholarship_code}
                      value={item.scholarship_code}
                    >
                      {`${item.name} (-${currencyFormat.format(item.amount)})`}
                    </option>
                  ))}
                </select>
                <div className='d-flex flex-column m-auto'>
                  <p className='px-1 mb-0 mt-3'>Scholarship Amount</p>
                  <Field
                    type='number'
                    name='price'
                    className='form-control form-control-solid mt-0'
                    placeholder='Amount'
                  />
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button variant='primary' type='submit' disabled={!selectedScholarship}>
                    Confirm
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }}
        
        </Formik>
      </Modal>
    </>
  );
};

export default AddDiscountModal;
