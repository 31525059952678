import React, { useEffect, useState, useRef } from 'react';
import './StudentType.scss';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Perks {
  includes: string;
  excludes: string;
}

interface StudentTypeProps {
  formData: any;
  setFormData: (data: any) => void;
}

const StudentType: React.FC<StudentTypeProps> = ({ formData, setFormData }) => {
  const [selectedStudentType, setSelectedStudentType] = useState<string | null>(formData.student_Type || null);
  
  // Ref to track the first render
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!selectedStudentType) return; // Do nothing if no student type selected

    if (!isFirstRender.current) {
      // Only run this block after the first render
      if (formData.student_Type && formData.student_Type !== selectedStudentType) {
        sessionStorage.removeItem('selectedExtras'); // Clear extras if user changes the type
        setFormData((prevData: any) => ({
          ...prevData,
          student_Type: selectedStudentType,
          enrollment_extras: null, // Clear extras only if student type changes
        }));
      }
    }

    // Set formData on student_Type change
    setFormData((prevData: any) => ({
      ...prevData,
      student_Type: selectedStudentType,
    }));

    // Mark that first render is complete
    isFirstRender.current = false;
  }, [selectedStudentType, setFormData, formData.student_Type]);

  const notify = async (values: string) => {
    toast.warn(values);
  };

  const studentTypeHandler = (type: string) => {
    const newType = selectedStudentType === type ? null : type;
    setSelectedStudentType(newType);
    toast.dismiss();
    if(newType != null) {
      const rawData = sessionStorage.getItem('waitlistData');
      const rawLocal = localStorage.getItem('multiStepFormData');
      if(rawData && rawLocal){
        const { sessions } = JSON.parse(rawData);
        const {selectedSession} = JSON.parse(rawLocal);
        const sessionWaitlist = sessions.filter((item: any) => item.id === selectedSession.id);
        const isCommuterFull = sessionWaitlist.some((item: any) => item.isCommuterFull === true);
        const isResidentialFull = sessionWaitlist.some((item: any) => item.isResidentialFull === true);
        const isSessionFull = sessionWaitlist.some((item: any) => item.isSessionFull === true);
        if(newType === 'commuter' && isCommuterFull && !isSessionFull) {
          notify(`
            No more commuter spots are currently available.
            However you may try another session, select residential,
            or proceed to join the commuter waitlist
          `)
          localStorage.setItem('isWaitlist', 'true');
        } else if(newType === 'residential' && isResidentialFull && !isSessionFull) {
          notify(`
            No more residential beds are currently available.
            However you may try another session, select commuter,
            or proceed to join the waitlist
          `)
          sessionStorage.setItem('isWaitlist', 'true');
        } else if (isSessionFull) {
          notify(`
            No more residential beds & commuter spots are currently available.
            However you may try another session,
            or proceed to join the waitlist
          `)
          sessionStorage.setItem('isWaitlist', 'true');
        } else {
          sessionStorage.removeItem('isWaitlist');
        }

        
      }
    }
  };
  const residentialPricing = formData.selectedProgram.pricing.find(
    (pricing: any) => pricing.tuitionName.toLowerCase() === 'residential'
  );
  const commuterPricing = formData.selectedProgram.pricing.find(
    (pricing: any) => pricing.tuitionName.toLowerCase() === 'commuter'
  );
  const onlinePricing = formData.selectedProgram.pricing.find(
    (pricing: any) => pricing.tuitionName.toLowerCase() === 'online'
  );

  const campus = formData?.decodedProgram
  const session = `${formData?.selectedSession?.session_name} (${moment.utc(formData?.selectedSession?.start_date).format('MM/DD/YYYY')} - ${moment.utc(formData?.selectedSession?.end_date).format('MM/DD/YYYY')}) ${formData?.selectedProgram?.name}` 
  return (
    <div>
      <h2 className='mx-3' style={{ marginTop: 45 }}>{campus}: {session}</h2>
      <div className='student-type-container'>
      <ToastContainer style={{ width: "90vw" }}
              position="top-center"
              autoClose={false}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
        {residentialPricing && (
          <div 
            className={`student-card card full-rounded ${selectedStudentType === 'residential' ? 'active' : ''}`} 
            onClick={() => studentTypeHandler('residential')}
          >
            <h2 className='text-capitalize'>Residential</h2>
            <div className=''>
              <div className='perks-text' style={{ fontSize: '15px'}}>{residentialPricing.description}</div>
            </div>
            <div className='d-flex perks-label'>
              Price: <div className='pricing' style={{ marginBottom: '10px'}}>${residentialPricing?.price || 'N/A'}</div>
            </div>
          </div>
        )}

        {commuterPricing && (
        <div 
          className={`student-card card full-rounded ${selectedStudentType === 'commuter' ? 'active' : ''}`} 
          onClick={() => studentTypeHandler('commuter')}
        >
          <h2 className='text-capitalize'>Commuter</h2>
          <div className=''>
            <div className='perks-text' style={{ fontSize: '15px'}}>{commuterPricing?.description}</div>
          </div>
          <div className='d-flex perks-label'>
            Price: <div className='pricing' style={{ marginBottom: '10px'}}>${commuterPricing?.price || 'N/A'}</div>
          </div>
        </div>
        )}

        {onlinePricing && (
        <div 
          className={`student-card card full-rounded ${selectedStudentType === 'online' ? 'active' : ''}`} 
          onClick={() => studentTypeHandler('online')}
        >
          <h2 className='text-capitalize'>Online</h2>
          <div className=''>
            <div className='perks-label'>Description: </div>
            <div className='perks-text'>{onlinePricing?.description}</div>
          </div>
          <div className='d-flex perks-label'>
            Price: <div className='pricing'>${onlinePricing?.price || 'N/A'}</div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default StudentType;
