import { useEffect, useMemo, useState } from 'react'
import Header from '../../../../_metronic/shared-components/Header'
import { Link, useParams } from 'react-router-dom'
import { axiosPrivate } from '../../../../axiosInstance'
import { currencyFormat } from '../../../../utils/funcHelpers'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'

export interface IDiscounts {
  id?: number
  name?: string
  type?: string
  due?: number | null
  amount?: number | null
  qb_name?: string | null
  division?: string | null
  is_active?: boolean
  scholarship_code: string | null
  sf_id?: string | null
  pb_id?: string | null
}

const Discount = () => {
  const {client} = useParams()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [discounts, setDiscounts] = useState<IDiscounts[]>([])
  const [visibleData, setVisibleData] = useState<IDiscounts[]>([])

  const handleDelete = async (id: number) => {
    try {
      await axiosPrivate.delete(`/delete_discount/${id}`)
      setDiscounts(prevDiscounts => prevDiscounts.filter(discount => discount.id !== id))
    } catch (error) {
      console.error("Failed to delete discount", error)
    }
  }
  
  useEffect(() => {
    setLoading(true)
    const getAllDiscount = async () => {
      const response = await axiosPrivate.get('/get_discounts')
      const sortedDiscount = response.data.sort((a: any, b: any) => b.id - a.id)
      setDiscounts(sortedDiscount)
      setLoading(false)
    }
    getAllDiscount()
  }, [])
  
  useEffect(() => {
    const filterDiscounts = search.length !== 0 
    ? discounts.filter(item => item.name?.toLowerCase().includes(search.toLowerCase())) 
    : discounts

    setVisibleData(filterDiscounts)
  }, [discounts, search])
  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props: any) => {
        const id = props.cell.row.original.id
        return (
          <Link to={`/apps/${client}/discount-management/edit/${id}`}>{props.cell.row.original.name}</Link>
        )
      }
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Due',
      accessor: 'due',
      Cell: ({value}: any) => currencyFormat.format(value)
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({value}: any) => currencyFormat.format(value)
    },
    {
      Header: 'QB Name',
      accessor: 'qb_name',
    },
    {
      Header: 'COA',
      accessor: 'coa',
    },
    {
      Header: 'Division',
      accessor: 'division',
    },
    {
      Header: 'Code',
      accessor: 'scholarship_code',
    },
    {
      Header: 'Active',
      accessor: 'is_active',
      Cell: ({value}: any) => (
        <div
          style={{
            backgroundColor: value === true ? '#e8fff3' : '#ffe8e8',
            color: value === true ? '#50cd89' : '#cd5050',
            padding: '0.5rem',
            borderRadius: '0.25rem',
            minWidth: value === true ? 60 : 70,
            maxWidth: value === true ? 60 : 70,
          }}
        >
          {value === true ? 'Active' : 'Inactive'}
        </div>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: (props: any) => {
        const id = props.cell.row.original.id;
    
        return (
          <div style={{ position: 'relative' }}>
            {/* Actions button */}
            <a
              href='#'
              className='btn btn-light btn-active-light-primary btn-sm'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
    
            {/* Dropdown menu */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
              style={{ position: 'absolute', top: '100%', right: 0 }}
            >
              <div className='menu-item px-3'>
                <a
                  href='#'
                  className='menu-link px-3'
                  onClick={() => handleDelete(id)}
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        );
      },
    }
  ],[])

  return (
    <>
      <div className='card card-body p-0'>
        <Header
          buttonLink={`/apps/${client}/discount-management/add`}
          buttonText='Add Discount'
          placeholder='Search Discount'
          setSearch={setSearch}
          search={search}
        />
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading}/>
      </div>
    </>
  )
}

export default Discount