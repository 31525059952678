import React from 'react'
import {useAuth} from '../../auth'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import ProgramList from './ProgramList'
import ProgramAdd from './ProgramAdd'
import ProgramEdit from './ProgramEdit'

const Program = () => {
    const {currentUser} = useAuth()
  
    const enrollmentFormBreadCrumbs: Array<PageLink> = [
      {
        title: 'Program Forms',
        path: `/apps/${currentUser?.currentClient}/program-management/program`,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]
    
    return (
        <Routes>
            <Route
                element={
                <UsersProvider>
                    <Outlet />
                </UsersProvider>
                }
            >
                <Route
                    path='program'
                    element={
                    <>
                        <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>Program Forms</PageTitle>
                        <ProgramList />
                    </>
                    }
                />
                <Route
                    path='add-program'
                    element={
                    <>
                        <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>Add Program</PageTitle>
                        <ProgramAdd />
                    </>
                    }
                />
                <Route
                    path='edit-program/:program_id'
                    element={
                    <>
                        <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>Edit Program</PageTitle>
                        <ProgramEdit />
                    </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default Program