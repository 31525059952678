import {ID, Response} from '../../../../../../_metronic/helpers'
import {IEnrollmentObject} from '../../../../application/core/_models'
import {ITab} from '../../../../Shared/core/_models'
import * as Yup from 'yup'

export interface IProduct extends IEnrollmentObject {}
export interface IProductDetailData {
  programDetailTab: ITab
  tuitionTabs: ITab
  programTabs: ITab[]
  formGroups: Questionnaire[]
  enrollmentExtras: iEnrollmentExtras[]
  selectedGroups: iSelectedGroups[]
  selectedExtraItems: iSelectedExtraItems[]
  sessions: []
  selectedSessions: iSelectedSessions[]
}

export interface iSelectedSessions {
  id: number
  is_active: boolean
  max_slots: number
  program_product_id: number
  program_session_id: number
}

export interface iSelectedExtraItems {
  enrollment_extra_id: number
  id: number
  is_required: boolean
  program_product_id: number
}
export interface iSelectedGroups {
  form_group_id: number
  id: number
  program_product_id: number
}

export interface iEnrollmentExtras {
  [key: string]: any
  amount: number
  category: number
  created_at: string
  default_client: null
  id: number
  name: string
  pricebook_entry: string
  sf_id: string
  status: {
    created_at: string
    id: number
    name: string
    type: string
    updated_at: string
  }
  status_id: number
  transaction_category: {
    created_at: string
    general_ledger_code: string
    id: number
    name: string
    updated_at: string
  }
  type: string
  updated_at: string
}

export interface Questionnaire {
  [key: string]: any
  client_id: string
  created_at: string
  description: string | null
  header: null
  id: number
  instruction: null
  name: string
  note: null
  route_name: null
  status: string
  title: null
  type: null
  updated_at: string
}

export interface IPriceTuition {
  id: number
  tuition: number
  active: boolean
  name: string
}

export const productSchema = Yup.object().shape({
  product_detail: Yup.object({
    name: Yup.string().label('Name').required(),
    client_id: Yup.string().label('Client Id').required(),
    division: Yup.string().label('Division'),
    country: Yup.string().label('Country').required(),
    // program_summary: Yup.string().label('Summary').required(),
    due_upon_application: Yup.number().positive().label('Due up application').required().min(0),
    start_date: Yup.date().label('Start date').required(),
    end_date: Yup.date()
      .label('End date')
      .min(Yup.ref('start_date'), "End date can't be before start date"),
    tuition: Yup.number().positive().label('Tuition').min(0),
  }),
  enrollment_form_groups: Yup.array()
    .of(Yup.number())
    .min(1, 'At least one enrollment form group must be selected'),
  enrollment_extras: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required('Extra ID is required'),
        is_required: Yup.boolean(),
      })
    )
    .min(1, 'At least one enrollment extra must be provided'),
})

export type ProductsQueryResponse = Response<Array<IProduct>>

export type ProductsListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  itemIdForDelete?: {id: ID; email: string}
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ProductsListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  isAllSelected: false,
  disabled: false,
}
