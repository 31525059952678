import {useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import { fetchAllAutoPaymentMethods, getExportDisableAutopay, runAutoPayment } from '../../../../network/api'
import Header from '../../../../_metronic/shared-components/Header'
import TransactionHistoryModal from './TransactionHistoryModal'
import { PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, setAuthPromise, useAuth } from '../../auth'
import { axiosPrivate } from '../../../../axiosInstance'
import { PiExportBold } from 'react-icons/pi'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'


const notify = async (values: any, status: string, option?: any) => {
  toast.dismiss()
  const setOption = option ? option : '';
  switch (status) {
    case 'warn':
      toast.warn(values, setOption)
      break;
    case 'success':
      toast.success(values, setOption)
      break;
    case 'error':
      toast.error(values, setOption)
      break;
    case 'dismiss':
      toast.dismiss()
      break;
    default:
      toast.info(values, setOption)
      break;
  }
}

const PendingActionList = () => {
  // STATES
  const [pendingActionList, setPendingActionlist] = useState<[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [visibleData, setVisibleData] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [selectedActionData, setSelectedActionData] = useState<any>();
  const {currentUser} = useAuth()
  const [progressLoading, setProgressLoading] = useState<boolean>(false)
  const [progressData, setProgressData] = useState<any>(null)

  // HOOKS
  const navigate = useNavigate()
  const { client } = useParams()
  const getExportProgress = async () => {
    setProgressLoading(true)
    try {
      const res = await getExportDisableAutopay()
      setProgressData(res)
    } catch (err) {
      console.error(err)
    } finally {
      setTimeout(() => {
        setProgressLoading(false)
        setProgressData('')
      }, 10000)
    }
  }

  const handleOpenAction = (id: any, data: any) => {
    setSelectedActionData(data)
    navigate(`/payment-tools/${client}/pending-actions/list`)
  }

  const getAllAutoPaymentMethod = async () => {
    setLoading(true)
    try {
      const data = await fetchAllAutoPaymentMethods()
      setPendingActionlist(data)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAllAutoPaymentMethod()
  }, [])

  const toggleAutoPay = async (oppId:number) => {
    const updateAutoPay = async (opp: number) => {
      const res = await axiosPrivate.post('/update_autopay', {oppId: opp, updateStatus: true})
      if(res.status == 200) {
        const statusMessage = "Successfully toggled on"
        notify(
          statusMessage,
          'success'
        )
        setPendingActionlist([])
        getAllAutoPaymentMethod()
      }
    }
    const cancelAction = () => {
      notify(
        '',
        'dismiss'
      )
    }
    const msg = (
      <div className='d-flex flex-column'>
        <p>Are you sure you want to turn this on?</p>
        <div className='d-flex justify-content-center'>
          <button
            className='btn btn-danger me-7'
            onClick={() => updateAutoPay(oppId)}
          >
            Yes
          </button>
          <button
            className='btn btn-success me-7'
            onClick={() => cancelAction()}
          >
            No
          </button>
        </div>
      </div>
    );
    notify(
      msg,
      'warn',
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      }
    )
  }
  const fixPaymentSchedule = async (oppId:number) => {
    setProgressLoading(true)
    try {
      const res = await axiosPrivate.post('/run_payment_fix', {oppId: oppId})
      notify(res.data.message, 'info')
    } catch (err) {
      notify("Error Occured: " + err, 'error')
    } finally {
      setTimeout(() => {
        setProgressLoading(false)
        setPendingActionlist([])
        getAllAutoPaymentMethod()
      }, 2000)
    }
  }
  useEffect(() => {
    const filterEnrollement = search.length !== 0 
    ? pendingActionList.filter((item: any) => 
    item.name?.toLowerCase().includes(search.toLowerCase()) ||
    item.student?.email?.toLowerCase().includes(search.toLowerCase()) ||
    item.program_product?.program_summary?.toLowerCase().includes(search.toLowerCase()) ||
    item.program_session?.name?.toLowerCase().includes(search.toLowerCase()) 
    ) 
    : pendingActionList

    setVisibleData(filterEnrollement)
  }, [pendingActionList, search])


  const loginUser = async (data: any) => {
    const currentAuth = await getAuthPromise()
    const {data: auth} = await axiosPrivate.post('/auth/login_user_by_admin', {email: data?.student?.email})
  
    if (auth.api_token && currentAuth) {
      currentAuth.email = currentUser?.email
      currentAuth.first_name = currentUser?.firstName || ''
      currentAuth.last_name = currentUser?.lastName || ''

      const promises = [
        setAuthPromise(currentAuth, PREV_AUTH_LOCAL_STORAGE_KEY),
        setAuthPromise(auth)
      ]

      Promise.all(promises)
        .then(async () => {
          const admin_body = {
            first_name: currentUser?.userclient.first_name || '',
            last_name: currentUser?.userclient.last_name || '',
            email: currentUser?.email,
            loginuser_id: data?.student?.id,
            userclient_id: data?.userclient.id,
            role_id: data?.student?.role_id,
            action: 'signed-in',
          }
          return await axiosPrivate.post('/add_admin_logs', admin_body)
        })
        .then(() => {
          window.location.href = '/';
        })
        .catch((error) => {
          console.error("Error setting auth:", error);
        });
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Campus',
        accessor: 'program_product.program_summary',
      },
      {
        Header: 'Course',
        accessor: 'program_session.name',
      },
      {
        Header: 'Application Status',
        accessor: 'opp_status.name',
      },
      {
        Header: 'Payment Schedule Dates',
        Cell: (props: any) => {
          const { payment_schedule_plan } = props.row.original
          return (
            <div>
              {payment_schedule_plan?.dueDates?.map((item: any) => <div>{item.date}</div>) || 'N / A'}
            </div>
          )
        },  
        minWidth: 150
      },
      {
        Header: 'Missed Payments',
        Cell: (props: any) => {
          const { payment_schedule_plan } = props.row.original;
          const DateToday = moment().utc(true);
          const FormattedDateToday = DateToday.format('MM/DD/YYYY');
          let MissedPayments = payment_schedule_plan?.dueDates?.filter((item: any) => {
            return moment(item.date).isSameOrBefore(FormattedDateToday) && item.isPaid === false;
          });
          
          if (MissedPayments === undefined || MissedPayments.length === 0) {
            MissedPayments = ['N/A'];
          }
          
          return (
            <div>
              {MissedPayments.length > 0 ? MissedPayments.map((item: any, index: number) => 
                <div key={index} className={`${item.date ? 'text-danger': ''}`}>{item.date || 'N / A'}</div>) : ''
              } 
            </div>
          );
        }
      },
      {
        Header: 'Reason',
        Cell: (props: any) => {
          const { payment } = props.row.original
          const SortedPayments = payment?.sort((a: any, b: any) => b.id - a.id)
          switch (true) {
            case SortedPayments === undefined || SortedPayments.length === 0:
            case SortedPayments[0] === null:
              return <div>Autopay-off</div>;
          
            case SortedPayments[0].status.toLowerCase() === 'declined':
              return <div>Last payment Declined</div>;
          
            default:
              return <div>Autopay-off</div>;
          }
        },
        minWidth: 150
      },
      {
        Header: 'Actions',
        accessor: 'id',
        disableSortBy: true,
        Cell: (props: any) => {
          const {customer, opp_status, payment, program_product, ...overrideData} = props.row.original
          const hasErrorOnNewFeature = (overrideData && overrideData.payment_schedule_plan)
          return(
          <>
            <button
              onClick={() => handleOpenAction(props.value, props.row.original)}
              className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              id={`action_button_` + props.value}
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </button>
            <div
              id={'subMenu_' + props.value}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <div className='menu-link px-3' onClick={() => setOpen(true)}>
                  Transaction History
                </div>
                {/* <div className='menu-link px-3'>
                  Audit Log
                </div> */}
                <div className='menu-link px-3' onClick={() => loginUser(overrideData)}>
                  Act as
                </div>
                {hasErrorOnNewFeature ?
                  <div className='menu-link px-3' onClick={() => toggleAutoPay(overrideData.id)}>
                    Turn On Auto-pay
                  </div>
                  :
                  <div className='menu-link px-3' onClick={() => fixPaymentSchedule(overrideData.id)}>
                    Update Auto-pay
                  </div>
                }
                
              </div>
            </div>
          </>
        )},
      },
    ],
    []
  )

  return (
    <>
    {progressLoading ? (
        <div className='card-body'>
          <div className='alert alert-warning d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>Export is in progress</span>
            </div>
          </div>
        </div>
      ) : (progressData && progressData?.status === 200) ? (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{progressData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : (progressData && progressData?.status !== 200) ? (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span className='fw-bold'>{progressData?.data?.message}</span>
            </div>
          </div>
        </div>
      ) : ''}
      <div className='card card-body p-0'>
        <div className="d-flex align-items-center justify-content-between pe-9">
          <Header
            setSearch={setSearch}
            search={search}
          />
          <div className='mt-5'>
            <button 
              className='btn btn-info d-flex align-items-center' 
              onClick={() => getExportProgress()} 
              disabled={progressLoading}
            >
              {progressLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <>
                  <PiExportBold size={20} className='me-2' color='white' />
                  Export
                </>
              )}
            </button>
          </div>
        </div>
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading} />
      </div>
        <TransactionHistoryModal 
          open={open}
          setOpen={setOpen}
          selectedData={selectedActionData}
          setSelectedActionData={setSelectedActionData}
        />
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  )
}

export default PendingActionList
