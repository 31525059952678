import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select'
import { axiosPrivate } from '../../../../../axiosInstance'

interface IProps {
    refetch: any
    program: any
}

const statusOptions = [
  {
    label: 'Incomplete - Deposit Due',
    value: 'Incomplete - Deposit Due',
  }, {
    label: 'Incomplete - Deposit Paid',
    value: 'Incomplete - Deposit Paid',
  }, {
    label: 'Received - Needs Review',
    value: 'Received - Needs Review',
  }, {
    label: 'Applied - Pending',
    value: 'Applied - Pending',
  }, {
    label: 'Enrolled - Balance Due',
    value: 'Enrolled - Balance Due',
  }, {
    label: 'Enrolled - Paid',
    value: 'Enrolled - Paid',
  }, {
    label: 'Ready to Depart',
    value: 'Ready to Depart',
  }, {
    label: 'Cancelled - Refunded',
    value: 'Cancelled - Refunded',
  }, {
    label: 'Cancelled - Rollover',
    value: 'Cancelled - Rollover',
  }, 
]

const EXCLUDE_STATUS_ON_CHECK = [2,17,18,20]

const ApplicationStatusModal = ({ refetch, program}: IProps) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [selectedStatus, setSelectedStatus] = useState<any>(null)

  useEffect(() => {
    const defaultStatus = statusOptions.find(
      (option) => option.value === program?.application_status
    )
    setSelectedStatus(defaultStatus || null)
  }, [program])

  const handleShow = () => setShowModal(true);

  const handleClose = () => setShowModal(false);
  
  const onSubmit = async () => {
    if (selectedStatus?.value === program.application_status) {
        setErrorMessage(`The application status is already set to '${program.application_status}'. No changes will be made.`)
        
        setTimeout(() => {
            setErrorMessage('')
        }, 3000)
        
        return;
    }

    try {
        if (!selectedStatus) {
            setErrorMessage('Select scholarship')
            return
        }
        setErrorMessage('')
        setLoading(true)

        const selectedStatusValue = selectedStatus?.value
        const data = { program_uuid: program.program_uuid, selectedStatusValue}
        await axiosPrivate.post('/api/contactprogram-updateApplicationStatus', data);
    
        setSuccessMessage(`Successfully updated. Please wait for a moment to allow the changes to be reflected`)
        setTimeout(async () => {
            await refetch()
            setShowModal(false)
            setSuccessMessage('');
        }, 3000)
    } catch (error: any) {
        setErrorMessage(error.message)
    } finally {
        setLoading(false)
    }
  }

  return (
    <>
      <button className='btn btn-primary ms-4' onClick={handleShow}>Application Status</button>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Application Status</Modal.Title>
          </Modal.Header>

          <div className='fv-row my-7 px-7'>
            <Select
              options={statusOptions}
              value={selectedStatus}
              onChange={(e: any) => setSelectedStatus(e)}
              noOptionsMessage={() => 'Select a type first'}
            />
          </div>

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          {successMessage ? (
            <div className='card-body'>
              <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{successMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}
        
          <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='secondary' onClick={handleClose}>
              Discard
            </Button>
            <Button 
              variant='primary' 
              className='ms-2' 
              onClick={onSubmit}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Change status'}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ApplicationStatusModal