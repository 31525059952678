import {ID, Response} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { IDocumentFile, IEnrollmentObject } from '../../../../application/core/_models'
import { ITab } from '../../../../Shared/core/_models'

export interface IOpportunity {
  id: number
  name: string
  account: number
  end_date: string
  deposit_received: boolean
  is_sponsored: boolean | null
  division: string | null
}

export interface IFormResponseTab extends ITab {}

export interface IOtherTab {
  enrolled_products: IEnrollmentObject[]
  documents: IDocumentFile[]
}

export const responsesSchema = Yup.object().shape({
  self_assessment: Yup.object({
    Self_Assessment_1__c: Yup.string(),
    Self_Assessment_2__c: Yup.string(),
    Self_Assessment_3__c: Yup.string(),
    Self_Assessment_4__c: Yup.string(),
    Self_Assessment_5__c: Yup.string(),
    Self_Assessment_6__c: Yup.string(),
    Self_Assessment_7__c: Yup.string(),
    Self_Assessment_8__c: Yup.string(),
    Self_Assessment_9__c: Yup.string(),
  }),
})


export type OpportunitiesQueryResponse = Response<Array<IOpportunity>>

export type OpportunitiesListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  itemIdForDelete?: {id: ID; email: string}
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: OpportunitiesListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  isAllSelected: false,
  disabled: false,
}