import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import CustomReactSelect from '../../../../../_metronic/shared-components/CustomReactSelect'
import { Field, Form, Formik } from 'formik'
import { axiosInstance, axiosPrivate } from '../../../../../axiosInstance'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'
import usePaymentInput from '../ConfirmPay/useInputPayments'
import CustomInput from '../../../../../_metronic/shared-components/CustomInput'
interface IProps {
  oppId: number
  refetch: any
}

interface AddOn {
  id: string;
  name: string;
  amount: number;
  sf_id: string;
}

const AddonCharges = ({ oppId, refetch }: IProps) => {
  const { client } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [addOns, setAddOns] = useState<AddOn[]>([])
  const [paymentAmount, handleInputChange, resetPaymentAmount] = usePaymentInput()
  const initialValue = {
    name: '',
    amount: '0',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Add-on name is required'),
    amount: Yup.string(),
  });

  const onSubmit = async (values: any) => {
    setLoading(true)
    try {
      const NewValues = {
        opportunity_id: oppId,
        price: values.amount,
        product_name: values.name,
        custom_name: values.custom_name,
        product_id: values.product_id,
      }
      const response = await axiosPrivate.post(`/add_addon`, NewValues)
      if (response.status === 201) {
        setSuccessMessage(response.data.message)
      }
      setTimeout(() => {
        handleCloseBtn()
      }, 3000)
    } catch (error: any) {
      setLoading(false)
      setErrorMessage(error.response.data.message)
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    } finally {
      setLoading(false)
      refetch()
    }
  }

  const fetchAddosByClientId = async () => {
    try {
      const { data } = await axiosPrivate.get(`/fetch_addons/${client}`)
      setAddOns(data?.addOns)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchAddosByClientId()
  }, [])

  const handleCloseBtn = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setSuccessMessage('')
    setErrorMessage('')
    resetPaymentAmount()
  }

  return (
    <>
      <button className='btn btn-primary ms-4' onClick={handleCloseBtn}>Add-On Charges</button>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors, handleSubmit }) => {
          console.log(values)
          const handleAddOnChange = (selectedAddOn: any) => {
            const selectedAddOnData = addOns.find((addOn: AddOn) => addOn.name === selectedAddOn.value);
            if (selectedAddOnData) {
              console.log(selectedAddOnData)
              setFieldValue('name', selectedAddOnData.name);
              setFieldValue('amount', selectedAddOnData.amount.toString());
              setFieldValue('product_id', selectedAddOnData.id)
              handleInputChange(selectedAddOnData.amount.toString());
            }
          }

          const handleClose = () => {
            setShowModal(prev => !prev)
            setLoading(false)
            setErrorMessage('')
            setFieldValue('amount', "0")
            setFieldValue('name', '');
            setFieldValue('custom_name', '')
            resetPaymentAmount()
          }

          return (
            <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} size='lg' centered>
              <Form>
                <Modal.Body className='p-0'>
                  <Modal.Header closeButton={false}>
                    <Modal.Title>Add-on Charge</Modal.Title>
                  </Modal.Header>
                  
                  <div className='p-5'>
                    <Select
                      name='name'
                      placeholder='Select add-on charge'
                      options={addOns.map((item) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                      onChange={handleAddOnChange}
                    />
                  </div>

                  <div className='px-5'>
                    <CustomInput 
                      label="Add-on Custom name"
                      placeholder='Custom name field (Optional)'
                      name='custom_name'
                      maxLength={100}
                    />
                  </div>

                  <div className='px-5 mb-5'>
                    <p>Selected Add-on Charge Amount:</p>
                    <Field
                      type='text'
                      name='amount'
                      value={paymentAmount || "0"}
                      className='form-control'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleInputChange(e.target.value)
                        setFieldValue('amount', e.target.value)
                      }}
                      maxLength={12}
                    />
                    {errors.amount && <div className='text-danger'>{errors.amount}</div>}
                  </div>

                  {errorMessage ? (
                    <div className='card-body mt-5'>
                      <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                        <div className='d-flex flex-column '>
                          <span>{errorMessage}</span>
                        </div>
                      </div>
                    </div>
                  ) : ''}

                  {successMessage ? (
                    <div className='card-body mt-5'>
                      <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                        <div className='d-flex flex-column '>
                          <span>{successMessage}</span>
                        </div>
                      </div>
                    </div>
                  ) : ''}
                </Modal.Body>

                <Modal.Footer style={{ padding: '20px 30px' }}>
                  <Button variant='secondary' onClick={handleClose}>
                    Discard
                  </Button>
                  <Button
                    variant='info'
                    className='ms-2'
                    type='submit'
                  >
                    {loading ? (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : 'Add'}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}

export default AddonCharges