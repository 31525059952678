import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import UsersProvider from './../user-management/users-list/UsersProvider'

// Document List Components

import {useAuth} from '../../../../app/modules/auth'
import UserProgressList from './WaitlistManagementList'

const WaitlistManagement = () => {
  const {currentUser} = useAuth()

  const waitlist_breadcrumbs: Array<PageLink> = [
    {
      title: 'Waitlist',
      path: `/apps/${currentUser?.currentClient}/waitlist-management/waitlist-user`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='waitlist-user'
          element={
            <>
              <PageTitle breadcrumbs={waitlist_breadcrumbs}>Waitlist</PageTitle>
              <UserProgressList />
            </>
          }
        />
        {/* EDIT AND ADD if needed */}
        {/* <Route
          path='add-program-session'
          element={
            <>
              <PageTitle breadcrumbs={program_session_breadcrumbs}>Add Program Session</PageTitle>
              <ProgramSesssionAdd />
            </>
          }
        /> */}
        {/* <Route
          path='edit-program-session/:program_session_id'
          element={
            <>
              <PageTitle breadcrumbs={program_session_breadcrumbs}>Edit Program Session</PageTitle>
              <ProgramSessionEdit />
            </>
          }
        /> */}
      </Route>
      <Route
        index
        element={
          <Navigate
            to={`/apps/${currentUser?.currentClient}/waitlist-management/waitlist-user`}
          />
        }
      />
    </Routes>
  )
}

export default WaitlistManagement
