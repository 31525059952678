import { useEffect, useMemo, useState } from "react"
import { useAuth } from "../../auth"
import { useNavigate, useParams } from "react-router-dom"
import Select from 'react-select';
import { getAllTypeForm, addOrEditProgram, getAllProgram } from '../../../../network/api'
import { axiosInstance } from '../../../../axiosInstance';

interface iPrograms {
    id: string;
    name: string;
    campus: string;
}

interface iData{
    program_id: any;
    form_id: number[];
}

interface iProgram_Form{
    program_id: number;
    form_id: number;
}

const ProgramAdd = () => {
    const navigate = useNavigate()
    const { client } = useParams()
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<iData>({
        program_id: 0,
        form_id: []
    });
    const [programs, setPrograms] = useState<iPrograms[]>([]); 
    const [typeForms, setTypeForms] = useState<any[]>([]);
    const [formProgramBridge, setFormProgramBridge] = useState<iProgram_Form[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch programs
                const programResponse = await axiosInstance.get('/terra-programs/api/programs')
                const programsArray = programResponse.data.data || [];

                if (Array.isArray(programsArray)) {
                    const allPrograms = programsArray.map((program) => ({
                        id: program.id,
                        name: program.name.trim(),
                        campus: program.programData.campus
                    }));
                
                    const sortedPrograms = allPrograms.sort((a, b) => {
                        const campusComparison = a.campus.localeCompare(b.campus);
                        if (campusComparison !== 0) {
                            return campusComparison;
                        }
                        return a.name.localeCompare(b.name);
                    });
                
                    setPrograms(sortedPrograms);
                }

                // Fetch type forms
                const { data } = await getAllTypeForm()
                const sortedTypeForms = data.sort((a: any, b: any) => a.id - b.id)
                setTypeForms(sortedTypeForms)

                // Fetch form-program bridge from `typeform_bridge`
                const bridgeResponse = await getAllProgram()
                setFormProgramBridge(bridgeResponse.data)

            } catch (error) {
                console.error('Error fetching data:', error)
                setErrorMessage('Failed to fetch data. Please try again later.')
            } finally {
                setLoading(false)
            }
        };

        fetchData();
    }, []);

    const filteredPrograms = useMemo(() => {
        return programs.filter(program => {
            const programForms = formProgramBridge
                .filter(bridge => Number(bridge.program_id) === Number(program.id))
                .map(bridge => bridge.form_id);
            const hasAllForms = typeForms.every(form => programForms.includes(form.id));
            return !hasAllForms;
        });
    }, [programs, formProgramBridge, typeForms]);
    
    const filteredForms = useMemo(() => {
        const linkedFormIds = formProgramBridge
            .filter(bridge => bridge.program_id === data.program_id)
            .map(bridge => bridge.form_id);
        return typeForms.filter(form => !linkedFormIds.includes(form.id));
    }, [data.program_id, typeForms, formProgramBridge]);

    const programOptions = useMemo(() => {
        return filteredPrograms.map(program => ({
            value: program.id,
            label: `${program.campus} - ${program.name}`
        }));
    }, [filteredPrograms]);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoading(true)
        try {
            
            await addOrEditProgram( data ) 
            setSuccessMessage('Form & Program added successfully')
            
            setTimeout(() => {
                navigate(`/apps/${client}/program-management/program`)
            }, 3000)
            
        } catch (error: any) {
            if (error.response.status === 500) {
                return setErrorMessage('Something went wrong, please try again.')
            } else if (error.response.status === 400) {
                return setErrorMessage('Something went wrong while processing the request!')
            } else if (error.response.status === 403) {
                return setErrorMessage('Need client id provided.')
            } else if (error.response.status === 400) {
                return setErrorMessage('Something went wrong while processing the request!')
            } else setErrorMessage('Something went wrong, please try again.')
            setTimeout(() => {
                setErrorMessage('')
        }, 3000)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <span className='indicator-progress' style={{display: 'flex', justifyContent: 'center'}}>
                <span
                    style={{height: '40px', width: '40px'}}
                    className='spinner-border spinner-border-sm align-middle mt-20'
                ></span>
            </span>
        )
    }

    const handleProgramChange = (selectedOption: any) => {
        setData({ ...data, program_id: selectedOption ? Number(selectedOption.value) : 0 });
    };

    const handleFormChange = (selectedOptions: any) => {
        const selectedValues = selectedOptions
            ? selectedOptions.map((option: any) => Number(option.value))
            : [];
            
        setData({ ...data, form_id: selectedValues });
    };
      
    return (
        <>
            {successMessage && (
                <div className='card-body'>
                <div className='alert alert-success d-flex align-items-center p-5'>
                    <div className='d-flex flex-column '>
                    <span>{successMessage}</span>
                    </div>
                </div>
                </div>
            )}

            {errorMessage && (
                <div className='card-body'>
                <div className='alert alert-danger d-flex align-items-center p-5'>
                    <div className='d-flex flex-column '>
                    <span>{errorMessage}</span>
                    </div>
                </div>
                </div>
            )}

            <div className='card-body'>
                <form onSubmit={onSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='program' className='form-label'>
                            Program
                        </label>
                        <Select
                            id='program'
                            options={programOptions}
                            value={programOptions.find(option => option.value === data.program_id) || null}
                            onChange={handleProgramChange}
                            className='basic-single'
                            classNamePrefix='select'
                        />
                    </div>

                    <div className='mb-3'>
                        <label htmlFor='formId' className='form-label'>
                            Form/s
                        </label>
                        <Select
                            id='forms'
                            isMulti
                            options={filteredForms.map(form => ({
                                value: form.id,
                                label: form.name
                            }))}
                            value={filteredForms
                                .filter(form => data.form_id.includes(form.id))
                                .map(form => ({ value: form.id, label: form.name }))
                            }
                            onChange={handleFormChange}
                            className='basic-multi-select'
                            classNamePrefix='select'
                        />
                    </div>

                    <button type='submit' className='btn btn-primary'>
                        Submit
                    </button>
                </form>
            </div>
        
        </>
    )
}

export default ProgramAdd