import React, { useEffect } from 'react'
import { axiosInstance } from '../../axiosInstance'
import { useParams } from 'react-router-dom'

const EmailInvitationRedirect = () => {
  const { action, hash, token} = useParams()
 
  const EmailInvitationHandler = async () => {
    const response = await axiosInstance.get(`invitation/${action}/${hash}/${token}`)
    console.log(response, '@@response')
  }

  useEffect(() => {
    EmailInvitationHandler()
  })

  return (
    <h2 className='text-center m-2'>
      You have successfully added a new contact. You can manage your contacts in your dashboard.
    </h2>
  )
}

export default EmailInvitationRedirect