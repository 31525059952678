import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { axiosPrivate } from '../../../../../axiosInstance';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useNavigate, useParams } from 'react-router-dom';
import { IPredepartureItem } from '../../../../modules/application/core/_models';
import { PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, useAuth } from '../../../../modules/auth';
import { useQuery } from 'react-query';
import useAppRequests from '../../../../modules/application/Hooks/useAppRequests';
import _queryKeys from '../../../../modules/application/core/_queryKeys';
import moment from 'moment';
import _ from 'lodash';
import ReactHTMLParser from 'react-html-parser';
import { Button, Modal } from 'react-bootstrap'
import './index.css'
interface Props {
  predeparture: IPredepartureItem;
}

const PredepartureUpload = ({ predeparture }: Props) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [fileState, setFileState] = useState<{ fileName: string; percentCompleted: number }>();
  const [errorUploadMessage, setErrorUploadMessage] = useState('');
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const { opportunity, client } = useParams();
  const { getPredepartureDocumentsReq, updatePredepartureStatusReq, RequestPresignedDocument } = useAppRequests();
  const [hasAdmin, setHasAdmin] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Submitted - Needs Review');
  const [signedURLs, setSignedURLs] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState<any>();
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  
  const {
    isLoading,
    isError,
    refetch: refetchDocuments,
    data: documents,
  } = useQuery(_queryKeys.getPredepartureDocumentsReq, () =>
    getPredepartureDocumentsReq({
      group: predeparture?.name,
      opportunity_id: Number(opportunity),
    })
  );

  const onDrop = useCallback((acceptedFiles: any) => {
    const formData = new FormData()
    formData.append('file', acceptedFiles[0])
    const info = {
      predeparture_id: predeparture?.id,
      type : predeparture?.name,
      opportunity_id: Number(opportunity),
    }
    formData.append('info', JSON.stringify(info))
    setFileState({
      fileName: acceptedFiles[0].name,
      percentCompleted: 0,
    })
    setErrorUploadMessage('')
    setIsUploadSuccess(false)
    //call API to upload
    axiosPrivate
      .post('/upload/predeparture', formData, {
        onUploadProgress: (p) => {
          const percentCompleted = Math.round((p.loaded * 100) / p.total)
          setFileState({
            fileName: acceptedFiles[0].name,
            percentCompleted,
          })
          console.log(`${percentCompleted}% uploaded`)
        },
      })
      .then((res) => {
        console.log('res', res)
        setIsUploadSuccess(true)
      })
      .catch((err) => {
        console.log('err', err.response.data)
        setErrorUploadMessage(err.response.data.message)
      })
      .finally(() => {
        refetchDocuments()
      })
  }, [])
  const {getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject} =
    useDropzone({onDrop})

  const handleSelect = async (event: any) => {
    setSelectedOption(event.target.value);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const inputs = {
      opportunity_id: Number(opportunity),
      predeparture_id: predeparture?.id,
      predeparture_task_name: predeparture.name,
      status_name: selectedOption
    }

    setShowModal(false);
    const response = await updatePredepartureStatusReq(inputs);
    setSuccess(response);
    console.log('- - - Pre-departure Status - - -');
    console.log(response)
    if (response.message === 'Predeparture Status successfully updated!') {
      setTimeout(() => {
        return navigate(`/dashboard/${currentUser?.currentClient}`, {replace: true})
      }, 3000)
    }
  }

  const RequestPresignedURLs = async () => {
    const signedURLs: { [key: string]: string } = {};
    for (const doc of documents?.data || []) {
      const response = await RequestPresignedDocument(doc.path);
      signedURLs[doc.path] = response?.data;
    }
    setSignedURLs(signedURLs);
  };

  useEffect(() => {
    if (predeparture) {

      const checkAdmin = async () => {
        const prevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
        if (prevAuth) {
          setHasAdmin(true)
        }
      }
      checkAdmin()
    }
    if (documents?.data) {
      RequestPresignedURLs();
    }
  }, [predeparture, documents?.data])


  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setErrorMessage('')
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='d-flex justify-content-center align-items-center card-header bg-primary rounded w-75 mx-auto mt-5'>
          <h1 className='fw-bolder fs-2x text-light text-uppercase'>
            Pre-Departure Tasks List - {predeparture.name}
          </h1>
        </div>
        
        <div style={{ fontSize: 20 }} className='card-header px-0 my-5 fw-bolder'>
          How to upload:
        </div>
        <h3>{predeparture?.description || ''}</h3>
        <div className='instructions'>
          {ReactHTMLParser(predeparture?.instruction) || ''}
        </div>
  
        <div>
          {predeparture?.task_type === 'upload-template' ?
            predeparture.status !== 'Incomplete' ? (
              <div className={`d-flex ${predeparture.status === 'Not Applicable' ? 'bg-warning' : 'bg-success'} fw-bolder text-white border-1 border-success justify-content-center p-5`}>
                <span>{predeparture.status === 'Not Applicable' ? 'Not Applicable' : 
                'You have already submitted, Please wait for the admin to review'
                }</span>
              </div>
            ) : (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className='d-flex flex-column border-2 border-dashed border-primary rounded bg-secondary justify-content-center py-10 my-5'>
                  <span className='fs-4 fw-bold text-gray-600 text-center'>
                    Drag n' Drop a file here, or click to Select File
                  </span>
                </div>    
              </div>
            ) : '' 
          }
        </div>

        {!_.isEmpty(documents?.data) && (
          <div className='alert alert-primary mb-7'>
            <h5> You have successfully uploaded {documents?.data.length ? documents?.data.length : '0'} file
            </h5>
          </div>
        )}

        {documents?.data.map((doc, idx) => (
          <div key={idx}>
            <a
              className='cursor-pointer btn btn-active-primary'
              href={signedURLs[doc.path] || ''}
              target='_blank'
              rel='noreferrer'
              onClick={() => RequestPresignedDocument(doc.path)}
            >
              {`${doc.original_name} at ${moment(doc.created_at).format('MMM DD, YYYY')}`}
            </a>
          </div>
        ))}

        {(predeparture?.status === 'Incomplete' && predeparture?.custom_input) &&
        <a
          href={predeparture.custom_input || '#'} 
          target='_blank'
          rel='noreferrer'
          className='d-flex border-2 border-dashed border-primary bg-white justify-content-center w-25 mx-auto my-3 text-primary cursor-pointer py-2'
        >
          {predeparture?.task_type === 'third-party-link' ? 'Click here to access Forms' : 'Click here to download template'}
        </a> 
        }

      {!isUploadSuccess &&
      <div className='mb-7'>
          <ProgressBar
            animated
            striped
            variant='primary'
            label={
              <span className='fs-6 fw-bold'>
                {fileState?.percentCompleted ? `${fileState?.percentCompleted}% uploaded` : null}
              </span>
            }
            now={fileState?.percentCompleted}
          />
        </div>}
        {errorUploadMessage && (
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorUploadMessage}</span>
            </div>
          </div>
        )}
        {isUploadSuccess && (
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>File has been uploaded!</span>
            </div>
          </div>
        )}
        <form className="d-flex flex-column w-25">
          {hasAdmin &&
            <select
              className="form-select mb-5"
              value={selectedOption}
              onChange={handleSelect}>
              <option>Review Predeparture Task</option>
              <option value="Incomplete">Incomplete</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Not Applicable">Not Applicable</option>
            </select>}

          <div className='mb-7'>
          {hasAdmin ? (
            <div className='btn btn-primary'  onClick={() => setShowModal(true)}>
              Submit
            </div> ) : (
             <div className={`btn btn-primary ${predeparture.status === 'Confirmed' || predeparture.status === 'Submitted - Needs Review' 
             || predeparture.status === 'Not Applicable'
             ? 'disabled' : ''}`} 
             onClick={() => {
               if (predeparture.status !== 'Confirmed' && 
                   predeparture.status !== 'Submitted - Needs Review' && 
                   predeparture.status !== 'Not Applicable'
                  ) {
                 setShowModal(true);
               }
             }}>
              Submit
            </div>
            )}
            <div className='mt-5'>
              {success && 
              <span className={success.message == "Predeparture Status successfully updated!"
              ? "w-50 text-center text-success fw-bold" : 
                "w-50 text-center text-danger fw-bold p-5 mt-3"}>
                {success.message}
              </span> }
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop='static' keyboard={false} centered>
              <Modal.Body className='p-0'>
                <Modal.Header closeButton>
                  <Modal.Title>Are you sure you want to submit?</Modal.Title>
                </Modal.Header>
              </Modal.Body>
              <Modal.Footer style={{padding: '20px 30px'}}>
                
                <Button 
                  variant='secondary' 
                  onClick={handleSubmit}
                >
                  Yes
                </Button>
                <Button variant='danger' className='ms-2' onClick={handleClose}>
                  {loading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : 'No'}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PredepartureUpload;
