/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQueryResponseLoading, useQueryResponsePagination} from '../core/QueryResponseProvider'
import BasePagination from '../../../../Shared/components/BasePagination'

const ProductsListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  return <BasePagination pagination={pagination} isLoading={isLoading}/>
}

export {ProductsListPagination}
