import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { axiosPrivate } from '../../../../../axiosInstance'

interface IProps {
  oppId: number
  refetch: any
}

const Discount = ({ oppId, refetch }: IProps) => {
  const { client,  } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [discounts, setDiscounts] = useState<any>([])
  const [selectedDiscount, setSelectedDiscount] = useState<any>(null)

  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await axiosPrivate.post('/user/fetch_discount', {division: client === '47821104' ? 'SSB' : 'TTN'})
      const filterActive = res.data.filter((item: any) => item.is_active)
      setDiscounts(filterActive)
    } catch (error: any) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (showModal) {
      fetchData()
    }
  }, [showModal])

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setErrorMessage('')
    setSuccessMessage('')
    setSelectedDiscount(null)
  }

  const onSubmit = async () => {
    try {
      setSuccessMessage('')
      if (!selectedDiscount) {
        setErrorMessage('Select scholarship')
        return
      }
      setErrorMessage('')
      setLoading(true)
      await axiosPrivate.post('/user/add_discount', {...selectedDiscount, oppId})
      refetch()
      setSuccessMessage(`Successfully deleted. Please wait for a moment to allow the changes to be reflected`)
      setTimeout(() => {
        setShowModal(false)
      }, 1000)
    } catch (error: any) {
      setErrorMessage(error.message)
    } finally {
      setSelectedDiscount(null)
      setLoading(false)
    }
  }

  return (
    <>
      <button className='btn btn-primary ms-4' onClick={handleClose}>Discount</button>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Discount</Modal.Title>
          </Modal.Header>
          <div className='fv-row my-7 px-7'>
            <Select
              options={discounts.map((item: any) => ({
                value: item,
                label: item.name,
              }))}
              value={selectedDiscount ? {value: selectedDiscount, label: selectedDiscount?.name} : ''}
              onChange={(e: any) => setSelectedDiscount(e.value)}
              noOptionsMessage={() => 'Select a type first'}
              isClearable
            />
          </div>

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}

          {successMessage ? (
            <div className='card-body'>
              <div className='alert alert-success d-flex align-items-center px-5 py-3 mx-7'>
                <div className='d-flex flex-column '>
                  <span>{successMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}
          </Modal.Body>
        
          <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='secondary' onClick={handleClose}>
              Discard
            </Button>
            <Button 
              variant='primary' 
              className='ms-2' 
              onClick={onSubmit}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Add Discount'}
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default Discount