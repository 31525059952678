import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import UsersProvider from './../user-management/users-list/UsersProvider'

// Document List Components
import FlightList from './FlightList'
import FlightAdd from './FlightAdd'
import FlightEdit from './FlightEdit'


import { useAuth } from '../../../../app/modules/auth'


const Flights = () => {
    console.log('Flight')
  const {currentUser} = useAuth();

  const flightListBreadCrumbs: Array<PageLink> = [
    {
      title: 'Flight List Management',
      path: `/apps/${currentUser?.currentClient}/flight-management/flights`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
          {/* Flight List */}
        <Route
          path='flights'
          element={
            <>
              <PageTitle breadcrumbs={flightListBreadCrumbs}>Flight list</PageTitle>
              <FlightList />
            </>
          }
        />
        <Route
          path='add-flight'
          element={
            <>
              <PageTitle breadcrumbs={flightListBreadCrumbs}>Add Flight</PageTitle>
              <FlightAdd />
            </>
          }
        />
        <Route
          path='edit-flight/:flightId'
          element={
            <>
              <PageTitle breadcrumbs={flightListBreadCrumbs}>Edit Flight</PageTitle>
              <FlightEdit />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/apps/${currentUser?.currentClient}/flight-management/flights`} />} />
    </Routes>
  )
}

export default Flights