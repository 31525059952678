import {ErrorMessage} from 'formik'
import {useQueryClient} from 'react-query'
import _queryKeys from '../../core/_queryKeys'
import {IEnrollPartTwo, IEnrollPartTwoForm} from '../../core/_models'
import CustomField from '../Shared/CustomField'
import CustomHiddenField from '../Shared/CustomHiddenField'
import ReactHtmlParser from 'react-html-parser'

interface Props {
  metadata: IEnrollPartTwoForm[]
  name: string
  //setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  currentFormikVal: IEnrollPartTwo
  errors?: any
  setFieldValue?: any
}

const CustomStep = ({currentFormikVal, metadata, name, errors, setFieldValue}: Props) => {
  const queryClient = useQueryClient()
  const enrollPartTwoFieldData = queryClient.getQueryData<{data: IEnrollPartTwoForm[]}>(
    _queryKeys.getEnrollPartTwoForms
  )
  
  const custom_form = metadata?.filter((f) => f.custom_form?.name === name).sort((a, b) => {
    // Use group order if available, otherwise use id
    const groupOrderA = a.group_order ?? a.id;
    const groupOrderB = b.group_order ?? b.id;
    return groupOrderA - groupOrderB;
  })
  return (
    <div className='w-100 row'>
      {custom_form?.map((form, idx) => {
        
        return (
          <div key={idx}>
            {form.type === 'question' && (
              <div className='col-lg-12 mb-5' key={idx}>
                <label className={'form-label fw-bolder text-dark fs-6 required'}>
                  {ReactHtmlParser(form.label)}
                </label>
                <div className='fw-bold text-dark mb-2'>
                  <span>{form.helper_text}</span>
                </div>
                <CustomField form={form} currentFormikVal={currentFormikVal} setFieldValue={setFieldValue}/>
                <div className='text-danger mt-2'>
                  {errors ? (
                    <>
                      {errors[form.name]}
                    </>
                  ) : (
                    <ErrorMessage name={form.name} />
                  )}
                </div>
              </div>
            )}
            <CustomHiddenField
              form={form}
              currentFormikVal={currentFormikVal}
              formsList={custom_form}
              objectName={name}
              prefixFieldName={name}
            />
            {/* <CustomHiddenField
              form={form}
              currentFormikVal={currentFormikVal}
              formsList={custom_form}
            /> */}
            {form.type === 'text' && (
              <div className='col-lg-12 mb-5' key={idx}>
                <label className={'form-label fw-bolder text-dark fs-6 required'}>
                  {ReactHtmlParser(form.label)}
                </label>
                <div className='fw-bold text-dark mb-2'>
                  <span>{ReactHtmlParser(form.helper_text ?? '')}</span>
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default CustomStep
