import React from 'react'

const Notes = () => {
  return (
    <div className='mb-7 position-relative'>
      <span className='text-danger' style={{fontSize: '16px', marginBottom: '10px'}}>
        <b className='text-danger me-2'>Note: </b>Please refrain from altering the below terms in any way.
      </span>
      <ol className='mb-0'>
        <li className='opacity-75'>{`{{ toEmail }}`}</li>
        <li className='opacity-75'>{`{{ portal }}`}</li>
      </ol>
    </div>
  )
}

export default Notes