export const clients = [
  {
    value: '88562775',
    label: 'Teen Travel Network',
    name: 'TTN',
  },
  // {
  //   value: '84984438',
  //   label: 'Experience GLA',
  //   name: 'GLA',
  // },
  {
    value: '47821104',
    label: 'Summer Spring Board',
    name: 'SSB',
  },
  // {
  //   value: '14302874',
  //   label: 'Terra Education',
  //   name: 'tEdu',
  // },
]

export const categories = [
  {value: 1, label: 'Cancellation fee'},
  {value: 2, label: 'Tuition'},
  {value: 3, label: 'Supplements'},
  {value: 4, label: 'Commuter Supplement'},
  {value: 5, label: 'Airport Supplements'},
  {value: 6, label: 'Protection Plan'},
  {value: 7, label: 'Roll Over'},
  {value: 8, label: 'Non-refundable Fee'},
]


export interface IEnrollmentExtras {
  id?: number
  name: string
  type: string
  category: number
  amount: number
  status_id: number | boolean
  default_client: string
  qb_full?: string
}
