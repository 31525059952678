import { useEffect } from "react";
import { useMutation } from "react-query";
import { Link, useSearchParams } from "react-router-dom"
import { useAuth } from "../core/Auth";
import useAuthRequests from "../hooks/useAuthRequests";

const SwitchProgram = () => {
  const [searchParams] = useSearchParams()
  const {saveAuth, setCurrentUser} = useAuth();
  const {processRedirectProgram, getCurrentUser} = useAuthRequests()
  const token = searchParams.get('token');
  const client = searchParams.get('client')

  const {isError, mutate: getNewToken} = useMutation(
    processRedirectProgram
  )
  useEffect(() => {
    if (token && client) {      
      getNewToken(
        {client, token},
        {
          onSuccess: async ({data: auth}) => {
            console.log('getNewToken', auth)
            
            saveAuth(auth)
            const {currentUser} = await getCurrentUser()
            console.log('new currentUser', currentUser)
            
            setCurrentUser(currentUser)
          },
        }
      )
    }
    return () => {}
  }, [token, client])

  if (!token || !client || isError) {
    return (
      <div>
        <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
          <div className='d-flex flex-column'>
            <h5 className='mb-1'>Oops!</h5>
            <span className='fs-5'>Could not redirect to your program. Please try again!</span>
          </div>
        </div>
        {/* begin::Separator */}
        <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>
        {/* end::Separator */}
        <Link to='/auth'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Sign in
          </button>
        </Link>
      </div>
    )
  }

  return (
    <div>
      Please wait for redirecting...{' '}
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </div>
  )
}

export default SwitchProgram