import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import CustomInput from '../../../../../_metronic/shared-components/CustomInput'
import {createNewAirline} from '../../../../../network/api'

interface CreateNewAirline {
  name: string
  code: string
}

function CreateAirlineModal() {
  const [show, setShow] = useState(false)
  const [formData, setFormData] = useState<CreateNewAirline>({
    name: '',
    code: '',
  })

  const handleClose = () => {
    setShow(false)
    setFormData({
      name: '',
      code: '',
    })
  }

  const handleShow = () => setShow(true)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    try {
      const response = await createNewAirline(formData)
      console.log('API response:', response) // Log API response
      handleClose()
    } catch (error) {
      console.error('API error:', error) // Log API error
    } finally {
      window.location.reload() // Refresh the page
    }
  }

  return (
    <>
      <Button className='mb-3' variant='primary' onClick={handleShow}>
        + Add Airline
      </Button>

      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Airline</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomInput
            label='Airline Name'
            name='name'
            placeholder='Airline Name'
            value={formData.name}
            onChange={handleChange}
          />
          <CustomInput
            label='Code'
            name='code'
            placeholder='Code'
            value={formData.code}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button onClick={onSubmit} variant='primary'>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateAirlineModal
