import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { moveToNotInterested } from '../../../../../network/api'

type Props = {
  opportunityId: number
}

const DeclineAction = ({opportunityId}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      setLoading(true)
      
      let res = await moveToNotInterested(opportunityId)
      if(res){
        setTimeout(() => {
          setShowModal(false)
        }, 1000)
        
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <a onClick={() => setShowModal(true)} className='menu-link px-3'>Decline</a>
      {/* <button className='btn btn-info ms-4'>Edit Protection Plan</button> */}
      <Modal title="Test" onHide={handleClose} show={showModal} backdrop='static' centered keyboard={false} >
        <Modal.Header closeButton>
            <Modal.Title>Remove this student in waitlist?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Removing will change their status to <b>"Waitlist - Not Interested"</b>.</h5>
        </Modal.Body>

        <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='danger' onClick={handleClose}>
              Cancel
            </Button>
            <Button 
              variant='primary' 
              className='ms-2' 
              onClick={onSubmit}
            >
            {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Remove'
            }
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeclineAction