import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../auth'

const EnrollmentAccess = () => {
   const {currentUser, logout, logoutServer} = useAuth()
   const isLoading = logoutServer?.isLoading
   function onLogout() {
     //execute to call logout API
     logoutServer?.mutate(null, {
       onSuccess: () => {
         //remove in local
         logout()
       },
       onError: (err: any) => {
         if (err.response.status === 404) {
           //remove in local
           logout()
         }
       },
     })
   }
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        {/* begin::Illustration */}
        <div className='fw-bold fs-5x text-danger'>403</div>
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className='fw-bold mb-10' style={{color: '#A3A3C7'}}>
          Access Denied/Forbidden
        </h1>
        <p>Sorry. your're not allowed in here </p>
        <p>Please login as student or parent account to continue. </p>
        {/* end::Message */}
        {/* begin::Link */}
        <Link to='/' className='btn btn-primary'>
          Return Home
        </Link>
        {/* end::Link */}
        {currentUser && (
          <>
            {' '}
            <div className='text-center text-muted text-uppercase fw-bolder my-5'>or</div>
            <button className='btn btn-light' onClick={onLogout}>
              {!isLoading ? (
                <div className='d-flex align-items-center justify-content-center'>
                  <span className='btn-label px-2'>Sign Out</span>
                  <i className='fa-solid fa-right-from-bracket'></i>
                </div>
              ) : (
                <div className='d-flex align-items-center justify-content-center'>
                  <span className='btn-label px-2' style={{display: 'block'}}>
                    Please wait...
                  </span>
                  <span className='spinner-border spinner-border-sm align-middle '></span>
                </div>
              )}
            </button>{' '}
          </>
        )}
      </div>
    </div>
  )
}

export default EnrollmentAccess