import { axiosInstance } from "../../../../axiosInstance";
import { useAuth } from "../core/Auth"

const useRefreshToken = () => {
  const {saveAuth} = useAuth();
   const refresh = async () => {
      const response = await axiosInstance.get('/auth/refresh_token')
      console.log(response,'response useRefreshToken')
      saveAuth(response.data.api_token)
      return response.data.api_token
   }
   return refresh
}

export default useRefreshToken