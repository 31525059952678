import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import CustomInput from '../../../../../_metronic/shared-components/CustomInput'
import {createNewAirport} from '../../../../../network/api'

interface CreateAirport {
  country: string
  city: string
  code: string
}

function CreateAirportModal() {
  const [show, setShow] = useState(false)
  const [formData, setFormData] = useState<CreateAirport>({
    country: '',
    city: '',
    code: '',
  })

  const handleClose = () => {
    setShow(false)
    setFormData({
      country: '',
      city: '',
      code: '',
    })
  }

  const handleShow = () => setShow(true)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    try {
      const response = await createNewAirport(formData)
      console.log(response, '@@@@@@@@@@@@')
      handleClose() // Close the modal after successful submission
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Button className='mb-3' variant='primary' onClick={handleShow}>
        + Add Airport
      </Button>

      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Airport</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomInput
            label='Country Name'
            name='country'
            placeholder='Country Name'
            value={formData.country}
            onChange={handleChange}
          />
          <CustomInput
            label='City Name'
            name='city'
            placeholder='City Name'
            value={formData.city}
            onChange={handleChange}
          />
          <CustomInput
            label='Code'
            name='code'
            placeholder='Code'
            value={formData.code}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button onClick={onSubmit} variant='primary'>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateAirportModal
