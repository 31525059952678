import { KTCard } from '../../../../../_metronic/helpers'
import { ClientListHeader } from './header/ClientListHeader'
import {ClientsTable} from './table/ClientsTable'

const ClientsList = () => {
  return (
    <KTCard>
      <ClientListHeader />
      <ClientsTable />
    </KTCard>
  )
}

export default ClientsList