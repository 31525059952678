const CustomCheckbox = ({label, name, values, setFieldValue}: any) => {

  return (
    <div className='fv-row mb-7'>
      <input
        id={`${name}check`}
        type='checkbox'
        className='form-check-input me-2'
        checked={values}
        onChange={(e) => {
          if (e.target.checked) {
            setFieldValue('is_active', true)
            return
          }
          setFieldValue('is_active', false)
        }}
      />
      <label className='flex-grow-1 fw-bold fs-6 mb-2' htmlFor={`${name}check`}>{label}</label>
    </div>
  )
}

export default CustomCheckbox
