import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useAuth} from '../../auth'
import ReportList from './ReportList'

const ReportsMain = () => {
  const {currentUser} = useAuth()
  const ReportsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Reports List',
      path: `/apps/${currentUser?.currentClient}/reports/list`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <Outlet />
        }
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={ReportsBreadcrumbs}>Progress Reports</PageTitle>
              <ReportList />
            </>
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate to={`/apps/${currentUser?.currentClient}/products-management/products`} />
        }
      />
    </Routes>
  )
}

export default ReportsMain
