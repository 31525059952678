// MyContactList.jsx
import { useEffect, useState } from "react"
import { axiosPrivate } from "../../axiosInstance"
import ContactCard from "./ContactCard"
import './MyContactList.scss'
import { useNavigate } from "react-router-dom"
import ConfirmPayBanner from "../modules/application/components/ConfirmPay/ConfirmPayBanner"

const MyContactList = () => {
  const [linkContacts, setLinkContacts] = useState([])
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const fetchLinkContacts = async () => {
    try {
      const response = await axiosPrivate.get('/contact/linked-accounts')
      setLinkContacts(response.data.data)
      setMessage(response.data.message)
    } catch (error) {
      setMessage('Error fetching contacts')
    }
  }

  useEffect(() => {
    fetchLinkContacts()
  }, [])

  return (
    <div>
      <div className='d-flex align-items-center position-relative mb-5'>
        <ConfirmPayBanner title={`Account Members`} />
      </div>
      <h1>My Contact List</h1>
      {linkContacts.length === 0 ? (
        <div className="w-100 h-100">
          <p>No users found</p>
        </div>
      ) : (
        <div className="my-contact-list">
          {linkContacts.map((contact: any) => (
            <ContactCard key={contact.id} contact={contact} />
          ))}
          <div className='add-contact-btn'>
            <i className="fa-solid fa-plus"></i>
              <button type="button" className="btn btn-primary" onClick={() => navigate('/add-contact/47821104')}>Add Member</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MyContactList
