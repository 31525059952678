import React, { useEffect, useState } from 'react'
import {Row, TableInstance, UsePaginationInstanceProps, UsePaginationState, UseSortByInstanceProps, usePagination, useSortBy, useTable} from 'react-table'
import './dynamic-table.css'
import LoadingGIF from '../../assets/Logo/loading.gif'

interface Props {
  columns: any
  data: any
  loading: boolean
}

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const DynamicTableV2 = ({columns, data, loading}: Props) => {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
    autoResetPage: false,
    autoResetFilters: false,
    autoResetSortBy: false
  }, useSortBy, usePagination)
  const [currentPage, setCurrentPage] = useState(1)
  const [visibleData, setVisibleData] = useState<Array<Row>>([])
  const totalPages = Math.ceil(rows.length / 10)

  useEffect(() => {
    const startIndex = (currentPage - 1) * 10
    const endIndex = startIndex + 10
    setVisibleData(rows?.slice(startIndex, endIndex))
  }, [currentPage, rows])
  /*
    Reset Pagination and Reset into Page 1 by Default
    This will only run when data is changed by Search, Filter, Dates Etc.
    That will affect Changing data
  */
  useEffect(() => {
    setCurrentPage(1)
  }, data)
  
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page)
    }
  }
  const renderPaginationItems = () => {
    const paginationItems = []

    paginationItems.push(
      <li key='prev' className={`page-item px-5 ${currentPage === 1 ? 'disabled' : ''}`}>
        <button
          className='page-link '
          onClick={() => handlePageChange(currentPage - 1)}
          aria-label='Previous'
        >
          Previous
        </button>
      </li>
    )

    // Show the first page
    paginationItems.push(
      <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
        <button className='page-link' onClick={() => handlePageChange(1)}>
          1
        </button>
      </li>
    )

    // Show ellipsis after "Previous" if the current page is beyond the second page
    if (currentPage > 2) {
      paginationItems.push(
        <li key='ellipsis-prev' className='page-item disabled'>
          <span className='page-link'>...</span>
        </li>
      )
    }

    // Show the previous 2 pages, current page, and next 2 pages
    const startPage = Math.max(2, currentPage - 2)
    const endPage = Math.min(totalPages - 1, currentPage + 2)
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <button className='page-link' onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      )
    }

    // Show ellipsis if the current page is before the second-to-last page
    if (currentPage < totalPages - 1) {
      paginationItems.push(
        <li key='ellipsis-next' className='page-item disabled'>
          <span className='page-link'>...</span>
        </li>
      )
    }

    // Show the last page
    if (totalPages > 1) {
      paginationItems.push(
        <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
          <button className='page-link' onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        </li>
      )
    }

    paginationItems.push(
      <li key='next' className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
        <button
          className='page-link'
          onClick={() => handlePageChange(currentPage + 1)}
          aria-label='Next'
        >
          Next
        </button>
      </li>
    )

    return paginationItems
  }
  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className='card card-body'>
      <div className='table-responsive scrollbar'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed table-hover dataTable no-footer gy-5'
          {...getTableProps()}
        >
          <colgroup>
            {columns.map((column: any) => {
              return <col style={{minWidth: column?.minWidth}} key={column.accessor} />
            })}
          </colgroup>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className='text-start fw-bolder fs-7 text-uppercase gs-0'
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                    <span>
                      {column.canSort ? column.isSorted
                        ? column.isSortedDesc
                          ? <img src='http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_desc.png' />
                          : <img src='http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_asc.png' />
                        : <img src='http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_both.png' />
                      : ''}
                    </span>
                  </th>
                })}
              </tr>
            ))}
          </thead>
          <tbody className='text-gray-600 fw-light' {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan={100}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={LoadingGIF} alt='LOADING...'/> 
                  </div>
                </td>
              </tr>
            ) : visibleData.length > 0 ? (
              visibleData.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={100}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          {rows.length > 10 ? (
            <tfoot className='pull-right'>
              <tr>
                <td colSpan={100}>
                  <nav aria-label='Page navigation' className='pull-right'>
                    <ul className='pagination justify-content-end mt-5'>{renderPaginationItems()}</ul>
                  </nav>
                </td>
              </tr>
            </tfoot>
          ) : ''}
        </table>
      </div>
    </div>
  )
}

export default DynamicTableV2
