import React from 'react'

const DefaultSpinnerLoading = ({title}: {title?: string}) => {
  return (
    <div className='w-100'>
      <div className='d-flex justify-content-center align-items-center'>
        <span className='me-5'>{title ? title : 'Please wait...'}</span>
        <output className='spinner-border text-primary'>
          <span className='visually-hidden'>Loading...</span>
        </output>
      </div>
    </div>
  )
}

export default DefaultSpinnerLoading