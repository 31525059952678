import React, {useCallback, useEffect, useState} from 'react'
import {MenuItem} from '../MenuItem'
import {MenuInnerWithSub} from '../MenuInnerWithSub'
import {useIntl} from 'react-intl'
import {AUTH_LOCAL_STORAGE_KEY, IPortalClient, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise, removeAuth, setAuthPromise, useAuth} from '../../../../../app/modules/auth'
import useAuthRequests from '../../../../../app/modules/auth/hooks/useAuthRequests'
import {useMutation, useQuery} from 'react-query'
import {automateSwitchClient} from '../../../../../app/modules/auth/core/_requests'
import {KTSVG} from '../../../../helpers'
import {useNavigate, useParams} from 'react-router-dom'
import useRole from '../../../../../app/modules/auth/hooks/useRole'
import './style.css'
import useWindowSize from '../../../../../hooks/useWindowSize'

export function TerraMenuInner() {
  const navigate = useNavigate()
  const {currentUser, saveAuth, setCurrentUser} = useAuth()
  const {isAdminOrSuperAdmin} = useRole()
  const {getCurrentUser} = useAuthRequests()
  const currentRoute = window.location.pathname.split('/')[2] || ''
  const intl = useIntl()
  const [hasAdmin, setHasAdmin] = useState<any>(null)

  const {getPrograms, getStatus} = useAuthRequests()
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(`getPrograms`, () => {
    return getPrograms()
  })
  useQuery(`getStatus`, () => {
    return isAdminOrSuperAdmin ? getStatus() : null
  })
  const {mutateAsync: redirectProgramMutateAsync} = useMutation(automateSwitchClient)
  const onSwitchingProgram = async (client: IPortalClient) => {
    try {
      const res = await redirectProgramMutateAsync(client?.reference_id)
      if (res.api_token) {
        saveAuth({api_token: res.api_token})
        const {currentUser} = await getCurrentUser()
        setCurrentUser(currentUser)
        return navigate(`/enroll/${client.reference_id}`, {replace: true})
      } else {
        //console.log('do something here', res)
        return navigate(`/enroll/${client.reference_id}`, {replace: true})
      }
    } catch (err) {
      console.log('automateSwitchClient', err)
    }
  }

  const [active, setActive] = useState(() => {
    const storedValue = localStorage.getItem('activeMenuItem')
    return storedValue ? parseInt(storedValue, 10) : 0
  })

  const automateSwitchClientHandler = useCallback(
    async (client: IPortalClient, i: number) => {
      try {
        const res = await redirectProgramMutateAsync(client?.reference_id)
        if (res.api_token) {
          saveAuth({api_token: res.api_token})
          const {currentUser} = await getCurrentUser()
          setCurrentUser(currentUser)
          localStorage.setItem('activeMenuItem', i.toString())
          navigate(`/dashboard/${client.reference_id}`, {replace: true})
          window.location.reload()
        } else {
          localStorage.setItem('activeMenuItem', i.toString())
          navigate(`/dashboard/${client.reference_id}`, {replace: true})
          window.location.reload()
        }
      } catch (err) {
        console.log('automateSwitchClient', err)
      }
    },
    [saveAuth, getCurrentUser, setCurrentUser, navigate, redirectProgramMutateAsync]
  )

  const {width} = useWindowSize()

  const isMobile = width <= 992

  useEffect(() => {
    const getCurrentAuth = async () => {
      const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
      if (PrevAuth) {
        setHasAdmin(true)
      } else {
        setHasAdmin(false)
      }
    }
    getCurrentAuth()

    if (currentRoute !== undefined) {
      const activeIndex = response?.findIndex((c) => c.reference_id === currentRoute);
      setActive(activeIndex ?? 0)
    }
  }, [currentRoute, response]);

  const goBackToAdmin = async () => {
    await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY).then(async (res: any) => {
      removeAuth(PREV_AUTH_LOCAL_STORAGE_KEY)
      removeAuth(AUTH_LOCAL_STORAGE_KEY)
      const {email, first_name, last_name, ...rest} = res
      return await setAuthPromise(rest)
    }).then(() => {
      window.location.href = '/'
    })
  }

  return (
    <>
      {/* <MenuItem
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        to={`/dashboard/${currentRoute}`}
      /> */}
      {!isAdminOrSuperAdmin && response && response.length !== 0 && (
        <>
          
          <div className={`${isMobile ? 'position-relative' : 'isWeb'} d-flex`}>
            {hasAdmin && (
              <div onClick={goBackToAdmin} className='menu-item menu-lg-down-accordion me-lg-1'>
                <span className='menu-link py-3'>Switch Back</span>
              </div>
            )}
            {/* <MenuInnerWithSub
              title='Switch Client'
              to='/switch-client'
              menuPlacement='bottom-start'
              menuTrigger='click'
            >
              {response
                .sort((a: any, b: any) => a.id - b.id)
                .map((c, i) => {
                  if (c.name !== 'terraEducation' && c.name !== 'experienceGla') {
                    return (
                      <div className='menu-item me-lg-1' key={c.id}>
                        <div
                          className={`menu-link py-3 ${
                            active === i ? 'opacity-30 activeBtn' : ''
                          } `}
                          onClick={() => automateSwitchClientHandler(c, i)}
                        >
                          <span className='menu-icon'>
                            <KTSVG
                              path={'/media/icons/duotune/general/gen051.svg'}
                              className='svg-icon-2'
                            />
                          </span>
                          <span className='menu-title'>{`Switch Client to: ${c.abbreviation}`}</span>
                        </div>
                      </div>
                    )
                  }
                })}
            </MenuInnerWithSub> */}
          </div>
        </>
      )}
    </>
  )
}
