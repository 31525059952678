import {useEffect, useState} from 'react'
import {Formik, Form, ErrorMessage} from 'formik'
import {addFlight, getAllUsersClient, getFlightDropdowns} from '../../../../network/api'
import {UserSchema} from './Schemas/ValidationSchema'
import CustomInput from '../../../../_metronic/shared-components/CustomInput'
import CustomTextArea from '../../../../_metronic/shared-components/CustomTextArea'
import CustomCheckbox from '../../../../_metronic/shared-components/CustomCheckbox'
import CreateAirportModal from './Modal/CreateAirportModal'
import CreateAirlineModal from './Modal/CreateAirlineModal'
import CustomReactSelect from '../../../../_metronic/shared-components/CustomReactSelect'
import {useNavigate, useParams} from 'react-router-dom'
import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components'
import AsyncSelect from 'react-select/async'
// CSS
import './Styles/FlighPage.css'

interface CreateFlight {
  student_id: number
  program_product_id: number
  airline_id: number
  flight_type: string
  flight_number: string
  departure_airport_id: number
  departure_date: string
  departure_time: string
  arrival_airport_id: number
  arrival_date: string
  arrival_time: string
  notes?: string
  status_id: boolean
}

interface Product {
  id: number
  program_summary: string
  name: string
}

interface AddAirport {
  id: number
  city: string
  country: string
  code: string
}

interface AddAirline {
  id: number
  name: string
  code: string
}

const flight_type_optioin = [
  {
    value: 'Outbound Flight',
    label: 'Outbound Flight',
  },
  {
    value: 'Return Flight',
    label: 'Return Flight',
  },
]

const FlightAdd = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [products, setProducts] = useState<Product[]>([])
  const [airports, setAirports] = useState<AddAirport[]>([])
  const [airlines, setAirlines] = useState<AddAirline[]>([])
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<any[]>([])
  const navigate = useNavigate()
  const params = useParams()

  const initialValue: CreateFlight = {
    student_id: 0,
    program_product_id: 0,
    airline_id: 0,
    flight_type: '',
    flight_number: '',
    departure_airport_id: 0,
    departure_date: '',
    departure_time: '',
    arrival_airport_id: 0,
    arrival_date: '',
    arrival_time: '',
    notes: '',
    status_id: false,
  }

  const onSubmit = async (values: CreateFlight, actions: any) => {
    try {
      const modifiedValues = {
        ...values,
        program_product_id: Number(values.program_product_id),
        airline_id: Number(values.airline_id),
        departure_airport_id: Number(values.departure_airport_id),
        arrival_airport_id: Number(values.arrival_airport_id),
      }

      if (modifiedValues.departure_time) {
        modifiedValues.departure_time = `${modifiedValues.departure_time}:00`
      }
      if (modifiedValues.arrival_time) {
        modifiedValues.arrival_time = `${modifiedValues.arrival_time}:00`
      }

      await addFlight(modifiedValues as CreateFlight)
      setSuccessMessage('Flight added successfully')

      setTimeout(() => {
        navigate(`/apps/${params.client}/flight-management/flights`)
      }, 3000)
    } catch (error: any) {
      if (error.response.status === 500) {
        return setErrorMessage(
          'There is no opportunity with provided student and product info. Please try again.'
        )
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else if (error.response.status === 403) {
        return setErrorMessage('Need client id provided.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else setErrorMessage('Something went wrong, please try again.')
    } finally {
      ScrollTopComponent.goTop()
    }
  }

  const getFlightDropdown = async () => {
    try {
      const response = await getFlightDropdowns()
      const {products, airports, airlines} = response.data

      if (Array.isArray(products)) {
        setProducts(products as Product[])
      } else {
        console.log('Invalid products data structure')
      }

      if (Array.isArray(airports)) {
        setAirports(airports as AddAirport[])
      } else {
        console.log('Invalid airports data structure')
      }

      if (Array.isArray(airlines)) {
        setAirlines(airlines as AddAirline[])
      } else {
        console.log('Invalid airlines data structure')
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getFlightDropdown()
    getAllUsers()
  }, [])

  const getAllUsers = async () => {
    try {
      const page = 1
      const items_per_page = 10000
      const {data} = await getAllUsersClient(page, items_per_page)
      console.log(data)

      setUsers(data)
    } catch (error) {
      console.log(error, 'No users found')
    }
  }

  return (
    <>
      {successMessage && (
        <div className='card-body'>
          <div className='alert alert-success d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{successMessage}</span>
            </div>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      <div className='card mb-5 mb-xl-8'>
        <div className='card-body'>
          <div className='card'>
            {loading ? (
              <span
                className='indicator-progress'
                style={{display: 'flex', justifyContent: 'center'}}
              >
                <span
                  style={{height: '40px', width: '40px'}}
                  className='spinner-border spinner-border-sm align-middle ms-2'
                ></span>
              </span>
            ) : (
              <Formik
                initialValues={initialValue}
                validationSchema={UserSchema}
                onSubmit={onSubmit}
              >
                {({isSubmitting, setFieldValue}) => {
                  const loadOptions = (inputValue: string, callback: any) => {
                    if (inputValue.trim().length === 0) {
                      callback([])
                      return
                    }

                    const filteredUsers = users.filter((user: any) => {
                      const fullName = `${user.userclient.map(
                        (item: any) => item.first_name
                      )} ${user.userclient.map((item: any) => item.last_name)}`.toLowerCase()
                      const value = user.email.toString()
                      return (
                        fullName.includes(inputValue.toLowerCase()) || value.includes(inputValue)
                      )
                    })

                    const options = filteredUsers.map((user: any) => ({
                      value: user?.id,
                      label: `${user.userclient.map(
                        (item: any) => item.first_name
                      )} ${user.userclient.map((item: any) => item.last_name)} <${user.email}>`,
                    }))

                    callback(options)

                    if (options.length > 0) {
                      const selectedOption = options[0]
                      setFieldValue('student_id', selectedOption.value)
                    }
                  }

                  return (
                    <Form>
                      <div className='fv-row mb-7'>
                        <h2>Student</h2>
                      </div>

                      <div className='fv-row mb-7'>
                        <label
                          htmlFor='student_id'
                          className='flex-grow-1 required fw-bold fs-6 mb-2'
                        >
                          Student
                        </label>
                        <AsyncSelect
                          loadOptions={loadOptions}
                          inputId='student_id'
                          name='student_id'
                          placeholder='Student Name'
                          isClearable
                        />
                        <ErrorMessage name='student_id' />
                      </div>

                      <CustomReactSelect
                        label='Programs'
                        name='program_product_id'
                        placeholder='Select a program'
                        options={products.map((option: Product) => ({
                          value: option.id,
                          label: `${option.program_summary}`,
                        }))}
                      />

                      <div className='fv-row mb-7'>
                        <h2>Flight</h2>
                      </div>

                      <CustomReactSelect
                        label='Airlines'
                        name='airline_id'
                        placeholder='Select Airline'
                        options={airlines.map((airline: AddAirline) => ({
                          value: airline.id,
                          label: `${airline.code} - ${airline.name}`,
                        }))}
                      />

                      <CreateAirlineModal />

                      <CustomInput
                        label='Flight Number'
                        name='flight_number'
                        placeholder='Enter a Flight Number'
                      />

                      <CustomReactSelect
                        label='Flight Type'
                        name='flight_type'
                        placeholder='Select a Flight Type'
                        options={flight_type_optioin.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      />

                      <div className='fv-row mb-7'>
                        <h2>Flight Departure Details</h2>
                      </div>

                      <CustomReactSelect
                        label='Departure Airport'
                        name='departure_airport_id'
                        placeholder='Select a departure airport'
                        options={airports.map((airport) => ({
                          value: airport.id,
                          label: `${airport.code} - ${airport.country}, ${airport.city}`,
                        }))}
                      />

                      <CreateAirportModal />

                      <CustomInput
                        label='Flight Departure Date'
                        name='departure_date'
                        placeholder='YYYY-MM-DD'
                      />

                      <CustomInput
                        label='Flight Departure Time'
                        name='departure_time'
                        placeholder='HH:MM'
                      />

                      <div className='fv-row mb-7'>
                        <h2>Flight Arrival Details</h2>
                      </div>

                      <CustomReactSelect
                        label='Arrival Airport'
                        name='arrival_airport_id'
                        placeholder='Arrival Airport'
                        options={airports.map((airport) => ({
                          value: airport.id,
                          label: `${airport.code} - ${airport.country}, ${airport.city}`,
                        }))}
                      />

                      <CustomInput
                        label='Flight Arrival Date'
                        name='arrival_date'
                        placeholder='YYYY-MM-DD'
                      />

                      <CustomInput
                        label='Flight Arrival Time'
                        name='arrival_time'
                        placeholder='HH:MM'
                      />

                      <div className='fv-row mb-7'>
                        <h2>Notes</h2>
                      </div>

                      <CustomTextArea
                        label='Notes'
                        name='notes'
                        placeholder='Notes'
                        maxLength={255}
                      />

                      <CustomCheckbox label='Active' type='checkbox' name='status_id' />

                      <div className='text-center pt-15'>
                        <button
                          disabled={isSubmitting}
                          type='submit'
                          className='btn btn-primary cursor-pointer'
                          data-kt-users-modal-action='submit'
                        >
                          <span className='indicator-label'>Submit</span>
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default FlightAdd
