import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useAuth} from '../../auth'
import AddClientPage from './AddClientPage'
import ClientsList from './components/ClientsList'
import ClientsProvider from './components/PortalClientsProvider'
import UpdateClientPage from './UpdateClientPage'

const ClientsPage = () => {
  const {currentUser} = useAuth()
  const clientsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Clients Management',
      path: `/apps/${currentUser?.currentClient}/clients-management/clients`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <ClientsProvider>
            <Outlet />
          </ClientsProvider>
        }
      >
        <Route
          path='clients'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Clients</PageTitle>
              <ClientsList />
            </>
          }
        />
        <Route
          path='edit/:clientId'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>Edit Client</PageTitle>
              <UpdateClientPage />
            </>
          }
        />

        <Route
          path='new'
          element={
            <>
              <PageTitle breadcrumbs={clientsBreadcrumbs}>New Client</PageTitle>
              <AddClientPage />
            </>
          }
        />
        <Route
          index
          element={
            <Navigate to={`/apps/${currentUser?.currentClient}/clients-management/clients`} />
          }
        />
      </Route>
    </Routes>
  )
}

export default ClientsPage
