import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IEnrollmentExtras, categories, clients } from './model'
import { addOrEditEnrollmentExtras, fetchEnrollmentExtrasById } from '../../../../network/api'
import CustomInput from '../../../../_metronic/shared-components/CustomInput'
import CustomReactSelect from '../../../../_metronic/shared-components/CustomReactSelect'
import { Form, Formik } from 'formik'
import { EnrollmentGroupSchema } from '../enrollment-group/Schemas/ValidationSchema'
import CustomCheckbox from '../../../../_metronic/shared-components/CustomCheckbox'
import { EnrollmentExtraSchema } from './Schemas/EnrollmentExtrasSchema'
import { codeMessage } from '../../../../network/codeMessage'

const EnrollmentExtrasEdit = () => {

  const [formData, setFormData] = useState({} as IEnrollmentExtras)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {enrollment_extras_id, client} = useParams()
  const navigate = useNavigate()

  const initialValues = {
    name: formData?.name,
    type: formData?.type,
    category: formData?.category,
    amount: formData?.amount,
    default_client: formData?.default_client,
    status_id: formData?.status_id === 14 ? true : false,
    qb_full: formData?.qb_full,
  }


  useEffect(() => {
    setLoading(true)
    const fetchFormData = async () => {
      const data = await fetchEnrollmentExtrasById(enrollment_extras_id)
      setLoading(false)
      setFormData(data)
    }

    fetchFormData()
  }, [enrollment_extras_id])

  const onSubmit = async (values: IEnrollmentExtras) => {
    try {
      const modifiedValues = {
        ...values,
        category: Number(values.category),
        amount: Number(values.amount),
        status_id: values?.status_id === true ? 14 : 15
      }
      const res = await addOrEditEnrollmentExtras(modifiedValues, enrollment_extras_id ? enrollment_extras_id : null);
      const Success = codeMessage[res.status]
      setSuccessMessage(Success)

      setTimeout(() => { 
        navigate(`/apps/${client}/enrollment-extras-management/enrollment-extras`)
      }, 3000)
    } catch (error: any) {
      const errorMsg = codeMessage[error.response.status]
      setErrorMessage(errorMsg)

      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  return (
    <>
    {successMessage && (
      <div className='card-body'>
        <div className='alert alert-success d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span>{successMessage}</span>
          </div>
        </div>
      </div>
    )}

    {errorMessage && (
      <div className='card-body'>
        <div className='alert alert-danger d-flex align-items-center p-5'>
          <div className='d-flex flex-column '>
            <span>{errorMessage}</span>
          </div>
        </div>
      </div>
    )}

    {loading ? (
    <span className='indicator-progress' style={{display: 'flex', justifyContent: 'center'}}>
      <span style={{height: '40px', width: '40px'}} className='spinner-border spinner-border-sm align-middle mt-20' /> 
    </span>
    ) : (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-body'>
        <div className='card'>
          <Formik
            initialValues={initialValues}
            validationSchema={EnrollmentExtraSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({isSubmitting}) => {
              return (
                <Form>
                <CustomInput label='Name' name='name' placeholder='Enter a Name' />

                <CustomInput label='Type' name='type' placeholder='Enter Type' />

                <CustomReactSelect
                  label='Client'
                  name='default_client'
                  required
                  placeholder='Select a Client'
                  options={clients.map((item) => ({
                  value: item.value,
                  label: item.label,
                  name: item.name,
                  }))}
                />

                  <CustomReactSelect
                    label='Category'
                    name='category'
                    required
                    placeholder='Select a category'
                    options={categories.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                  />
                <CustomInput label='Amount' name='amount' placeholder='Enter an Amount' />
                <CustomInput label='QB Full Name' name='qb_full' placeholder='Enter QB Full Name' />

                <CustomCheckbox label='Status' type='checkbox' name='status_id' />

                <div className='text-center pt-15'>
                  <button
                    disabled={isSubmitting}
                    type='submit'
                    className='btn btn-primary cursor-pointer'
                    data-kt-users-modal-action='submit'
                  >
                    <span className='indicator-label'>Update</span>
                  </button>
                </div>
              </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
    )}
  </>
  )
}

export default EnrollmentExtrasEdit