import React from 'react'
import {useAuth} from '../../auth'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import TypeFormList from './TypeFormList'
import TypeFormAdd from './TypeFormAdd'
import TypeFormEdit from './TypeFormEdit'

const TypeForm = () => {
    const {currentUser} = useAuth()
  
    const enrollmentFormBreadCrumbs: Array<PageLink> = [
      {
        title: 'TypeForm List',
        path: `/apps/${currentUser?.currentClient}/typeform-management/typeform`,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]
    
    return (
        <Routes>
          <Route
            element={
              <UsersProvider>
                <Outlet />
              </UsersProvider>
            }
          >
              <Route
                path='typeform'
                element={
                  <>
                    <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>TypeForm List</PageTitle>
                    <TypeFormList />
                  </>
                }
              />
              <Route
                path='add-typeform'
                element={
                  <>
                    <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>Add TypeForm</PageTitle>
                    <TypeFormAdd />
                  </>
                }
              />
              <Route
                path='edit-typeform/:typeform_id'
                element={
                  <>
                    <PageTitle breadcrumbs={enrollmentFormBreadCrumbs}>Edit TypeForm</PageTitle>
                    <TypeFormEdit />
                  </>
                }
              />
          </Route>
        </Routes>
    )
}

export default TypeForm