import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useAuth} from '../../auth'
import EmailTemplateUpdate from './EmailTemplateUpdate'

const EmailMain = () => {
  const {currentUser} = useAuth()
  const ReportsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Emails',
      path: `/apps/${currentUser?.currentClient}/email-template-management/email-template`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <Outlet />
        }
      >
        <Route
          path='email-template'
          element={
            <>
              <PageTitle breadcrumbs={ReportsBreadcrumbs}>Email Template</PageTitle>
              <EmailTemplateUpdate />
            </>
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate to={`/apps/${currentUser?.currentClient}/products-management/products`} />
        }
      />
    </Routes>
  )
}

export default EmailMain
