import React from 'react'
import {useAuth} from '../../auth'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import CouponCodeList from './CouponCodeList'
import CouponCodeAdd from './CouponCodeAdd'
import CouponCodeEdit from './CouponCodeEdit'

const CouponCodes = () => {
  const {currentUser} = useAuth()

  const CouponCodesBreadCrumbs: Array<PageLink> = [
    {
      title: 'Coupon Code',
      path: `/apps/${currentUser?.currentClient}/coupon-code-management/coupon-code-list`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='coupon-code-list'
          element={
            <>
              <PageTitle breadcrumbs={CouponCodesBreadCrumbs}>Coupon Code List</PageTitle>
              <CouponCodeList />
            </>
          }
        />
        <Route
          path='add-coupon-code'
          element={
            <>
              <PageTitle breadcrumbs={CouponCodesBreadCrumbs}>Add Coupon Code</PageTitle>
              <CouponCodeAdd />
            </>
          }
        />
        <Route
          path='edit-coupon-code-list/:coupon_code_id'
          element={
            <>
              <PageTitle breadcrumbs={CouponCodesBreadCrumbs}>Edit Coupon Code</PageTitle>
              <CouponCodeEdit />
            </>
          }
        />
      </Route>
      <Route
        index
        element={<Navigate to={`/apps/${currentUser?.currentClient}/coupon-code-management/coupon-code-list`} />}
      />
    </Routes>
  )
}

export default CouponCodes
