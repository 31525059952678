import {useListView} from '../core/ProductsListViewProvider'
import {ProductListToolbar} from './ProductListToolbar'
import {ProductListSearchComponent} from './ProductListSearchComponent'

const ProductListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ProductListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<ProductListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ProductListHeader}
