import React from 'react';
import './ProgramDetails.scss'
import { Alert } from 'react-bootstrap';
interface ProgramData {
  id: number;
  name: string;
  programData: {
    campus: string;
    segment: string;
    duration: string;
    countries: string;
    surcharge: string;
    focusAreas: string;
    description: string;
    schoolLevel: string;
    subFocusAreas: string;
    tuitionOption: string;
    commuterOption: string;
    experienceType: string;
    surchargeAmount: string;
    commuterDiscount: string;
  };
  newPrice: number;
  createdDate: string;
  lastModifiedDate: string;
  uniqueIdentifier: string;
  sessions: any[];
  historicalPricings: any[];
  SpecialRequiredForms: any[];
  images: {
    id: number;
    imageUrl: string;
    programId: number;
    header: string;
    body: string;
  }[];
  highlights: any[];
}

interface ProgramDetailsProps {
  program: ProgramData;
  campus: string | null;
  selectedProgram?: boolean
}

const ProgramDetails: React.FC<any> = ({ selectedProgram, program }) => {

  return (
    <div className="card-body">
      <div className="card-text">
        <div className='text-center'>
          <strong className='text fs-5'>{program.name}</strong>
        </div>
      </div>
    </div>
  );
};

export default ProgramDetails;
