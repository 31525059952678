import React, { useCallback, useEffect, useState } from 'react';
import { axiosPrivate } from '../../axiosInstance';
import './SelectStudentProfile.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import {useAuth, PREV_AUTH_LOCAL_STORAGE_KEY, getAuthPromise} from '../../app/modules/auth'
import { getAllProgram, getAllTypeForm, getAllTypeFormData } from '../../network/api';
import moment from 'moment';
import { debounce } from 'lodash';

interface StudentTypeProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

interface TypeformData {
    id: number;
    form_id: string;
    response_type: string;
    name: string;
    overview: string;
  }

const FormsPage: React.FC<StudentTypeProps> = ({ formData, setFormData }) => {
    const [message, setMessage] = useState('');
    const navigate = useNavigate()
    const {currentUser} = useAuth()

    const [getFormsByProgram, setFormsByProgram] = useState<{ [key: number]: number[] }>({});
    const [getTypeForm, setTypeform] = useState<TypeformData[]>([]);
    const [getTypeFormData, setTypeFormData] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [hasAdmin, setHasAdmin] = useState<any>(null)

    useEffect(() => {
        const getCurrentAuth = async () => {
        const PrevAuth = await getAuthPromise(PREV_AUTH_LOCAL_STORAGE_KEY)
        if (PrevAuth) {
            setHasAdmin(true)
        } else {
            setHasAdmin(false)
        }
        }
        getCurrentAuth()
    }, []);

    const fetchData = useCallback(
        debounce(async () => {
          try {
            setIsLoading(true);
    
            // Fetch both typeform and typeform data in parallel
            const [typeformRes, typeformDataRes] = await Promise.all([
              getAllTypeForm(),
              getAllTypeFormData(),
            ]);
    
            const { data: typeformData } = typeformRes;
            const categoryForms = typeformData.filter((form: { category: string }) => form.category === "application");
            
            const studentIds = Array.isArray(formData.student_contact_id) ? formData.student_contact_id : [formData.student_contact_id];
            if (studentIds.length === 0) {
              console.error('No student IDs found.');
              return;
            }
    
            const formIds = categoryForms.map((form: { id: any }) => form.id);
            const formsForStudent = studentIds.reduce(
              (acc: { [key: string]: number[] }, studentId: number) => {
                acc[studentId] = formIds;
                return acc;
              }, {}
            );
    
            setTypeform(categoryForms);
            setFormsByProgram(formsForStudent);
    
            const typeformDataList = typeformDataRes.data;
            const matchedTypeform = typeformDataList.filter(
              (form: { student_uuid: string }) => String(form.student_uuid) === String(formData.student_uuid)
            );
            setTypeFormData(matchedTypeform);
    
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setIsLoading(false);
          }
        }, 300),
        [formData]
      );
    
      useEffect(() => {
        fetchData();
        return () => {
          fetchData.cancel()
        };
      }, [fetchData]);

    const navigateForms = async (student_uuid: string, id: any, program_uuid: string, form_id: string, completedForms: string[], sf_id: string) => {
        try {
        setIsLoading(true);
        const data  = getTypeForm

        const formIdToFormId = data.reduce((acc: { [key: number]: string }, form: any) => {
            acc[form.id] = form.form_id;
            return acc;
        }, {});

        const matchedForm = data.find((form: any) => form.id === form_id);

        if (!matchedForm) {
            console.error('No matching form_id found.')
            return;
        }

        const formsForProgram = getFormsByProgram[id] || []
        const completedFormsSet = new Set(completedForms.map(id => Number(id)));
        const filterIncompleteForms = formsForProgram.filter((formId: number) => !completedFormsSet.has(formId));
        const currentForm = matchedForm.form_id
        const remainingForms = filterIncompleteForms
            .map(formId => formIdToFormId[formId])
            .filter(form => form && form !== currentForm)

        navigate(`/dashboard/${currentUser?.currentClient}/forms/${currentForm}`, { replace: true, state: {
            student_uuid: student_uuid,
            program_uuid: program_uuid,
            form_id: currentForm,
            remaining_forms: remainingForms,
            auth: currentUser?.currentClient,
            rawTypeformData: data,
            formsPage: true,
            sf_id
        }})
        } catch (error) {
        console.error("Error handling navigation:", error);
        } finally {
        setIsLoading(false);
        }
    }

    const getCompletedForms = getTypeFormData
        .filter((item: any) => item.response_type === 'Submitted - Needs Review')
        .map((item: any) => item.form_id)

    const uniqueForm = Array.from(
        new Map(getTypeForm.map((form) => [form.form_id, {
        id: form.id,
        form_id: form.form_id,
        name: form.name,
        overview: form.overview
        }])).values()
    );

    const campus = formData?.decodedProgram
    const session = `${formData?.selectedSession?.session_name} (${moment.utc(formData?.selectedSession?.start_date).format('MM/DD/YYYY')} - ${moment.utc(formData?.selectedSession?.end_date).format('MM/DD/YYYY')}) ${formData?.selectedProgram?.name}`

    return (
			<>
				<h2 className='mx-3' style={{ margin: '45px 0 30px' }}>{campus}: {session}</h2>
				<div className='flex-grow-1 mx-3' style={{ alignSelf: 'flex-start' }}>
					{isLoading ? (
						<div className="spinner-border" role="status">
								<span className="sr-only">Loading...</span>
						</div>
					) : getTypeForm.length === 0 ? (
							<div style={{ textAlign: 'center', background: '#f8d7da', color: '#721c24', padding: '10px'}}>
									Forms missing, Please contact the administrator.
							</div>
					) : (
						<table className='table table-bordered'>
							<thead>
									<tr>
											<th>Section</th>
											<th>Task Name</th>
											<th>Overview</th>
											<th>Status</th>
											<th style={{ width: 70 }}>Action</th>
									</tr>
							</thead>
							<tbody style={{ border: '1px solid #dee2e6' }}>
								{isLoading ? (
										<tr>
												<td colSpan={6} style={{ textAlign: 'center' }}>
														<div className="spinner-border" role="status">
																<span className="sr-only">Loading...</span>
														</div>
												</td>
										</tr>
								) : (
												<>
														{uniqueForm.map((typeform: any, index: number) => {
																const form_id = typeform.id; 
																const correspondingTypeform = Array.isArray(getTypeFormData)
																		? getTypeFormData.find((typeform: { form_id: any; }) => typeform.form_id === form_id)
																		: null;
																
																const isSubmitted = correspondingTypeform ? correspondingTypeform.response_type === 'Submitted - Needs Review' : false;
																const isCompleted = correspondingTypeform ? correspondingTypeform.response_type === 'Completed' : false;

																let status = 'Incomplete';
																if (isCompleted) status = 'Completed';
																else if (isSubmitted) status = 'Submitted - Needs Review';

																return (
																		<tr key={index}>
																		{index === 0 && (
																				<td rowSpan={getTypeForm.length} style={{ verticalAlign: "middle", background: '#ccc', textAlign: 'center' }}>
																				Application Form
																				</td>
																				)}
																				<td className='px-1'>{typeform?.name || 'N/A'}</td>
																				<td>{typeform?.overview || 'N/A'}</td>
																				<td 
																						className={`${status === 'Incomplete' ? 'incomplete' : status === 'Completed' ? 'completed' : 'submitted'}`}
																						onClick={status === 'Incomplete' ? () => navigateForms( 
																						formData.student_uuid, 
																						formData.student_contact_id, 
																						formData.program_uuid, 
																						form_id!,
																						getCompletedForms,
																						formData?.contact_sf_id
																						) : undefined}
																				>
																						{status}
																				</td>
																				<td style={{ textAlign: 'center'}}>
																						{!hasAdmin && status === 'Incomplete' && (
																						<Button 
																								variant="primary"
																								style={{ width: '100px' }}
																								onClick={() => navigateForms(
																								formData.student_uuid, 
																								formData.student_contact_id,
																								formData.program_uuid, 
																								form_id!,
																								getCompletedForms,
																								formData?.contact_sf_id
																						)}
																						>
																								Submit
																						</Button>
																						)}
																				</td>
																		</tr>
																);
														})}
												</>
										)
								}
							</tbody>
						</table>
					)}
				</div>
			</>
    );
};

export default FormsPage;
