import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { addOrEditEnrollmentForm, getAllEnrollmentFormGroup, getEnrollmentFormById } from '../../../../network/api'
import { FormikValues } from 'formik'
import { EnrollmentFormSchema } from './Schemas/ValidationSchema'
import CustomForm from './components/CustomForm'
import { IEnrollmentForm } from './data'

const EnrollmentFormEdit = () => {
  const [formData, setFormData] = useState<IEnrollmentForm>({})
  const [enrollmentGroup, setEnrollmentGroup] = useState<any>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const {enrollment_form_id, client} = useParams()
  const navigate = useNavigate()

  const initialValues = {
    client_id: formData?.client_id,
    enrollment_form_group_id: formData?.enrollment_form_group_id,
    name: formData?.name,
    type: formData?.type,
    field_type: formData?.field_type,
    label: formData?.label,
    helper_text: formData?.helper_text || '',
    required: formData?.required,
    choices: formData?.choices || '',
    status: formData?.status,
  }

  useEffect(() => {
    setLoading(true)
    const fetchFormData = async () => {
      const form: any = await getEnrollmentFormById(enrollment_form_id)
      const group: any = await getAllEnrollmentFormGroup(client)
      const sortGroup = group.data.sort((a: any, b: any) => b.id - a.id)
      setFormData(form.data)
      setLoading(false)
      setEnrollmentGroup(sortGroup)
    }
    fetchFormData()
  }, [enrollment_form_id])


  const onSubmit = async (values: IEnrollmentForm, actions: FormikValues) => {
    try {
      await addOrEditEnrollmentForm(values, formData.id)
      setSuccessMessage('Enrollment Form Group added successfully')
    
      setTimeout(() => {
        navigate(`/apps/${client}/enrollment-form-management/enrollment-form`)
      }, 3000)
      
    } catch (error: any) {
      if (error.response.status === 500) {
        return setErrorMessage('Something went wrong, please try again.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else if (error.response.status === 403) {
        return setErrorMessage('Need client id provided.')
      } else if (error.response.status === 400) {
        return setErrorMessage('Something went wrong while processing the request!')
      } else setErrorMessage('Something went wrong, please try again.')
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
  }

  if (loading) {
    return (
      <span className='indicator-progress' style={{display: 'flex', justifyContent: 'center'}}>
        <span
          style={{height: '40px', width: '40px'}}
          className='spinner-border spinner-border-sm align-middle mt-20'
        ></span>
      </span>
    )
  }
  return (
    <>
      {successMessage && (
          <div className='card-body'>
            <div className='alert alert-success d-flex align-items-center p-5'>
              <div className='d-flex flex-column '>
                <span>{successMessage}</span>
              </div>
            </div>
          </div>
        )}

      {errorMessage && (
        <div className='card-body'>
          <div className='alert alert-danger d-flex align-items-center p-5'>
            <div className='d-flex flex-column '>
              <span>{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      <CustomForm
        initialValues={initialValues}
        EnrollmentFormSchema={EnrollmentFormSchema}
        onSubmit={onSubmit}
        enrollmentGroup={enrollmentGroup}
      />
    </>
  )
}

export default EnrollmentFormEdit