import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../axiosInstance';
import ProgramDetails from './ProgramDetails';
import './ProgramDetails.scss';
import { Form, Formik } from 'formik';
import { Alert } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ProgramData {
  id: number;
  name: string;
  programData: {
    campus: string;
    segment: string;
    duration: string;
    countries: string;
    surcharge: string;
    focusAreas: string[];
    description: string;
    schoolLevel: string;
    subFocusAreas: string;
    tuitionOption: string;
    commuterOption: string;
    experienceType: string;
    surchargeAmount: string;
    commuterDiscount: string;
    supplement?: any;
  };
  newPrice: number;
  createdDate: string;
  lastModifiedDate: string;
  uniqueIdentifier: string;
  sessions: any[];
  historicalPricings: any[];
  SpecialRequiredForms: any[];
  images: {
    id: number;
    imageUrl: string;
    programId: number;
    header: string;
    body: string;
  }[];
  highlights: any[];
}

interface Course {
  experienceTypeId: number;
  id: number;
  name: string;
}

const Campus = ({ formData, setFormData }: any) => {
  const [programs, setPrograms] = useState<ProgramData[]>([]);
  const [focus, setFocus] = useState<Course[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<any>(formData.selectedProgram || null);
  const [unfilteredPrograms, setUnfilteredPrograms] = useState<ProgramData[]>([]);
  const { campus } = useParams<{ campus: string }>();
  const navigate = useNavigate();
  const uuidKey = 'program_uuid';
  const storedUUID = sessionStorage.getItem(uuidKey);

  const decodedProgram = campus ? decodeURIComponent(campus) : '';

  useEffect(() => {
    fetchProgramByCampusName();
    fetchFocus();

    // Generate or retrieve the program UUID and update form data
    const program_uuid = storedUUID || generateUUID();
    setFormData((prevData: any) => ({ ...prevData, program_uuid }));

  }, []);

  useEffect(() => {
    setSelectedProgram(formData.selectedProgram);
  }, [formData.selectedProgram]);

  useEffect(() => {
    const programsInFocus = focus.reduce((acc, course) => {
      return acc.concat(programs.filter(program => program.programData.focusAreas.includes(course.name)));
    }, [] as ProgramData[]);

    const remainingPrograms = programs.filter(program => !programsInFocus.includes(program));
    setUnfilteredPrograms(remainingPrograms);
  }, [focus, programs]);

  const fetchProgramByCampusName = async () => {
    try {
      const { data } = await axiosPrivate.get(`/terra-programs/api/campus/${decodedProgram}`);
      data.data.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));
      setPrograms(data.data);
    } catch (error) {
      console.error("Error fetching program data:", error);
    }
  };

  const fetchFocus = async () => {
    try {
      const response = await axiosPrivate.get(`/terra-programs/api/focus-area`);
      if (response && Array.isArray(response.data.data.data)) {
        setFocus(response.data.data.data);
      } else {
        console.error('Fetched data is not an array:', response.data.data.data);
      }
    } catch (error) {
      console.error("Error fetching focus areas:", error);
    }
  };

  const generateUUID = () => {
    const newUUID = uuidv4();
    sessionStorage.setItem(uuidKey, newUUID);
    return newUUID;
  };
  const notify = async (values: string) => {
    toast.warn(values);
  };
  const handleCardClick = async (program: ProgramData) => {
    toast.dismiss();
    const newSelectedProgram = selectedProgram === program ? null : program;
    setSelectedProgram(newSelectedProgram);
    setFormData({ ...formData, selectedProgram: newSelectedProgram });

    // Remove other session storage keys
    sessionStorage.removeItem('selectedExtras');
    sessionStorage.removeItem('selectedAppType');
    sessionStorage.removeItem('selectedParent');
    sessionStorage.removeItem('selectedStudent');
    if(newSelectedProgram != null) {
      const { id, name } = program;
      const { data } = await axiosPrivate.get(`/get-waitlist/${id}`);
      const { sessions } = data;
      sessionStorage.setItem('waitlistData', JSON.stringify(data));
      const isCampusFull = sessions.some( (item: any) => item.isResidentialFull === true && item.isCommuterFull === true);
      if(isCampusFull) {
        notify(`We are excited to have you join this summer!
          Unfortunately at this stage, our ${name} is fully enrolled,
          however you may continue your application in order to join our waitlist.
          No application fee / deposit will be charged. If a spot becomes available,
          we will contact you in order to confirm your continued interest prior to enrolling you.
          In addition, we suggest that you separately apply to your second choice for the upcoming summer,
          so as not to miss out on what will be an incredible experience. We look forward to seeing you this summer!`)
      }
    }
  };

  const handleSubmit = (values: any) => {
    const selectedProgramDetails = programs.find(program => program.id === values.selectedProgram?.id);
    navigate('/next-page', { state: selectedProgramDetails });
  };

  return (
    <Formik
      initialValues={{ selectedProgram: formData.selectedProgram || null }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
        <ToastContainer style={{ width: "90vw" }}
              position="top-center"
              autoClose={false}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          <div className='mx-3 mt-5'>
            <h2 style={{ position: 'fixed', background: 'white', top: 75, zIndex: 100, paddingBottom: 20 }} className='w-100'>{decodedProgram}</h2>
            <div style={{ paddingTop: 70}}>
              {focus.map(course => {
                const filteredPrograms = programs.filter(program => 
                  program.programData.focusAreas.includes(course.name)
                );
                
                if (filteredPrograms.length === 0) {
                  return null;
                }

                return (
                  <div key={course.id} className="focus-area-section my-3">
                    <h3 style={{ background: '#2d9bf0', color: 'white' }} className='border text-center w-100 rounded p-2 mx-auto'>{course.name}</h3>
                    <div className='focus-area-container my-5'>
                      {filteredPrograms.map(filteredProgram => {
                        console.log(selectedProgram, 'selectedProgram')
                        return (
                          <div className='programs-container'>
                            <div key={filteredProgram.id} className={`programs-list ${selectedProgram?.id === filteredProgram.id ? 'selected' : ''}`} 
                              onClick={() => {
                                handleCardClick(filteredProgram);
                                setFieldValue("selectedProgram", selectedProgram === filteredProgram ? null : filteredProgram);
                              }}
                            >
                              <ProgramDetails selectedProgram={selectedProgram === filteredProgram.id} program={filteredProgram} campus={campus || ''} />
                            </div>
                            {(selectedProgram?.id === filteredProgram.id && 
                              Object.keys(filteredProgram?.programData?.supplement).length > 0) && (
                              <Alert variant='danger'>This course has a mandatory supplement fee of $250 which will be added.</Alert>
                            )}
                            {(selectedProgram?.name === 'Interior Design 2' && selectedProgram?.name === filteredProgram.name) && (
                              <Alert style={{ fontSize: 12 }} variant='danger'>Please note: Interior Design 1 is a 
                              prerequisite for Interior Design 2. You must have taken 
                              Interior Design 1 at NYSID before you can enroll in Interior Design 2.</Alert>
                            )}
                          </div>
                        )})}
                    </div>
                  </div>
                );
              })}

              {unfilteredPrograms.length > 0 && (
                <div className="focus-area-section mx-5">
                  <h3 style={{ background: '#e6e6e6'}} className='border text-center w-100 rounded p-2 mx-auto'>Others</h3>
                  <div className='my-5 focus-area-container'>
                    {unfilteredPrograms.map(program => (
                      <div
                        key={program.id}
                        className={`programs-list ${selectedProgram?.id === program.id ? 'selected' : ''}`}
                        onClick={() => {
                          handleCardClick(program);
                          setFieldValue("selectedProgram", selectedProgram === program ? null : program);
                        }}
                      >
                        <ProgramDetails program={program} campus={campus || ''} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Campus;
