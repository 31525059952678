import {AxiosResponse} from 'axios'
import { ID } from '../../../../../_metronic/helpers'
import { IPaymentSchedule } from '../../../application/core/_models'
import useAxiosPrivate from '../../../auth/hooks/useAxiosPrivate'
import {PaymentSchedulesQueryResponse} from '../components/core/_models'


export const usePaymentSchedulesRequests = () => {
  const axisosPrivate = useAxiosPrivate()
  const getAllPaymentSchedulesReq = (query: string): Promise<PaymentSchedulesQueryResponse> => {
    return axisosPrivate
      .get(`/fetch_all_payment_schedules/?${query}`)
      .then((d: AxiosResponse<PaymentSchedulesQueryResponse>) => d.data)
  }

  const getPaymentScheduleDetailReq = async (id: string | undefined) => {
    if (!id) {
      return
    }
    const {data} = await axisosPrivate.get<{
      data: IPaymentSchedule
    }>(`/fetch_payment_schedule/${id}`)
    return data
  }
  const savePaymentScheduleDetailReq = async (inputs: any) => {
    const {data} = await axisosPrivate.post(`/save_payment_schedule`, inputs)
    return data
  }
  const addPaymentScheduleDetailReq = async (inputs: any) => {
    const {data} = await axisosPrivate.post(`/add_payment_schedule`, inputs)
    return data
  }
  const deletePSReq = (paymentScheduleId: ID): Promise<void> => {
    return axisosPrivate
      .delete(`/deactive_payment_schedule/${paymentScheduleId}`)
      .then(() => {})
  }
  return {
    getAllPaymentSchedulesReq,
    getPaymentScheduleDetailReq,
    savePaymentScheduleDetailReq,
    addPaymentScheduleDetailReq,
    deletePSReq,
  }
}
