import { QueryRequestProvider } from '../../../Shared/core/QueryRequestProvider'
import {ClientsViewProvider} from './core/ClientsListViewProvider'

import {QueryResponseProvider} from './core/QueryResponseProvider'

type Props = {
  children: JSX.Element
}

const PortalClientsProvider = ({children}: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ClientsViewProvider>{children}</ClientsViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default PortalClientsProvider
