import moment from 'moment';
import { useState, useEffect } from 'react';
import './SessionPage.scss';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Session {
  id: number;
  startDate: string;
  endDate: string;
}

const SessionPage = ({ formData, setFormData }: any) => {
  const [selectedSession, setSelectedSession] = useState<Session | null>(formData.selectedSession || null);
  const { campus } = useParams<{ campus: string }>();
  const decodedProgram = campus ? decodeURIComponent(campus) : '';
  useEffect(() => {
    if (selectedSession) {
      setFormData((prevData: any) => ({ ...prevData, selectedSession, decodedProgram}));
    }
  }, [selectedSession, setFormData, decodedProgram]);

  function calculateDuration(startDate: string, endDate: string): string {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));
    const days = duration.asDays();
    return `${Math.round(days + 1)} days`;
  }

  const notify = async (values: string) => {
    toast.warn(values);
  };

  const handleCardClick = (session: Session) => {
    toast.dismiss();
    const newSelectedSession = selectedSession === session ? null : session;
    setSelectedSession(newSelectedSession);
    if(newSelectedSession != null) {
      const rawData = sessionStorage.getItem('waitlistData');
      if(rawData){
        const { sessions } = JSON.parse(rawData);
        const sessionWaitlist = sessions.filter((item: any) => item.id === newSelectedSession.id);
        const isSessionFull = sessionWaitlist.some((item: any) => item.isSessionFull === true);
        if(isSessionFull) {
          notify(`We are excited to have you join this summer!
            Unfortunately at this stage, our ${decodedProgram} is fully enrolled,
            however you may continue your application in order to join our waitlist.
            No application fee / deposit will be charged. If a spot becomes available,
            we will contact you in order to confirm your continued interest prior to enrolling you.
            In addition, we suggest that you separately apply to your second choice for the upcoming summer,
            so as not to miss out on what will be an incredible experience. We look forward to seeing you this summer!`)
        }
      }
      
    }
  };

  return (
    <div style={{ marginTop: 45 }}>
    <h2 className='my-5 mx-3'>{decodedProgram}: {formData?.selectedProgram?.name}</h2>
    <ToastContainer style={{ width: "90vw" }}
              position="top-center"
              autoClose={false}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
      <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '30px',
      }}
    >
      {formData?.selectedProgram?.sessions?.length > 0 ? (
        <>
          {formData.selectedProgram.sessions.map((item: any, index: number) => (
            <div
              key={index}
              className={`session-list card mb-3 mx-3 ${selectedSession === item ? 'selected' : ''}`}
              onClick={() => handleCardClick(item)}
              style={{ cursor: 'pointer', width: '100%' }}
            >
              <div className="card-body">
                <div>
                  <strong>{item.sessionName}</strong>
                </div>
                <div>
                  <strong>Session date:</strong>{' '}
                  {moment.utc(item.start_date).format('MM/DD/YYYY')} -{' '}
                  {moment.utc(item.end_date).format('MM/DD/YYYY')}
                </div>
                <div>
                  <strong>Duration:</strong> {calculateDuration(item.start_date, item.end_date)}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        ''
      )}
    </div>
    </div>
  );
};

export default SessionPage;
