import { AxiosError } from 'axios'
import {IAuth} from './_models'
//import {AxiosResponse, AxiosError} from 'axios'

const AUTH_LOCAL_STORAGE_KEY = 'terraed-auth-credentials'
const PREV_AUTH_LOCAL_STORAGE_KEY = 'prev-terraed-auth-credentials'
const getAuth = (storageKey = AUTH_LOCAL_STORAGE_KEY): IAuth | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(storageKey)
  if (!lsValue) {
    return
  }

  try {
    const auth: IAuth = JSON.parse(lsValue) as IAuth
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const getAuthPromise = async (storageKey = AUTH_LOCAL_STORAGE_KEY): Promise<IAuth | undefined> => {
  return new Promise((resolve, reject) => {
    if (!localStorage) {
      resolve(undefined);
    }

    const lsValue: string | null = localStorage.getItem(storageKey);
    if (!lsValue) {
      resolve(undefined);
    }

    try {
      if (!lsValue) {
        throw new Error('No data in getAuthPromise method')
      }
      const auth: IAuth = JSON.parse(lsValue) as IAuth;
      if (auth) {
        // You can easily check auth_token expiration also
        resolve(auth);
      }
    } catch (error) {
      // console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
      reject(error);
    }
  });
};

const setAuth = (auth: IAuth, storageKey = AUTH_LOCAL_STORAGE_KEY) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(storageKey, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setAuthPromise = (auth: IAuth, storageKey = AUTH_LOCAL_STORAGE_KEY) => {
  return new Promise((resolve, reject) => {
    if (!localStorage) {
      reject("localStorage is not supported");
      return;
    }

    try {
      const lsValue = JSON.stringify(auth);
      localStorage.setItem(storageKey, lsValue);
      resolve(true);
    } catch (error) {
      console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
      reject(error);
    }
  });
};

const removeAuth = (storageKey = AUTH_LOCAL_STORAGE_KEY) => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(storageKey)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

// export function setupAxios(axios: any) {
//    axios.defaults.headers.Accept = 'application/json'
//    axios.interceptors.request.use(
//      (config: {headers: {Authorization: string}}) => {
//        const auth = getAuth()
//        console.log('set bearer', `Bearer ${auth?.api_token}`)
       
//        if (auth && auth.api_token) {
//          config.headers.Authorization = `Bearer ${auth.api_token}`
//        }

//        return config
//      },
//      (err: AxiosError) => Promise.reject(err)
//    )
// }

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, PREV_AUTH_LOCAL_STORAGE_KEY, setAuthPromise, getAuthPromise}
