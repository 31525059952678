import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { axiosPrivate } from '../../../../../axiosInstance'

interface IProps {
  oppId: number
}

const RemoveOpportunity = ({ oppId }: IProps) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleClose = () => {
    setShowModal(prev => !prev)
    setLoading(false)
    setErrorMessage('')
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const res: any = await axiosPrivate.post('remove_opportunity', { oppId })
      if (res.status && res.status === 200) {
        window.location.reload()
      }
    } catch (error) {
      setLoading(false)
      setErrorMessage('Error while removing opportunity. Please try again later.')
    }
  }

  return (
    <>
      <button className='btn btn-danger ms-4' onClick={handleClose}>Remove</button>
      <Modal show={showModal} onHide={handleClose} backdrop='static' keyboard={false} centered>
        <Modal.Body className='p-0'>
          <Modal.Header closeButton>
              <Modal.Title>Are you sure you want to remove this opportunity?</Modal.Title>
          </Modal.Header>
          <p style={{margin: '20px 50px 20px 23px', fontSize: '16px'}}>This will remove this opportunity. You cannot undo this action.</p>

          {errorMessage ? (
            <div className='card-body'>
              <div className='alert alert-danger d-flex align-items-center p-5 mx-6'>
                <div className='d-flex flex-column '>
                  <span>{errorMessage}</span>
                </div>
              </div>
            </div>
          ) : ''}
          </Modal.Body>
        
          <Modal.Footer style={{padding: '20px 30px'}}>
            <Button variant='secondary' onClick={handleClose}>
              Discard
            </Button>
            <Button 
              variant='danger' 
              className='ms-2' 
              onClick={onSubmit}
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Remove'}
            </Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}

export default RemoveOpportunity