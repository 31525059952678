/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from '../../core';
import { KTSVG } from '../../../helpers';
import { AsideMenu } from './AsideMenu';
import { useAuth } from '../../../../app/modules/auth';
import { useQueryClient } from 'react-query';
import './AsideDefault.scss'

const AsideDefault: FC = () => {
  const { config, classes } = useLayout();
  const asideRef = useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();
  const { aside } = config;

  const auth = useAuth();
  const [isMinimized, setIsMinimized] = useState(false);

  const minimize = () => {
    asideRef.current?.classList.add('animating');
    setTimeout(() => {
      asideRef.current?.classList.remove('animating');
      setIsMinimized((prev) => !prev); // Toggle minimized state
    }, 300);
  };

  const isLoading = auth.logoutServer?.isLoading;

  function onLogout() {
    auth.logoutServer?.mutate(null, {
      onSuccess: () => {
        localStorage.removeItem('profileModalShown');
        auth.logout();
        queryClient.clear();
      },
      onError: (err: any) => {
        if (err.response.status === 404) {
          auth.logout();
        }
      },
    });
  }

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      ref={asideRef}
    >
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
          {isMinimized ? (
            <h3 className='text-warning fw-bold my-0'>SSB</h3>
          ) : (
            <Link to={`/dashboard/${auth.currentUser?.currentClient}`}>
              <img
                src='https://newterraedu-staging.s3.us-west-1.amazonaws.com/static-images/SSB_LOGO.png'
                width={100}
                alt=''
              />
            </Link>
          )}
          {/* {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            onClick={minimize}
          >
            <KTSVG path={'/media/icons/duotune/arrows/arr080.svg'} className={'svg-icon-1 rotate-180'} />
          </div>
          )} */}

      </div>

     
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      <div className='aside-footer flex-column-auto pt-5 px-5' id='kt_aside_footer'>
        <button className='btn btn-custom w-100 position-relative tooltip-btn' 
          onClick={() => window.location.href = 'mailto:support@summerspringboard.com'}>
          <span className='btn-label px-2'>Contact Us</span>
          <span className='tooltip-text'>support@summerspringboard.com</span>
        </button>
      </div>

      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
        <button className='btn btn-custom w-100' onClick={onLogout}>
          {!isLoading ? (
            <>
              <span className='btn-label px-2'>Sign Out</span>
              <i className='fa-solid fa-right-from-bracket'></i>
            </>
          ) : (
            <>
              <span className='btn-label px-2' style={{ display: 'block' }}>
                Please wait...
              </span>
              <span className='spinner-border spinner-border-sm align-middle '></span>
            </>
          )}
        </button>
      </div>
      {/* end::Footer */}
    </div>
  );
};

export { AsideDefault };
