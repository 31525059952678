import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { axiosPrivate } from '../../axiosInstance';
import { currencyFormat } from '../../utils/funcHelpers';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
interface Props {
  program_uuid: string;
  ledger_id: string;
  refetch: () => {}
}

interface IInitialValues {
  program_uuid: string
  description: string
  price: number
}

const EditDiscountModal = ({ program_uuid, ledger_id, refetch }: Props) => {

  const [scholarship, setScholarship] = useState([]);
  const [selectedScholarship, setSelectedScholarship] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [isLoadingSubmit, setIsLodingSubmit] = useState(false);
  const [initialValues, setInitialValue] = useState<IInitialValues>({
    program_uuid: program_uuid,
    description: "",
    price: 0
  });
  useEffect(() => {
    const fetchScholarship = async () => {
      try {
        const response = await axiosPrivate.get(`/get-addon-info/${ledger_id}`);
        setScholarship(response?.data);
        if(response.status === 200) {
          const responseData = response.data;
          const {description, price, ...data} = initialValues;
          setInitialValue({
            ...data,
            description: responseData.description,
            price: Math.abs(responseData.paid)
          })
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchScholarship();
  }, [open]);
  const validateEdit = Yup.object().shape({
    program_uuid: Yup.string().required(),
    price: Yup.number().required('Scholaship Amount Is required')
  })
  const handleConfirm = async (values: IInitialValues) => {
    setIsLodingSubmit(true);
    try {
      const response = await axiosPrivate.post(`/edit-addons/${ledger_id}`, values);
      if (response.status === 200) {
        setSelectedScholarship(null);
      }
    } catch (error) {
      console.error('Error editing scholarship:', error);
    } finally {
      setIsLodingSubmit(false);
      setOpen(false);
    }
  };
  const handleDelete = async () => {
    try {
      setIsLodingSubmit(true);
      const response = await axiosPrivate.post(`/remove-discount/${ledger_id}`);
      if (response.status === 200) {
        setSelectedScholarship(null);
      }
    } catch (error) {
      console.log(error);
    }finally {
      refetch();
      setIsLodingSubmit(false);
      setOpen(false);
    }
  }
  return (
    <>
      <Button className='btn btn-hover' variant='primary' onClick={() => setOpen(true)}>Edit Scholarship</Button>
      <Modal backdrop='static' show={open} onHide={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Edit Scholarship</Modal.Title>
        </Modal.Header>
        <Formik 
          initialValues={initialValues}
          onSubmit={handleConfirm}
          validationSchema={validateEdit}
          enableReinitialize
        >
          <Form>
            <Modal.Body>
              <Field
                type='text'
                name='description'
                className='form-control form-control-solid mt-0'
                disabled
              />
              <div className='d-flex flex-column m-auto'>
                <p className='px-1 mb-0 mt-3'>Scholarship Amount</p>
                <Field
                  type='text'
                  name='price'
                  className='form-control form-control-solid mt-0'
                />
              </div>
            </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='secondary' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant='primary' type='submit' disabled={isLoadingSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default EditDiscountModal;
