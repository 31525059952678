import {Field, ErrorMessage} from 'formik'
import { COUNTRIES_ISO_3166_ALPHA_3 } from '../../../../../../utils'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import React, {useState} from 'react'
import {
  IPayOpt,
} from '../../../core/_models'

interface Props {
  //setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  currentFormikVal: IPayOpt
}

const PaybyCreditCard = ({currentFormikVal}:Props) => {
  const [selected, setSelected] = useState('')

  const changeSelectOptionHandler = (e: React.ChangeEvent<HTMLInputElement>): any => { 
    setSelected(e.target.value);
    currentFormikVal.creditCardInfo.billingStateProvince = ""
    currentFormikVal.creditCardInfo.billingCountry = e.target.value
  };

  return (
    <div className='row my-10'>
      <div className='col-lg-6'>
        <div className='d-flex flex-column mb-7 fv-row'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Name On Account</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title="Specify a card holder's name"
            ></i>
          </label>

          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder=''
            name='creditCardInfo.nameOnCard'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='creditCardInfo.nameOnCard' />
          </div>
        </div>

        <div className='d-flex flex-column mb-7 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>

          <div className='position-relative'>
            <Field
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter card number'
              name='creditCardInfo.cardNumber'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='creditCardInfo.cardNumber' />
            </div>

            <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                alt=''
                className='h-25px'
              />
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                alt=''
                className='h-25px'
              />
              <img
                src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                alt=''
                className='h-25px'
              />
            </div>
          </div>
        </div>

        <div className='row mb-10'>
          <div className='col-md-8 fv-row'>
            <label className='required fs-6 fw-bold form-label mb-2'>Expiration Date</label>

            <div className='row fv-row'>
              <div className='col-6'>
                <Field
                  as='select'
                  name='creditCardInfo.cardExpiryMonth'
                  className='form-select form-select-solid'
                >
                  <option></option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                </Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='creditCardInfo.cardExpiryMonth' />
                </div>
              </div>

              <div className='col-6'>
                <Field
                  as='select'
                  name='creditCardInfo.cardExpiryYear'
                  className='form-select form-select-solid'
                >
                  <option></option>
                  <option value='2021'>2021</option>
                  <option value='2022'>2022</option>
                  <option value='2023'>2023</option>
                  <option value='2024'>2024</option>
                  <option value='2025'>2025</option>
                  <option value='2026'>2026</option>
                  <option value='2027'>2027</option>
                  <option value='2028'>2028</option>
                  <option value='2029'>2029</option>
                  <option value='2030'>2030</option>
                  <option value='2031'>2031</option>
                </Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='creditCardInfo.cardExpiryYear' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>CVV</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Enter a card CVV code'
              ></i>
            </label>

            <div className='position-relative'>
              <Field
                type='text'
                className='form-control form-control-solid'
                minLength={3}
                maxLength={4}
                placeholder='CVV'
                name='creditCardInfo.cardCvv'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='creditCardInfo.cardCvv' />
              </div>

              <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-2hx' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div className='d-flex flex-column mb-7 fv-row'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Billing Address</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder=''
            name='creditCardInfo.billingAddress'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='creditCardInfo.billingAddress' />
          </div>
        </div>
        <div className='d-flex flex-column mb-7 fv-row'>
          <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
            <span className='required'>Billing City</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder=''
            name='creditCardInfo.billingCity'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='creditCardInfo.billingCity' />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>Billing Postal Code</span>
            </label>
            <Field
              type='text'
              className='form-control form-control-solid'
              placeholder=''
              name='creditCardInfo.billingPostalCode'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='creditCardInfo.billingPostalCode' />
            </div>
          </div>
          <div className='col-lg-4'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>Billing Country</span>
            </label>
            <Field
              as='select'
              name='creditCardInfo.billingCountry'
              className='form-select form-select-lg form-select-solid'
              onChange={changeSelectOptionHandler}
            >
              <option>Select Country</option>
              {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                return (
                  <option value={c.alpha_3} key={idx}>
                    {c.name}
                  </option>
                )
              })}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='creditCardInfo.billingCountry' />
            </div>
          </div>
          {selected && 
            <div className='col-lg-4'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>Billing State/Province</span>
            </label>
            <Field
              as='select'
              name='creditCardInfo.billingStateProvince'
              className='form-select form-select-lg form-select-solid'
            >
              <option>Select State/Province</option>
              {COUNTRIES_ISO_3166_ALPHA_3.map((c, idx) => {
                if (c.alpha_3 == selected) {
                  let provinces = c.divisions?.map((d, id) => {
                    return (
                      <option value={d.code} key={id}>
                        {d.name}
                      </option>
                    )
                  })
                  return provinces
                }
              })}
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='creditCardInfo.billingStateProvince' />
            </div>
          </div>
        }
        </div>
      </div>
    </div>
  )
}

export default PaybyCreditCard
