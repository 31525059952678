import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import UsersProvider from '../user-management/users-list/UsersProvider'
import PendingActionsList from './PendingActionsList'
import TransactionHistoryModal from './TransactionHistoryModal'

const PendingActions = () => {
  const {client} = useParams()

  const PendingActionsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Pending Actions Management',
      path: `/payment-tools/${client}/pending-actions/list`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route
        element={
          <UsersProvider>
            <Outlet />
          </UsersProvider>
        }
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={PendingActionsBreadCrumbs}>Pending Action List</PageTitle>
              <PendingActionsList />
            </>
          }
        />
        {/* <Route
          path='add-enrollment-group'
          element={
            <>
              <PageTitle breadcrumbs={PendingActionsBreadCrumbs}>Add Enrollment Group</PageTitle>
              <EnrollmentAdd />
            </>
          }
        /> */}
        {/* <Route
          path='list/:id'
          element={
            <>
              <PageTitle breadcrumbs={PendingActionsBreadCrumbs}>Transaction History</PageTitle>
              <TransactionHistoryModal />
            </>
          }
        /> */}
      </Route>
      <Route
        index
        element={<Navigate to={`/payment-tools/${client}/pending-actions/list`} />}
      />
    </Routes>
  )
}

export default PendingActions
