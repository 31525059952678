import React from 'react';
import { useParams, useLocation, Navigate } from 'react-router-dom';
import PredepartureUpload from '../../../modules/application/components/Dashboard/PredepartureUpload';
import MedicalInfo from './MedicalInfo';
import PredepartureInfo from './PredepartureInfo';
import PreprogramQuestionare from './PreprogramQuestionare';
import Waiver from './uploads/Waiver';
import PredepartureForm from './PredepartureForm';
import { IPredepartureItem } from '../../../modules/application/core/_models';
import { useAuth } from '../../../modules/auth';

interface CustomizedState {
  predeparture: IPredepartureItem;
}

const PredepartureItemPage = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { client, predepartureName, opportunity } = useParams();

  if (!state) {
    return <Navigate to={`/dashboard/${currentUser?.currentClient}`} />;
  }

  const { predeparture } = state;

  if (predeparture.title === 'upload') {
    return <PredepartureUpload predeparture={predeparture} />;
  }

  const itemPages: { [key: string]: JSX.Element } = {
    'emergency-contacts': <PredepartureInfo predeparture={predeparture} opportunity={opportunity || '-1'} />,
    'medical-info': <MedicalInfo predeparture={predeparture} opportunity={opportunity || '-1'} />,
    'pre-departure-info': <PredepartureInfo predeparture={predeparture} opportunity={opportunity || '-1'} />,
    'waiver': <Waiver predeparture={predeparture} />,
    'preprogram-questionnaire': <PreprogramQuestionare predeparture={predeparture} opportunity={opportunity || '-1'} />,
    'form': <PredepartureForm predeparture={predeparture} opportunity={opportunity || '-1'} />,
  };

  const selectedPage = itemPages[predeparture.title];

  return selectedPage;
};

export default PredepartureItemPage;
