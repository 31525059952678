import {Field, useField} from 'formik'
import {IProduct, IProductDetail, IProgram, IGeneralFee} from '../../core/_models'
import {useQuery, useQueryClient} from 'react-query'
import { scroller } from 'react-scroll'

interface Props {
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  toScroll?: (name: string) => void
  errors?: any
  checkCapacity?: (values: number) => void
  isLimit?: boolean
}

const Step2a = ({currentFormikVal, setFieldValue, toScroll, checkCapacity, errors, isLimit}: Props) => {
  const [field, meta, helper] = useField('academicTrack')
  const queryClient = useQueryClient()
  const productgroup_list = queryClient.getQueryData<{
    programs: IProduct[]
    general_fees: IGeneralFee[]
  }>('programsEnroll')

  const selected_productgroup =
    productgroup_list &&
    productgroup_list.programs.filter((p, i) =>
    i == currentFormikVal?.productgroup)[0]

  const selected_productgroup_details = selected_productgroup?.detail;

let selected_products: IProductDetail[] = []

selected_productgroup_details?.forEach((dt) => {
    if (currentFormikVal.product == dt.id) {
    selected_products.push(dt)
    }
})

  return (
    <div className='pb-10 pb-lg-15 text-dark'>
        <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
          STEP 2A: COURSES
        </h2>
      {/* begin::Body */}
      <div className='container'>
        {selected_products[0]?.program_track.map((pt) => {
            const track = JSON.stringify({
            program_id: pt.id.toString(),
            academic_track_id: pt.track_id.toString(),
            })
            return (
              <>
              {pt.status === 'active' ? (<div className='col-lg-12 d-flex align-items-center' key={pt.id}>
                    <Field
                        type='radio'
                        className='form-check-input me-2'
                        name='academicTrack'
                        style={{ border: '1px #009df7 solid', marginTop: 10}}
                        value={track}
                        id={'academicTrack_' + pt.id}
                        // onChange={(e: any) => checkSSBValidDate(e, track)}
                        onClick={() => {
                          setFieldValue('additionalOpt', undefined)
                          const isReq = selected_products[0].extras.find(
                            (item) => item.enrollment_extra_id === pt.required_extra_id
                          )
                          if (isReq) {
                            const additionalOptVal = JSON.stringify({
                              program_id: currentFormikVal.product,
                              additional: isReq.id,
                            })
                            setFieldValue('additionalOpt', [additionalOptVal])
                          }
                          if(checkCapacity) {
                            checkCapacity(pt.track_id);
                            setFieldValue('isLimit',isLimit);
                          }
                          scroller.scrollTo('enrollment-step2b', {
                            duration: 300,
                            smooth: true,
                            offset: -22
                          });
                          if (toScroll) {
                            toScroll('step2a')
                          }
                        }}
                    />
                    <label className='form-check-label' style={{ marginTop: 10}} htmlFor={'academicTrack_' + pt.id}>
                        {pt.name}
                    </label>
                </div>) : ''}
                

             </>
            )})
          }
      </div>
      {meta.touched && meta.error ? (
        <div className='text-danger text-center fs-5 my-5'>{meta.error}</div>
      ): ""}
    </div>
  )
}

export default Step2a
