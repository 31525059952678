import React, { useState, FC } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface ChangeEmailModalProps {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (newEmail: string) => void;
}

const ChangeEmailModal: FC<ChangeEmailModalProps> = ({ show, handleClose, handleSubmit }) => {
  const [newEmail, setNewEmail] = useState('');

  const handleEmailChange = () => {
    handleSubmit(newEmail);
    setNewEmail('');
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formNewEmail'>
            <Form.Label>New Email</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter new email'
              className='form-control-lg form-control'
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleEmailChange}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeEmailModal;
