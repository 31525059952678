import {Dispatch, SetStateAction, useState, useEffect} from 'react'
import {UseMutateAsyncFunction, useQueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {IPaymentSchedule} from '../../../application/core/_models'
import {IPortalClient, IStatus, useAuth} from '../../../auth'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import _queryKeys from './core/_queryKeys'
import {initPaymentSchedule, psSchema} from './core/_models'
import DatePicker from 'react-datepicker'
import {KTSVG, QUERIES, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import _ from 'lodash'
import moment from 'moment'
import {useQueryRequest} from '../../../Shared/core/QueryRequestProvider'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  paymentScheduleId?: string
  isLoading: boolean
  setMessage: Dispatch<SetStateAction<string>>
  updatePSByAdminAsync?: UseMutateAsyncFunction<any, unknown, any, unknown>
  addPSByAdminAsync?: UseMutateAsyncFunction<any, unknown, any, unknown>
}
const AddUpdatePaymentSchedule = ({
  paymentScheduleId,
  isLoading,
  setMessage,
  updatePSByAdminAsync,
  addPSByAdminAsync,
}: Props) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {state} = useQueryRequest()
  const [query] = useState<string>(stringifyRequestQuery(state))
  const {currentUser} = useAuth()
  const [openModal, setOpenModal] = useState<{[key: string]: any} | undefined>(undefined)
  const [dueDateVal, setDueDateVal] = useState<Date | null>(null)
  const psDetail = queryClient.getQueryData<{
    data: IPaymentSchedule
  }>(_queryKeys.getPaymentScheduleDetailReq)
  const portalClients = queryClient.getQueryData<IPortalClient[]>('getPrograms')
  const status = queryClient.getQueryData<{data: IStatus[]}>('getStatus')

  const [initDataForEdit, setInitDataForEdit] = useState<IPaymentSchedule>(initPaymentSchedule)
  //create init formik

  useEffect(() => {
    let dataForEdit = {...initPaymentSchedule}
    if (paymentScheduleId) {
      dataForEdit.name = psDetail?.data.name || initPaymentSchedule.name
      dataForEdit.value = psDetail?.data.value || initPaymentSchedule.name
      dataForEdit.type = psDetail?.data.type || initPaymentSchedule.type

      dataForEdit.due_dates = psDetail?.data.due_dates || initPaymentSchedule.due_dates
      dataForEdit.due_dates.sort(function (a, b) {
        return a.localeCompare(b)
      })
      dataForEdit.status_id = psDetail?.data.status_id || initPaymentSchedule.status_id
      dataForEdit.client_id = psDetail?.data.client_id || initPaymentSchedule.client_id
      setInitDataForEdit(dataForEdit)
    } else {
      initPaymentSchedule.due_dates = []
      setInitDataForEdit(initPaymentSchedule)
    }

    return () => {
      initPaymentSchedule.due_dates = []
      setInitDataForEdit(initPaymentSchedule)
    }
  }, [psDetail])

  const onSubmit = async (values: any, actions: FormikValues) => {
    actions.setSubmitting(true)
    try {
      if (paymentScheduleId && updatePSByAdminAsync) {
        console.log('updatePS', values)
        values.id = psDetail?.data.id
        const res = await updatePSByAdminAsync(values)
        if (res) {
          setMessage('Payment schedule has been updated successfully!')
        }
      } else {
        console.log('addPS', values)
        if (!addPSByAdminAsync) return
        const res = await addPSByAdminAsync(values)
        if (res) {
          setMessage('New Payment schedule has been added successfully!')
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      queryClient.prefetchQuery(`${QUERIES.PAYMENT_SCHEDULES_LIST}-${query}`)
      actions.setSubmitting(true)
    }
  }
  const removeDueDate = (
    index: number,
    currentFormikVal: IPaymentSchedule,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const currentDueDates = currentFormikVal.due_dates
    if (!_.isUndefined(index) && index > -1) {
      // only splice array when item is found
      currentDueDates?.splice(index, 1)
      setFieldValue('due_dates', currentDueDates)
    }
  }
  const onAddDueDateSubmit = (
    currentFormikVal: IPaymentSchedule,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const dueDate = moment(dueDateVal)
    if (dueDate.isValid()) {
      //console.log('due date', dueDate.format('MM/DD/YYYY'))
      const currentDueDates = currentFormikVal.due_dates
      currentDueDates.push(dueDate.format('MM/DD/YYYY'))
      currentDueDates.sort(function (a, b) {
        return a.localeCompare(b)
      })
      //console.log('currentDueDates', currentDueDates)

      setFieldValue('due_dates', currentDueDates)
      setOpenModal(undefined)
    } else {
      window.alert('Please Enter a valid date.')
    }
  }

  return (
    <>
      <Formik
        validationSchema={psSchema}
        initialValues={initDataForEdit}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({values, errors, setFieldValue, isSubmitting, validateForm}) => {
          console.log(values.value, '123123123')
          
          return (
            <Form>
              <div className='d-flex my-5'>
                {/* begin::Actions */}
                <div className='text-center'>
                  <button
                    type='reset'
                    onClick={() => {
                      navigate(
                        `/apps/${currentUser?.currentClient}/payment-schedules-management/payment-schedules`,
                        {replace: true}
                      )
                    }}
                    className='btn btn-secondary me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={isLoading}
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={isLoading}
                  >
                    {!isLoading && <span className='indicator-label'>Submit</span>}
                    {isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              <div className='card mb-7'>
                <div className='card-header'>
                  <h3 className='card-title'>Payment Schedule</h3>
                </div>
                <div className='card-body'>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>Name</label>
                    </div>
                    <div className='col-lg-8'>
                      <Field type='text' className='form-control form-control-md' name='name' />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='name' />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>Type</label>
                    </div>
                    <div className='col-lg-8'>
                      <Field as='select' name={'type'} className='form-select form-select-md'>
                        <option value='amount'>amount</option>
                        <option value='percentage'>percentage</option>
                      </Field>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='type' />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>
                        Client
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field as='select' name='client_id' className='form-select form-select-md'>
                        <option value=''>Select a client</option>
                        {portalClients?.map((p, i) =>
                          p.name !== 'terraEducation' ? (
                            <option value={p.reference_id} key={i}>
                              {p.name}
                            </option>
                          ) : null
                        )}
                      </Field>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='client_id' />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>
                        Status
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <Field as='select' name='status_id' className='form-select form-select-md'>
                        <option value=''>Select Status</option>
                        {status?.data.map((s, i) =>
                          s.type === 'general' ? (
                            <option value={s.id} key={i}>
                              {s.name}
                            </option>
                          ) : null
                        )}
                      </Field>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='status_id' />
                      </div>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      {/* begin::Label */}
                      <label className='required fw-bold fs-6required'>Due Dates</label>
                      {/* end::Label */}
                    </div>
                    <div className='col-lg-8'>
                      {/* begin::Input */}
                      {values.due_dates.map((d, i) => (
                        <div className='d-flex mb-5' key={i}>
                          <input
                            className='form-control form-control-md'
                            name='due_dates'
                            disabled={true}
                            value={d}
                          />
                          <button
                            type='button'
                            className='btn btn-icon-danger btn-text-danger'
                            onClick={() => removeDueDate(i, values, setFieldValue)}
                          >
                            <span className='svg-icon svg-icon-1'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-muted svg-icon-2hx'
                              />
                            </span>
                          </button>
                        </div>
                      ))}
                      <a
                        href='#'
                        className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm'
                        onClick={() => {
                          setDueDateVal(null)
                          setOpenModal({id: paymentScheduleId})
                        }}
                      >
                        + Add Due Date
                      </a>
                      {/* end::Input */}
                      <div className='fv-plugins-message-container text-danger'>
                        <ErrorMessage name='due_dates' />
                      </div>
                      {/* end::Input */}
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-lg-4 mb-5'>
                      <label className={'form-label fw-bolder text-dark fs-6 required'}>
                        Value
                      </label>
                    </div>
                    <div className='col-lg-8'>
                      <ReactQuill theme="snow" value={values.value} onChange={(value) => {
                        setFieldValue('value', value)
                      }} className=''/>
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='value' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openModal && (
                <>
                  <div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='true'
                  >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog modal-dialog-centered mw-650px'>
                      {/* begin::Modal content */}
                      <div className='modal-content'>
                        <div className='modal-header'>
                          {/* begin::Modal title */}
                          <h2 className='fw-bolder'>Add Due Date</h2>
                          {/* end::Modal title */}

                          {/* begin::Close */}
                          <div
                            className='btn btn-icon btn-sm btn-active-icon-primary'
                            data-kt-users-modal-action='close'
                            onClick={() => {
                              setOpenModal(undefined)
                            }}
                            style={{cursor: 'pointer'}}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon-1'
                            />
                          </div>
                          {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-10 my-7'>
                          <div className='fs-6'>
                            <div className='h-200px'>
                              <div className='row mb-7'>
                                <div className='col-lg-4 mb-5'>
                                  <label className={'form-label fw-bolder text-dark fs-6 required'}>
                                    Payment date
                                  </label>
                                </div>
                                <div className='col-lg-8'>
                                  <DatePicker
                                    className='form-control form-control-md'
                                    selected={dueDateVal}
                                    onChange={(val) => {
                                      setDueDateVal(val)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end::Modal body */}
                        <div className='modal-footer'>
                          <button
                            type='reset'
                            onClick={() => {
                              setOpenModal(undefined)
                            }}
                            className='btn btn-light'
                            data-bs-dismiss='modal'
                          >
                            Discard
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary'
                            onClick={() => {
                              onAddDueDateSubmit(values, setFieldValue)
                            }}
                          >
                            <span className='indicator-label'>Submit</span>
                          </button>
                        </div>
                      </div>
                      {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                  </div>
                  {/* begin::Modal Backdrop */}
                  <div className='modal-backdrop fade show'></div>
                  {/* end::Modal Backdrop */}
                </>
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default AddUpdatePaymentSchedule
