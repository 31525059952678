import React, { Dispatch, SetStateAction } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { IPaymentMethod } from '../../core/_models'

interface Props {
  header: string
  body: string
  setItem: Dispatch<
    SetStateAction<
      {
          modal?: string
          item: IPaymentMethod
        }
      | undefined
    >
  >
  pMethodLoading: boolean
  onPMethod: () => Promise<void>
}

const PaymentMethodModal = ({header, body, setItem, pMethodLoading, onPMethod} : Props) => {
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>{header}</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setItem(undefined)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-10 my-7'>
              <div className='fs-6'>{body}</div>
            </div>
            {/* end::Modal body */}
            <div className='modal-footer'>
              <button
                type='reset'
                onClick={() => setItem(undefined)}
                className='btn btn-light'
                data-bs-dismiss='modal'
              >
                Discard
              </button>
              <button type='button' disabled={pMethodLoading} className='btn btn-primary' onClick={() => onPMethod()}>
                {!pMethodLoading && <span className='indicator-label'>Submit</span>}
                {pMethodLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export default PaymentMethodModal