import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useAuth} from '../../auth'
import AddProductPage from './AddProductPage'
import ProductsList from './components/ProductsList'
import ProductsProvider from './components/ProductsProvider'
import UpdateProductPage from './UpdateProductPage'

const ProductsPage = () => {
  const {currentUser} = useAuth()
  const productsBreadcrumbs: Array<PageLink> = [
    {
      title: 'Products Management',
      path: `/apps/${currentUser?.currentClient}/products-management/products`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <ProductsProvider>
            <Outlet />
          </ProductsProvider>
        }
      >
        <Route
          path='products'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>Products list</PageTitle>
              <ProductsList />
            </>
          }
        />
        <Route
          path='edit/:productId'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>Edit Product</PageTitle>
              <UpdateProductPage />
            </>
          }
        />
        <Route
          path='new'
          element={
            <>
              <PageTitle breadcrumbs={productsBreadcrumbs}>New Product</PageTitle>
              <AddProductPage />
            </>
          }
        />
      </Route>
      <Route
        index
        element={
          <Navigate to={`/apps/${currentUser?.currentClient}/products-management/products`} />
        }
      />
    </Routes>
  )
}

export default ProductsPage
