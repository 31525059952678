import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaCircleCheck } from 'react-icons/fa6'

interface Props {
  open: boolean
  client?: string
  showPay: boolean
  close: () => void
  title: string
  checker: string
  accept: () => void
  statusId?: number
}

const NotifcationModal = ({open, client, showPay, close, title, checker = 'payment', accept, statusId = 0}: Props) => {
  const [loading, setLoading] = useState(false)
  const size = checker === 'payment' ? 'sm' : 'lg'
  const height = checker === 'payment' ? 300 : 100
  const currentIsCapacity = statusId === 18
  const declineClass = !currentIsCapacity ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-success'
  const movingToWaitlist = () => {
    setLoading(true)
    accept()
  }
  const checkerModal = (<Modal show={open}  backdrop='static' keyboard={false} size={size} centered>
  <Modal.Body style={{ minHeight: height,  padding: 30 }}>
    <div className='d-flex align-items-center gap-2'>
      <FaCircleCheck size={25} className='text-warning'/>
      <span className='fw-bold text-warning' style={{ fontSize: 18, fontWeight: 900}}>{title}</span>
    </div>
    {checker === 'payment' ? (  <div className='mt-5 card card-body'>
      <p className='m-0 p-0' style={{ fontSize: 13 }}>
        <b>Thank you for making a payment of ${showPay}!</b>
      </p>
      <p className='m-0 p-0' style={{ fontSize: 13 }}>
        This payment will reflect on your profile shortly, once confirmed by our banking partner.
      </p>
      <p className='p-0 mt-5' style={{ fontSize: 13 }}>
        If you have any questions please feel free to contact our team at {' '}
        <b>
          <a href={`mailto:${client === '47821104' ? 'info@summerspringboard.com' : 'info@teentravelnetwork.com'}`}>
            {client === '47821104' ? 'info@summerspringboard.com' : 'info@teentravelnetwork.com'}
          </a>
        </b>
      </p>
      <p className='p-0 m-0' style={{ fontSize: 13 }}>
        Kind regards,
      </p>
      <p className='p-0' style={{ fontSize: 13 }}>
        {client === '47821104' ? 'The Summer Springboard Team' : 'The Teen Travel Network Team'}
      </p>
      <button className='btn btn-sm btn-success' onClick={close}>
        Okay!
      </button>
    </div>
    ) : checker === 'capacity' ? (
      <>
        <p className='mt-5 p-0' style={{ fontSize: 16 }}>
          <b>Unfortunately, this course is now full capacity. Would you like to be added to the waitlist?</b>
        </p>
      </>
    ) : (
      <>
        <p className='mt-5 p-0' style={{ fontSize: 16 }}>
          <b>{!currentIsCapacity ? 'This class is full capacity. Please adjust Capacity numbers in order to update Application Status.' : `Please use the Waitlist Management Tab to update this student's status!`}</b>
        </p>
      </>
    )}
  </Modal.Body>
    {
      checker !== 'payment' ? (
        <Modal.Footer>
          {checker === 'capacity2' ? (
            <button className='btn btn-sm btn-danger' onClick={close}>
              Close
            </button>
          ) : !currentIsCapacity ? (
            <button className='btn btn-sm btn-success' disabled={loading} onClick={movingToWaitlist}>
            {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : 'Add to Waitlist!'}
            </button>) : ''}
          {checker !== 'capacity2' ? (
            <button className={declineClass} onClick={close}>
              {  !currentIsCapacity ? 'No thanks' : 'Okay!'}
            </button>
          ) : ''}
        </Modal.Footer>
      ) : ''
    }
  </Modal>)
  const inactiveSessionModal = (
    <Modal show={open}  backdrop='static' keyboard={false} size={size} centered>
      <Modal.Body style={{ minHeight: height,  padding: 30 }}>
        <div className='d-flex align-items-center gap-2'>
          <FaCircleCheck size={25} className='text-warning'/>
          <span className='fw-bold text-warning' style={{ fontSize: 18, fontWeight: 900}}>{title}</span>
        </div>
        <>
          <p className='mt-5 p-0' style={{ fontSize: 16 }}>
            <b>Sorry this course is no longer available! Please choose another course!</b>
          </p>
        </>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-sm btn-danger' onClick={close}>
            Close
        </button>
      </Modal.Footer>
      </Modal>
  )
  
  return (
    <>
      {checker !== 'inactiveSession' ? checkerModal : inactiveSessionModal}
    </>
  )
}

export default NotifcationModal