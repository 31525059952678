import {useEffect, useMemo, useState} from 'react'
import {useAuth} from '../../auth'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import { getAllEnrollmentFormGroup } from '../../../../network/api'
import Header from '../../../../_metronic/shared-components/Header'
import DynamicTableV2 from '../../../../_metronic/shared-components/dynamic-table-v2'


interface IPortalClient {
  id?: number
  name?: string
  reference_id?: string
  abbreviation?: string
  status_id?: number 
}
export interface IEnrollmentFormGroup {
  name: string
  description?: string
  client_id?: string
  status?: string | boolean
  title?: string
  type?: string
  route_name?: string | null
  portal_client?: IPortalClient | null
  task_type?: string
  custom_input?: string | number
  selected_items?: any
  is_selected_all?: any
  selected_programs?: any
  selected_sessions?: any
  task_deadline?: string
  task_overview?: string
  instruction?: string
  sf_api_name_date?: string
  sf_api_name_status?: string
}

const EnrollmentList = () => {
  // STATES
  const [enrollmentGroup, setEnrollmentGroup] = useState<IEnrollmentFormGroup[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [visibleData, setVisibleData] = useState<any[]>([])
  const [search, setSearch] = useState('')
  
  // HOOKS
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const params = useParams()
  
  const editEnrollmengGroup = (e: any, id: any) => {
    navigate(
      `/apps/${currentUser?.currentClient}/enrollment-group-management/edit-enrollment-group/${id}`
    )
  }

  const handleOpenAction = (id: any) => {
    console.log(id, 'id')
    navigate(`/apps/${params.client}/enrollment-group-management/enrollment-group`)
  }

  useEffect(() => {
    const getAllEnrollmentGroup = async () => {
      setLoading(true)
      try {
        const {data} = await getAllEnrollmentFormGroup(params.client)
        data.sort((a: any, b: any) => b.id - a.id)
        setEnrollmentGroup(data)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    getAllEnrollmentGroup()
  }, [])

  useEffect(() => {
    const filterEnrollement = search.length !== 0 
    ? enrollmentGroup.filter(item => item.name?.toLowerCase().includes(search.toLowerCase())) 
    : enrollmentGroup

    setVisibleData(filterEnrollement)
  }, [enrollmentGroup, search])
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Client_ID',
        accessor: 'portal_client.name',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Title',
        accessor: 'title',
        Cell: (props: any) => {
          const title = props?.row?.original?.title  
          return title || 'N/A'
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({value}: any) => (
          <div
            style={{
              backgroundColor: value === 'active' ? '#e8fff3' : '#ffe8e8',
              color: value === 'active' ? '#50cd89' : '#cd5050',
              padding: '0.5rem',
              borderRadius: '0.25rem',
              minWidth: value === 'active' ? 60 : 70,
              maxWidth: value === 'active' ? 60 : 70,
            }}
          >
            {value === 'active' ? 'Active' : 'Inactive'}
          </div>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'id',
        disableSortBy: true,
        Cell: (props: any) => (
          <>
            <a
              href='javascript:void(0)'
              onClick={() => {
                handleOpenAction(props.value)
              }}
              className='btn btn-light btn-active-light-primary btn-sm d-flex flex-row'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              id={`action_button_` + props.value}
            >
              Actions
              <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
              id={'subMenu_' + props.value}
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  onClick={(e) => {
                    editEnrollmengGroup(e, props.value)
                  }}
                >
                  Edit
                </a>
              </div>
            </div>
          </>
        ),
      },
    ],
    []
  )

  return (
    <>
      <div className='card card-body p-0'>
        <Header
          buttonLink={`/apps/${currentUser?.currentClient}/enrollment-group-management/add-enrollment-group`}
          buttonText='Add Enrollment Group'
          placeholder='Search Enrollment Group'
          setSearch={setSearch}
          search={search}
        />
        <DynamicTableV2 columns={columns} data={visibleData} loading={loading}/>
      </div>
    </>
  )
}

export default EnrollmentList
