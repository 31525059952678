import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../../auth"
import useCurrentClientRoute from "../../auth/hooks/useCurrentClientRoute"
import GLALogo from "../assets/gla-logo.png"
import SSBLogo from "../../../../assets/Logo/SSB_Logo_Horiz_Hi.png"
import TTNLogo from "../assets/ttn_logo.png"
import moment from "moment"


const Nav = () => {
    const {currentUser, logout, logoutServer} = useAuth()
    const {currentClientRoute} = useCurrentClientRoute()

    const navigate = useNavigate()
    const location = useLocation()
    function onLogout() {
      //execute to call logout API
      logoutServer?.mutate(null, {
        onSuccess: () => {
          //remove in local
          logout()
        },
        onError: (err:any)=>{
          if(err.response.status === 404){
            logout()
          }
        }
      })
    }
  return (
    <div className='d-flex justify-content-between align-items-center container py-8'>
      {currentClientRoute === '84984438' ? (
        <div className='gla-logo'>
          <img
            src={GLALogo}
            className='img-fluid w-300px'
            alt='Global Leadership Adventures Logo'
            height={28}
          />
        </div>
      ) : (currentClientRoute === '47821104' ? (
        <img src={SSBLogo} alt='Summer Springboard Logo' height={50}/>
      ) : ( 
        <div className='ttn-logo'>
          <img src={TTNLogo} className='img-fluid' alt='Teen Travel Network Logo' height={28} />
        </div>
      ))}

      <div className='d-flex gla-r-menu fw-bold fs-4'>
        {currentUser ? (
          <>
            <a href='#' onClick={onLogout} className='menu-link px-5' role='button'>
              <div className='me-5'>Log Out</div>
            </a>
            <Link to={`/dashboard/${currentUser.currentClient}`}>
              <div className=''>Dashboards</div>
            </Link>
          </>
        ) : (
          <>
            {/* <a
              href=''
              onClick={() => navigate('/auth', {state: {from: location}, replace: true})}
              className='menu-link px-5'
            >
              Log In
            </a> */}
            <Link to={'/auth'}>
              <div className='me-5'>Log In</div>
            </Link>
            <Link to={'/enroll'}>
              <div className=''>Enrollment</div>
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

export default Nav