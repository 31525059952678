import {Field, ErrorMessage} from 'formik'
import {
  IProduct,
  IProductDetail,
  IProgram,
  IGeneralFee,
  IAcademicTrack,
} from '../../core/_models'
import {currencyFormat} from '../../../../../utils/funcHelpers'
import {useQueryClient} from 'react-query'
import moment from 'moment'

interface Props {
  currentFormikVal: IProgram
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const Step2b = ({currentFormikVal, setFieldValue}: Props) => {
  const queryClient = useQueryClient()
  const selected_productgroup = queryClient.getQueryData<{
    programs: IProduct[]
    general_fees: IGeneralFee[]
  }>('programsEnroll')
    
  let selected_products: IProductDetail[] = []

  selected_productgroup?.programs.forEach((pd) =>
    pd.detail.forEach((dt) => {
      if (currentFormikVal.product == dt.id) {
        selected_products.push(dt)
      }
    })
  )

  const selected_session = currentFormikVal.academicTrack ? JSON.parse(currentFormikVal.academicTrack) : null
  const selected_program = selected_products && selected_products.length > 0
  ? selected_products[0].program_track
    ? selected_products[0].program_track.find((item) => item.id === Number(selected_session?.program_id))
    : null
  : null

  const sorting = (a: any, b: any) => {
    const condition1 = b.is_required - a.is_required;
    const condition2 = a.enrollment_extra_id === selected_program?.required_extra_id 
    ? -1 : b.enrollment_extra_id === selected_program?.required_extra_id ? 1 : 0;
    return condition1 || condition2;
  }

  const updateExtras = (e: any, setVal: any) => {
    setFieldValue('additionalOpt', setVal)
  }

  return (
    <div className='pb-10 pb-lg-15 text-dark'>
      <h2 className='fw-bolder text-dark mb-10 fs-xl-2x text-center text-uppercase'>
        STEP 2B: TUITION SUPPLEMENTS
      </h2>
      <p>
        Please select from these optional add-ons below. If you are flying to our program and using your airline company's {' '}
        <b>Unaccompanied Minor service</b>, you are required to also purchase the unaccompanied minor <b>Airport pick-up</b> and <b>Drop-off service</b>. 
        The following courses have a required <b>$250</b> course supplement: <b>Advanced Engineering and Internet of Things</b>, <b>Emergency Medicine</b>, <b>Pre-Medicine</b>, <b>Fundamentals of Engineering</b>, <b>Architecture</b>, <b>3D CAD Design</b>, and <b>Veterinary Science</b>.
      </p>
      {/* begin::Body */}
      <div className='container'>
        {selected_products?.map((pd, i) => (
          <div className='row' key={i}>
            <span className='fs-5 my-5'>
              <i className='text-info'>
                <b>
                  {pd?.name} {'(' + moment(pd?.startDate).format('MM/DD/YYYY') + ' - ' + moment(pd?.endDate).format('MM/DD/YYYY') + ')'}
                </b>
              </i>{' '}
            </span>
            {pd.extras.sort(sorting).map((ext, k) => {
              const additionalOptVal = JSON.stringify({
                program_id: pd.id.toString(),
                additional: ext.id,
              })
              return (
                <div className='col-lg-12' key={k}>
                  {/* **QUESTION: Is there a way to do this without if/else? */}
                  {ext.is_required ? (
                    <Field
                      type='checkbox'
                      className='form-check-input me-2 fw-bolder'
                      name='additionalOpt'
                      value={additionalOptVal}
                      id={'additionalOpt_' + ext.id}
                      checked={ext.is_required}
                      disabled={ext.is_required}
                    />
                  ) : (
                    <Field
                      type='checkbox'
                      className='form-check-input me-2 fw-bolder'
                      name='additionalOpt'
                      value={additionalOptVal}
                      id={'additionalOpt_' + ext.id}
                      onClick={(e: any) => updateExtras(e, additionalOptVal)}
                      {...(ext.enrollment_extra_id === selected_program?.required_extra_id ? {checked: true} : {})}
                      {...(ext.enrollment_extra_id === selected_program?.required_extra_id ? {disabled: true} : {})}
                    />
                  )}

                  <label className='form-check-label fw-bolder' htmlFor={'additionalOpt_' + ext.id}>
                    {ext.enrollment_extra?.name} ({' '}
                    {currencyFormat.format(ext.enrollment_extra?.amount)} )
                  </label>
                </div>
              )
            })}
          </div>
        ))}

        <div className='text-danger text-center fs-5 my-5'>
          <ErrorMessage name='student_type' />
        </div>
      </div>
    </div>
  )
}

export default Step2b
