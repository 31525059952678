import { AxiosResponse } from 'axios'
import React from 'react'
import { ID } from '../../../../../../_metronic/helpers'
import useAxiosPrivate from '../../../../auth/hooks/useAxiosPrivate'
import { User, UsersQueryResponse } from '../core/_models'

const useUsersRequests = () => {
  const axisosPrivate = useAxiosPrivate()
  const getUsersHook = (query: string): Promise<UsersQueryResponse> => {
    return axisosPrivate
      .get(`auth/get_all_users?${query}`)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }
  const addUserReq = (user: User): Promise<UsersQueryResponse> => {
    return axisosPrivate
      .post(`/auth/add_user_by_admin`, user)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  const updateUserReq = (user: User): Promise<UsersQueryResponse> => {
    return axisosPrivate
      .put(`/auth/update_user_by_admin`, user)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }
  const deleteUserReq = (userId: ID): Promise<void> => {
    return axisosPrivate.delete(`/auth/delete_user_by_admin/${userId}`).then(() => {})
  }
  return {getUsersHook, addUserReq, updateUserReq, deleteUserReq}
}

export default useUsersRequests